import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';

import { PatientService, Patient } from "telescan-core";
import {RootState} from '../reducers';
import {loadSuccess} from '.';
import { loading, failure } from './general_actions';


export function loadPatienten(): ThunkAction<Promise<Patient[]>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const patientService: PatientService = new PatientService();
        return new Promise((resolve, reject) => {
            patientService.all().then(
                (patienten) => {
                    dispatch(loadSuccess(loadInstanceId));
                    resolve(patienten);
                }
            ).catch(
                (response) => {
                    dispatch(failure(loadInstanceId, response));
                    reject(response);
                }
            );
        });
    }
}

/* export function checkVernetzungStatus(konsil: Konsil, patient: Patient): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove

        const patientService: PatientService = new PatientService();
        if (patient.egkNummer !== "" && patient.egkNummer) {
            patientService.checkTeilnehmerVernetzung(patient?.egkNummer).then(
                (response) => {
                    if (konsil.patienteneinwilligung)
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    else {
                        konsil.patienteneinwilligung = new Patienteneinwilligung();
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    }
                    dispatch(updateKonsil(konsil));
                    dispatch(loadSuccess(loadInstanceId));
                }
            ).catch(
                (response) => {
                    if (konsil.patienteneinwilligung)
                        konsil.patienteneinwilligung.einwilligungKonsil = false;
                    else {
                        konsil.patienteneinwilligung = new Patienteneinwilligung();
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    }
                    dispatch(updateKonsil(konsil));
                    dispatch(failure(loadInstanceId, String(response)));
                }
            );
        } else
            dispatch(failure(loadInstanceId, "Keine eGK-Versichertennummer angegeben."));
    }
}
 */