import React from 'react';
import {RootState} from '../../redux/reducers';
import {getHPMInfo} from '../../redux/actions';
import {connect, ConnectedProps} from 'react-redux'
import {Box, Tooltip} from '@material-ui/core';
import {VerifiedUser, Loop} from '@material-ui/icons';
import { HPMConnectionStatus } from 'telescan-core';

interface IState {
    info: HPMConnectionStatus;
}

const mapDispatchToProps = {
    dispatch_getHPMInfo: () => getHPMInfo()
}
const mapStateToProps = (state: RootState, ownProps: any) => ({
    path: state.router.location.pathname,
    ...ownProps
})
const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class HpmBadge extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            info: new HPMConnectionStatus(),
        };
        this.timeoutFunction = this.timeoutFunction.bind(this);
    }

    componentDidMount() {
        this.timeoutFunction();
    }


    timeoutFunction() {
        this.props.dispatch_getHPMInfo()
        .then((info: HPMConnectionStatus) => {
            if (this.props.path === "/login" || this.props.path.includes("/konsil"))
                this.setState({info: info});
        })
        .catch(() => setTimeout(this.timeoutFunction, 10000));
    }

    render() {
        if (this.state.info?.status === "ok")
            return (
                <Tooltip title={this.state.info?.status || ""}>
                    <Box className="status-badge hpm-ok">HPM Status: <VerifiedUser/></Box>
                </Tooltip>
                )
        else 
            return (
                <Tooltip title="unbekannt, versuche Status abzufragen">
                    <Box className="status-badge hpm-loading">HPM Status: <Loop/></Box>
                </Tooltip>
                )
    }
}

export const HpmBadgeContainer = connector(HpmBadge);
export default HpmBadgeContainer;
