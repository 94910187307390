import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, Paper } from "@material-ui/core";
import { ViewText } from '../konsil/viewComponents/view_text'
import { RootState } from "../../redux/reducers";
import { getInitialisationStatus } from "../../redux/actions";


const mapStateToProps = (state: RootState) => ({
    initialisationStatus: state.user.initialisationStatus,
})

const mapDispatchToProps = {
    dispatch_getInitialisationStatus: () => getInitialisationStatus(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class Zusammenfassung extends React.Component<TPropsFromRedux> {

    componentDidMount() {
        this.props.dispatch_getInitialisationStatus();
    }
    
    render() {
        return (
            <Box className="main-content initialisation-content initialisation-overview">
                <form className="form-content" noValidate autoComplete="off">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Zusammenfassung
                    </Typography>
                    <Paper className="bs-infos-card" elevation={0}>
                        <Box className="bs-left">
                            <ViewText labelVariant='subtitle1'
                                label='BSNR'
                                text={localStorage.getItem("BSNR") || "-"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='LANR'
                                text={localStorage.getItem("LANR") || "-"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='TeleScan-Variante'
                                text={(this.props.initialisationStatus.standalone)? "Standalone": "PVS-basiert"}
                            />
                        </Box>
                        <Box className="bs-right">
                            <ViewText labelVariant='subtitle1'
                                label='Betriebsstätten-Daten erfasst'
                                text={(this.props.initialisationStatus.betriebsstaetteErfasst)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='Arzt-Daten erfasst'
                                text={(this.props.initialisationStatus.arztErfasst)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='Passwort geändert'
                                text={(this.props.initialisationStatus.passwortErfasst)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='Recovery-Code erzeugt'
                                text={(this.props.initialisationStatus.recoveryCodeErzeugt)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='Betriebsstätten-Zertifikat erzeugt'
                                text={(this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='Arzt-Zertifikat erzeugt'
                                text={(this.props.initialisationStatus.arztZertifikatErzeugt)? "JA": "NEIN"}
                            />
                            <ViewText labelVariant='subtitle1'
                                label='HPM installiert'
                                text={(this.props.initialisationStatus.hpmInstalliert)? "JA": "NEIN"}
                            />
                        </Box>
                    </Paper>
                </form>
        </Box>
        )
    }
}

export const ZusammenfassungComponent = connector(Zusammenfassung);
export default ZusammenfassungComponent;