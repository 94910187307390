import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import { Patient, KonsilService, Konsil, Failure } from 'telescan-core';
import {RootState} from "../../redux/reducers";
import uuid from 'uuid';

// material components
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputBase from "@material-ui/core/InputBase";
import { Divider, ListItem, Typography, Card, CardContent, CardMedia, List, Tooltip } from '@material-ui/core';

// Icons
import SearchIcon from "@material-ui/icons/Search";
import { Person, Cancel, Help } from '@material-ui/icons';
import { KonsilPatient } from '../konsilliste';
import {loadFailure, loadArztKonsile} from '../../redux/actions';
import SimpleModalContainer from '../elements/simple_modal';
import { calculateAge } from '../../utils';

function countInPatientArray(array: Patient[], what: Patient) {
    return array.filter(item => item.id === what.id).length;
}

interface IState {
    open: boolean;
    showSearchBar: boolean;
    selectedPatient: Patient | undefined;
    unmergedKonsile: {pvsPatient: Patient[], konsil: Konsil}[];
    pvsPatients: Patient[];
    active: boolean;
    konsil: Konsil | undefined;
    konsilPvsMap: Map<Konsil, Patient>;
    searchValue: string;
    //filteredPatienten: Patient[];
}

interface IProps {
    open: boolean;
    onClose: any;
    patienten: Patient[];
    unmergedKonsile: {pvsPatient: Patient[], konsil: Konsil}[];
    getUnmergedKonsileCount: () => void;
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    isDesktop: state.general.isDesktop,
    userDetails: state.user.user,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loadKonsile: () => loadArztKonsile(),
    loadFailure: (id: string, failure: Failure) => loadFailure(id, failure),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class PvsPatientSelect extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
           open: props.open,
           showSearchBar: false,
           selectedPatient: undefined,
           unmergedKonsile: this.props.unmergedKonsile,
           pvsPatients: [],
           active: false,
           konsil: undefined,
           konsilPvsMap: new Map(),
           searchValue: "",
        };

        this.selectPatient = this.selectPatient.bind(this);
        this.saveForMerging = this.saveForMerging.bind(this);
        this.mergePatients = this.mergePatients.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (prevState.open !== this.props.open) {
            this.setState({
                open: this.props.open
            });
        }
        if (prevProps.unmergedKonsile !== this.props.unmergedKonsile) {
            this.setState({
                unmergedKonsile: this.props.unmergedKonsile
            });
        }
    }

    selectPatient(active: boolean, unmergedKonsil: {pvsPatient: Patient[], konsil: Konsil}): void {
        // deal with non-unique patients
        let pvsPatients = unmergedKonsil.pvsPatient;
        //pvsPatients.forEach((patient) => {if (countInPatientArray(pvsPatients, patient) > 1) patient.id = uuid.v4()})
        pvsPatients.forEach((patient, index) => {if (countInPatientArray(pvsPatients, patient) > 1) pvsPatients.splice(index, 1)})

        if (active === true) {
            this.setState({
                selectedPatient: undefined,
                konsil: undefined
            });
        } else {
            this.setState({
                selectedPatient: unmergedKonsil.konsil.konsilPatient,
                konsil: unmergedKonsil.konsil,
                pvsPatients: (unmergedKonsil.konsil && this.state.konsilPvsMap.has(unmergedKonsil.konsil))? [this.state.konsilPvsMap.get(unmergedKonsil.konsil) || new Patient()]: unmergedKonsil.pvsPatient,
            });
        }
    }

    saveForMerging(pvsPatient: Patient): void {
        if (this.state.konsil) {
            let konsilPvsMap = this.state.konsilPvsMap;
            if (konsilPvsMap.get(this.state.konsil) === pvsPatient)
                konsilPvsMap.delete(this.state.konsil);
            else if (konsilPvsMap.get(this.state.konsil) && konsilPvsMap.get(this.state.konsil) !== pvsPatient) {
                konsilPvsMap.delete(this.state.konsil);
                konsilPvsMap.set(this.state.konsil, pvsPatient);
            } else
                konsilPvsMap.set(this.state.konsil, pvsPatient);
            this.setState({
                konsilPvsMap: konsilPvsMap
            });
        }

    }

    mergePatients() {
        this.state.konsilPvsMap.forEach((pvsPatient, konsil) => {
            const id: string = uuid.v4();
            const konsilService: KonsilService = new KonsilService();
            if (konsil.id && pvsPatient.id) {
                konsilService.mergeKonsilPvsPatient(konsil.id, pvsPatient.id)
                .then(() => {
                    this.setState({
                        selectedPatient: undefined,
                        konsil: undefined,
                        pvsPatients: [],
                    });
                })
                .then(() => {
                    this.props.getUnmergedKonsileCount();
                    this.props.dispatch_loadKonsile(); // try to fix bug: unmerged symbol stays in konsil_uebersicht
                }).catch(
                    (error) => {
                        this.props.loadFailure(id, error);
                    }
                )}
        })
    }

    handleSearchValue = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }

    handlePatientenSearchChange = (e: any) => {
        this.handleSearchValue(e);
        let currentList: Patient[] = this.props.patienten;
        let newList: Patient[] = [];

        if (e.target.value !== "") { //search bar not empty
            newList = currentList.filter(item => {
                let patientInfo: string = [(item.vorname !== "")? item.vorname: undefined , (item.nachname !== "")? item.nachname: undefined, (item.geburtsdatum !== "")? new Date(item.geburtsdatum).toLocaleDateString(): undefined].filter(Boolean).join(" ");
                const lc = patientInfo.toLowerCase();
                // change search term to lowercase to deal with capitalization
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            this.setState({
                pvsPatients: newList
            });
        } else { // search bar is empty
            newList = []
            this.setState({
                pvsPatients: newList
            });
        }
    }


    renderModalContentRightSide(pvsPatients: Patient[], selectedPatient: Patient | undefined) {
        if (pvsPatients.length === 0 && selectedPatient !== undefined)
            return (
                <Typography className="no-content" variant="caption" color="inherit" style={{width: "300px"}}>
                    Keine Daten gefunden. Bitte übertragen Sie Patientendaten aus Ihrem PVS oder nutzen Sie die erweiterte Suche.
                </Typography>
            )
        else if (pvsPatients.length === 1 && selectedPatient !== undefined)
            return (
                <List component="div" className="results multiple-patients">
                    <ListItem component="div" className={((this.state.konsil && this.state.konsilPvsMap.get(this.state.konsil) === pvsPatients[0]) || false)? "result selected": "result"}>
                    <CardComponent konsil_id={this.state.konsil?.id} isUniqueMatch={true} patient={pvsPatients[0]}
                            isSelected={(this.state.konsil && this.state.konsilPvsMap.get(this.state.konsil) === pvsPatients[0]) || false}
                            saveForMerging={this.saveForMerging}/>
                    </ListItem>
                </List>
            )
        else if (pvsPatients.length > 1 && selectedPatient !== undefined)
            return (
                <List component="div" className="results multiple-patients">
                    {pvsPatients.map((patient) => {
                        const isSelected: boolean = (this.state.konsil && this.state.konsilPvsMap.get(this.state.konsil) === patient) || false;
                        return (
                            <ListItem  key={patient.id} component="div" className={(isSelected)? "result selected": "result"}>
                                <CardComponent konsil_id={this.state.konsil?.id} isUniqueMatch={false}
                                                patient={patient} isSelected={isSelected} saveForMerging={this.saveForMerging}/>
                            </ListItem>
                        )}
                    )}
                </List>
            )
        else
            return(
                <Typography className="no-content" variant="caption" color="inherit">
                    Bitte links einen Patienten wählen.
                </Typography>
            )
    }

    render() {
        return (
                <SimpleModalContainer isOpen={this.state.open} onClose={this.props.onClose}>
                    <Paper className="modal-wrapper modal-pvs-patient-select">
                        <Box className="modal-left">
                            <Box className="modal-header">
                                <h2>Patientzuweisung</h2>
                            </Box>
                            <Box className="modal-content">
                                <List component="div" className="results">
                                    {this.state.unmergedKonsile.map((unmergedKonsil) =>
                                    <ListItem key={uuid.v4()} component="div" className={(this.state.selectedPatient?.id === unmergedKonsil.konsil.konsilPatient.id)? "result active": "result"} onClick={() => {this.selectPatient((this.state.selectedPatient?.id === unmergedKonsil.konsil.konsilPatient.id), unmergedKonsil)}} >
                                        <KonsilPatient patient={unmergedKonsil.konsil.konsilPatient} />
                                        <Typography variant="caption" color="inherit">eGK-Nr: {unmergedKonsil.konsil.konsilPatient.egkNummer}</Typography>
                                        <Typography className="date" variant="caption" color="inherit" >{(unmergedKonsil.konsil.konsilPatient.geburtsdatum)? new Date(unmergedKonsil.konsil.konsilPatient.geburtsdatum).toLocaleDateString(): "Kein Geburtsdatum hinterlegt"}</Typography>
                                    </ListItem>
                                    )}
                                </List>
                            </Box>
                            <Divider/>
                            <Box className="modal-footer">
                                <Box style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                                    <Typography className="pad-bottom" variant="caption" color="inherit">
                                        {this.state.konsilPvsMap.size} Patienten für die Zuweisung vorgemerkt.
                                    </Typography>
                                    <Box>
                                        <Button className="add-konsil slim" variant="contained" color="primary" size="small" onClick={() => {this.mergePatients(); this.props.onClose();}}>
                                            Zuweisen
                                        </Button>
                                        <Button className="add-konsil slim" variant="text" size="small" onClick={() => {this.props.onClose();}}>
                                            Abbrechen
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="modal-right">
                            <Box className="modal-header">
                            {(!this.state.showSearchBar && !(this.state.pvsPatients && this.state.pvsPatients.length === 0 && this.state.selectedPatient !== undefined)) &&
                                <h2>Im PVS gefunden
                                    <IconButton size="small" aria-label="Suche Patienten" onClick={
                                        () => this.setState({
                                            showSearchBar: true
                                        })
                                    } >
                                        <SearchIcon />
                                    </IconButton>
                                </h2>
                            }
                            {(this.state.showSearchBar || (this.state.pvsPatients && this.state.pvsPatients.length === 0 && this.state.selectedPatient !== undefined)) &&
                                <Box className="search-container" style={{alignSelf: "center"}} >
                                    <IconButton
                                        type="submit"
                                        size="small"
                                        className=""
                                        aria-label="search"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase
                                        className="search-input"
                                        placeholder="Suche"
                                        inputProps={{ "aria-label": "Suche" }}
                                        value = {this.state.searchValue}
                                        onChange={this.handlePatientenSearchChange}
                                    />
                                    <IconButton
                                        size="small"
                                        className=""
                                        aria-label="cancel"
                                        onClick={() => {
                                            this.setState({
                                                showSearchBar: false,
                                                pvsPatients: [],
                                                searchValue: ""
                                            });
                                        }}
                                    >
                                        <Cancel />
                                    </IconButton>
                                </Box>
                            }
                            </Box>
                            <Box className="modal-content">
                                {this.renderModalContentRightSide(this.state.pvsPatients, this.state.selectedPatient)}
                            </Box>
                        </Box>
                    </Paper>
            </SimpleModalContainer>
        )
    }
}
export const PvsPatientSelectContainer = connector(PvsPatientSelect);
export default PvsPatientSelectContainer;



interface ICardState {
    isHovering: boolean;
    previousKonsilIds: string[];
}

interface ICardProps {
    patient: Patient;
    konsil_id: string | undefined;
    isSelected: boolean;
    saveForMerging: (pvsPatient: Patient) => void;
    isUniqueMatch: boolean;
}


export class CardComponent extends React.Component<ICardProps, ICardState> {
    constructor(props: ICardProps) {
        super(props);
        this.state = {
            isHovering: false,
            previousKonsilIds: [],
        };

        this.saveKonsilId = this.saveKonsilId.bind(this);
    }

    componentDidMount() {
        if (this.props.isUniqueMatch && !this.props.isSelected && !this.state.previousKonsilIds.includes(this.props.konsil_id || "")){
            this.props.saveForMerging(this.props.patient)
        }
        this.saveKonsilId();
    }

    componentDidUpdate(prevProps: Readonly<ICardProps>) {
        if (prevProps.patient !== this.props.patient) {
            if (this.props.isUniqueMatch && !this.props.isSelected && !this.state.previousKonsilIds.includes(this.props.konsil_id || "")){
                this.props.saveForMerging(this.props.patient)
            }
            this.saveKonsilId();
        }
    }

    saveKonsilId() {
        let previousKonsilIds = this.state.previousKonsilIds;
        if (!previousKonsilIds.includes(this.props.konsil_id || ""))
            previousKonsilIds.push(this.props.konsil_id || "")
        this.setState({
            previousKonsilIds: previousKonsilIds
        })
    }

    render() {
        return (
            <Card>
                <Tooltip title="Jetzt zuweisen" placement="top-start">
                    <CardMedia onMouseOver={() => this.setState({isHovering: true})}
                        onMouseOut={() => this.setState({isHovering: false})}
                        onClick={() => this.props.saveForMerging(this.props.patient)}
                    >
                        {(this.state.isHovering || this.props.isSelected) &&
                            <Person/>
                        }
                        {!(this.state.isHovering || this.props.isSelected) &&
                            <Help/>
                        }
                    </CardMedia>
                </Tooltip>
                <CardContent >
                    <KonsilPatient patient={this.props.patient} />
                    <Typography variant="caption" color="inherit">eGK-Nr: {this.props.patient.egkNummer}</Typography>
                    <Typography variant="caption" color="inherit">{(this.props.patient.geburtsdatum)? new Date(this.props.patient.geburtsdatum).toLocaleDateString() + " (" + calculateAge(this.props.patient.geburtsdatum).toString() + " Jahre)": "Kein Geburstdatum hinterlegt"}</Typography>
                </CardContent>
            </Card>
        )
    }
}
