import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import { Failure, HPMService, KonsilMetaInformation, KonsilStatusType, UploadDownloadEventData } from 'telescan-core';
import {RootState} from '../reducers';
import * as SendDownloadKonsilTypes from '../types/upload_download_konsil_types';
import uuid from 'uuid';
import { addSendFailure, failure, loadKonsil, removeMetadatenAdressiertKonsil, removeMetadatenGerichtetKonsil, setCurrentKonsilBearbeitungsStatus, setCurrentKonsilTherapyDringlichkeit, setKonsilBearbeitungsStatus, setKonsilStatus, setKonsilTherapyDringlichkeit, setMetadatenAdressiertKonsilBearbeitungsStatus, setMetadatenGerichtetKonsilBearbeitungsStatus, setSuccessMessage } from '.';
import { getKonsilValidationStatus, loadArztKonsile } from './konsil_actions';

export function setEventText(eventText: string): SendDownloadKonsilTypes.ISetEventText {
    return {
        type: SendDownloadKonsilTypes.ESendDownloadKonsilActions.SET_EVENT_TEXT,
        eventText: eventText,
    }
}

export function setIsSendingKonsil(isSendingKonsil: boolean): SendDownloadKonsilTypes.ISetIsSendingKonsil {
    return {
        type: SendDownloadKonsilTypes.ESendDownloadKonsilActions.SET_IS_SENDING_KONSIL,
        isSendingKonsil: isSendingKonsil,
    }
}

export function handleVersandKonsil(konsilId: string, password: string, eventText: string, empfaengerGruppeId?: string, empfaengergruppeBezeichnung?: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch(setEventText(eventText));
        const currentKonsilStatus: keyof typeof KonsilStatusType = getState().konsile.current_konsil.konsilStatus;
        const hpmService = new HPMService();
        if (currentKonsilStatus === "IN_ARBEIT") {
            if (empfaengerGruppeId && empfaengergruppeBezeichnung) {
                hpmService.sendKonsilauftragGruppe(konsilId, password, empfaengerGruppeId, empfaengergruppeBezeichnung, getState().webSocket.clientId)
                .catch(error => {
                    const failureId: string = uuid.v4();
                    dispatch(failure(failureId, error));
                })
            }
            else {
                hpmService.sendKonsilauftrag(konsilId, password, getState().webSocket.clientId)
                .catch(error => {
                    const failureId: string = uuid.v4();
                    dispatch(failure(failureId, error)); 
                })
            }
        }
        else if((currentKonsilStatus === "BEAUFTRAGT" && eventText === "Versand der Rückfrage zum Konsil mit der ID") || (currentKonsilStatus === "BEANTWORTET" && eventText === "Versand der Rückfrage zum Konsil mit der ID")) {
            dispatch(setIsSendingKonsil(true));
            dispatch(getKonsilValidationStatus(konsilId, "RUECKFRAGE"))
            .then(() => {
                hpmService.sendRueckfrage(konsilId, password, getState().webSocket.clientId)
                .catch(error => {
                    const failureId: string = uuid.v4();
                    dispatch(failure(failureId, error));
                })
            });
        }
        else if((currentKonsilStatus === "BEAUFTRAGT" && eventText === "Versand des Konsiliarberichts zum Konsil mit der ID") || (currentKonsilStatus === "BEANTWORTET" && eventText === "Versand des Konsiliarberichts zum Konsil mit der ID")) {
            hpmService.sendKonsiliarbericht(konsilId, password, getState().webSocket.clientId)
            .catch(error => {
                const failureId: string = uuid.v4();
                dispatch(failure(failureId, error));
            })
        }
        else if(currentKonsilStatus === "RUECKFRAGE") {
            hpmService.sendReply(konsilId, password, getState().webSocket.clientId)
            .catch(error => {
                const failureId: string = uuid.v4();
                dispatch(failure(failureId, error));
            })
        }
        else if(currentKonsilStatus === "BEFUNDET") {
            dispatch(setIsSendingKonsil(true));
            dispatch(getKonsilValidationStatus(konsilId, "ABGESCHLOSSEN"))
            .then(() => {
                hpmService.sendKonsilabschluss(konsilId, password, getState().webSocket.clientId)
                .catch(error => {
                    const failureId: string = uuid.v4();
                    dispatch(failure(failureId, error));
                })
            });
        }
    }
}

export function handleUploadKonsilEvent(data: UploadDownloadEventData, eventText: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const currentKonsilId: string = getState().konsile.current_konsil.id || "";
        const isKonsilInList: boolean = getState().konsile.konsile.some(item => item.id === data.konsilId);
        if (data.bearbeitungsStatus === "UPLOAD") {        
            isKonsilInList && data.konsilId && dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
            if (data.initiator && data.initiator === getState().webSocket.clientId)
                dispatch(setIsSendingKonsil(true));
            if (currentKonsilId === data.konsilId)
                dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            if (data.initiator && data.initiator !== getState().webSocket.clientId && getState().router.location.pathname === "/konsil/" + data.konsilId) {
                const failureId: string = uuid.v4();
                const failureObj: Failure = new Failure();
                failureObj.error = "Der Versand des Konsil wurde von einer anderen Telescan-Session initiiert.";
                dispatch(failure(failureId, failureObj));
            }
        }
        else if (data.bearbeitungsStatus === "OK") {
            if (isKonsilInList && data.konsilId && data.konsilStatus)  {
                dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
                dispatch(setKonsilStatus(data.konsilId, data.konsilStatus));
            }
            if (data.initiator && data.initiator === getState().webSocket.clientId) {
                dispatch(setIsSendingKonsil(false));
                dispatch(setSuccessMessage(`${eventText} ${data.konsilId} erfolgreich`));
            }
            if (currentKonsilId === data.konsilId)
                dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            if (getState().router.location.pathname === "/konsil/" + data.konsilId) {
                data.konsilId && dispatch(loadKonsil(data.konsilId));
            }
            isKonsilInList && data.konsilId && data.urgency && dispatch(setKonsilTherapyDringlichkeit(data.konsilId, data.urgency));
            (currentKonsilId === data.konsilId) && data.urgency && dispatch(setCurrentKonsilTherapyDringlichkeit(data.urgency));
        } else {
            isKonsilInList && data.konsilId && data.bearbeitungsStatus && dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
            if ((currentKonsilId === data.konsilId) && data.bearbeitungsStatus)
                dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            if (data.initiator && data.initiator === getState().webSocket.clientId) {
                dispatch(setIsSendingKonsil(false));          
                dispatch(addSendFailure(data.konsilId || "", data.error || new Failure()));
            }
        }

    }
}

export function handleDownloadKonsilEvent(data: UploadDownloadEventData): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const role = getState().user.role;
        const currentKonsilId: string = getState().konsile.current_konsil.id || "";
        const isKonsilInList: boolean = getState().konsile.konsile.some(item => item.id === data.konsilId);
        const metadatenAdressiert: KonsilMetaInformation[] = getState().dermatologe.metadatenAdressiert;
        const metadatenGerichtet: KonsilMetaInformation[] = getState().dermatologe.metadatenGerichtet;
        const isKonsilInMetadatenAdressiert: boolean = metadatenAdressiert.some(item => item.vorgangsId === data.konsilId);
        const isKonsilInMetadatenGerichtet: boolean = metadatenGerichtet.some(item => item.vorgangsId === data.konsilId);
        if (data.bearbeitungsStatus === "DOWNLOAD") {
            isKonsilInList && data.konsilId && dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
            (currentKonsilId === data.konsilId) && data.konsilId && dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            if (role === "DERMATOLOGE") {
                (isKonsilInMetadatenAdressiert && data.konsilId) && dispatch(setMetadatenAdressiertKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
                (isKonsilInMetadatenGerichtet && data.konsilId) && dispatch(setMetadatenGerichtetKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));    
            }
        }
        else if (data.bearbeitungsStatus === "OK") {
            if (isKonsilInList && data.konsilId && data.konsilStatus) {
                dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
                dispatch(setKonsilStatus(data.konsilId, data.konsilStatus));
            }
            (currentKonsilId === data.konsilId) && data.konsilId && dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            isKonsilInList && data.konsilId && data.urgency && dispatch(setKonsilTherapyDringlichkeit(data.konsilId, data.urgency));
            (currentKonsilId === data.konsilId) && data.urgency && dispatch(setCurrentKonsilTherapyDringlichkeit(data.urgency));
            if (role === "DERMATOLOGE") {
                (isKonsilInMetadatenAdressiert && data.konsilId) && dispatch(removeMetadatenAdressiertKonsil(data.konsilId));
                (isKonsilInMetadatenGerichtet && data.konsilId) && dispatch(removeMetadatenGerichtetKonsil(data.konsilId));
                !getState().router.location.pathname.includes("konsil") && dispatch(loadArztKonsile());
            }
            if (getState().webSocket.clientId === data.initiator)
                dispatch(setSuccessMessage("Konsil erfolgreich heruntergeladen.")); 
            if (getState().router.location.pathname === "/konsil/" + data.konsilId) {
                data.konsilId && dispatch(loadKonsil(data.konsilId));
            }
        }
        else {
            isKonsilInList && data.konsilId && data.bearbeitungsStatus && dispatch(setKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
            (currentKonsilId === data.konsilId) && data.konsilId && data.bearbeitungsStatus && dispatch(setCurrentKonsilBearbeitungsStatus(data.bearbeitungsStatus));
            if (role === "DERMATOLOGE") {
                (data.bearbeitungsStatus && isKonsilInMetadatenAdressiert && data.konsilId) && dispatch(setMetadatenAdressiertKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
                (data.bearbeitungsStatus && isKonsilInMetadatenGerichtet && data.konsilId) && dispatch(setMetadatenGerichtetKonsilBearbeitungsStatus(data.konsilId, data.bearbeitungsStatus));
            }
            if (getState().webSocket.clientId === data.initiator) {
                const failureId = uuid.v4();
                dispatch(failure(failureId, data.error));
            }
        }
    }
}