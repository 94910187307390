import { Arzt } from "telescan-core";

export enum EArztActions {
    SET_KONSILIARE = "SET_KONSILIARE",
    SET_LAST_RECEIVER = "SET_LAST_RECEIVER",
    UPLOAD_RECEIVER_PROCESS = "UPLOAD_RECEIVER_PROCESS"
}


export interface ISetLastReceiverAction {
    readonly type: EArztActions.SET_LAST_RECEIVER;
    readonly lastReceiver: Arzt;
    readonly loading: boolean;
}

export interface IUploadReceiverProcess {
    readonly type: EArztActions.UPLOAD_RECEIVER_PROCESS;
    readonly percent: number;
    readonly receiver_id: string | undefined;
}


export type TArztActionTypes =
    ISetLastReceiverAction |
    IUploadReceiverProcess;
