import React from 'react';
import {connect, ConnectedProps} from 'react-redux'

import {Arzt, TelescanConfig} from 'telescan-core';

import {
    changeKonsilTablePage,
    changeKonsilTabelRowsPerPage,
    login,
    logout,
    loadZertifikate,
    getUserWithToken,
} from '../../redux/actions';
import {RootState} from '../../redux/reducers';
import {DermaKonsilTableContainer, DermaRow, KonsilTableContainer, Row} from './';
import LanrSelectionContainer from '../login/lanr_selection';
import ZertifikateComponent from '../initalisation/zertifikate';
import { Paper, Box, Button, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { needsUpdate } from '../../utils/utils';
import SimpleModalContainer from '../elements/simple_modal';

const mapStateToProps = (state: RootState) => ({
    queryString: state.router.location.search,
    location: state.router.location.pathname,
    konsile: state.konsile.konsile,
    loading: state.general.loading,
    userDetails: state.user.user,
    role: state.user.role,
    arztList: state.user.betriebsstaettenAerzte,
    metadatenAdressiert: state.dermatologe.metadatenAdressiert,
    totalSize: state.konsile.totalSize,
    initialisationStatus: state.user.initialisationStatus,
    zertifikatArztVorhanden: state.user.zertifikatArztVorhanden,
    zertifikatBetriebsstaetteVorhanden: state.user.zertifikatBetriebsstaetteVorhanden,
    zertifikatStatus: state.zertifikate.zertifikatStatus,
    arztZertifikateMetaInfo: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikatMetaInfo: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
})

const mapDispatchToProps = {
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
    dispatch_changePage: (page: number) => changeKonsilTablePage(page),
    dispatch_changeRowsPerPage: (rowsPerPage: number) => changeKonsilTabelRowsPerPage(rowsPerPage),
    dispatch_logout: () => logout(),
    dispatch_loadZertifikate: () => loadZertifikate(),
    dispatch_getUserWithToken: (refresh_token: string, targetPath) => getUserWithToken(refresh_token, targetPath),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


interface IState {
    showPopup: boolean;
    error: string;
    isOpenCreateZertifikatModal: boolean;
    isOpenRefreshZertifikatModal: boolean;
}

export class KonsilUebersicht extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            showPopup: false,
            error: "",
            isOpenCreateZertifikatModal: false,
            isOpenRefreshZertifikatModal: false,
        };

        this.newPopup = this.newPopup.bind(this);
        this.automaticLogin = this.automaticLogin.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.openCreateZertifikatPopup = this.openCreateZertifikatPopup.bind(this);
        this.closeCreateZertifikatPopup = this.closeCreateZertifikatPopup.bind(this);
        this.openRefreshZertifikatPopup = this.openRefreshZertifikatPopup.bind(this);
        this.closeRefreshZertifikatPopup = this.closeRefreshZertifikatPopup.bind(this);
        this.checkNutzerStatus = this.checkNutzerStatus.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.userDetails !== this.props.userDetails) {
            if (this.props.userDetails[0]?.bsnr !== "" && this.props.userDetails[0]?.bsnr !== null) {
                this.checkNutzerStatus(this.props.userDetails)
            } else
                console.log("Ungültige BSNR")
        }
        if (prevProps.zertifikatArztVorhanden !== this.props.zertifikatArztVorhanden || prevProps.zertifikatBetriebsstaetteVorhanden !== this.props.zertifikatBetriebsstaetteVorhanden)
            this.checkZertifikatStatusUser();
        if (prevProps.zertifikatStatus !== this.props.zertifikatStatus ) {
            this.checkZertifikatStatusUser();}
    }

    componentDidMount() {
        if (this.props.userDetails.length > 0 && this.props.userDetails[0]?.bsnr !== "" && this.props.userDetails[0]?.bsnr !== null) {
            this.checkNutzerStatus(this.props.userDetails)
        }
        
    }
    checkNutzerStatus(arztList: Arzt[]) {
        //const lanr = (this.props.userDetails.length === 1) ? this.props.userDetails[0].lanr : undefined
        //if (lanr === "" || lanr === undefined) {
        if (this.props.role === "BETRIEBSSTAETTE_HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
            if (arztList.length === 0) {
                this.setState({
                    error: "Keine Ärzte mit gültigen Arztzertifikaten in der Betriebsstätte vorhanden!"
                }, () => this.newPopup());
            } else if (arztList.length === 1) {
                this.automaticLogin(arztList[0].lanr)
            } else {
                this.newPopup();
            }
        }
    }

    checkZertifikatStatusUser() {
        if (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && !this.props.zertifikatArztVorhanden) || !this.props.zertifikatBetriebsstaetteVorhanden)
            {this.openCreateZertifikatPopup()}
        /*
        if (this.props.initialisationStatus.standalone && (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false))) || 
            this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1)) )
            {this.openRefreshZertifikatPopup()}
        else if (!this.props.initialisationStatus.standalone && (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && this.props.zertifikatStatus.arztZertifikat !== "AKTIV") || 
            this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV") )
            {this.openRefreshZertifikatPopup()}
        else
            this.closeRefreshZertifikatPopup()
        */
        if (this.props.role === "DERMATOLOGE") {
            if (this.props.initialisationStatus.standalone && ((this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false)) || 
                this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1)) )
                {this.openRefreshZertifikatPopup()}
            else if (!this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || 
                this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV") )
                {this.openRefreshZertifikatPopup()}
            else
                this.closeRefreshZertifikatPopup()
        }
        if (this.props.role === "HAUSARZT") {
            if (this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat === "GESPERRT" || this.props.zertifikatStatus.arztZertifikat === "GELOESCHT" ||
                this.props.zertifikatStatus.betriebsstaettenZertifikat === "GESPERRT" || this.props.zertifikatStatus.betriebsstaettenZertifikat === "GELOESCHT"))
                {this.openRefreshZertifikatPopup()}
            else if (!this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat === "GESPERRT" || this.props.zertifikatStatus.arztZertifikat === "GELOESCHT" ||
            this.props.zertifikatStatus.betriebsstaettenZertifikat === "GESPERRT" || this.props.zertifikatStatus.betriebsstaettenZertifikat === "GELOESCHT") )
                {this.openRefreshZertifikatPopup()}
            else
                this.closeRefreshZertifikatPopup()
        }
    }

    openCreateZertifikatPopup() {
        this.setState({
            isOpenCreateZertifikatModal: true
        })
    }

    closeCreateZertifikatPopup() {
        this.setState({
            isOpenCreateZertifikatModal: false
        })
    }

    openRefreshZertifikatPopup() {
        this.setState({
            isOpenRefreshZertifikatModal: true
        })
    }

    closeRefreshZertifikatPopup() {
        this.setState({
            isOpenRefreshZertifikatModal: false
        })
    }

    newPopup() {
        this.setState({
            showPopup: true
        });
    };

    automaticLogin(lanr: string | undefined) {
        localStorage.setItem("LANR", lanr || "");
        TelescanConfig.getInstance().setConstant("LANR", lanr || "");
        let bsnr = (this.props.userDetails.length > 0) ? this.props.userDetails[0].bsnr: "";
        const password = TelescanConfig.getInstance().getConstant("PASSWORD")
        this.props.dispatch_logout();
        this.props.dispatch_login(bsnr || "", password, lanr || "")
        .catch( (error) => {
            console.log(error);
            this.props.dispatch_logout();
        })
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.dispatch_changeRowsPerPage(parseInt(event.target.value, 10));
    };

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.props.dispatch_changePage(newPage);
    };


    render() {
        const konsil_rows: Row[] = this.props.konsile ? this.props.konsile
            .map((konsil) => {
                return {
                    adressat: konsil.adressat,
                    id: konsil.id,
                    patient: konsil.konsilPatient,
                    date_created: konsil.dateCreated,
                    status: konsil.konsilStatus,
                    bearbeitungsStatus: konsil.bearbeitungsStatus,
                    konsiliar: konsil.konsiliar,
                    konsil: konsil
                } as Row;
            }) : [];

        let dermaKonsil_rows: DermaRow[] = this.props.konsile ? this.props.konsile
            .map((konsil) => {
                return {
                    id: konsil.id,
                    patient: konsil.konsilPatient,
                    date_created: konsil.dateCreated,
                    date_send: konsil.dateSend,
                    status: konsil.konsilStatus,
                    bearbeitungsStatus: konsil.bearbeitungsStatus,
                    senderArzt: konsil.beauftragender_arzt,
                    konsil: konsil,
                    isMetaData: false,
                    dokumentenId: undefined
                } as DermaRow;
            }) : [];

        let dermaKonsil_metadata_rows: DermaRow[] = [];
        this.props.metadatenAdressiert.forEach((metainfo) => {
            const senderArztToSave: Arzt = new Arzt();
            senderArztToSave.nachname = metainfo.senderArzt;
            dermaKonsil_metadata_rows.push({
                id: metainfo.vorgangsId,
                patient: undefined, // replace with actually empty thing
                date_created: undefined,
                letzterBearbeitungszeitpunkt: metainfo.letzterBearbeitungszeitpunkt,
                status: metainfo.statusTelekonsil,
                bearbeitungsStatus: metainfo.bearbeitungsStatus,
                senderArzt: senderArztToSave,
                senderLanr: metainfo.senderLanr,
                konsil: undefined,
                isMetaData: true,
                dokumentenId: metainfo.dokumentIdentifikator
            } as DermaRow)
        })
        return (
            <div className="konsil-uebersicht-container">
                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                <KonsilTableContainer data={konsil_rows} handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                      handleChangePage={this.handleChangePage}/>
                }
                {/* <KonsilTableContainer data={konsil_rows} />*/}
                {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                <DermaKonsilTableContainer data={dermaKonsil_rows} metadata={dermaKonsil_metadata_rows}
                                           totalSize={this.props.totalSize}
                                           handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                           handleChangePage={this.handleChangePage}/>
                }
                <LanrSelectionContainer showPopup={this.state.showPopup} bsnr={this.props.userDetails[0]?.bsnr || ""} lanr={this.props.userDetails[0]?.lanr || ""}
                                        onConfirm={this.automaticLogin} error={this.state.error}/>
                {/* <Modal
                    open={this.state.isOpenCreateZertifikatModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="modal"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpenCreateZertifikatModal}>
                    <Paper className="modal-wrapper konsil-question-pop">
                        <ZertifikateComponent message="Achtung! Ihre Zertifikate sind unvollständig." arztZertifikatVorhanden={this.props.zertifikatArztVorhanden} />
                    <Box className="modal-footer">
                            <Button disabled={!this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.zertifikatArztVorhanden} variant="contained" color="primary" onClick={() => {this.closeCreateZertifikatPopup(); this.props.dispatch_loadZertifikate()}}>
                                Fertig
                            </Button>
                        </Box>
                    </Paper>
                </Fade>
            </Modal> */}
            <SimpleModalContainer isOpen={this.state.isOpenCreateZertifikatModal} >
                    <Paper className="modal-wrapper konsil-question-pop">
                        <ZertifikateComponent message="Achtung! Ihre Zertifikate sind unvollständig." arztZertifikatVorhanden={this.props.zertifikatArztVorhanden} />
                    <Box className="modal-footer">
                            <Button disabled={!this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.zertifikatArztVorhanden} 
                                variant="contained" color="primary" 
                                onClick={() => {this.closeCreateZertifikatPopup(); this.props.dispatch_loadZertifikate()}}>
                                Fertig
                            </Button>
                        </Box>
                    </Paper>
            </SimpleModalContainer>
            <SimpleModalContainer isOpen={this.state.isOpenRefreshZertifikatModal} >
                <Paper className="modal-wrapper">
                    <Box className="modal-content konsil-submit-feedback">
                        <h2>Zertifikate aktualisieren</h2>
                        <Warning className="konsil-submit-alert"/>
                        <Box>{this.props.initialisationStatus.standalone? 
                            <Typography variant="body1" color="inherit" style={{width: "60vw"}}>
                                Achtung! Eines Ihrer Zertifikate ist nicht aktiv, bzw. läuft in Kürze ab. 
                                Dies beeinflusst das Senden und Empfangen von Telekonsilen.
                                Bitte aktualisieren Sie Ihre Zertifikate in den Einstellungen.
                            </Typography>
                            :
                            <Typography variant="body1" color="inherit" style={{width: "60vw"}}>
                                Achtung! Eines Ihrer Zertifikate ist nicht aktiv, bzw. läuft in Kürze ab. 
                                Dies beeinflusst das Senden und Empfangen von Telekonsilen.
                                Bitte aktualisieren Sie Ihre Zertifikate in Ihrem Praxisverwaltungssystem.
                            </Typography>
                            }
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="contained" color="primary" onClick={() => this.closeRefreshZertifikatPopup()}>
                            OK
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
            </div>
        );
    }
}

export const KonsilUebersichtContainer = connector(KonsilUebersicht);
export default KonsilUebersichtContainer;
