import React, {ChangeEvent} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {Arzt, EmpfaengerTyp, Failure, Konsil, KonsilEmpfaengerGruppe, KonsilStatusType,} from 'telescan-core';
import {
    addSendFailure,
    deleteKonsilAnamneseErrors,
    deleteKonsilFrageErrors,
    deleteKonsilPatientErrors,
    deleteLocationErrors,
    deleteTabStatus,
    loadArztKonsile,
    loadKonsil,
    loadKonsiliare,
    loadKonsiliarGruppen,
    navigate,
    setCdaSize,
    setLastReceiver,
    setSuccessMessage,
    updateKonsil,
    handleVersandKonsil,
    setIsSendingKonsil,
    setIsComfortVersand,
    setComfortVersandPassword,
    clearPdfString,
    getPdfString,
} from '../../redux/actions';

import {RootState} from "../../redux/reducers";
import uuid from 'uuid';

// material components
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputBase,
    List,
    Menu,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core/';
import PropTypes from 'prop-types';

// Icons
import {Search, HelpOutline} from '@material-ui/icons';
import {HpmBadgeContainer} from '../elements/hpm_badge';
import {StartSendPopup} from './sending_results_popup';
import {checkCdaSize, isEmpty} from '../../utils/utils';
import SimpleModalContainer from '../elements/simple_modal';
import {CdaSizeComponent} from '../elements/cda_size';
import {Patienteneinwilligung} from 'telescan-core/lib/entities/patienteneinwilligung';
import {withTypingTimeout, WithTypingTimeoutsProps} from '../../utils/with_typing_timeout';
import PdfInline from '../elements/pdf_inline';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';
import { Autocomplete } from '@material-ui/lab';
import { KonsilValidationBadge } from '../elements/konsil_validation_badge';


interface IState {
    konsil: Konsil,
    tabValue: React.SetStateAction<number>,
    filteredKonsiliare: Arzt[],
    anchorEl: HTMLInputElement | null,
    selectedReceiver: Arzt,
    konsiliare: Arzt[],
    empfaengerTyp: keyof typeof EmpfaengerTyp,
    selectedReceiverGroup: KonsilEmpfaengerGruppe,
    lastReceiverGroup: KonsilEmpfaengerGruppe,
    password: string,
    isSelectedReciever: boolean;
    typingReminder: string | undefined;
    isEmptySearch: boolean;
    isMouseMoving: boolean;
    hasPasswordChanged: boolean;
}

interface IProps {
    isOpenReceiverModal: boolean,
    toggleReceiverModal: () => void,
    selectedReceiver: Arzt,
    selectedReceiverGroup: KonsilEmpfaengerGruppe,
    pdfPreviewData: any,
    createPdfPreview: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => void,
    dispatch_failure: (id: string, failureObject: Failure) => void,
    isPdfLoading: boolean,
    receiverGroups: KonsilEmpfaengerGruppe[],
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    ...ownProps,
    lastReceiver: state.konsiliare.lastReceiver,
    userDetails: state.user.user,
    currentKonsil: state.konsile.current_konsil,
    role: state.user.role,
    location: state.router.location.pathname,
    cdaSize: state.konsile.cdaSize,
    isSendingKonsil: state.send_download_konsil.isSendingKonsil,
    konsilValidationStatus: state.konsile.konsilValidationStatus,
    konsilValidationError: state.konsile.konsilValidationError,
    isComfortVersand: state.general.isComfortVersand,
    comfortVersandPassword: state.general.comfortVersandPassword,
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil) => updateKonsil(konsil),
    dispatch_loadKonsiliare: (suchtext: string, empfaengerTyp: keyof typeof EmpfaengerTyp) => loadKonsiliare(suchtext, empfaengerTyp),
    dispatch_setLastReceiver: (konsiliar: Arzt) => setLastReceiver(konsiliar),
    dispatch_loadKonsiliarGruppen: () => loadKonsiliarGruppen(),
    dispatch_successMessage: (message: string) => setSuccessMessage(message),
    dispatch_addSendFailure: (konsilID: string, failure: Failure) => addSendFailure(konsilID, failure),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_loadArztKonsile: () => loadArztKonsile(),
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_setCdaSize: (size: number) => setCdaSize(size),
    dispatch_deleteTabStatus: (konsilId: string) => deleteTabStatus(konsilId),
    dispatch_deleteKonsilAnamneseErrors: (konsilId: string) => deleteKonsilAnamneseErrors(konsilId),
    dispatch_deleteKonsilFrageErrors: (konsilId: string) => deleteKonsilFrageErrors(konsilId),
    dispatch_deleteKonsilPatientErrors: (konsilId: string) => deleteKonsilPatientErrors(konsilId),
    dispatch_deleteLocationErrors: (konsilId: string) => deleteLocationErrors(konsilId),
    dispatch_handleVersandKonsil: (konsilId: string, password: string, eventText: string, empfaengerGruppeId?: string, empfaengergruppeBezeichnung?: string) => handleVersandKonsil(konsilId, password, eventText, empfaengerGruppeId, empfaengergruppeBezeichnung),
    dispatch_setIsSendingKonsil: (isStartSendkonsilPopup: boolean) => setIsSendingKonsil(isStartSendkonsilPopup),
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => setIsComfortVersand(isComfortVersand),
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => setComfortVersandPassword(comfortVersandPassword),
    dispatch_getPdfString: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getPdfString(konsilId, konsilStatus),
    dispatch_clearPdfString: () => clearPdfString(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithTypingTimeoutsProps


export class KonsilAnfrageReceiver extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            konsil: props.currentKonsil || new Konsil(),
            tabValue: 0,
            filteredKonsiliare: [],
            anchorEl: null,
            selectedReceiver: this.props.selectedReceiver,
            konsiliare: [],
            empfaengerTyp: 'NAME',
            selectedReceiverGroup: new KonsilEmpfaengerGruppe(),
            lastReceiverGroup: new KonsilEmpfaengerGruppe(),
            password: "",
            isSelectedReciever: false,
            typingReminder: undefined,
            isEmptySearch: true,
            isMouseMoving: false,
            hasPasswordChanged: false,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMenuSelect = this.handleMenuSelect.bind(this);
        this.setKonsiliare = this.setKonsiliare.bind(this);
        this.search = this.search.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.updateKonsil = this.updateKonsil.bind(this);
        this.closeStartSendPopup = this.closeStartSendPopup.bind(this);
        //this.handleClickLastReciever = this.handleClickLastReciever.bind(this);
        this.handleVersendenKonsilauftragAdressiert = this.handleVersendenKonsilauftragAdressiert.bind(this);
        this.handleVersendenKonsilauftragGerichtet = this.handleVersendenKonsilauftragGerichtet.bind(this);
        this.timeHandleSearchChange = this.timeHandleSearchChange.bind(this);
        this.setTypingReminder = this.setTypingReminder.bind(this);
    }

    componentDidMount() {
        this.setState({selectedReceiverGroup: this.props.receiverGroups[0]});
        this.setState({lastReceiverGroup: this.props.receiverGroups[0]});
        this.props.comfortVersandPassword && this.props.comfortVersandPassword.length !== 0 &&
        this.setState({password: this.props.comfortVersandPassword});
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil(),
            });
        }
        if (prevProps.receiverGroups !== this.props.receiverGroups) {
            this.setState({selectedReceiverGroup: this.props.receiverGroups[0]});
            this.setState({lastReceiverGroup: this.props.receiverGroups[0]});
        }
    }

    private updateKonsil(): Promise<any> {
        return new Promise((resolve) => {
            resolve(this.props.updateKonsil(this.state.konsil));
            //resolve("ok")
        })
    }

    handleTabChange = (event: ChangeEvent, newTabValue: React.SetStateAction<number>) => {
        this.setState({
            tabValue: newTabValue,
            anchorEl: null,
        });
        if (newTabValue === 0 && !this.state.konsil.patienteneinwilligung?.einwilligungVersandUmwandlung)
            this.changePatientConsent(true);
    };

    handlePasswordChange(e) {
        this.setState({hasPasswordChanged: true});
        this.setState({password: e.target.value});
    }

    setKonsiliare(konsiliare: Arzt[], target: HTMLInputElement): Arzt[] {
        let threshold: number;
        (this.state.empfaengerTyp === 'NAME') ? threshold = 3 : threshold = 1;

        if (target.value.length >= threshold) {
            this.setState({konsiliare: konsiliare});
            return konsiliare
        } else {
            return []
        }
    };

    search(konsiliare: Arzt[], anchor: HTMLInputElement): Arzt[][] {
        let value: string = anchor.value;
        let currentList: Arzt[] = konsiliare
        let newList = currentList.filter(item => {
            let arztInfo: string;
            if (EmpfaengerTyp[this.state.empfaengerTyp] === "Straße" || EmpfaengerTyp[this.state.empfaengerTyp] === "Postleitzahl" || EmpfaengerTyp[this.state.empfaengerTyp] === "Ort") {
                arztInfo = item.betriebsstaette?.strasseHausnummer + " " + item.betriebsstaette?.plz + " " + item.betriebsstaette?.ort || ""
            } else {
                arztInfo = item.vorname + " " + item.nachname
            }
            const lc = arztInfo.toLowerCase();
            // change search term to lowercase to deal with capitalization
            const lc_filter = value.toLowerCase();

            return lc.includes(lc_filter);
        });
        this.setState({
            filteredKonsiliare: newList,
            anchorEl: anchor
        });
        return [currentList, newList]
    };

    handleSearchChange(e: ChangeEvent<HTMLInputElement>): string {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let currentList: Arzt[];
        let newList: Arzt[] = [];
        let anchor: HTMLInputElement | null = null;
        let threshold: number;

        (this.state.empfaengerTyp === 'NAME') ? threshold = 3 : threshold = 1;

        if (e.target.value.length === 0) {
            this.setState({
                isEmptySearch: true
            })
        } else
            this.setState({
                isEmptySearch: false
            })

        if (e.target.value.length < threshold) {
            //currentList = [];
        }

        if (e.target.value.length === threshold) {
            if (this.props.userDetails.length === 1) {
                let target = e.currentTarget;
                this.props.dispatch_loadKonsiliare(e.target.value, this.state.empfaengerTyp).then(
                    konsiliare => this.setKonsiliare(konsiliare, target)
                ).then(
                    konsiliare => this.search(konsiliare, target)
                )
            } else
                console.log("Bitte Arzt auswählen")
        }

        if (e.target.value !== "" && e.target.value.length > threshold) { //search bar not empty
            [ , newList] = this.search(this.state.konsiliare, e.currentTarget);
        } else { // search bar is empty
            newList = []
            this.setState({
                filteredKonsiliare: newList,
                anchorEl: anchor
            });
        }

        return e.target.value;
    }

    handleMenuOpen(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        this.setState({
            anchorEl: event.target as HTMLInputElement
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleListReset = () => {
        this.setState({
            filteredKonsiliare: [],
            isSelectedReciever: false,
        });
    }

    handleMenuSelect = (arzt: Arzt) => {
        // select dermatologe to send konsil to
        // also close the menu
        this.props.dispatch_setLastReceiver(arzt);
        this.setState({
            isSelectedReciever: false
        })
        // set konsiliar
        this.setState({
            konsil: {
                ...this.state.konsil,
                konsiliar: arzt
            }
        }, () => {
            this.updateKonsil()
        });
        this.setState({
            selectedReceiver: arzt,
            anchorEl: null
        });
    };

    /* handleClickLastReciever() {
        //search for reciever again to check that the Derma is still in EAV
        if (this.props.lastReceiver.nachname !== "" && this.props.lastReceiver.nachname !== undefined && this.state.isSelectedReciever === false) {
            this.props.dispatch_loadKonsiliare([this.props.lastReceiver.vorname, this.props.lastReceiver.nachname].filter(item => (item !== null && item !== "")).join(" "), "NAME")
            .then(() => {
                this.handleMenuSelect(this.props.lastReceiver);
                this.setState({
                    isSelectedReciever: true
                })
            })
        } else
            this.setState({
                isSelectedReciever: false
            })
    } */

    handleVersendenKonsilauftragAdressiert(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();

       if (this.props.currentKonsil.id) {
            this.props.toggleReceiverModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged)
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsils mit der ID");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand des Konsils mit der ID");
       } else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(failureId, failureObj);
            this.props.toggleReceiverModal();
       }
    }

    handleVersendenKonsilauftragGerichtet(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        let empfaengerGruppeId: string | undefined;
        let empfaengergruppeBezeichnung: string | undefined;
        if (this.state.selectedReceiverGroup?.empfaengergruppeIdentifikator == null || this.state.selectedReceiverGroup?.empfaengergruppeIdentifikator === "")
            empfaengerGruppeId = this.state.lastReceiverGroup?.empfaengergruppeIdentifikator
        else
            empfaengerGruppeId = this.state.selectedReceiverGroup?.empfaengergruppeIdentifikator

        if (this.state.selectedReceiverGroup?.empfaengergruppeBezeichnung == null || this.state.selectedReceiverGroup?.empfaengergruppeBezeichnung === "")
            empfaengergruppeBezeichnung = this.state.lastReceiverGroup?.empfaengergruppeBezeichnung
        else
            empfaengergruppeBezeichnung = this.state.selectedReceiverGroup?.empfaengergruppeBezeichnung

        if (this.props.currentKonsil.id) {
            this.props.toggleReceiverModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "")
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsils mit der ID", empfaengerGruppeId || "", empfaengergruppeBezeichnung || "");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand des Konsils mit der ID", empfaengerGruppeId || "", empfaengergruppeBezeichnung || "");
        }
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(failureId, failureObj);
            this.props.toggleReceiverModal();
        }
    }

    closeStartSendPopup() {
        this.props.dispatch_setIsSendingKonsil(false);
        this.props.dispatch_navigate("/");
    }


    timeHandleSearchChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.props.timeOutMethod(e, this.handleSearchChange, this.setTypingReminder, 500)
    }

    setTypingReminder(value: string): void {
        if (value.length < 3 && this.state.empfaengerTyp === 'NAME')
            this.setState({
                typingReminder: "Bitte geben Sie mindestens 3 Zeichen ein, um die Suche auszuführen."
            })
        else
            this.setState({
                typingReminder: undefined
            })
    }

    changePatientConsent = (checked: boolean) => {
        const patienteneinwilligung: Patienteneinwilligung = this.state.konsil.patienteneinwilligung || new Patienteneinwilligung();
        patienteneinwilligung.einwilligungVersandUmwandlung = checked;
        patienteneinwilligung.dateUmwandlungseinwilligung = Date.now();
        let updatedKonsil = {
            ...this.state.konsil,
            patienteneinwilligung: patienteneinwilligung
        }
        this.setState({
                konsil: updatedKonsil,
            }, //reload pdf preview
            () => {
                this.updateKonsil()
                .then(() => {
                    this.props.dispatch_clearPdfString();
                    this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                });
            })
    }


    render() {
        const hasPvsPatient: boolean = this.state.konsil.pvsPatient != null;
        const disabledVersenden: boolean =
            (this.state.tabValue === 1) ?
                (!this.props.pdfPreviewData || this.props.pdfPreviewData === "" || isEmpty(this.state.selectedReceiver) || this.props.lastReceiver.lanr === "" ||
                    this.props.isSendingKonsil || (this.state.password === "" && this.props.comfortVersandPassword === "") || this.props.konsilValidationStatus !== "OK"
                    || !hasPvsPatient || !checkCdaSize(this.props.cdaSize, "IN_ARBEIT"))
                :
                (!this.props.pdfPreviewData || this.props.pdfPreviewData === "" || this.state.lastReceiverGroup?.empfaengergruppeBezeichnung === undefined || this.props.isSendingKonsil  ||
                    //!this.state.konsil.patienteneinwilligung?.einwilligungVersandGerichtet ||
                    (this.state.password === "" && this.props.comfortVersandPassword === "") || this.props.konsilValidationStatus !== "OK" ||
                    !hasPvsPatient || !checkCdaSize(this.props.cdaSize, "IN_ARBEIT"))

        const handleClickVersenden: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            this.props.dispatch_deleteTabStatus(this.props.currentKonsil.id || "");

            if (this.state.tabValue === 1)
                this.handleVersendenKonsilauftragAdressiert(event);
            else {
                this.setState({
                    konsil: {
                        ...this.state.konsil,
                        konsiliar: undefined
                    }
                }, () => {
                    this.updateKonsil()
                    .then(() => this.handleVersendenKonsilauftragGerichtet(event));
                });
            }

            this.handleListReset();
        }
        return (
            <>
                <SimpleModalContainer
                    isOpen={this.props.isOpenReceiverModal}
                    onClose={() => {
                        this.handleListReset();
                        this.props.toggleReceiverModal()
                    }}
                >
                    <Paper className="modal-wrapper reciever-pop">
                        <Box className="reciever-split">
                            <form className="form-content" noValidate autoComplete="off">
                                <Box className="modal-header">
                                    <KonsilValidationBadge
                                        konsilValidationStatus = {this.props.konsilValidationStatus}
                                        konsilValidationError = {this.props.konsilValidationError}
                                    />
                                    <HpmBadgeContainer/>
                                    <h2>Empfänger Auswählen</h2>
                                    <Tabs
                                        value={this.state.tabValue}
                                        className="headerTabs"
                                        onChange={this.handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="off"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                    >
                                        <Tab label="Gruppe" {...a11yProps(1)} />
                                        <Tab label="Einzelperson" {...a11yProps(0)} />
                                    </Tabs>
                                </Box>

                                <Box className="modal-content">
                                    {(this.state.tabValue === 0 || (this.state.tabValue === 1)) &&
                                    <>
                                        <CdaSizeComponent size={this.props.cdaSize} konsilStatus="IN_ARBEIT"/>
                                        <Typography className="last-reciver-label" variant="body1" color="inherit">
                                            Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen
                                            Sie den Konsilauftrag (.pdf) auf Richtigkeit.
                                        </Typography>
                                    </>
                                    }
                                    <TabPanel className="receiver-container" value={this.state.tabValue} index={1}>
                                        <Typography className="failure"
                                                    variant="caption">{this.state.typingReminder}</Typography>
                                        {/* to cheat chrome which tries to fill some none password filled with credentials*/}
                                        <input className = "hidden" id="username"  type="text" name="fakeusernameremembered"/>
                                        <input className = "hidden" id="password"  type="password" name="fakepasswordremembered"/>
                                        {/* //////////////////////////////////////////////////////////////////////////////*/}
                                        <Box className="search-container empfaenger-search">
                                            <IconButton
                                                type="submit"
                                                size="small"
                                                className=""
                                                aria-label="search"
                                                onClick = {event => event.preventDefault()}
                                            >
                                                <Search/>
                                            </IconButton>
                                            <InputBase
                                                name = "search1"
                                                className="search-input"
                                                placeholder="Empfängersuche"
                                                inputProps={{"aria-label": "Suche"}}
                                                onChange={this.timeHandleSearchChange}
                                                onClick={this.handleMenuOpen}
                                            />
                                            <Select className="search-select"
                                                    labelId="status-select-label"
                                                //id="status-select"
                                                    value={this.state.empfaengerTyp}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            empfaengerTyp: event.target.value as 'NAME' | 'STRASSE' | 'PLZ' | 'ORT'
                                                        });
                                                    }}
                                                    displayEmpty>
                                                {Object.keys(EmpfaengerTyp).map(key =>
                                                    <MenuItem key={key} value={key}>
                                                        {EmpfaengerTyp[key]}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </Box>
                                        {!this.state.isEmptySearch &&
                                        < RecieverPickerComponent
                                            context="INDIVIDUAL"
                                            konsiliareList={this.state.filteredKonsiliare}
                                            anchorEl={this.state.anchorEl}
                                            handleMenuClose={this.handleMenuClose}
                                            handleMenuSelect={this.handleMenuSelect}
                                        />}
                                        {/*{(isEmpty(this.state.selectedReceiver) && this.props.lastReceiver.lanr !== "" && this.props.lastReceiver.lanr != null) &&
                                            <RecieverCardComponent title="Letzter Empfänger" receiver={this.props.lastReceiver}
                                                isSelected={this.state.isSelectedReciever}
                                                handleClick={this.handleClickLastReciever} />
                                        } */}
                                        {(!isEmpty(this.state.selectedReceiver)) &&
                                        <RecieverCardComponent title="Konsil wird an folgenden Empfänger gesendet:"
                                                               isSelected={true}
                                                               receiver={this.state.selectedReceiver}/>
                                        }
                                        <FormGroup className="form-grid-content full-span">
                                            <FormControlLabel
                                                control =
                                                    {<Checkbox
                                                        name="einwilligung-versand-gerichtet"
                                                        checked={!this.state.konsil.patienteneinwilligung?.einwilligungVersandUmwandlung || false}
                                                        onChange={(event) => {
                                                            const patienteneinwilligung: Patienteneinwilligung = this.state.konsil.patienteneinwilligung || new Patienteneinwilligung();
                                                            patienteneinwilligung.einwilligungVersandUmwandlung = !event.target.checked;
                                                            patienteneinwilligung.dateUmwandlungseinwilligung = Date.now();
                                                            let updatedKonsil = {
                                                                ...this.state.konsil,
                                                                patienteneinwilligung: patienteneinwilligung
                                                            }
                                                            this.setState({
                                                                    konsil: updatedKonsil,
                                                                }, //reload pdf preview
                                                                () => {
                                                                    this.updateKonsil()
                                                                    .then(() => {
                                                                        this.props.dispatch_clearPdfString();
                                                                        this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                                                                    });
                                                                    // this.props.resetPdfPreviewData().then(() => {
                                                                    //     this.props.createPdfPreview(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                                                                    // });
                                                                })
                                                        }}
                                                    />}
                                                label={<Typography variant="subtitle1" className="bold-label">Widerspruch
                                                    Konsilumwandlung</Typography>}
                                            />
                                            <Typography variant="caption" color="inherit">
                                                Hiermit widerspreche ich der Umwandlung des adressierten Konsils in ein
                                                gerichtetes Konsil. (Dies entspricht: unbedingtes adressiertes Konsil)
                                            </Typography>
                                        </FormGroup>
                                    </TabPanel>
                                    <TabPanel className="receiver-container" value={this.state.tabValue} index={0} >
                                        <Typography
                                            className="last-reciver-label"
                                            variant="body1"
                                            color="inherit"
                                        >
                                                Das Senden des Konsilauftrags an eine Empfängergruppe gewährleistet eine schnelle Bearbeitung
                                                <Tooltip title="Der Konsilauftrag wird an eine Empfängergruppe gesendet. z.B. enthält die Empfängergruppe '513 - Dermatologie' alle Vertragsdermatologen. Der Empfänger, der Ihren Konsilauftrag als erstes annimmt, wird Konsiliar. Für alle anderen Empfänger wird der Konsilauftrag ab diesem Zeitpunkt nicht mehr zur Verfügung stehen.">
                                                <HelpOutline
                                                    style={{verticalAlign: 'middle', display: 'inline-flex'}}/>
                                                </Tooltip>
                                        </Typography>
                                        <Typography
                                            className="last-reciver-label"
                                            variant="body1"
                                            color="inherit"
                                        >
                                                Das Konsil wird an folgende Empfängergruppe gesendet:
                                        </Typography>
                                        <Box className="search-container">
                                            {this.state.selectedReceiverGroup ?//Object.keys(this.state.selectedReceiverGroup).length !== 0 ?
                                                <Autocomplete
                                                    id="send_groups"
                                                    options={this.props.receiverGroups}
                                                    getOptionLabel={(option) => `${option?.empfaengergruppeIdentifikator?.toString()} \u2022 ${option?.empfaengergruppeBezeichnung}` || ""}
                                                    value = {this.state.selectedReceiverGroup}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label="Empfängergruppe"
                                                        />
                                                    }
                                                    onChange={(event: any, newValue) => {
                                                        newValue &&
                                                        this.setState({
                                                            selectedReceiverGroup: newValue,
                                                            lastReceiverGroup: newValue,
                                                        })
                                                    }}
                                                /> :
                                                <CircularProgress size = {25}/>
                                            }
                                        </Box>
                                    </TabPanel>

                                    <ZertifikatPasswortComponent
                                        password = {(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged) ? this.props.comfortVersandPassword : this.state.password}
                                        handlePasswordChange = {this.handlePasswordChange}
                                    />
                                     <Tooltip
                                        title = "Passwort für die aktuelle Sitzung speichern"
                                        placement = 'top'
                                    >
                                        <FormControlLabel
                                            control =
                                                {<Checkbox
                                                    name="comfort-versand-checkbox"
                                                    checked = {this.props.isComfortVersand}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (!event.target.checked)
                                                            this.props.dispatch_setComfortVersandPassword("");
                                                        this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                    }}
                                                />}
                                            label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                        />
                                    </Tooltip>
                                    {
                                        !hasPvsPatient &&
                                        <Typography
                                            className="pad-top failure"
                                            variant="body1">
                                            Kein PVS-Patient im Konsil vorhanden!
                                        </Typography>
                                    }
                                </Box>

                                <Box className="modal-footer">
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            this.props.dispatch_setCdaSize(NaN);
                                            this.handleListReset();
                                            this.props.toggleReceiverModal();
                                            this.setState({password: ""});
                                            this.props.dispatch_clearPdfString();
                                        }}
                                    >
                                        Abbrechen
                                    </Button>
                                    <Button
                                        disabled={disabledVersenden}
                                        variant="contained" color="primary"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleClickVersenden(event);
                                            this.props.dispatch_setCdaSize(NaN);
                                            this.props.dispatch_clearPdfString();
                                            if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.state.hasPasswordChanged))
                                                this.props.dispatch_setComfortVersandPassword(this.state.password)
                                        }}
                                        type="submit"
                                    >
                                        Versenden
                                    </Button>
                                </Box>
                            </form>
                            <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                        </Box>
                    </Paper>
                </SimpleModalContainer>
                <StartSendPopup open={this.props.isSendingKonsil} closeStartSendPopup={this.closeStartSendPopup}/>
            </>
        );
    }
}

export const KonsilAnfrageReceiverContainer = connector(withTypingTimeout(KonsilAnfrageReceiver));
export default KonsilAnfrageReceiverContainer;


function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


interface ICardProps {
    title: string;
    receiver: Arzt;
    isSelected?: boolean;
    handleClick?: () => void;
}

export class RecieverCardComponent extends React.Component<ICardProps> {


    render() {
        return (
            <Box className="last-reciver" onClick={this.props.handleClick}>
                <Typography className="last-reciver-label bold-label" variant="subtitle1"
                            color="inherit">{this.props.title}</Typography>
                <Box
                    className={(this.props.receiver.nachname !== "" && this.props.receiver.nachname !== undefined) ? (this.props.isSelected) ? "reciver-card active" : "last-reciver-card" : "reciver-card"}>
                    <Typography variant="h4"
                                color="inherit">{(this.props.receiver.nachname !== "" && this.props.receiver.nachname !== undefined) ? [this.props.receiver.titel, this.props.receiver.vorname, this.props.receiver.nachname].filter(item => (item !== null && item !== "")).join(" ") : "Dr. Vorname Nachname"}</Typography>
                    <Typography variant="caption" color="inherit">Dermatologe</Typography>
                    <Typography className="reciver-credentials" variant="caption"
                                color="inherit">LANR: {this.props.receiver.lanr}</Typography>
                    <Typography className="reciver-credentials" variant="caption"
                                color="inherit">BSNR: {this.props.receiver.betriebsstaette?.bsnr || ""}</Typography>
                    <Typography variant="caption"
                                color="inherit">{(this.props.receiver.betriebsstaette?.strasseHausnummer) ? this.props.receiver.betriebsstaette?.strasseHausnummer + ", " : "Strasse und Hausnummer"}</Typography>
                    <Typography variant="caption"
                                color="inherit">{(this.props.receiver.betriebsstaette?.plz && this.props.receiver.betriebsstaette?.ort) ? this.props.receiver.betriebsstaette?.plz + " " + this.props.receiver.betriebsstaette?.ort : "PLZ und Ort"}</Typography>
                </Box>
            </Box>
        )
    }
}


interface IPickerProps {
    konsiliareList: Arzt[]; //filteredKonsiliare
    groupList: KonsilEmpfaengerGruppe[]; //filteredKonsiliare
    anchorEl: any; // element type
    handleMenuClose: () => void;
    handleMenuSelect: (konsiliar: Arzt) => void;
    handleGruppenMenuSelect: (group: KonsilEmpfaengerGruppe) => void;
    context: "INDIVIDUAL" | "GROUP";
}

export class RecieverPickerComponent extends React.Component<IPickerProps> {
    public static defaultProps = {
        konsiliareList: [],
        groupList: [],
        handleMenuSelect: () => {
        },
        handleGruppenMenuSelect: () => {
        },
    };


    render() {
        return (
            <Menu
                anchorEl={this.props.anchorEl}
                keepMounted
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.handleMenuClose}
                disableAutoFocus
                disableEnforceFocus
                autoFocus={false}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                className="reviever-picker"
            >
                {this.props.context === "INDIVIDUAL" &&
                <List style={{maxHeight: '300px', overflow: 'auto'}}>
                    {(this.props.konsiliareList.length > 0) && this.props.konsiliareList.map(konsiliar => (
                        <MenuItem key={(konsiliar.id || uuid.v4())}
                                  onClick={() => this.props.handleMenuSelect(konsiliar)}>
                            <Box className="receiver-picker-card">
                                <Typography variant="body1"
                                            color="inherit">{konsiliar.titel} {konsiliar.vorname} {konsiliar.nachname}</Typography>
                                <Typography variant="caption"
                                            color="inherit">Dermatologe</Typography>
                                <br/>
                                <Typography className="Receiver-credentials"
                                            variant="caption"
                                            color="inherit">LANR: {konsiliar.lanr}</Typography>
                                <Typography className="Receiver-credentials pad-left"
                                            variant="caption"
                                            color="inherit">BSNR: {konsiliar.betriebsstaette?.bsnr || ""}</Typography>
                                <br/>
                                <Typography variant="caption"
                                            color="inherit">{(konsiliar.betriebsstaette?.strasseHausnummer) ? konsiliar.betriebsstaette?.strasseHausnummer + ", " : ""}</Typography>
                                <Typography variant="caption"
                                            color="inherit">{(konsiliar.betriebsstaette?.plz && konsiliar.betriebsstaette?.ort) ? konsiliar.betriebsstaette?.plz + " " + konsiliar.betriebsstaette?.ort : ""}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    {(this.props.konsiliareList.length === 0) &&
                    <Box className="receiver-picker-nomatch">
                        <Typography variant="body1" color="inherit">
                            Keine Suchtreffer gefunden.
                        </Typography>
                    </Box>
                    }
                </List>
                }
                {this.props.context === "GROUP" &&
                <List style={{maxHeight: '300px', overflow: 'auto'}}>
                    {(this.props.groupList.length > 0) && this.props.groupList.map(gruppe => (
                        <MenuItem key={gruppe.empfaengergruppeIdentifikator}
                                  onClick={() => this.props.handleGruppenMenuSelect(gruppe)}>
                            <Box className="receiver-picker-card">
                                <Typography variant="body1" color="inherit">
                                    {`${gruppe?.empfaengergruppeIdentifikator} \u2022 ${gruppe?.empfaengergruppeBezeichnung}`}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    {(this.props.groupList.length === 0) &&
                    <Box className="receiver-picker-nomatch">
                        <Typography variant="body1" color="inherit">
                            Keine Suchtreffer gefunden.
                        </Typography>
                    </Box>
                    }
                </List>
                }
            </Menu>
        )
    }
}

