import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import uuid from 'uuid';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Paper, Box, Button, Divider} from '@material-ui/core';
import { Replay, Delete, Add } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { ZertifikatMetainformation, Arzt, ZertifikatService, ZertifikatStatus, Failure, } from 'telescan-core';
import { RootState } from '../../redux/reducers';
import { setBetriebsstaettenAerzte, loading, loadSuccess, loadZertifikate, failure} from '../../redux/actions';
import CreateZertifikatPopupComponent from './createcertificate_popup';
import ArztInfoModalComponent from './arztinfo_popup';
import UpdateZertifikatPopupComponent from './updatecertificate_popup';
import { ViewText } from '../konsil';
import { needsUpdate } from '../../utils/utils';
import SimpleModalContainer from '../elements/simple_modal';

export interface ArztRow {
    id: string;
    lanr: string;
    inhaber: string;
    arzt: Arzt;
    zertifikat: ZertifikatMetainformation;
    arztIndex: number;
}

interface TableState {
    arztInfo: ArztRow | undefined;
    zertifikatInfo: ZertifikatMetainformation | undefined;
    isOpen: boolean;
}

interface TableProps {
    data: ArztRow[]
    toggleArztModal: (arzt: Arzt, isAdd: boolean) => void;
    isOpenArzt: boolean,
    arzt: Arzt, // is only passed through
    isAdd: boolean;
}

const mapStateToProps = (state: RootState, ownProps: TableProps) => ({
    user: state.user.user,
    role: state.user.role,
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    initialisationStatus: state.user.initialisationStatus,
    ...ownProps
})


const mapDispatchToProps = {
    dispatch_setBetriebsstaettenAerzte: (aerzte: Arzt[]) => setBetriebsstaettenAerzte(aerzte),
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_loadZertifikate: () => loadZertifikate(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class ArztInfoTable extends React.Component<TPropsFromRedux, TableState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            arztInfo: undefined,
            zertifikatInfo: undefined,
            isOpen: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.openZertifikatInfoModal = this.openZertifikatInfoModal.bind(this);
        this.deleteArzt = this.deleteArzt.bind(this);
        this.updateAerzte = this.updateAerzte.bind(this);
        this.createArztCertificate = this.createArztCertificate.bind(this);
        this.refreshZertifikat = this.refreshZertifikat.bind(this);
    }


    toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    openZertifikatInfoModal(data: ZertifikatMetainformation) {
        this.setState({zertifikatInfo: data});
        this.toggleModal();
    }

    deleteArzt(id: string) {
        let aerzte = this.props.basisdatenAerzte.filter((item) => item.id !== id);
        this.props.dispatch_setBetriebsstaettenAerzte(aerzte);
    }

    updateAerzte(arzt: Arzt) {
        arzt.bsnr = localStorage.getItem("BSNR") || undefined;
        //arzt.bsnr = this.state.basisdatenBetriebsstaette.bsnr; // move to better place
        if (this.props.basisdatenAerzte.length === 0 || (this.props.basisdatenAerzte.length === 1 && !this.props.basisdatenAerzte[0].id)){
            this.props.dispatch_setBetriebsstaettenAerzte([arzt]);
        }
        else {
            let aerzte = this.props.basisdatenAerzte;
            let matchingArztIndex: number = aerzte.findIndex((value, index, obj) => value.id === arzt.id)
            if (matchingArztIndex !== -1){
                aerzte[matchingArztIndex] = arzt}
            else {
                // this.props.basisdatenAerzte.push(arzt)
                console.log("neuer Arzt muss hinzugefügt werden")
            }
            this.props.dispatch_setBetriebsstaettenAerzte(aerzte);
        }
    }

    createArztCertificate(password: string, identifikationsCode: string, setZertifikat: Function, toggleModal: Function) {
        const loadInstanceIdArzt = uuid.v4();
        this.props.dispatch_loading(loadInstanceIdArzt);
        const zertifikatService = new ZertifikatService();
        zertifikatService.createArztZertifikat(password, identifikationsCode).then((metaInfo) => {
            this.props.dispatch_loadSuccess(loadInstanceIdArzt);
            setZertifikat(metaInfo);
            this.props.dispatch_loadZertifikate();
            toggleModal();
        }).catch((error) => {
            this.props.dispatch_failure(loadInstanceIdArzt, error);
        })
    }

    refreshZertifikat(altesPassword: string, neuesPassword: string, loadInstanceId: string, setZertifikat: Function, toggleModal: Function) {
        this.props.dispatch_loading(loadInstanceId);
        const zertifikatService = new ZertifikatService();
        zertifikatService.updateArztZertifikat(altesPassword, neuesPassword).then((metaInfo) => {
            this.props.dispatch_loadSuccess(loadInstanceId);
            setZertifikat(metaInfo);
            this.props.dispatch_loadZertifikate();
            toggleModal();
        }).catch((error) => {
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    render() {
        return (
            <>
                <TableContainer className="zertifikate-table selectable-rows">
                    <Table className="table-connector" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>LANR</TableCell>
                                <TableCell>Arzt</TableCell>
                                <TableCell>Zertifikat gültig bis</TableCell>
                                <TableCell>Zertifikat-Status</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map(rowData => (
                                <ArztRowComponent key={rowData.arzt.lanr} initialisationStatus={this.props.initialisationStatus} createArztCertificate={this.createArztCertificate}
                                    openZertifikatInfoModal={this.openZertifikatInfoModal} refreshZertifikat={this.refreshZertifikat}
                                    rowData={rowData} toggleArztModal={this.props.toggleArztModal}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ArztInfoModalComponent isAdd={this.props.isAdd} 
                    index={this.props.basisdatenAerzte.findIndex((value, index) => value.id === this.props.arzt.id)} 
                    updateAerzte={this.updateAerzte} toggleModal={this.props.toggleArztModal} 
                    isOpen={this.props.isOpenArzt} arzt={this.props.arzt}/>
                <ZertifikatInfoModal zertifikat={this.state.zertifikatInfo} toggleModal={this.toggleModal} isOpen={this.state.isOpen}/>
            </>
        );
    }
}

export const ArztInfoTableContainer = connector(ArztInfoTable);
export default ArztInfoTableContainer;



interface RowState {
    rowData: ArztRow;
    isOpenCreateZertifikat: boolean;
    isOpenUpdateZertifikat: boolean;
}

interface RowProps {
    rowData: ArztRow;
    initialisationStatus: any;
    openZertifikatInfoModal: (data: ZertifikatMetainformation) => void;
    toggleArztModal: (arzt: Arzt, isAdd: boolean) => void;
    createArztCertificate: (password: string, identifikationsCode: string, setZertifikat: Function, toggleModal: Function) => void;
    refreshZertifikat: (altesPassword: string, neuesPassword: string, loadInstanceId: string, setZertifikat: Function, toggleModal: Function) => void;
}

export class ArztRowComponent extends React.Component<RowProps, RowState> {
    constructor(props: RowProps) {
        super(props);
        this.state = {
            rowData: this.props.rowData,
            isOpenCreateZertifikat: false,
            isOpenUpdateZertifikat: false,
        };

        this.toggleCreateZertifikatModal = this.toggleCreateZertifikatModal.bind(this);
        this.toggleUpdateZertifikatModal = this.toggleUpdateZertifikatModal.bind(this);
        this.setZertifikat = this.setZertifikat.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<RowProps>, prevState: Readonly<RowState>) {
        if (prevProps.rowData !== this.props.rowData) {
            this.setState({
                rowData: this.props.rowData
            });
        }
    }

    toggleCreateZertifikatModal() {
        this.setState({
            isOpenCreateZertifikat: !this.state.isOpenCreateZertifikat
        })
    }

    toggleUpdateZertifikatModal() {
        this.setState({
            isOpenUpdateZertifikat: !this.state.isOpenUpdateZertifikat
        })
    }

    setZertifikat(metaInfo: ZertifikatMetainformation) {
        this.setState({
            rowData: {
                ...this.state.rowData,
                zertifikat: metaInfo
            }
        })
    }


    render() {
        return (
            <>
            <TableRow hover key={this.state.rowData.id} onClick={() => this.props.toggleArztModal(this.state.rowData.arzt, false)}>
                <TableCell>{this.state.rowData.lanr}</TableCell>
                <TableCell>{this.state.rowData.inhaber || '-'}</TableCell>
                <TableCell><span className={(this.state.rowData.zertifikat?.gueltigBis && needsUpdate(new Date(this.state.rowData.zertifikat?.gueltigBis), 3))? "failure": ""}>{(this.state.rowData.zertifikat?.bsnr)? (this.state.rowData.zertifikat?.gueltigBis)? new Date(this.state.rowData.zertifikat?.gueltigBis).toLocaleDateString(): "keine Angabe": "kein Zertifikat vorhanden"}</span></TableCell>
                <TableCell><span className={(this.state.rowData.zertifikat?.status && ZertifikatStatus[this.state.rowData.zertifikat?.status] !== ZertifikatStatus.AKTIV)? "failure": ""}>{(this.state.rowData.zertifikat?.status)? ZertifikatStatus[this.state.rowData.zertifikat?.status] : "keine Angabe"}</span></TableCell>
                <TableCell  align="right">
                    {(this.props.initialisationStatus.standalone && this.state.rowData.lanr !== "" && this.state.rowData.lanr === localStorage.getItem("LANR")) &&
                    <Tooltip title="Arztzertifikat erstellen" placement="top" >
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Arztzertifikat erstellen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.toggleCreateZertifikatModal();
                            }}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                    }
                    {(this.props.initialisationStatus.standalone && this.state.rowData.lanr !== "" && this.state.rowData.lanr === localStorage.getItem("LANR")) &&
                    <Tooltip title="Arztzertifikat aktualisieren" placement="top" >
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Arztzertifikat aktualisieren"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.toggleUpdateZertifikatModal();
                            }}>
                            <Replay/>
                        </IconButton>
                    </Tooltip>
                    }
                    {this.props.initialisationStatus.standalone &&
                    <Tooltip title="Zertifikatinfos anzeigen" placement="top">
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Zertifikatinfos anzeigen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.props.openZertifikatInfoModal(this.state.rowData.zertifikat);}}>
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                    }
                    {/* <Tooltip title="Arzt löschen" placement="top"> */}
                    {this.props.initialisationStatus.standalone &&
                        <IconButton
                            disabled
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Arzt löschen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                //this.deleteArzt(rowData.arzt.id || "");
                            }}>
                            <Delete/>
                        </IconButton>
                    }
                    {/* </Tooltip> */}
                </TableCell>
            </TableRow>
            <CreateZertifikatPopupComponent type="Arzt" 
                createZertifikat={(identificationCode: string, password: string) => this.props.createArztCertificate(password, identificationCode, 
                this.setZertifikat, this.toggleCreateZertifikatModal)} toggleModal={this.toggleCreateZertifikatModal} 
                isOpen={this.state.isOpenCreateZertifikat}/>
            <UpdateZertifikatPopupComponent type="Arzt" 
                refreshZertifikat={(altesPassword: string, neuesPassword: string, loadInstanceId: string) => this.props.refreshZertifikat(altesPassword, neuesPassword, loadInstanceId, 
                this.setZertifikat, this.toggleUpdateZertifikatModal)} toggleModal={this.toggleUpdateZertifikatModal} 
                isOpen={this.state.isOpenUpdateZertifikat}/>
            </>
        );
    }
}



interface ZertifikatInfoProps {
    isOpen: boolean;
    toggleModal: () => void;
    zertifikat: ZertifikatMetainformation | undefined;
}

export class ZertifikatInfoModal extends React.Component<ZertifikatInfoProps> {

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper settings-certificate-pop">
                    <h2 className="modal-header">Zertifikatinfos</h2>
                    <Box className="modal-content label-content-grid">
                        <ViewText labelVariant='subtitle1'
                            label='BSNR'
                            text={this.props.zertifikat?.bsnr || "-"}
                        />
                        {this.props.zertifikat?.lanr != null &&
                        <ViewText labelVariant='subtitle1'
                            label='LANR'
                            text={this.props.zertifikat?.lanr || "-"}
                        />
                        }
                        <Divider />
                        <ViewText labelVariant='subtitle1'
                            label='Inhaber'
                            text={this.props.zertifikat?.inhaber || "-"}
                        />
                        <ViewText labelVariant='subtitle1'
                            label='Seriennummer'
                            text={this.props.zertifikat?.seriennummer || "-"}
                        />
                        <ViewText labelVariant='subtitle1'
                            label='Gültig ab'
                            text={(this.props.zertifikat?.gueltigVon)? new Date(this.props.zertifikat?.gueltigVon).toLocaleDateString() : "-"}
                        />
                        <ViewText labelVariant='subtitle1'
                            label='Gültig bis'
                            text={(this.props.zertifikat?.gueltigBis)? new Date(this.props.zertifikat?.gueltigBis).toLocaleDateString() : "-"}
                        />
                        <ViewText labelVariant='subtitle1'
                            label='Status'
                            text={(this.props.zertifikat?.status)? ZertifikatStatus[this.props.zertifikat?.status] : "-"}
                        />
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="contained" color="primary" onClick={() => this.props.toggleModal()}>
                            OK
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
