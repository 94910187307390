import { DermatoscopeType, KonsilImageType } from "telescan-core";

export enum EImageAttachmentActions {
    LOAD_KONSIL_IMAGE = "LOAD_KONSIL_IMAGE",
    ADD_KONSIL_IMAGE = "ADD_KONSIL_IMAGE",
    REMOVE_KONSIL_IMAGE = "REMOVE_KONSIL_IMAGE",
    CLEAR_KONSIL_IMAGE_LIST = "CLEAR_KONSIL_IMAGE_LIST",
    CLEAR_RUECKANTWORT_IMAGE_LIST = "CLEAR_RUECKANTWORT_IMAGE_LIST",
    UPLOAD_KONSIL_IMAGE_PROCESS = "UPLOAD_KONSIL_IMAGE_PROCESS",
    ADD_ATTACHMENT = "ADD_ATTACHMENT",
    REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT",
    REMOVE_RUECKANTWORT_ATTACHMENT = "REMOVE_RUECKANTWORT_ATTACHMENT",
    CLEAR_ATTACHMENT_LIST = "CLEAR_ATTACHMENT_LIST",
    UPLOAD_ATTACHMENT_PROCESS = "UPLOAD_ATTACHMENT_PROCESS",
    UPLOAD_RUECKANTWORT_ATTACHMENT_PROCESS = "UPLOAD_RUECKANTWORT_ATTACHMENT_PROCESS",
    CHANGE_KONSIL_IMAGE_TYPE = "CHANGE_KONSIL_IMAGE_TYPE",
    CHANGE_KONSIL_IMAGE_LOCATION = "CHANGE_KONSIL_IMAGE_LOCATION",
    ADD_RUECKANTWORT_IMAGE = "ADD_RUECKANTWORT_IMAGE",
    LOAD_RUECKANTWORT_IMAGE = "LOAD_RUECKANTWORT_IMAGE",
    REMOVE_RUECKANTWORT_IMAGE = "REMOVE_RUECKANTWORT_IMAGE",
    CHANGE_RUECKANTWORT_IMAGE_TYPE = "CHANGE_RUECKANTWORT_IMAGE_TYPE",
    ADD_RUECKANTWORT_ATTACHMENT = "ADD_RUECKANTWORT_ATTACHMENT",
    CLEAR_RUECKANTWORT_ATTACHMENT_LIST = "CLEAR_RUECKANTWORT_ATTACHMENT_LIST",
    LOAD_ATTACHMENT = "LOAD_ATTACHMENT",
    LOAD_RUECKANTWORT_ATTACHMENT = "LOAD_RUECKANTWORT_ATTACHMENT",
    SET_IS_FIRST_IMAGE_LOADING = "SET_IS_FIRST_IMAGE_LOADING",
    CHANGE_KONSIL_IMAGE_LOADING_STATUS = "CHANGE_KONSIL_IMAGE_LOADING_STATUS",
}

export interface ILoadKonsilImage {
    readonly type: EImageAttachmentActions.LOAD_KONSIL_IMAGE;
    readonly image_id: string;
    readonly url: string;
    readonly loading: boolean;
}

export interface ILoadRueckantwortImage {
    readonly type: EImageAttachmentActions.LOAD_RUECKANTWORT_IMAGE;
    readonly image_id: string;
    readonly url: string;
    readonly loading: boolean;
}

export interface IAddKonsilImage {
    readonly type: EImageAttachmentActions.ADD_KONSIL_IMAGE;
    readonly dateCreated: number;
    readonly dateRecorded: number;
    readonly image_id: string;
    readonly image_type: KonsilImageType;
    readonly loading: boolean;
    readonly originalFilename: string;
    readonly dermatoscopeType: keyof typeof DermatoscopeType;
}

export interface IChangeKonsilImageLoadingStatus {
    readonly type: EImageAttachmentActions.CHANGE_KONSIL_IMAGE_LOADING_STATUS;
    readonly image_id: string;
    readonly loading: boolean;
}

export interface IAddRueckantwortImage {
    readonly type: EImageAttachmentActions.ADD_RUECKANTWORT_IMAGE;
    readonly dateRecorded: number;
    readonly image_id: string;
    readonly image_type: KonsilImageType;
    readonly loading: boolean;
    readonly originalFilename: string;
}

export interface IRemoveKonsilImage {
    readonly type: EImageAttachmentActions.REMOVE_KONSIL_IMAGE;
    readonly image_id: string;
}

export interface IRemoveRueckantwortImage {
    readonly type: EImageAttachmentActions.REMOVE_RUECKANTWORT_IMAGE;
    readonly image_id: string;
}

export interface IChangeRueckantwortImageType {
    readonly type: EImageAttachmentActions.CHANGE_RUECKANTWORT_IMAGE_TYPE;
    readonly imageType: KonsilImageType;
    readonly image_id: string;
}

export interface IClearKonsilImageList {
    readonly type: EImageAttachmentActions.CLEAR_KONSIL_IMAGE_LIST;
}

export interface IClearRueckantwortImageList {
    readonly type: EImageAttachmentActions.CLEAR_RUECKANTWORT_IMAGE_LIST;
}

export interface IUploadKonsilImageProcess {
    readonly type: EImageAttachmentActions.UPLOAD_KONSIL_IMAGE_PROCESS;
    readonly percent: number;
    readonly image_id: string;
}

export interface IChangeKonsilImageType {
    readonly type: EImageAttachmentActions.CHANGE_KONSIL_IMAGE_TYPE;
    readonly imageType: KonsilImageType;
    readonly image_id: string;
}

export interface IChangeKonsilImageLocation {
    readonly type: EImageAttachmentActions.CHANGE_KONSIL_IMAGE_LOCATION;
    readonly location: string;
}

export interface IAddAttachment {
    readonly type: EImageAttachmentActions.ADD_ATTACHMENT;
    readonly date: any;
    readonly attachment_id: string;
    readonly filename: string;
    readonly originalFilename: string;
    readonly loading: boolean;
}

export interface IAddRueckantwortAttachment {
    readonly type: EImageAttachmentActions.ADD_RUECKANTWORT_ATTACHMENT;
    readonly date: any;
    readonly attachment_id: string;
    readonly filename: string;
    readonly originalFilename: string;
    readonly loading: boolean;
}

export interface ILoadAttachment {
    readonly type: EImageAttachmentActions.LOAD_ATTACHMENT;
    readonly attachment_id: string;
    readonly data: string;
    readonly loading: boolean;
}

export interface ILoadRueckantwortAttachment {
    readonly type: EImageAttachmentActions.LOAD_RUECKANTWORT_ATTACHMENT;
    readonly attachment_id: string;
    readonly data: string;
    readonly loading: boolean;
}

export interface IRemoveAttachment {
    readonly type: EImageAttachmentActions.REMOVE_ATTACHMENT;
    readonly attachment_id: string;
}

export interface IRemoveRueckantwortAttachment {
    readonly type: EImageAttachmentActions.REMOVE_RUECKANTWORT_ATTACHMENT;
    readonly attachment_id: string;
}

export interface IClearAttachmentList {
    readonly type: EImageAttachmentActions.CLEAR_ATTACHMENT_LIST;
}

export interface IClearRueckantwortAttachmentList {
    readonly type: EImageAttachmentActions.CLEAR_RUECKANTWORT_ATTACHMENT_LIST;
}

export interface IUploadAttachmentProcess {
    readonly type: EImageAttachmentActions.UPLOAD_ATTACHMENT_PROCESS;
    readonly percent: number;
    readonly attachment_id: string;
}

export interface IUploadRueckantwortAttachmentProcess {
    readonly type: EImageAttachmentActions.UPLOAD_RUECKANTWORT_ATTACHMENT_PROCESS;
    readonly percent: number;
    readonly attachment_id: string;
}

export interface ISetIsFirstImageLoading {
    readonly type: EImageAttachmentActions.SET_IS_FIRST_IMAGE_LOADING;
    readonly isImageLoading: boolean;
}



export type TImageAttachmentActionTypes =
    ILoadKonsilImage |
    IAddKonsilImage |
    IRemoveKonsilImage |
    IClearKonsilImageList |
    IUploadKonsilImageProcess |
    IChangeKonsilImageType |
    IChangeKonsilImageLocation |
    IAddAttachment |
    IRemoveAttachment |
    IClearAttachmentList |
    IUploadAttachmentProcess |
    IAddRueckantwortImage |
    ILoadRueckantwortImage |
    IRemoveRueckantwortImage |
    IChangeRueckantwortImageType |
    IAddRueckantwortAttachment |
    IRemoveRueckantwortAttachment |
    IClearRueckantwortAttachmentList |
    IClearRueckantwortImageList |
    IUploadRueckantwortAttachmentProcess |
    ILoadAttachment |
    ILoadRueckantwortAttachment |
    ISetIsFirstImageLoading |
    IChangeKonsilImageLoadingStatus;