import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';
import {
    Quartalsabrechnungen,
    AbrechnungService,
    QuartalsabrechnungUpdate,
    StatusQuartalsabrechnung,
} from 'telescan-core';
import * as AbrechnungTypes from '../types/abrechnung_types';
import {RootState} from '../reducers';
import {loadSuccess} from '.';
import { loading, failure, navigate, setSuccessMessage } from './general_actions';

export function setQuartalsAbrechnungen(quartalsabrechnungen: Quartalsabrechnungen[]): AbrechnungTypes.ISetQuartalsabrechnungenAction {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_QUARTALSABRECHNUNGEN,
        quartalsabrechnungen: quartalsabrechnungen,
    }
}

export function setCurrentQuartalsAbrechnung (currentQuartalsabrechnung: Quartalsabrechnungen): AbrechnungTypes.ISetCurrentQuartalsabrechnungAction {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_CURRENTQUARTALSABRECHNUNG,
        currentQuartalsabrechnung: currentQuartalsabrechnung,
    }
}

export function setNewInQuartalsBill(updatedQuartalsBill: QuartalsabrechnungUpdate): AbrechnungTypes.ISetNewAvailableBills {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_NEW_AVAILABLE_BILLS,
        quartalsabrechnungUpdate: updatedQuartalsBill,
    }
}

export function setKonsilabrechnungenStatus(id: string): AbrechnungTypes.ISetKonsilabrechnungenStatus {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_KONSILABRECHNUNGEN_STATUS,
        quartalsabrechnungId: id,
    }
}

export function setCurrentQuartalsAbrechnungStatus(status: keyof typeof StatusQuartalsabrechnung) {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_STATUS,
        status: status,
    }
}

export function setKonsilabrechnungInAbrechnungFlag(konsilabrechnungId: string, isInAbrechnung: boolean) {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_KONSILABRECHNUNG_IN_ABRECHNUNG_FLAG,
        konsilabrechnungId: konsilabrechnungId,
        isInAbrechnung: isInAbrechnung
    }
}

export function setCurrentQuartalsabrechnungIsInProgress(isInProgress: boolean) {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_IS_IN_PROGRESS,
        isInProgress: isInProgress,
    }
}

export function setIsKonsilFromBillingOpened(billId: string, isKonsilFromBillingOpened: boolean) {
    return {
        type: AbrechnungTypes.EAbrechnungActions.SET_IS_KONSIL_FROM_BILLING_OPENED,
        isKonsilFromBillingOpened: isKonsilFromBillingOpened,
        billId: billId,
    }
}

export function getAllBills(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));
        return new Promise((resolve, reject) => {
            const abrechnungService: AbrechnungService = new AbrechnungService();
            abrechnungService.getAllQuartalsabrechnungen()
            .then(quartalsabrechnungen => {
                resolve(quartalsabrechnungen);
                dispatch(loadSuccess(loadInstanceId));
                dispatch(setQuartalsAbrechnungen(quartalsabrechnungen));
            })
            .catch(error => {
                reject(error);
                dispatch(failure(loadInstanceId, error));
            });
        });    
    }
}

export function getBill(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.getQuartalsabrechnung(id).then(
            (quartalsabrechnung: Quartalsabrechnungen) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(setCurrentQuartalsAbrechnung(quartalsabrechnung))
            }
        ).catch(
            error => {
                dispatch(failure(loadInstanceId, error));
            }
        );
    }
}

export function deleteBill(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.deleteQuartalsabrechnung(id).then(
            () => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(getAllBills())
            }
        )
        .catch(
            error => {
                dispatch(failure(loadInstanceId, error));
            }
        );
    }
}

export function createBill(bsnr: string, lanr: string, year: number, quartal: number): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.createQuartalsabrechnung(bsnr, lanr, year, quartal).then(
            (response) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(getBill(response.id || ""));
                dispatch(navigate(`/billing/${response.id}`))
            }
        )
        .catch(
            error => {
                dispatch(failure(loadInstanceId, error));
            }
        );
    }
}

export function toggleInAbrechnungStatus(id: string, status: boolean): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch) => {
        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.toggleStatus(id, status)
        .then(() => dispatch(setKonsilabrechnungInAbrechnungFlag(id, status)))
        .catch(
            error => {
                const failureId: string = uuid.v4();
                dispatch(failure(failureId, error));
            }
        );
    }
}

export function proveBillStatus(quartalsabrehnungId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setCurrentQuartalsabrechnungIsInProgress(true));
        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.proveStatus(quartalsabrehnungId)
        .then(() => {
            dispatch(setCurrentQuartalsabrechnungIsInProgress(false));
            dispatch(setCurrentQuartalsAbrechnungStatus("GEPRÜFT"));
        })
        .catch(error => {
            dispatch(setCurrentQuartalsabrechnungIsInProgress(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, error));
        });
    }
}

export function checkNewInQuartalsBill(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));
        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.checkNewInQuartalsabrechnung(id)
        .then((response: QuartalsabrechnungUpdate) => {
            dispatch(loadSuccess(loadInstanceId));
            dispatch(setNewInQuartalsBill(response));
            if (response.changed)
                dispatch(updateQuartalsBill(id));
        })
        .catch(error => {
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function updateQuartalsBill(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.updateQuartalsabrechnung(id)
        .then((quartalsabrechnung: Quartalsabrechnungen) => {
            dispatch(loadSuccess(loadInstanceId));
            //dispatch(setCurrentQuartalsAbrechnung(quartalsabrechnung));
            dispatch(getAllBills());
        })
    .catch(error => {
            dispatch(failure(loadInstanceId, error));
        });
    }
}

export function sendQuartalsBill(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setCurrentQuartalsabrechnungIsInProgress(true));
        const abrechnungService: AbrechnungService = new AbrechnungService();
        return abrechnungService.sendQuartalsabrechnung(id)
        .then((quartalsabrechnung: Quartalsabrechnungen) => {
            dispatch(setCurrentQuartalsabrechnungIsInProgress(false));
            dispatch(setCurrentQuartalsAbrechnung(quartalsabrechnung));
            dispatch(setSuccessMessage("Die Abrechnung wurde erfolgreich gesendet"));
        })
        .catch(error => {
            dispatch(setCurrentQuartalsabrechnungIsInProgress(false));
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}
