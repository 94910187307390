import React from 'react';
import {ViewTextHtml} from './view_text';
import {Box, FormLabel, Paper, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {Vorbehandlung} from 'telescan-core';
import {mapZeitEinheit} from '../vorbehandlung_display';
import { VorbehandlungDisplay } from '../vorbehandlung_display';

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewFragestellungContent extends React.Component<TPropsFromRedux> {

    assembleVorbehandlung(vorbehandlung: Vorbehandlung | undefined) {
        if (vorbehandlung == null) // || vorbehandlung?.beschreibung === "" || vorbehandlung?.beschreibung == null)
            return "keine Angaben"
        else
            return (((vorbehandlung?.beschreibung == null || vorbehandlung?.beschreibung === "") ? "keine Angabe" :
                vorbehandlung?.beschreibung) + ", " + ((vorbehandlung?.dauer == null || vorbehandlung?.dauer === "") ? "-" : vorbehandlung?.dauer)
                + " " + mapZeitEinheit[vorbehandlung?.einheit || "d"])
    }

    render() {
        return (
            <Box className="scroll-box">
                <Box className="data-content">
                    {/* Old veiw of free text
                        <ViewText
                        labelVariant='subtitle1' 
                        label='Frage'
                        text={(this.props.currentKonsil?.fragestellung && this.props.currentKonsil?.fragestellung !== "") ? this.props.currentKonsil?.fragestellung : "Es wurde keine Frage gestellt"}
                    />
                    */}
                    <ViewTextHtml
                        labelVariant='subtitle1' 
                        label='Frage'
                        textHtml = {this.props.currentKonsil.fragestellungHtml ? this.props.currentKonsil.fragestellungHtml: "Es wurde keine Frage gestellt"}
                    />
                </Box>
                <Box className = "pad-vorbehandlungen">
                    <Box className = "button-header">
                        <FormLabel className="bold-label" component="legend">Vorbehandlungen</FormLabel>                        
                    </Box>
                    {
                        this.props.currentKonsilPatient.vorbehandlung.length === 0 &&
                        <Typography color="inherit">
                            Keine Vorbehandlungen vorhanden
                        </Typography>
                    }
                    {
                        this.props.currentKonsilPatient.vorbehandlung.length>0 &&
                        this.props.currentKonsilPatient.vorbehandlung.map(item => {
                            return (
                                <Paper
                                    className = "pad margin-vorbehandlungen"
                                    key={"vorbehandlung_" + item.id}
                                    variant="outlined"
                                    elevation = {0}
                                >
                                    <VorbehandlungDisplay
                                        vorbehandlung = {item}
                                        isViewOnly = {true}
                                    />
                                </Paper>
                            )
                        })
                    }
                </Box>
            </Box>
        );
    }
}

export const ViewFragestellungContentContainer = connector(ViewFragestellungContent);
export default ViewFragestellungContentContainer;
