import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import { Patient, Arzt, Konsil } from 'telescan-core';
import { navigate, createKonsil, updateKonsil } from '../../redux/actions';
import {RootState} from "../../redux/reducers";

// material components
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {IconButton, Tooltip} from '@material-ui/core';
import SyncProblemOutlinedIcon from '@material-ui/icons/SyncProblemOutlined';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import InputBase from "@material-ui/core/InputBase";
import MenuItem from '@material-ui/core/MenuItem';
import {Typography, Popper, Grow, ClickAwayListener, MenuList} from '@material-ui/core';

// Icons
import SearchIcon from "@material-ui/icons/Search";
import SimpleModalContainer from '../elements/simple_modal';
import { Patienteneinwilligung } from 'telescan-core/lib/entities/patienteneinwilligung';



interface IState {
    open: boolean;
    anchorEl: HTMLInputElement | null;
    filteredPatienten: Patient[];
    selectedPatient: Patient | null;
    searchValue: string;
    menuOpen: boolean;
    einwilligungPatient: boolean;
    enableOK: boolean;
    enableAlert: boolean;
    defaultPatientValue: string;

}

interface IProps {
    open: boolean;
    patienten: Patient[];
    togglePatientModal: () => void;
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    userDetails: state.user.user,
    betriebsstaettenAerzte: state.user.betriebsstaettenAerzte,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_createKonsil: (patient: Patient, arzt: Arzt) => createKonsil(patient, arzt),
    dispatch_navigate: (url: string) => navigate(url),
    updateKonsil: (konsil: Konsil) => updateKonsil(konsil),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class PatientProfileSelect extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
           open: props.open,
           anchorEl: null,
           filteredPatienten: [],
           selectedPatient: null,
           searchValue: "",
           menuOpen: false,
           einwilligungPatient: false,
           enableOK: false,
           enableAlert: false,
           defaultPatientValue: "Patientensuche",
        };

        this.handlePatientClose = this.handlePatientClose.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.openKonsil = this.openKonsil.bind(this);
        this.handleSearchValue = this.handleSearchValue.bind(this);
        this.handleListKeyDown = this.handleListKeyDown.bind(this);

    }


    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (prevState.open !== this.props.open) {
            this.setState({
                open: this.props.open
            });
        }
    }
    handleSearchValue(e) {
        this.setState({
            searchValue: e.target.value
        });
    }

    handlePatientClose() {
        this.props.togglePatientModal();
        this.setState({
            //open: false,
            selectedPatient: null,
            searchValue: "",
            einwilligungPatient: false,
            enableAlert: false,
            enableOK: false,
            defaultPatientValue: "Patientensuche",

        });
    }

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.target,
            menuOpen: true
        });
    };

    handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
        this.setState({
            anchorEl: null,
            menuOpen: false
        });
    };

    handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            this.setState({
                menuOpen: false
            });
        }
    }

    handlePatientenSearchChange = (e: any) => {
        this.handleSearchValue(e);
        let currentList: Patient[] = this.props.patienten;
        let newList: Patient[] = [];
        let anchor: any = null;

        if (e.target.value !== "") { //search bar not empty
            newList = currentList.filter(item => {
                let patientInfo: string = [(item.vorname !== "")? item.vorname: undefined , (item.nachname !== "")? item.nachname: undefined, (item.geburtsdatum !== "")? new Date(item.geburtsdatum).toLocaleDateString(): undefined].filter(Boolean).join(" ");
                const lc = patientInfo.toLowerCase();
                // change search term to lowercase to deal with capitalization
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            this.setState({
                filteredPatienten: newList,
                anchorEl: e.currentTarget,
                menuOpen: true
            });
        } else { // search bar is empty
            newList = []
            this.setState({
                filteredPatienten: newList,
                anchorEl: anchor,
                menuOpen: false
            });
        }
    }

    handlePatientenMenuSelect = (event, patient: Patient) => {
        this.setState({
            selectedPatient: this.patientDataOlderThan(patient, 30)? null: patient,
            searchValue: "",
            enableOK: this.patientDataOlderThan(patient, 30)? false: true,
            enableAlert: this.patientDataOlderThan(patient, 30)? true: false,
            defaultPatientValue: ([(patient.title !== "")? patient.title: undefined , (patient.vorname !== "")? patient.vorname: undefined , (patient.nachname !== "")? patient.nachname: undefined , (patient.geburtsdatum !== "")? new Date(patient.geburtsdatum).toLocaleDateString(): undefined].filter(Boolean).join(" ")),
        });
    };


    addKonsil() {
        const arzt: Arzt = (this.props.userDetails.length >= 1)? this.props.userDetails[0]: new Arzt() // TODO: new arzt ist nicht gut
        //const arzt: Arzt = this.props.betriebsstaettenAerzte.filter((item) => item.lanr === String(user.lanr))[0]

        if (this.state.selectedPatient)
            this.props.dispatch_createKonsil(this.state.selectedPatient, arzt).then(
                (response) => {
                    let updatedKonsil: Konsil = {...response}
                    if (!updatedKonsil.patienteneinwilligung)
                        updatedKonsil.patienteneinwilligung = new Patienteneinwilligung();
                    updatedKonsil.patienteneinwilligung.einwilligungKonsil = this.state.einwilligungPatient;
                    updatedKonsil.patienteneinwilligung.dateKonsileinwilligung = Date.now();
                    this.props.updateKonsil(updatedKonsil).then(
                        () => {
                            this.openKonsil(updatedKonsil)}
                    );
                }
            ).then(() => this.handlePatientClose())
            .catch(error => console.log(error))
        else
            console.log("Kein Patient ausgewählt")
    }

    openKonsil(konsil: Konsil) {
        const url = 'konsil/' + konsil.id;
        this.props.dispatch_navigate(url);
    }


    patientDataOlderThan = (patient: Patient, days: number) => {
        var camparisonDate = new Date();
        camparisonDate.setDate(camparisonDate.getDate() - days);
        return (new Date(patient.lastUpdatedPVS)).getTime() <= camparisonDate.getTime();
    };



    render() {
        return (
            <SimpleModalContainer isOpen={this.state.open} onClose={this.handlePatientClose}>
                <Paper className="modal-wrapper add-new-konsil-pop">
                <h2 className="modal-header">Neuer Konsilauftrag</h2>

                <form noValidate autoComplete="off">
                <Box className="modal-content">
                    <Typography variant="caption" color="inherit" className="new-konsil-text">
                        Neue Konsilaufträge können aus TeleScan nur für Patienten angelegt werden, deren Patientendaten von
                        Ihrem Praxisverwaltungssystem (PVS) übertragen worden sind. Können Sie den gewünschten Patienten nicht
                        finden, übertragen Sie bitte zuerst Patientendaten oder legen Sie direkt in Ihrem PVS einen neuen
                        Konsilauftrag an.
                    </Typography>
                    {this.state.enableAlert?
                    <Typography variant="caption" color="inherit" className="new-konsil-text-alert">
                        Die im System hinterlegten Patientendaten sind älter als 30 Tage. Bitte übertragen Sie die Patientendaten aus Ihrem PVS erneut, bevor Sie einen neuen Konsilauftrag anlegen.
                    </Typography>
                    :
                    <Typography variant="caption" color="inherit" className="new-konsil-text">
                        Bitte beachten Sie: Neue Konsilaufträge können nur für solche Patienten angelegt werden, deren Patientendatenübertragung nicht länger als 30 Tage her ist.
                    </Typography>
                    }
                    <Box className="search-container">
                    {this.state.enableAlert?
                        <IconButton style={{ color: 'crimson' }} size="small" aria-label="sync-problem" disabled>
                            <SyncProblemOutlinedIcon />
                        </IconButton>
                        :
                        <IconButton
                            size="small"
                            className=""
                            aria-label="search"
                            disabled
                        >
                            <SearchIcon />
                        </IconButton>
                        }
                        <InputBase
                            autoComplete="off"
                            className={this.state.enableAlert? "search-input-alert":"search-input"}
                            placeholder={this.state.defaultPatientValue} //'Patientensuche'
                            id="search-input"
                            value={this.state.searchValue}
                            inputProps={{ "aria-label": "Suche" }}
                            //onClick={this.handleMenuOpen}
                            onChange={this.handlePatientenSearchChange}
                            aria-controls={this.state.menuOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            autoFocus
                        />
                    </Box>
                    <Popper className="search-results-list" open={this.state.menuOpen} anchorEl={this.state.anchorEl} disablePortal role={undefined} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            >
                            <Paper variant="outlined">
                                <ClickAwayListener onClickAway={this.handleMenuClose}>
                                <MenuList //autoFocusItem={this.state.menuOpen}
                                    className="patient-picker"
                                    id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                    {(this.state.filteredPatienten.length === 0) ?
                                        <MenuItem >
                                            <Box className="patient-picker-card">
                                                <Typography>
                                                    Keine Suchtreffer gefunden.
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    :
                                    this.state.filteredPatienten.map((patient) => (
                                        <MenuItem key={patient.id}
                                            onClick={(event) => {this.handlePatientenMenuSelect(event, patient); this.state.anchorEl?.focus(); this.handleMenuClose(event);} }
                                            selected={patient === this.state.selectedPatient}
                                        >
                                            <Box className="patient-picker-card">
                                                <Tooltip title={this.patientDataOlderThan(patient,30)? "ACHTUNG! Die Patientendaten wurden vor mehr als 30 Tagen übertragen. Bitte übertragen Sie die Daten erneut aus Ihrem PVS. Damit stellen Sie sicher, dass die Patientendaten aktuell sind." : ""} placement="top">
                                                    <Typography>{[(patient.title !== "")? patient.title: undefined ,
                                                        (patient.vorname !== "")? patient.vorname: undefined ,
                                                        (patient.nachname !== "")? patient.nachname: undefined ,
                                                        new Date(patient.geburtsdatum).toLocaleDateString()].filter(Boolean).join(" ")}
                                                        {this.patientDataOlderThan(patient,7) && !this.patientDataOlderThan(patient,30) &&
                                                        <Tooltip title="Der Patient wurde vor mehr als 7 Tagen übertragen. Sollten sich die Patientendaten (z.B. Diagnosen, Medikation, ...) in dieser Zeit geändert haben, übertragen Sie den Patienten aus Ihrem PVS erneut" placement="top">
                                                            <IconButton size="small" aria-label="sync-problem">
                                                                <SyncProblemOutlinedIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        }
                                                        {this.patientDataOlderThan(patient, 30) &&
                                                        <IconButton size="small" aria-label="sync-error">
                                                            <SyncDisabledIcon/>
                                                        </IconButton>
                                                        }
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                    </Popper>
                    {/*
                    <FormGroup className="form-grid-content">

                        <FormControlLabel className="pad-bottom"
                            disabled={this.state.selectedPatient == null}
                            control={<Checkbox name="einwilligung-versand-gerichtet"
                                checked={this.state.einwilligungPatient}
                                onChange={(event) => {
                                    this.setState({
                                        einwilligungPatient: event.target.checked
                                    });
                                }}
                                />
                            }
                            label={<Typography variant="subtitle1">Einwilligungserklärung Patient</Typography>}
                        />
                    </FormGroup>
                    <Typography variant="caption">
                    Die Einwilligung des Patienten in den Konsilauftrag beinhaltet das Einverständnis in die Anfertigung des Konsilauftrags und grundsätzlich das Einverständnis in den Versand dieses Konsils an eine Gruppe von Dermatologen (gerichteter Versand).
                    </Typography>
                    {/* <FormGroup className="form-grid-content">
                        <FormLabel className="pad-bottom bold-label"
                            component="legend">Einwilligungserklärung Patient
                        </FormLabel>
                        <FormControlLabel
                            disabled={this.state.selectedPatient == null}
                            control={<Checkbox name="einwilligung-versand-gerichtet"
                                checked={this.state.einwilligungPatient}
                                onChange={(event) => {
                                    this.setState({
                                        einwilligungPatient: event.target.checked
                                    });
                                }}
                                />
                            }
                            label="PLATZHALTER: Die Einwilligung des Patienten in den Konsilauftrag beinhaltet neben dem
                                Einverständnis in die Anfertigung des Konsilauftrags auch das Einverständinis mit dem Versand
                                dieses Konsils an eine Gruppe von Dermatologen."
                        />
                    </FormGroup> */}
                </Box>

                <Box className="modal-footer">
                    <Button variant="text" onClick={this.handlePatientClose}>
                        Abbrechen
                    </Button>
                    {this.state.enableOK &&
                    <Button variant="contained" color="primary" type="submit" onClick={(event) => {event.preventDefault(); this.addKonsil();}}>
                        Weiter
                    </Button>
                    }
                    {!this.state.enableOK &&
                    <Button variant="contained" type="submit" disabled>
                        Weiter
                    </Button>
                    }
                </Box>
                </form>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
export const PatientProfileSelectContainer = connector(PatientProfileSelect);
export default PatientProfileSelectContainer;
