import React from "react";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';

import {Box, Button, Chip, Snackbar, Tab, Tabs, Tooltip} from "@material-ui/core";
import {Assignment, Close, ContactSupport, Description, MyLocation, Person, Send,} from "@material-ui/icons";
import {Alert, AlertTitle} from "@material-ui/lab";

import {KonsilStatusType} from 'telescan-core';


import Logo from "../../static/images/logo-telescan.svg";
import {UserContainer} from "../header"
import {RootState} from "../../redux/reducers";
import {
    addSendFailure,
    changeKonsilTab,
    clearPdfString,
    failure,
    getKonsilValidationStatus,
    getPdfString,
    handleVersandKonsil,
    loadArztKonsile,
    loadKonsil,
    navigate,
    setActiveAreas,
    setComfortVersandPassword,
    setIsComfortVersand,
    setIsKonsilFromBillingOpened,
    setIsSendingKonsil,
    setSuccessMessage,
} from '../../redux/actions';

import {KonsilStatus} from "../konsilliste";
import {TherapieDringlichkeit} from "telescan-core/lib/entities/konsil_abschluss";
import {StartSendPopup} from "../konsil/sending_results_popup";
import {a11yProps} from "./header_konsil";
import {withPdfPreviewCreation, WithPdfPreviewCreationProps} from "../../utils/with_pdf_preview";
import { Failure } from 'telescan-core';
import { KonsiliarberichtVersandPopup } from "../konsil/dermatologeComponents/konsiliarbericht_send_popup";

const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    tabIndex: state.konsile.tabIndex,
    tabStatus: state.evaluation.tabStatus,
    currentKonsil: state.konsile.current_konsil,
    user: state.user.user,
    konsiliarberichtErrors: state.evaluation.konsiliarberichtErrors,
    konsiliarFeedbackErrors: state.evaluation.konsiliarFeedbackErrors,
    location: state.router.location.pathname,
    role: state.user.role,
    activeAreas: state.dermatologe.activeAreas,
    isSendingKonsil: state.send_download_konsil.isSendingKonsil,
    isKonsilFromBillingOpened: state.quartalsabrechnungenStore.isKonsilFromBillingOpened,
    billId: state.quartalsabrechnungenStore.billId,
    isComfortVersand: state.general.isComfortVersand,
    comfortVersandPassword: state.general.comfortVersandPassword,
    pdfString: state.pdf.pdfString,
    isPdfLoading: state.pdf.isPdfLoading,
    konsilValidationStatus: state.konsile.konsilValidationStatus,
    konsilValidationError: state.konsile.konsilValidationError,
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_tabChange: (index: number) => changeKonsilTab(index),
    dispatch_successMessage: (message: string) => setSuccessMessage(message),
    dispatch_addSendFailure: (konsilID, failure) => addSendFailure(konsilID, failure),
    dispatch_loadArztKonsile: () => loadArztKonsile(),
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_setActiveAreas: (areaIndex: number, isActive: boolean) => setActiveAreas(areaIndex, isActive),
    dispatch_failure: (loadInstanceId: string, error: Failure) => failure(loadInstanceId, error),
    dispatch_handleVersandKonsil: (konsilId: string, password: string, eventText: string) => handleVersandKonsil(konsilId, password, eventText),
    dispatch_setIsSendingKonsil: (isSendingKonsil: boolean) => setIsSendingKonsil(isSendingKonsil),
    dispatch_setIsKonsilFromBillingOpened: (billId: string, isKonsilFromBillingOpened: boolean) => setIsKonsilFromBillingOpened(billId, isKonsilFromBillingOpened),
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => setIsComfortVersand(isComfortVersand),
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => setComfortVersandPassword(comfortVersandPassword),
    dispatch_getPdfString: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getPdfString(konsilId, konsilStatus),
    dispatch_clearPdfString: () => clearPdfString(),
    dispatch_getKonsilValidationStatus: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getKonsilValidationStatus(konsilId, konsilStatus),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithPdfPreviewCreationProps

interface IState {
    showId: boolean;
    password: string;
    openModal: boolean;
    hasPasswordChanged: boolean;
}

export class HeaderDermaKonsil extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showId: false,
            password: "",
            openModal: false,
            hasPasswordChanged: false,
        }
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.handleVersendenKonsiliarbericht = this.handleVersendenKonsiliarbericht.bind(this);
        this.closeStartSendPopup = this.closeStartSendPopup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.findActiveTab = this.findActiveTab.bind(this);
        this.handleClickAbsenden = this.handleClickAbsenden.bind(this);
        this.deactiveAreas = this.deactiveAreas.bind(this);
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.activeAreas !== this.props.activeAreas) {
            this.findActiveTab();
        }
        if (prevProps.tabIndex !== this.props.tabIndex) {
            if (this.props.tabIndex === 4)
                this.deactiveAreas();
        }
    }

    deactiveAreas() {
        console.log("DEACTIVATE")
        let i: number;
        for (i = 0; i < 4; i++) {
            this.props.dispatch_setActiveAreas(i, false);
        }
    }

    copyToClipboard(url: string) {
        var f = (e: ClipboardEvent) => {
            e.clipboardData?.setData("text/plain", url);
            e.preventDefault();
        };
        document.addEventListener("copy", f);
        document.execCommand("copy");
        document.removeEventListener("copy", f);
    };

    toggleModal() {
        this.setState({openModal: !this.state.openModal})
    }


    closeStartSendPopup() {
        this.props.dispatch_setIsSendingKonsil(false);
        this.props.dispatch_navigate("/");
    }

    handlePasswordChange(e) {
        this.setState({password: e.target.value});
        this.setState({hasPasswordChanged: true});
    }

    handleVersendenKonsiliarbericht() {
        if (this.props.currentKonsil.id) {
            this.toggleModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged)
            this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsiliarberichts zum Konsil mit der ID");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand des Konsiliarberichts zum Konsil mit der ID");
        }
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(failureId, failureObj);
            this.toggleModal();
        }
    }

    findActiveTab(): number {
        if (this.props.activeAreas[0] === true)
            return 0;
        else {
            if (this.props.activeAreas[1] === true)
                return 1;
            else {
                if (this.props.activeAreas[2] === true)
                    return 2;
                else {
                    if (this.props.activeAreas[3] === true)
                        return 3;
                    else
                        return 5;
                }
            }
        }
    }

    handleClickAbsenden(disabledAbsenden: boolean) {
        const loadInstanceId: string = uuid.v4();
        if (disabledAbsenden) {
            const errors = Array.from(this.props.konsiliarberichtErrors.entries()).concat(Array.from(this.props.konsiliarFeedbackErrors.entries()))
            let falsyElement = document.getElementById(errors[0][0]);
            if (falsyElement) {
                falsyElement.scrollIntoView({block: 'center'});
                falsyElement.focus();
            }
            let failure = new Failure();
            failure.error = errors[0][1];
            this.props.dispatch_failure(loadInstanceId, failure)
        } else {
            this.toggleModal();
            //this.props.createPdfPreview(this.props.currentKonsil.id || "", "BEFUNDET");
            this.props.dispatch_getKonsilValidationStatus(this.props.currentKonsil.id || "", "BEFUNDET");
            this.props.dispatch_clearPdfString();
            this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEFUNDET");
        }
    }


    render() {
        let sendError: string | undefined = undefined;
        if (this.props.currentKonsil.konsilStatus === "RUECKFRAGE")
            sendError = "Der Versand ist nicht möglich, bis Ihre Rückfrage beantwortet wurde!";

        const disabledAbsenden:
            boolean = this.props.konsiliarFeedbackErrors.size !== 0 ||
            this.props.konsiliarberichtErrors.size !== 0 ||
            this.props.currentKonsil.bearbeitungsStatus === "UPLOAD";
        return (
            <header className="header-konsil header-derma">
                <Box className="header-top-left-content">
                    <Button variant="text" size="small" className="btn-left slim"
                            onClick={() => {
                                if (this.props.isKonsilFromBillingOpened) {
                                    this.props.dispatch_setIsKonsilFromBillingOpened(this.props.billId, false);
                                    this.props.dispatch_navigate(`/billing/${this.props.billId}`);
                                }
                                else this.props.dispatch_navigate("/");
                            }}>
                        <Close/>
                    </Button>
                    {this.props.isDesktop &&
                    <Box className="title">
                        <h1>Konsil
                            <Tooltip title={this.props.currentKonsil?.id + " | Kopieren"}
                                     aria-label={this.props.currentKonsil?.id}>
                                <Chip size="small" color="primary" label="ID" className="konsil-id" variant="outlined"
                                      onClick={
                                          () => {
                                              this.setState({showId: true});
                                              this.copyToClipboard(this.props.currentKonsil?.id ? this.props.currentKonsil?.id : "");
                                          }
                                      }/>
                            </Tooltip>
                            <div className="konsil-status">
                                <KonsilStatus
                                    therapiedringlichkeit={(this.props.currentKonsil?.konsiliarbericht?.therapieDringlichkeit) ? TherapieDringlichkeit[this.props.currentKonsil?.konsiliarbericht?.therapieDringlichkeit] : undefined}
                                    hasPvsPatient={true}
                                    bearbeitungsStatus={this.props.currentKonsil?.bearbeitungsStatus}
                                    status={this.props.currentKonsil?.konsilStatus}
                                    isMetaData={false}
                                    role="DERMATOLOGE"
                                />
                            </div>
                        </h1>
                        <div
                            className="patient">{this.props.currentKonsil?.konsilPatient?.nachname}, {this.props.currentKonsil?.konsilPatient?.vorname}</div>
                    </Box>}
                    <Snackbar
                        open={this.state.showId}
                        autoHideDuration={5000}
                        onClose={() => this.setState({showId: false})}>
                        <Alert severity="info">
                            <AlertTitle>Konsil ID (in Zwischenablage kopiert)</AlertTitle>
                            {this.props.currentKonsil?.id}
                        </Alert>
                    </Snackbar>
                </Box>

                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <UserContainer userDetails={this.props.user}/>

                {!this.props.isDesktop &&
                <Box className="title">
                    <h1>Konsil
                        <Tooltip title={this.props.currentKonsil?.id + " | Kopieren"}
                                 aria-label={this.props.currentKonsil?.id}>
                            <Chip size="small" color="primary" label="ID" className="konsil-id" variant="outlined"
                                  onClick={
                                      () => {
                                          this.setState({showId: true});
                                          this.copyToClipboard(this.props.currentKonsil?.id ? this.props.currentKonsil?.id : "");
                                      }
                                  }/>
                        </Tooltip>
                    </h1>
                    <div
                        className="patient">{this.props.currentKonsil?.konsilPatient?.nachname}, {this.props.currentKonsil?.konsilPatient?.vorname}</div>
                </Box>
                }
                {this.props.isDesktop &&
                <Box className="derma-header-tabs">
                    <Box className="derma-tabs-left">
                        {[{
                            title: "Fragestellung",
                            component: <ContactSupport/>,
                            id: "question-link",
                            isActive: (this.findActiveTab() === 0)
                        },
                            {
                                title: "Lokalisation",
                                component: <MyLocation/>,
                                id: "location-link",
                                isActive: (this.findActiveTab() === 1)
                            },
                            {
                                title: "Anamnese",
                                component: <Assignment/>,
                                id: "anamnese-link",
                                isActive: (this.findActiveTab() === 2)
                            },
                            {
                                title: "Patientendaten",
                                component: <Person/>,
                                id: "patient-link",
                                isActive: (this.findActiveTab() === 3)
                            }
                        ].map((item) =>
                            <a href={"#" + item.id} key={item.id}
                               onClick={() => {
                                   this.props.dispatch_tabChange(0);
                               }}
                            >
                                <Button className={item.id + ((item.isActive) ? " active" : "")} variant="text">
                                    {item.component}&nbsp;{item.title}
                                </Button>
                            </a>
                        )
                        }
                    </Box>
                    <Box className="derma-tabs-right">
                        <Tabs
                            value={(this.props.tabIndex === 4) ? this.props.tabIndex : false}
                            onChange={(_, index) => {
                                this.props.dispatch_tabChange(index);
                            }}
                            variant="scrollable"
                            className="headerTabs"
                            scrollButtons="off"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example">

                            <Tab value={4}
                                 label="Konsiliarbericht"
                                 icon={<Description/>}
                                 {...a11yProps(4)}
                            />
                        </Tabs>
                        {(this.props.currentKonsil?.konsilStatus != null && this.props.currentKonsil?.konsilStatus !== "ABGESCHLOSSEN" &&
                            this.props.currentKonsil?.konsilStatus !== "BEFUNDET" && this.props.currentKonsil?.konsilStatus !== "RUECKFRAGE") &&
                        <Button //disabled={disabledAbsenden}
                            variant={(disabledAbsenden) ? "outlined" : "contained"} color="primary" size="small"
                            className="drema-send"
                            onClick={() => this.handleClickAbsenden(disabledAbsenden)}
                        >
                            Absenden
                        </Button>
                        }
                    </Box>
                </Box>
                }
                {!this.props.isDesktop &&
                <>
                    {(this.props.currentKonsil?.konsilStatus != null && this.props.currentKonsil?.konsilStatus !== "ABGESCHLOSSEN" &&
                        this.props.currentKonsil?.konsilStatus !== "BEFUNDET" && this.props.currentKonsil?.konsilStatus !== "RUECKFRAGE") &&
                        <Button variant={(disabledAbsenden) ? "outlined" : "contained"} color="primary" size="small"
                                className="btn-right slim" //disabled={disabledAbsenden}
                                onClick={() => this.handleClickAbsenden(disabledAbsenden)}>
                            <Send/>
                        </Button>
                    }
                    <Box className="derma-mobile-konsil-tabs">
                        <Tabs
                            value={this.props.tabIndex}
                            onChange={(_, index) => {
                                this.props.dispatch_tabChange(index);
                                let i;
                                for (i = 0; i < 4; i++) {
                                    this.props.dispatch_setActiveAreas(i, false);
                                }
                            }}
                            variant="scrollable"
                            className="headerTabs"
                            scrollButtons="off"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                        >
                            <Tab value={4}
                                 label="Konsiliarbericht"
                                 icon={<Description/>}
                                 {...a11yProps(4)}
                            />
                        </Tabs>
                        {[{
                            title: "Fragestellung",
                            component: <ContactSupport/>,
                            id: "question-link",
                            isActive: (this.findActiveTab() === 0)
                        },
                            {
                                title: "Bildaufnahmen",
                                component: <MyLocation/>,
                                id: "location-link",
                                isActive: (this.findActiveTab() === 1)
                            },
                            {
                                title: "Anamnese",
                                component: <Assignment/>,
                                id: "anamnese-link",
                                isActive: (this.findActiveTab() === 2)
                            },
                            {
                                title: "Patientendaten",
                                component: <Person/>,
                                id: "patient-link",
                                isActive: (this.findActiveTab() === 3)
                            }
                        ].map((item) =>
                            <a href={"#" + item.id}
                               onClick={() => {
                                   this.props.dispatch_tabChange(0);
                               }}
                            >
                                <Button className={item.id + ((item.isActive) ? " active" : "")} variant="text">
                                    {item.component}&nbsp;{item.title}
                                </Button>
                            </a>
                        )
                        }
                    </Box>
                </>}
                <KonsiliarberichtVersandPopup
                    konsilId={this.props.currentKonsil.id || ""}
                    //pdfPreviewData={this.props.pdfPreviewData}
                    pdfPreviewData = {this.props.pdfString}
                    isPdfLoading = {this.props.isPdfLoading}
                    openModal={this.state.openModal}
                    error={sendError}
                    password={this.state.password} toggleModal={this.toggleModal}
                    handleVersenden={this.handleVersendenKonsiliarbericht}
                    handlePasswordChange={this.handlePasswordChange}
                    dispatch_clearPdfString={this.props.dispatch_clearPdfString}
                    isComfortVersand = {this.props.isComfortVersand}
                    comfortVersandPassword = {this.props.comfortVersandPassword}
                    dispatch_setIsComfortVersand = {this.props.dispatch_setIsComfortVersand}
                    dispatch_setComfortVersandPassword = {this.props.dispatch_setComfortVersandPassword}
                    konsilValidationStatus = {this.props.konsilValidationStatus}
                    konsilValidationError = {this.props.konsilValidationError}
                    hasPasswordChanged = {this.state.hasPasswordChanged}
                />
                <StartSendPopup
                    open={this.props.isSendingKonsil}
                    closeStartSendPopup={this.closeStartSendPopup}
                />
            </header>
        );
    }
}

const HeaderDermaKonsilContainer = connector(withPdfPreviewCreation(HeaderDermaKonsil));
export default HeaderDermaKonsilContainer;

