import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import { Vorbehandlung } from 'telescan-core';

export const mapZeitEinheit: Object = {
    min: "Minute(n)",
    h: "Stunde(n)",
    d: "Tag(e)",
    wk: "Woche(n)",
    mo: "Monat(e)",
    a: "Jahr(e)",
}

interface IVorbehandlungDisplayProps {
    vorbehandlung: Vorbehandlung;
    handleDeleteVorbehandlung?: (vorbehandlung: Vorbehandlung) => void;
    isViewOnly: boolean;
}
export class VorbehandlungDisplay extends React.Component<IVorbehandlungDisplayProps> {
    render() {
        const vorbehandlungVon = this.props.vorbehandlung.vorbehandlungVon ? new Date(this.props.vorbehandlung.vorbehandlungVon).toLocaleString().split(',')[0] : "unbekannt";
        const vorbehandlungBis = this.props.vorbehandlung.vorbehandlungBis ? new Date(this.props.vorbehandlung.vorbehandlungBis).toLocaleString().split(',')[0] : "unbekannt";
        
        return (
            <>
                <Box className="flex-row-center">
                    {this.props.vorbehandlung.dauer &&
                        <Typography variant="body1" color="textSecondary">
                            {`
                                ${this.props.vorbehandlung.dauer}
                                ${this.props.vorbehandlung.einheit && mapZeitEinheit[this.props.vorbehandlung.einheit]}
                            `}
                        </Typography>
                    }
                    {this.props.vorbehandlung.vorbehandlungAm &&
                        <Typography variant="body1" color = "textSecondary">
                            {`
                                am ${new Date(this.props.vorbehandlung.vorbehandlungAm).toLocaleString().split(',')[0]}                        
                            `}
                        </Typography>
                    }
                    {(this.props.vorbehandlung.vorbehandlungVon || this.props.vorbehandlung.vorbehandlungBis) &&
                        <Typography variant="body1" color = "textSecondary">
                            {vorbehandlungBis === "unbekannt" ?
                               `seit ${vorbehandlungVon}`:
                               `von ${vorbehandlungVon} bis ${vorbehandlungBis}`
                            }

                        </Typography>
                    }
                    {
                        !this.props.isViewOnly &&
                        <Tooltip title="Löschen" placement="top">
                            <IconButton
                                className="delete-vorbehandlung"
                                size="small"
                                aria-label="delete laborwert"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.props.handleDeleteVorbehandlung && this.props.handleDeleteVorbehandlung(this.props.vorbehandlung);
                                }}
                            >
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    }

                </Box>
                <Typography className="diagnose-text" variant="body1">
                    {this.props.vorbehandlung.beschreibung ? this.props.vorbehandlung.beschreibung: ""}
                </Typography>
            </>
        )
    }
}




