import React from 'react';
import uuid from 'uuid';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {Edit, Visibility} from '@material-ui/icons';

import {DatePicker} from '../index';
import {RootState} from '../../../redux/reducers';
import {connect, ConnectedProps} from 'react-redux';
import {DMPZuordnung, Geschlecht, Krankenkasse, Patient, Personengruppe, VersichertenStatus, Failure} from 'telescan-core';
import {checkKonsilPatientStatus, loadFailure, updateKonsilPatient} from '../../../redux/actions';
import SimpleModalContainer from '../../elements/simple_modal';
import {Patienteneinwilligung} from 'telescan-core/lib/entities/patienteneinwilligung';
import {ViewPatientDetailComponent} from '../viewComponents/view_personendatencontent';


const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient || new Patient(),
    konsilPatientErrors: state.evaluation.konsilPatientErrors
})

const mapDispatchToProps = {
    updateKonsilPatient: (konsil_id: string, patient: Patient | null, patienteneinwilligung: Patienteneinwilligung, loadInstanceId: string) => updateKonsilPatient(konsil_id, patient, patienteneinwilligung, loadInstanceId),
    loadFailure: (id: string, message: Failure) => loadFailure(id, message),
    dispatch_checkKonsilPatientStatus: (patient: Patient, patienteneinwilligung: Patienteneinwilligung, konsilId: string) => checkKonsilPatientStatus(patient, patienteneinwilligung, konsilId),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    isOpenEditModal: boolean;
    isOpenViewModal: boolean;
    patient: Patient | null;
    strasse: string;
    hausnummer: string;
}

type TProps = TPropsFromRedux & {
    hasError: boolean
}

export class EditPatientDetailModal extends React.Component<TProps, IState> {
    constructor(props: TProps) {
        super(props);
        this.state = {
            isOpenEditModal: false,
            isOpenViewModal: false,
            patient: this.props.currentKonsilPatient,
            strasse: "",
            hausnummer: "",
        };

        this.handleCloseEdit = this.handleCloseEdit.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);
        this.updateKonsilPatient = this.updateKonsilPatient.bind(this);
        this.renderTextFieldPatient = this.renderTextFieldPatient.bind(this);
        this.renderSelectPatient = this.renderSelectPatient.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentKonsilPatient !== this.props.currentKonsilPatient) {
            this.setState({patient: this.props.currentKonsilPatient});
        }
    }

    handleCloseEdit = () => {
        this.setState({
            isOpenEditModal: false
        });
        this.updateKonsilPatient();
    };

    handleCloseView = () => {
        this.setState({
            isOpenViewModal: false
        });
    };

    private updateKonsilPatient() {
        const loadInstanceId: string = uuid.v4();
        if (this.props.currentKonsil === undefined) {
            let failure = new Failure();
            failure.error = "Kann kein aktuelles Konsil finden, Update der Daten nicht möglich";
            this.props.loadFailure(loadInstanceId, failure);
            return;
        }

        this.props.updateKonsilPatient(this.props.currentKonsil?.id as string, this.state.patient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), loadInstanceId);
        this.state.patient && this.props.dispatch_checkKonsilPatientStatus(this.state.patient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), this.props.currentKonsil.id || "");
    }


    renderTextFieldPatient(prop: string, label: string, id: string, error?: string) {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <TextField id={id} label={label}
                       value={this.state.patient?.[prop] || ""}
                       onChange={(event) => {
                           let updatedPatient = {...this.state.patient} as Patient;
                           updatedPatient[prop] = event.target.value;
                           this.setState({
                               patient: updatedPatient
                           })
                       }}
                       onBlur={() => this.updateKonsilPatient()}
                       required={error ? true : false}
                       error={error ? patientError.has(error) : false}
            />
        )
    }

    renderSelectPatient(prop: string, defaultValue: string, id: string, labelId: string, label: string, menuItems: JSX.Element[], error?: string) {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <FormControl
                required={error ? true : false}
                error={error ? patientError.has(error) : false}
            >
                <InputLabel shrink id="gender-select-label">
                    {label}
                </InputLabel>
                <Select required
                        labelId={labelId}
                        id={id}
                        value={this.state.patient?.[prop] || defaultValue}
                        onChange={(event) => {
                            let updatedPatient = {...this.state.patient} as Patient;
                            updatedPatient[prop] = event.target.value;

                            this.setState({
                                patient: updatedPatient
                            }, () => this.updateKonsilPatient())
                        }}
                        displayEmpty
                >
                    {menuItems.map(menuItem =>
                        menuItem
                    )}
                </Select>
            </FormControl>
        )
    }

    render() {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        if (!this.state.patient)
            return (<strong>Konnte keinen Patienten finden!</strong>)
        else
            return (
                <>
                <span
                    className={(patientError.has("patient") || patientError.has("krankenkasse")) ? " failure" : ""}
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        if (this.props.currentKonsil.pvsPatient != null)
                            this.setState({
                                isOpenViewModal: true
                            })
                        else {
                            this.setState({
                                isOpenEditModal: true
                            })
                        }
                    }}
                >
                    <span>
                        {this.props.currentKonsil?.konsilPatient?.title} {this.props.currentKonsil?.konsilPatient?.vorname} {this.props.currentKonsil?.konsilPatient?.namenszusatz} {this.props.currentKonsil?.konsilPatient?.vorsatzwort} {this.props.currentKonsil?.konsilPatient?.nachname} {this.props.currentKonsil?.konsilPatient?.suffix}
                    </span>
                    {this.props.currentKonsilPatient.geburtsdatum && 
                        <span>&emsp;•&emsp;{new Date(this.props.currentKonsilPatient?.geburtsdatum).toLocaleDateString()}</span>
                    }
                    <span>&emsp;•&emsp;{this.props.currentKonsilPatient?.egkNummer}</span>
                </span>
                    <div className="profile-data-edit">
                        <div>
                            {(this.props.currentKonsil.pvsPatient != null) ?
                                <Tooltip title="Profil Informationen" placement="top">
                                    <IconButton size="small" aria-label="delete" onClick={() => this.setState({
                                        isOpenViewModal: true
                                    })}>
                                        <Visibility/>
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Profil Informationen" placement="top">
                                    <IconButton size="small" aria-label="delete" onClick={() => this.setState({
                                        isOpenEditModal: true
                                    })}>
                                        <Edit className={this.props.hasError ? "failure" : ""}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        <SimpleModalContainer isOpen={this.state.isOpenEditModal}
                                              onClose={this.handleCloseEdit}
                                              additionalClassname="modal-profile-data"
                        >
                            <Paper className="modal-wrapper">
                                <h2 id="transition-modal-title" className="modal-header">Patienten Informationen</h2>
                                <Typography variant="caption" color="inherit" className="modal-subheader">Die folgenden
                                    Patientendaten wurden aus der mobilen Anwendung übertragen.
                                    Bitte übertragen Sie die Daten des Patienten aus Ihrem PVS und führen Sie diese in
                                    der Konsilübersicht mit dem Konsilauftrag zusammen.
                                    Bitte beachten Sie, dass eine Änderung der Patientendaten keine Änderung der Daten
                                    in Ihrem PVS bewirkt.
                                </Typography>
                                <Box className="modal-content">
                                    <Box className="left-side">
                                        { /* className="full-span" */}
                                        <span className="full-span patient-additions">
                                        {this.renderTextFieldPatient("title", "Titel", "patient-title")}
                                            {this.renderTextFieldPatient("namenszusatz", "Namenszusatz", "patient-nb")}
                                            {this.renderTextFieldPatient("vorsatzwort", "Vorsatzwort", "patient-vv")}
                                    </span>
                                        {this.renderTextFieldPatient("vorname", "Vorname", "patient-surname", "patient.vorname")}
                                        {this.renderTextFieldPatient("nachname", "Name", "patient-name", "patient.name")}
                                        {this.renderSelectPatient("geschlecht", "UNBEKANNT", "gender-select", "gender-select-label", "Geschlecht",
                                            Object.keys(Geschlecht).map(key =>
                                                <MenuItem key={key} value={key}>
                                                    {Geschlecht[key]}
                                                </MenuItem>
                                            ), "patient.geschlecht")
                                        }
                                        <DatePicker isRequired = {true} label="Geburtstag" id="patient-birthday"
                                                    inputLabelProps={null}
                                                    initialDate={this.state.patient.geburtsdatum ? new Date(this.state.patient.geburtsdatum) : null}
                                                    onChange={(date: Date) => {
                                                        let updatedPatient = {
                                                            ...this.state.patient,
                                                            geburtsdatum: date?.getTime()
                                                        } as Patient
                                                        this.setState({
                                                            patient: updatedPatient
                                                        })
                                                    }}
                                                    onBlur={this.updateKonsilPatient}
                                                    hasError={true}
                                        />
                                        {this.renderTextFieldPatient("strasseHausnummer", "Straße und Hausnr", "patient-address-street", "patient.strasse")}
                                        {this.renderTextFieldPatient("plz", "PLZ", "patient-adress-plz", "patient.plz")}
                                        {this.renderTextFieldPatient("wohnort", "Wohnort", "patient-adress-location", "patient.wohnort")}
                                    </Box>
                                    <Box className="right-side">
                                        {[{
                                            prop: "name",
                                            label: "Krankenkasse",
                                            id: "patient-insurance",
                                            error: "krankenkasse.name"
                                        },
                                            {
                                                prop: "iknr",
                                                label: "Kassen-IKNr",
                                                id: "patient-insurance-iknr",
                                                error: "krankenkasse.iknr"
                                            }]
                                            .map((item) => 
                                                <TextField
                                                    key={item.prop}
                                                    required
                                                    id={item.id}
                                                    label={item.label}
                                                    value={this.state.patient?.krankenkasse?.[item.prop] || ""}
                                                    onChange={(event) => {
                                                        let krankenkasse: Krankenkasse = this.state.patient?.krankenkasse ? this.state.patient.krankenkasse : new Krankenkasse();
                                                        krankenkasse[item.prop] = event.target.value;
                                                        let updatedPatient = {
                                                            ...this.state.patient,
                                                            krankenkasse: krankenkasse
                                                        } as Patient
                                                        this.setState({
                                                            patient: updatedPatient
                                                        })
                                                    }}
                                                    onBlur={() => this.updateKonsilPatient()}
                                                    error={patientError.has(item.error)}
                                                />
                                            )}
                                        {this.renderTextFieldPatient("egkNummer", "eGK-Versichertennr", "patient-egk-nr", "patient.egknummer")}
                                        {this.renderSelectPatient("versichertenStatus", "", "status-select", "status-select-label", "Versichertenstatus",
                                            Object.keys(VersichertenStatus).map(key =>
                                                <MenuItem key={key} value={key}>
                                                    {VersichertenStatus[key]}
                                                </MenuItem>
                                            ), "patient.versichertenStatus")
                                        }
                                        {this.renderSelectPatient("personengruppe", "", "patient-group-select", "patient-group-select-label", "Personengruppe",
                                            [<MenuItem key="PG_00" value="PG_00">{Personengruppe.PG_00}</MenuItem>,
                                                <MenuItem key="PG_04" value="PG_04">{Personengruppe.PG_04}</MenuItem>,
                                                <MenuItem key="PG_06" value="PG_06">{Personengruppe.PG_06}</MenuItem>,
                                                <MenuItem key="PG_07" value="PG_07">{Personengruppe.PG_07}</MenuItem>,
                                                <MenuItem key="PG_08" value="PG_08">{Personengruppe.PG_08}</MenuItem>,
                                                <MenuItem key="PG_09"
                                                          value="PG_09">{Personengruppe.PG_09}</MenuItem>], "patient.personengruppe")
                                        }
                                        {this.renderSelectPatient("dmpZuordnung", "", "dmp-select", "dmp-select-label", "DMP-Zuordnung",
                                            [<MenuItem key="DMP_00" value="DMP_00">{DMPZuordnung.DMP_00}</MenuItem>,
                                                <MenuItem key="DMP_01" value="DMP_01">{DMPZuordnung.DMP_01}</MenuItem>,
                                                <MenuItem key="DMP_02" value="DMP_02">{DMPZuordnung.DMP_02}</MenuItem>,
                                                <MenuItem key="DMP_03" value="DMP_03">{DMPZuordnung.DMP_03}</MenuItem>,
                                                <MenuItem key="DMP_04" value="DMP_04">{DMPZuordnung.DMP_04}</MenuItem>,
                                                <MenuItem key="DMP_05" value="DMP_05">{DMPZuordnung.DMP_05}</MenuItem>,
                                                <MenuItem key="DMP_06" value="DMP_06">{DMPZuordnung.DMP_06}</MenuItem>,
                                                <MenuItem key="DMP_07" value="DMP_07">{DMPZuordnung.DMP_07}</MenuItem>,
                                                <MenuItem key="DMP_08" value="DMP_08">{DMPZuordnung.DMP_08}</MenuItem>,
                                                <MenuItem key="DMP_09"
                                                          value="DMP_09">{DMPZuordnung.DMP_09}</MenuItem>], "patient.dmp_zuordnung")
                                        }
                                        {this.renderTextFieldPatient("externePatientenNummer", "Patientennummer", "patient-identifikator")}
                                    </Box>
                                </Box>

                                <Box className="modal-footer">
                                    <Button variant="contained" color="primary" onClick={this.handleCloseEdit}>
                                        Fertig
                                    </Button>
                                </Box>
                            </Paper>
                        </SimpleModalContainer>
                        <ViewPatientDetailModalComponent patient={this.state.patient}
                                                         isOpen={this.state.isOpenViewModal}
                                                         onClose={this.handleCloseView}/>
                    </div>
                </>
            );
    }
}

export const EditPatientDetailModalComponent = connector(EditPatientDetailModal);
export default EditPatientDetailModalComponent;


interface IViewProps {
    patient: Patient;
    isOpen: boolean;
    onClose: () => void
}

export class ViewPatientDetailModalComponent extends React.Component<IViewProps> {
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}
                                  onClose={this.props.onClose}
                                  additionalClassname="modal-profile-data"
            >
                <Paper className="modal-wrapper">
                    <h2 id="transition-modal-title" className="modal-header">Patienten Informationen</h2>
                    <Typography variant="caption" color="inherit" className="modal-subheader">
                        Die folgenden Patientendaten wurden aus Ihrem Praxisverwaltungssystem (PVS) übertragen.
                        Bitte nehmen Sie Änderungen an den Patientendaten direkt in Ihrem PVS vor.
                        Eventuell ist es notwendig, einen neuen Konsilauftrag anzulegen, damit die Änderungen wirksam
                        werden.
                    </Typography>
                    <Box className="modal-content">
                        <ViewPatientDetailComponent patient={this.props.patient} role="HAUSARZT"/>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={this.props.onClose}>
                            Schliessen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
