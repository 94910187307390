import React from 'react';
import uuid from 'uuid';
import {Box, Typography} from '@material-ui/core';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../redux/reducers";
import {clearAttachmentList, clearKonsilImageList, clearRueckantwortImageList, downloadKonsil, navigate, removeSendFailure} from "../../redux/actions";
import { setIsNormalNotification } from '../../redux/actions/notification_actions';

interface IProps {
    alerts: any[];
    handleClose: () => void;
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    isDesktop: state.general.isDesktop,
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_downloadKonsil: (dokumentenId: string) => downloadKonsil(dokumentenId),
    dispatch_removeSendFailure: (dokumentenId: string) => removeSendFailure(dokumentenId),
    dispatch_clearKonsilImageList: () => clearKonsilImageList(),
    dispatch_clearRueckantwortImageList: () => clearRueckantwortImageList(),
    dispatch_clearAttachmentList: () => clearAttachmentList(),
    dispatch_setIsNormalNotification: (isNormalNotification: boolean) => setIsNormalNotification(isNormalNotification),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class BellPopup extends React.Component<TPropsFromRedux> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {};

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(alertItem) {
        if (alertItem.type === "failure") {
            this.props.dispatch_removeSendFailure(alertItem.content.konsilID);
            this.props.dispatch_navigate("/konsil/" + alertItem.content.konsilID);
            if (this.props.currentKonsil.id !== alertItem.content.konsilID) {
                this.props.dispatch_clearKonsilImageList();
                this.props.dispatch_clearRueckantwortImageList();
                this.props.dispatch_clearAttachmentList();
            }
        } else if (this.props.role === "DERMATOLOGE" && alertItem.type === "new_konsil") {
            this.props.dispatch_downloadKonsil(alertItem.content.dokumentIdentifikator)
        } else if (alertItem.type === "zertifikat") {
            this.props.dispatch_navigate("/settings");
        } else if (alertItem.type === "notification") {
            this.props.dispatch_setIsNormalNotification(true);
            this.props.handleClose();
        }
    }

    render() {
        return (
            <Box className="bell-popup">
                {this.props.alerts.length === 0 ?
                    <Box className="no-content-item bell-popup-container">
                        <Typography variant="body1" color="inherit">- keine Nachrichten vorhanden -</Typography>
                    </Box>
                    :
                    this.props.alerts.map((alertItem) =>
                            <Box className="alert-item bell-popup-container"
                                key={uuid.v4()}
                                onClick={() => this.handleClick(alertItem)}
                            >
                                <Typography variant="h2" color="inherit">{alertItem.title}</Typography>
                                <Typography variant="body1" color="inherit">{alertItem.displayContent}</Typography>
                            </Box>
                    )
                }
            </Box>
        );
    }
}

export const BellPopupContainer = connector(BellPopup);
export default BellPopupContainer;
