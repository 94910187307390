import React from 'react';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {Box, Button, CircularProgress, Divider, IconButton, Modal, Paper, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {KonsilImageType, Lateralitaet, Failure, DermatoscopeType} from 'telescan-core';
import {KonsilImageState} from '../../../redux/reducers/image_attachment_reducer';
import {Close, NavigateBefore, NavigateNext, ZoomIn, ZoomOut} from '@material-ui/icons';
import {withLokalisationHelper, WithLokalisationHelpertsProps} from '../../../utils/with_lokalisation_helper';
import { failure } from '../../../redux/actions';
import ImagePlaceholder from "../../../static/images/placeholder_img.svg";


interface IState {
    shouldShowSide: boolean;
    img_id: string;
    shouldShowSideKorperregion: boolean; // flag to track if body element is selected to expand
    locationImagePath: string; // string to store selected body element image path
}

const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
    konsilImages: state.image_attachment.konsilImages,
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithLokalisationHelpertsProps


export class ViewLokalisationContent extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shouldShowSide: false,
            img_id: Object.keys(this.props.konsilImages)[0] || "",
            shouldShowSideKorperregion: false,
            locationImagePath: "",
        };

        this.showSideClick = this.showSideClick.bind(this);
        this.closeSideClick = this.closeSideClick.bind(this);
        this.showSideClickKorperregion = this.showSideClickKorperregion.bind(this);
        this.closeSideClickKorperregion = this.closeSideClickKorperregion.bind(this);

    }

    private frauContext = require.context('../../../static/images/localisation/frau', true, /\.svg$/);
    private mannContext = require.context('../../../static/images/localisation/mann', true, /\.svg$/);
    private gemeinsamContext = require.context('../../../static/images/localisation/gemeinsam', true, /\.svg$/);


    private getBodyElementImagePath(): string[] {
        // retrieve all paths of body element images on the page, store in string array
        const bodyElementImagePath: string[] = [];
        this.props.currentKonsil.befund.lokalisation.forEach((location) => {
            try {
                if (this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH")
                    bodyElementImagePath.push(this.frauContext('./' + this.props.getElementName(location) + '.svg').default);
                else if (this.props.currentKonsil.konsilPatient.geschlecht === "MAENNLICH")
                    bodyElementImagePath.push(this.mannContext('./' + this.props.getElementName(location) + '.svg').default);
            } catch {
                try {
                    bodyElementImagePath.push(this.gemeinsamContext('./' + this.props.getElementName(location) + '.svg').default);
                } catch {
                    bodyElementImagePath.push("");
                }
            }
        })
        return bodyElementImagePath;
    }

    showSideClick(id: string) {
        this.setState({
            shouldShowSide: true,
            img_id: id
        });
    };

    closeSideClick() {
        this.setState({
            shouldShowSide: false,
            img_id: ""
        });
    };

    showSideClickKorperregion(locImagePath: string) {
        // set true to ""shouldShowSideKorperregion"", to expand the body element
        // store selected path to body element image in ""locationImagePath""
        this.setState({
            shouldShowSideKorperregion: true,
            locationImagePath: locImagePath,
        });
    }

    closeSideClickKorperregion() {
        // set false to ""shouldShowSideKorperregion"", to shrink the body element
        this.setState({
            shouldShowSideKorperregion: false,
            locationImagePath: "",
        });
    }

    handleClickPrevImg(id: string) {
        const idArray = Object.keys(this.props.konsilImages);
        const index = (idArray.indexOf(id) - 1 !== -1) ? idArray.indexOf(id) - 1 : idArray.length - 1
        this.setState({
            img_id: idArray[index]
        });
    };

    handleClickNextImg(id: string) {
        const idArray = Object.keys(this.props.konsilImages);
        const index = (idArray.indexOf(id) + 1 !== idArray.length) ? idArray.indexOf(id) + 1 : 0
        this.setState({
            img_id: idArray[index]
        });
    };

    handleClickPrevLokalisation(id: string) {
        // method to shift to previous localisation if localisations>1
        const idArray = this.getBodyElementImagePath();
        const index = (idArray.indexOf(id) - 1 !== -1) ? idArray.indexOf(id) - 1 : idArray.length - 1
        this.setState({
            locationImagePath: idArray[index]
        });
    }

    handleClickNextLokalisation(id: string) {
        // method to shift to next localisation if localisations>1
        const idArray = this.getBodyElementImagePath();
        const index = (idArray.indexOf(id) + 1 !== idArray.length) ? idArray.indexOf(id) + 1 : 0
        this.setState({
            locationImagePath: idArray[index]
        });
    }

    render() {
        return (
            <>
                <Box className="side-view">
                    <Box className="bodypart">
                        <Box className="bodypart-header">
                            <Typography variant="subtitle1" color="inherit">
                                Körperregion
                            </Typography>
                        </Box>

                        {this.getBodyElementImagePath().map((bodyElement, idx) => {
                            // iterate through all body element images loaded on the page, click on body element expands the picture, handled by onClick,
                            // path of clicked body element image get saved in locationImagePath State
                            if (bodyElement !== null) return (
                                <Box className="body-location-list-element" key={bodyElement}>
                                    {bodyElement === "" ?
                                        <Paper
                                            elevation={3}
                                            className="vorschau"
                                            style={{backgroundImage: `url(${ImagePlaceholder})`}}
                                            onClick={() => this.showSideClickKorperregion(bodyElement)}
                                        /> :
                                        <Paper
                                            elevation={3}
                                            className="vorschau"
                                            style={{backgroundImage: "url(" + bodyElement + ")"}}
                                            onClick={() => this.showSideClickKorperregion(bodyElement)}
                                        />
                                    }

                                    <Typography className="image-txt" variant="subtitle1" color="inherit">
                                        {this.props.getBodyElementValue(this.props.currentKonsil.befund.lokalisation[idx].lokalisation || "")}, {(this.props.currentKonsil.befund.lokalisation[idx].lateralitaet) ? Lateralitaet[this.props.currentKonsil.befund.lokalisation[idx].lateralitaet || ""] : "Keine Angabe"}
                                    </Typography>
                                </Box>
                            )
                            else return (
                                null
                            )
                        })}
                    </Box>
                    <Divider/>
                    <Box className="body-images">
                        <Box className="body-images-header">
                            <Typography variant="subtitle1" color="inherit">
                                Fotografien
                            </Typography>
                            {this.props.role === "DERMATOLOGE" &&
                            <Typography variant="body2" color="inherit">
                                Die Vorschaubilder zeigen nur einen Bildausschnitt und sind in reduzierter Qualität dargestellt. Bitte verwenden Sie zur Befundung ausschließlich die Vollbilddarstellung.
                            </Typography>
                            }
                        </Box>
                        <Box className="body-image-content">
                            {Object.keys(this.props.konsilImages).map((image_id) => {                              
                                const konsilImageState: KonsilImageState = this.props.konsilImages[image_id];
                                return (!konsilImageState.loading ?
                                    <Box className="body-image" key={"konsil_image_" + image_id}>

                                        <Paper
                                            elevation={3}
                                            className="vorschau"
                                            style={{backgroundImage: `url(${this.props.konsilImages[image_id].url})`}}
                                            onClick={() => this.showSideClick(image_id)}
                                        >
                                        </Paper>
                                        <Box className="image-txt">
                                            <Typography variant="subtitle1" color="inherit">
                                                {KonsilImageType[konsilImageState.imageType]}
                                            </Typography>
                                            {konsilImageState.dermatoscopeType &&
                                                <Typography variant = "body2" color="inherit">
                                                {DermatoscopeType[konsilImageState.dermatoscopeType]}
                                            </Typography>
                                            }                                         
                                            <Typography variant="body2" color="inherit">
                                                {konsilImageState.dateRecorded !== null &&
                                                    new Date(konsilImageState.dateRecorded).toLocaleDateString().split(",")[0]
                                                }
                                                {(konsilImageState.dateRecorded === null && konsilImageState.dateCreated !== null) &&
                                                    new Date(konsilImageState.dateCreated).toLocaleDateString().split(",")[0]
                                                }
                                                {(konsilImageState.dateRecorded === null && konsilImageState.dateCreated === null) &&
                                                    "-"
                                                }
                                            </Typography>
                                        </Box>
                                    </Box> :
                                    <>
                                        <Paper
                                            elevation={3}
                                            className="vorschau vorschau-loading"
                                        >
                                            <CircularProgress/>
                                        </Paper>
                                        <Box className="image-txt">
                                            <Typography variant="subtitle1" color="inherit">
                                                Wird geladen…
                                            </Typography>

                                        </Box>
                                    </>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
                <FullScreenImageComponent
                    isActive={(this.state.shouldShowSide || this.state.shouldShowSideKorperregion)}
                    handleClose={this.state.shouldShowSideKorperregion ? this.closeSideClickKorperregion : this.closeSideClick}
                    handleClickPrev={this.state.shouldShowSideKorperregion ? () => this.handleClickPrevLokalisation(this.state.locationImagePath) : () => this.handleClickPrevImg(this.state.img_id)}
                    handleClickNext={this.state.shouldShowSideKorperregion ? () => this.handleClickNextLokalisation(this.state.locationImagePath) : () => this.handleClickNextImg(this.state.img_id)}
                    title={this.state.shouldShowSideKorperregion ?
                        this.props.getBodyElementValue(this.props.currentKonsil.befund.lokalisation[this.getBodyElementImagePath().indexOf(this.state.locationImagePath)].lokalisation || "") + ", " + Lateralitaet[this.props.currentKonsil.befund.lokalisation[this.getBodyElementImagePath().indexOf(this.state.locationImagePath)].lateralitaet || "keine Angabe"] || "keine Angabe"
                        :
                        KonsilImageType[this.props.konsilImages[this.state.img_id]?.imageType]}
                    src={this.state.shouldShowSideKorperregion ? this.state.locationImagePath : this.props.konsilImages[this.state.img_id]?.url}
                    alt={this.state.shouldShowSideKorperregion ? this.props.getBodyElementValue(this.props.currentKonsil.befund.lokalisation[this.getBodyElementImagePath().indexOf(this.state.locationImagePath)].lokalisation || "") : this.props.konsilImages[this.state.img_id]?.imageType}
                    timeStamp={this.state.shouldShowSide ? this.props.konsilImages[this.state.img_id].dateRecorded === null ? "-" : new Date(this.props.konsilImages[this.state.img_id]?.dateRecorded).toLocaleString() : ""}
                />
            </>
        );
    }
}

export const ViewLokalisationContentContainer = connector(withLokalisationHelper(ViewLokalisationContent));
export default ViewLokalisationContentContainer;


interface IFullScreenImgProps {
    isActive: boolean;
    handleClose: () => void;
    handleClickPrev: () => void;
    handleClickNext: () => void;
    title: string;
    src: string;
    alt?: string;
    timeStamp?: string;

}

interface IFullScreenImgState {
    showId: boolean;

}


export class FullScreenImageComponent extends React.Component<IFullScreenImgProps, IFullScreenImgState> {
    constructor(props: IFullScreenImgProps) {
        super(props);
        this.state = {
            showId: false,
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(event: KeyboardEvent) {
        if (!Array.prototype.some.call(document.getElementsByClassName("MuiInputBase-input"), item => item === document.activeElement)) {
            if (event.keyCode === 39) {
                event.preventDefault();
                this.props.handleClickNext();
            } else if (event.keyCode === 37) {
                event.preventDefault();
                this.props.handleClickPrev();
            }
        }
    }

    render() {
        return (
            <Modal className="modal-map" open={this.props.isActive}>
                <Box className="map modal-map-container" /* image gets expanded if body element image or patient image is clicked*/>
                    <Button className="slim btn-lokalisation-list" variant="contained" color="primary"
                            onClick={this.props.handleClose /* images get schrinked on click"" */}>
                        <Close/>
                    </Button>
                    <IconButton aria-label="previous" className="img-prev" onClick={this.props.handleClickPrev}>
                        {/* switch to previous localisation or patient image*/}
                        <NavigateBefore fontSize="large"/>
                    </IconButton>
                    <IconButton aria-label="next" className="img-next" onClick={this.props.handleClickNext}>
                        {/* switch to next localisation or patient image*/}
                        <NavigateNext fontSize="large"/>
                    </IconButton>
                    <Paper className="image-description">
                        <Typography variant="subtitle1" color="inherit">
                            {/* checking what image to be enlarged (body element or picture from patient), inserting title for enlarged picture accordingly */}
                            {this.props.title}
                        </Typography>
                        <Typography variant="body2" color="inherit">
                            {/* Inserting timestamp for enlarged picture (only for pictures from patients, for body element picture there is no timestamp) */}
                            {this.props.timeStamp}
                        </Typography>
                    </Paper>
                    <Typography variant="body2" className="tip-scaling" color="error">Gegebenfalls vorhandene Maßstäbe sind nicht kalibriert. Rückschlüsse auf tatsächliche Größen sind <strong>nicht</strong> möglich.</Typography>
                    <TransformWrapper>
                        {({zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest}) => (
                            <>
                                <div className="zoom-btn-container">
                                    <Button className="slim zoom-btn" variant="contained" color="primary" size="small"
                                            onClick={zoomIn}>
                                        <ZoomIn/>
                                    </Button>
                                    <Button className="slim zoom-btn" variant="contained" color="primary" size="small"
                                            onClick={zoomOut}>
                                        <ZoomOut/>
                                    </Button>
                                </div>
                                <TransformComponent>
                                    {/* checking what image to be enlarged (body element or picture from patient), inserting enlarged image accordingly */}
                                    <img src={this.props.src}
                                        alt={this.props.alt}
                                        style={{width: "40vw", height: "40vw", objectFit: "contain"}}
                                        className = "image-centered"
                                    />
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                </Box>
            </Modal>
        )
    }
}
