import {Failure, WebSocketService} from "telescan-core";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../reducers";
import {Action} from "redux";
import uuid from "uuid";
import {hpmStatusEventListenerCallback, loadFailure} from "./general_actions";
import * as WebSocketTypes from '../types/websocket_types';
import { loadArztKonsile, removeKonsil, setIsKonsilDeleted, setIsKonsilUpdateConflict, setIsKonsilUpdatedEventShown, setKonsilUpdateConflictData } from "./konsil_actions";
import { getImageMetadata, handleRemoveKonsilImage, loadKonsilImage } from "./image_attachment_actions";
import { handleGlobalUpdateEvent, handleUpdatingEvent } from "./update_actions";
import { handleDownloadKonsilEvent, handleUploadKonsilEvent } from "./upload_download_konsil_actions";
import { getIcd10Info, handleDeleteIcd10, handleInstallIcd10 } from "./icd10_actions";
import { checkLocationStatus } from ".";
import { getCurrentRiskAndWarningNotifications } from "./notification_actions";

export function setWebSocketClientId(id: string): WebSocketTypes.ISetClientId {
    return {
        type: WebSocketTypes.EWebSocketActions.SET_CLIENT_ID,
        clientId: id
    }
}

export function connectWebSocketService(url: string, accessToken: string, webSocketService: WebSocketService): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const clientId: string = uuid.v4();
        dispatch(setWebSocketClientId(clientId));
        const failureId: string = uuid.v4();
        if (webSocketService === undefined) {
            const failureObj = new Failure();
            failureObj.error = "WebSocketService ist nicht gesetzt!";
            dispatch(loadFailure(failureId, failureObj))
            return;
        }
        if (url && url !== "" && accessToken && accessToken !== "") {
            webSocketService = WebSocketService.getInstance();
            webSocketService.setErrorCallback((error) => console.error("A websocket error occurred: ", error)); 
            webSocketService.connect(url, getState().webSocket.clientId, accessToken);
            // 1. General listeners
            webSocketService.addListener(
                "clearMessageQueue",
                (data) => data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString())
            );
            // 2. Update listeners
            webSocketService.addListener(
                "updateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleGlobalUpdateEvent(data.data));
                }
            )
            webSocketService.addListener(
                "teleScanUpdateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUpdatingEvent(data.data, getState().update.module))
                }
            )
            webSocketService.addListener(
                "hpmUpdateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUpdatingEvent(data.data, getState().update.module))
                }
            )
            //3. Konsile send/download and update listeners
            webSocketService.addListener(
                "telekonsilUploadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUploadKonsilEvent(data.data, getState().send_download_konsil.eventText))
                }
            )
            webSocketService.addListener(
                "telekonsilDownloadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleDownloadKonsilEvent(data.data))
                }
            )
            webSocketService.addListener(
                "konsilUpdatedEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id)
                        dispatch(setIsKonsilUpdatedEventShown(true));
                }
            )
            webSocketService.addListener(
                "konsilUpdateConflictEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id) {
                        dispatch(setIsKonsilUpdateConflict(true));
                        dispatch(setIsKonsilUpdatedEventShown(false));
                        dispatch(setKonsilUpdateConflictData(data.data.changes));
                    }
                }
            )
            //4. Image manipulation listeners
            webSocketService.addListener(
                "ImageDeletedEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id) {
                        dispatch(handleRemoveKonsilImage(data.data.imageId))
                        .then(() => {
                            dispatch(checkLocationStatus(getState().konsile.current_konsil, getState().image_attachment.konsilImages));
                        })
                        .catch(error => console.log(error));
                    }
                }
            );
            webSocketService.addListener(
                "ImageUploadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString())
                    if (data.data.konsilId === getState().konsile.current_konsil.id) {
                        dispatch(getImageMetadata(data.data.imageId))
                        .then(() => {
                            dispatch(loadKonsilImage(data.data.imageId));
                        })
                    }
                }
            );
            //5. ICD10 install/delete listeners
            webSocketService.addListener(
                "icd10InstallEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().webSocket.clientId !== data.data.initiator) {
                        dispatch(getIcd10Info())
                        .then(() => dispatch(handleInstallIcd10(data.data)))
                        .catch(error => console.log(error));
                    } else 
                        dispatch(handleInstallIcd10(data.data));             
                }
            );
            webSocketService.addListener(
                "icd10DeleteEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().webSocket.clientId !== data.data.initiator) {
                        dispatch(getIcd10Info())
                        .then(() => dispatch(handleDeleteIcd10(data.data)))
                        .catch(error => console.log(error));
                    } else 
                        dispatch(handleDeleteIcd10(data.data));
                }
            );
            //6. Create/delete konsil listeners
            webSocketService.addListener(
                "konsilCreatedEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().router.location.pathname === "/")
                        dispatch(loadArztKonsile());
                }
            );
            webSocketService.addListener(
                "konsilDeletedEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().router.location.pathname === "/")
                        data.data.konsilId && dispatch(removeKonsil(data.data.konsilId))
                    if (data.data.konsilId && (getState().konsile.current_konsil.id === data.data.konsilId)) {
                        dispatch(setIsKonsilDeleted(true));
                    }
                        
                }
            );
            //7. Start/Stop/Restart HPM listeners
            webSocketService.addListener(
                "hpmStatusEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(hpmStatusEventListenerCallback(data.data.status));
                }
            )

            //8. Telescan notifications
            webSocketService.addListener(
                "notificationEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().user.initialisationStatus.abgeschlossen)
                        dispatch(getCurrentRiskAndWarningNotifications());
                }
            )

        } else {
            const failureObj = new Failure();
            failureObj.error = "url oder accessToken leer, kann den WebSocket nicht verbinden!";
            dispatch(loadFailure(failureId, failureObj));
        }
    }
}



