import React from "react";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';

import {
    Box,
    Button,
    Fab,
    IconButton,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
    Typography,
    Chip} from "@material-ui/core";
import {
    Add,
    Close,
    Replay,
    VolumeMute,
    AccountCircle,
    GetApp
} from "@material-ui/icons";

import Logo from "../../static/images/logo-telescan.svg";
import {UserContainer} from "../header"
import {HeaderSearch, PatientProfileSelectContainer} from "./"
import {RootState} from "../../redux/reducers";
import {loadFailure, loadSuccess, updateDermaKonsileAndMetaData, getKonsileStatusChanges, downloadHausarztCDAs, downloadNewKonsile, loadArztKonsile, getUserWithToken, connectWebSocketService, searchKonsile, filterKonsile, resetKonsilSearchFilter, changeKonsilTablePage} from "../../redux/actions";
import { Patient, KonsilStatusType, KonsilStatusTypeShort, KonsilService, Konsil, Failure, PatientNumber, TelescanConfig, WebSocketService} from "telescan-core";
import { loadPatienten } from '../../redux/actions/patient_actions';
import { loading } from '../../redux/actions/general_actions';
import PvsPatientSelectContainer from "./pvs_patient_select";

const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    user: state.user.user,
    userRole: state.user.role,
    metadatenAdressiert: state.dermatologe.metadatenAdressiert,
    location: state.router.location.pathname,
    konsilSortSearchFilterParams: state.konsile.konsilSortSearchFilterParams,
})

const mapDispatchToProps = {
    dispatch_loadPatienten: () => loadPatienten(),
    dispatch_getKonsileStatusChanges: () => getKonsileStatusChanges(),
    dispatch_updateDermaKonsileAndMetaData: () => updateDermaKonsileAndMetaData(),
    dispatch_downloadHausarztCDAs: () => downloadHausarztCDAs(),
    loadFailure: (id: string, failure: Failure) => loadFailure(id, failure),
    loading: (id: string) => loading(id),
    loadSuccess: (id: string) => loadSuccess(id),
    dispatch_downloadNewKonsile: (ids: string[]) => downloadNewKonsile(ids),
    dispatch_loadKonsile: () => loadArztKonsile(),
    dispatch_getUserWithToken: (refresh_token: string, targetPath) => getUserWithToken(refresh_token, targetPath),
    dispatch_webSocketConnect: (url: string, accessToken: string, webSocketService: WebSocketService) => connectWebSocketService(url, accessToken, webSocketService),
    dispatch_searchKonsile: (searchString: string) => searchKonsile(searchString),
    dispatch_filterKonsile: (konsilStatus: (keyof typeof KonsilStatusType)[]) => filterKonsile(konsilStatus),
    dispatch_resetKonsilSearchFilter: () => resetKonsilSearchFilter(),
    dispatch_changePage: (page: number) => changeKonsilTablePage(page),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IHeaderOverviewState {
    newPatientOpen: boolean;
    patienten: Patient[];
    canOpen: boolean;
    pvsOpen: boolean;
    unmergedKonsileCount: number;
    unmergedKonsile: {pvsPatient: Patient[], konsil: Konsil}[];
}

export class HeaderKonsilliste extends React.Component<TPropsFromRedux, IHeaderOverviewState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            newPatientOpen: false,
            patienten: [],
            canOpen: false,
            pvsOpen: false,
            unmergedKonsileCount: 0,
            unmergedKonsile: [],
        };

        this.newPatientModal = this.newPatientModal.bind(this);
        this.loadPatienten = this.loadPatienten.bind(this);
        this.setPatienten = this.setPatienten.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.selectHandleOnOpen = this.selectHandleOnOpen.bind(this);
        this.refreshKonsilList = this.refreshKonsilList.bind(this);
        this.newPvsPatientSelectModal = this.newPvsPatientSelectModal.bind(this);
        this.getUnmergedKonsileCount = this.getUnmergedKonsileCount.bind(this);
        this.downloadAllNewKonsile = this.downloadAllNewKonsile.bind(this);
        this.togglePatientModal = this.togglePatientModal.bind(this);
        this.loadKonsillist = this.loadKonsillist.bind(this);
    }

    componentDidMount() {
        const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
        if (telescanConfig.getConstant("ACCESS_TOKEN")==="" && telescanConfig.getConstant("REFRESH_TOKEN")!=="") {
            // Set User (Hausarzt or Dermatologist) for the session when following direct route to konsil table with refresh token as parameter, that is bypassing login procedure
            const refresh_token = telescanConfig.getConstant("REFRESH_TOKEN");
            this.props.dispatch_getUserWithToken(refresh_token, this.props.location)
        }
        else if(telescanConfig.getConstant("ACCESS_TOKEN")!=="" && telescanConfig.getConstant("REFRESH_TOKEN")!=="") {
            this.loadKonsillist(); 
        }
    }

    loadKonsillist() {
        //this.props.dispatch_changePage(0);
        if (this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") {
            this.props.dispatch_loadKonsile();
            this.props.dispatch_getKonsileStatusChanges()
            this.props.dispatch_downloadHausarztCDAs();
            this.getUnmergedKonsileCount();
        } else if (this.props.userRole === "DERMATOLOGE" || this.props.userRole === "BETRIEBSSTAETTE_DERMATOLOGE") {
            this.props.dispatch_updateDermaKonsileAndMetaData()
        }
    }

    setPatienten(patienten: Patient[]) {
        this.setState({patienten: patienten});
    };

    loadPatienten = () => {
        this.props.dispatch_loadPatienten().then(
            (patienten) => this.setPatienten(patienten)
        );
    }

    newPatientModal() {
        this.togglePatientModal();
        this.loadPatienten();
    };

    togglePatientModal() {
        this.setState({
            newPatientOpen: !this.state.newPatientOpen
        });
    };

    newPvsPatientSelectModal() {
        this.setState({
            pvsOpen: !this.state.pvsOpen
        });
        this.loadPatienten();
    };

    changeFilter(event) {
        this.props.dispatch_filterKonsile(event.target.value);
    }

    selectHandleOnOpen(event) {
        this.setState({canOpen: !this.state.canOpen});
    }

    refreshKonsilList() {
        this.props.dispatch_changePage(0);
        if (this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT"){
            this.props.dispatch_loadKonsile();
            this.props.dispatch_getKonsileStatusChanges();
            this.props.dispatch_downloadHausarztCDAs();
            this.getUnmergedKonsileCount();         
            this.setState({
                newPatientOpen: false
            });
        } else if (this.props.userRole === "DERMATOLOGE" || this.props.userRole === "BETRIEBSSTAETTE_DERMATOLOGE") {
            this.props.dispatch_getKonsileStatusChanges();
            this.props.dispatch_updateDermaKonsileAndMetaData();
        }
    }

    getUnmergedKonsileCount(): void {
        const id: string = uuid.v4();
        const konsilService: KonsilService = new KonsilService();
        konsilService.countUnmergedKonsile().then(
            (count) => {
                this.setState({
                    unmergedKonsileCount: count
                });
            }
        ).catch(
            (response) => {
                this.props.loadFailure(id, response);
            }
        )
    }

    getUnmergedKonsile() {
        const id: string = uuid.v4();
        const konsilService: KonsilService = new KonsilService();
        this.props.loading(id);
        konsilService.getUnmergedKonsile().then(
            (response) => {
                this.props.loadSuccess(id);
                this.setState({
                    unmergedKonsile: response
                });
            }
        ).catch(
            (error) => {
                this.props.loadFailure(id, error);
            }
        )
    }

    downloadAllNewKonsile() {
        const ids: string[] = this.props.metadatenAdressiert.filter((item) => item.dokumentIdentifikator).map((item) => item.dokumentIdentifikator || "")
        this.props.dispatch_downloadNewKonsile(ids)
    }

    onFilterChipDelete = (deletedFilter: string) => {
        const newFilters = this.props.konsilSortSearchFilterParams.konsilStatus.filter(item => item !== deletedFilter);
        this.props.dispatch_filterKonsile(newFilters);
    }

    render() {
        return (
            <header className="header-konsiluebersicht">
                {this.props.isDesktop && <h1 className="title">Konsilübersicht</h1>}
                <img src={Logo} alt="Logo" className="logo"/>
                <UserContainer userDetails={this.props.user}/>
                <Box className="title-actions">
                    {!this.props.isDesktop && <h1 className="title">Konsilübersicht</h1>}
                    <div className="actions flex-row-center">
                        {(this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") &&
                        <>
                        <Tooltip title="Neuen Konsilauftrag anlegen" placement="top">
                            <Button className="add-konsil slim" variant="contained" color="primary" size="small"
                                    onClick={this.newPatientModal}>
                                <Add/>
                            </Button>
                        </Tooltip>
                        <PatientProfileSelectContainer togglePatientModal={this.togglePatientModal} open={this.state.newPatientOpen} patienten={this.state.patienten}/>
                        <Tooltip title="Neues Konsil" placement="left">
                            <Fab className="add-konsil-action" color="primary" aria-label="add"
                                onClick={this.newPatientModal}>
                                <Add/>
                            </Fab>
                        </Tooltip>
                        </>
                        }
                        {(this.props.userRole === "DERMATOLOGE" || this.props.userRole === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                            <Tooltip title="Alle neuen Konsile herunterladen" placement="top">
                                <Button className="add-konsil slim" variant="contained" color="primary" size="small"
                                        onClick={this.downloadAllNewKonsile}
                                >
                                    {this.props.metadatenAdressiert.length !== 0 &&
                                        <Box className="notify-badge">
                                            {this.props.metadatenAdressiert.length}
                                        </Box>
                                    }
                                    <GetApp/>
                                </Button>
                            </Tooltip> 
                        }

                        {(this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") && this.state.unmergedKonsileCount > 0 &&
                        <>
                            <Tooltip title="Fehlende Profile verknüpfen" placement="top">
                                <Button className="slim danger connect-profile-btn" variant="contained" color="inherit" size="small" onClick={() => {this.getUnmergedKonsile(); this.newPvsPatientSelectModal();}} >
                                    <AccountCircle/>
                                    <Box className="pad-left">
                                        {this.state.unmergedKonsileCount}
                                    </Box>
                                </Button>
                            </Tooltip>
                            <PvsPatientSelectContainer open={this.state.pvsOpen} onClose={this.newPvsPatientSelectModal} patienten={this.state.patienten} unmergedKonsile={this.state.unmergedKonsile} getUnmergedKonsileCount={this.getUnmergedKonsileCount}/>
                        </>
                        }
                        <Tooltip title="Konsilübersicht & Status aktualisieren" placement="top">
                            <IconButton
                                type="submit"
                                size="small"
                                className=""
                                aria-label="Konsilübersicht und Status aktualisieren"
                                onClick={() => {
                                    const patientNumber: PatientNumber = PatientNumber.getInstance();
                                    if (patientNumber.getConstant("PATIENT_NUMBER")!=="") {
                                        patientNumber.setConstant("PATIENT_NUMBER", "");
                                        this.loadKonsillist();
                                    }
                                    else
                                        this.refreshKonsilList();
                                }}
                            >
                                <Replay/>
                            </IconButton>
                        </Tooltip>
                        {this.props.isDesktop &&
                            <Box className="flex-spacer"></Box>
                        }

                        {this.props.isDesktop &&
                            <Box className = 'flex-row-column-gap'>
                                {this.props.konsilSortSearchFilterParams.konsilStatus.map(item => {
                                    return <Tooltip title = {KonsilStatusType[item]} placement = 'top'>
                                        <Chip
                                            label = {KonsilStatusTypeShort[item]}
                                            variant= "default"
                                            color = 'primary'
                                            size = 'small'
                                            onDelete = {() => this.onFilterChipDelete(item)}
                                            className = 'filter-chip'
                                        />
                                    </Tooltip>
                                })}
                            </Box>
                        }    

                        {(this.props.konsilSortSearchFilterParams.search !== "" || this.props.konsilSortSearchFilterParams.konsilStatus.length !== 0) &&
                             <Tooltip title="Suche und Filter zurücksetzen" placement="top">
                                <IconButton
                                    className="filter-close"
                                    onClick={this.props.dispatch_resetKonsilSearchFilter}
                                >
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        }
                            
                            <FormControl variant="outlined">
                                <Select
                                    labelId="filter-label"
                                    id="mutiple-filter-checkbox"
                                    className="table-filter select-dopdown-btn MuiButtonBase-root MuiButton-root MuiButton-outlined slim MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                    multiple
                                    displayEmpty
                                    value={this.props.konsilSortSearchFilterParams.konsilStatus}
                                    onChange={this.changeFilter}
                                    input={<Input />}
                                    SelectDisplayProps={{
                                        onClick: this.selectHandleOnOpen
                                    }}
                                    MenuProps={{
                                        onClose:(event, reason:'backdropClick' | 'escapeKeyDown') => this.selectHandleOnOpen(event)
                                    }}
                                    open={this.state.canOpen}
                                    renderValue={(selected) => {
                                        if ((selected as string[]).length === 0) {
                                            return <span>
                                                {this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><span>Filter</span></Tooltip>}
                                                {!this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><VolumeMute className="filter-icon"/></Tooltip>}
                                            </span>
                                        } else {
                                            return (
                                                <span className="flex-row-center" >
                                                    {this.props.isDesktop &&
                                                        <Tooltip
                                                            title = {
                                                                <>
                                                                    <Typography variant = 'body1'>Gesetzte Filter:</Typography>
                                                                    {this.props.konsilSortSearchFilterParams.konsilStatus.map(item => {
                                                                        return <Typography variant = 'body2'>{KonsilStatusType[item]}</Typography>
                                                                    })}
                                                                </>
                                                            }
                                                            placement="top"
                                                        >
                                                            <span>Filter</span>
                                                        </Tooltip>
                                                    }
                                                    {!this.props.isDesktop &&
                                                        <Tooltip
                                                            title = {
                                                                <>
                                                                    <Typography variant = 'body1'>Gesetzte Filter:</Typography>
                                                                    {this.props.konsilSortSearchFilterParams.konsilStatus.map(item => {
                                                                        return <Typography variant = 'body2'>{KonsilStatusType[item]}</Typography>
                                                                    })}
                                                                </>
                                                            }
                                                            placement="top"
                                                        >
                                                            <VolumeMute className="filter-icon"/>
                                                        </Tooltip>
                                                    }
                                                </span>
                                            );
                                        }
                                        }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    //MenuProps={MenuProps}
                                    //{(selected) => ((selected as string[]).join(', '))}
                                >
                                    <MenuItem disabled value="">
                                        <em>Filter</em>
                                    </MenuItem>
                                {(this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") &&
                                Object.keys(KonsilStatusType).map((status: keyof typeof KonsilStatusType) => (
                                    <MenuItem key={status} value={status}>
                                        <Checkbox checked={this.props.konsilSortSearchFilterParams.konsilStatus.indexOf(status) > -1}/>
                                        <ListItemText primary={KonsilStatusType[status]} />
                                    </MenuItem>
                                ))}
                                {(this.props.userRole === "DERMATOLOGE" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") &&
                                Object.keys(KonsilStatusType).filter(item => item !== "IN_ARBEIT").map((status: keyof typeof KonsilStatusType) => (
                                    <MenuItem key={status} value={status}>
                                        <Checkbox checked={this.props.konsilSortSearchFilterParams.konsilStatus.indexOf(status) > -1}/>
                                        <ListItemText primary={KonsilStatusType[status]} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                    </div>

                </Box>
                <HeaderSearch
                    dispatch_searchKonsile = {this.props.dispatch_searchKonsile}
                    searchString = {this.props.konsilSortSearchFilterParams.search}
                />
            </header>
        );
    }
}

const HeaderKonsillisteContainer = connector(HeaderKonsilliste);
export default HeaderKonsillisteContainer;
