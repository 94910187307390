import React from "react";
import {connect, ConnectedProps} from "react-redux";
import IdleTimer from 'react-idle-timer'; // library to track user activity, mouse and keyboard actions, needs to be added in the first node of react tree, it tracks activity in all children

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

import Logo from "../../static/images/logo-telescan.svg";
import {RootState} from "../../redux/reducers";
import {loadSuccess, navigate, changeKonsilTab, removeLoadFailure, removeSuccessMessage, removeSendFailure, logout, refresh, clearKonsilImageList, clearRueckantwortImageList, clearAttachmentList,} from "../../redux/actions";

import HeaderKonsilContainer from "./header_konsil";
import HeaderKonsillisteContainer from "./header_konsilliste";
import HeaderDermaKonsilContainer from "./header_konsil_derma";
import { UserContainer } from "../header";
import HeaderInitialisationContainer from "./header_initialisation";
import { FailurePopup, ServerAwayPopupComponent } from "../konsil/sending_results_popup";
import { needsUpdate } from "../../utils/utils";
import HeaderBillingListContainer from "../billing/quartal_bills_table/quartal_bills_table_header";
import HeaderBillingContainer from "../billing/single_quartal_bill_table/single_quartal_bill_table_header";
import alertIcon from '../../static/images/ic-hinweis.svg';
import { Failure, TelescanConfig, Notification, NotificationActionType } from "telescan-core";
import { sendNotification, setIsNormalNotification, removeNotification, setIsWarningNotification } from "../../redux/actions/notification_actions";
import { NotificationLoginPopup } from "./notifications/notification_login_popup";
import { NotificationPopover } from "./notifications/notification_popover";

interface IState {
    openReceiverModal: boolean;
    sendFailure: {konsilID: string, failure: Failure};
    isOpenFailure: boolean;
    timeout: number;
    isTimedOut: boolean;
    isShowLogoutWarning: boolean;
    setTimeoutId: number;
}
interface IProps {
    idleTimer: IdleTimer; // declaring props of IdleTimer
}
const mapStateToProps = (state: RootState) => ({
    failures: state.general.failures,
    successMessage: state.general.successMessage,
    loading: state.general.loading,
    location: state.router.location.pathname,
    role: state.user.role,
    user: state.user.user,
    sendFailures: state.konsile.sendFailures,
    arztZertifikateMetaInfo: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikatMetaInfo: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
    updateInfo: state.update.updateInfo,
    zertifikatStatus: state.zertifikate.zertifikatStatus,
    isCriticalNotifications: state.notifications.isCriticalNotification,
    isWarningNotifications: state.notifications.isWarningNotification,
    isNormalNotifications: state.notifications.isNormalNotification,
    notifications: state.notifications.notifications,
    currentKonsil: state.konsile.current_konsil,
})

const mapDispatchToProps = {
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_changeKonsilTab: (index: number) => changeKonsilTab(index),
    dispatch_removeFailure: (id: string) => removeLoadFailure(id),
    dispatch_removeSuccessMessage: () => removeSuccessMessage(),
    dispatch_removeSendFailure: (id: string) => removeSendFailure(id),
    dispatch_logout: () => logout(),
    dispatch_refresh: (token: string) => refresh(token),
    dispatch_clearKonsilImageList: () => clearKonsilImageList(),
    dispatch_clearRueckantwortImageList: () => clearRueckantwortImageList(),
    dispatch_clearAttachmentList: () => clearAttachmentList(),
    dispatch_setIsNormalNotification: (isNormalNotification: boolean) => setIsNormalNotification(isNormalNotification),
        dispatch_sendNotification: (id: string, notificationAction: NotificationActionType) => sendNotification(id, notificationAction),
        dispatch_removeNotification: (id: string) => removeNotification(id),
        dispatch_setIsWarningNotification: (isWarningNotification: boolean) => setIsWarningNotification(isWarningNotification),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & IProps
export class Header extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            openReceiverModal: false,
            sendFailure: {konsilID: "", failure: new Failure()},
            isOpenFailure: false,
            timeout: 10*60*1000, // idle timeout - 10 min in millisecons,
            isTimedOut: false, // boolean to track if time. out expires
            isShowLogoutWarning: false,
            setTimeoutId: 0,
        }
        this.toggleReceiverModal = this.toggleReceiverModal.bind(this);
        this.openKonsil = this.openKonsil.bind(this);
        this.updateSendFailure = this.updateSendFailure.bind(this);
        this.onAction = this.onAction.bind(this);
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.stopLogout = this.stopLogout.bind(this);
    }
    componentDidMount() {
        this.updateSendFailure();
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>) {
        if (prevProps.sendFailures !== this.props.sendFailures) {
            this.updateSendFailure();
        }
    }

    onAction(event: any) { // Function to call on user action.
        this.setState({isTimedOut: false,})
    }

    onActive(event: any) {
        this.setState({isTimedOut: false,}) // Function to call when user is no longer idle.
    }

    onIdle() { //Function to call when user is now idle => show dialog warning about logout in 30 sec, if user do not click ok -> logout
        this.setState({isShowLogoutWarning: true,})
        const setTimeoutId: number = window.setTimeout(() => this.props.dispatch_logout(), 30*1000)
        this.setState({
            isShowLogoutWarning: true,
            setTimeoutId: setTimeoutId,
        })
    }

    stopLogout() {
        clearTimeout(this.state.setTimeoutId);
    }

    openKonsil() {
        this.props.dispatch_removeSendFailure(this.state.sendFailure?.konsilID || "");
        this.props.dispatch_navigate("/konsil/" + this.state.sendFailure?.konsilID || "");
        if (this.state.sendFailure && this.props.currentKonsil.id !== this.state.sendFailure.konsilID) {
            this.props.dispatch_clearKonsilImageList();
            this.props.dispatch_clearRueckantwortImageList();
            this.props.dispatch_clearAttachmentList();
        }
    };

    toggleReceiverModal() {
        this.setState({
            openReceiverModal: !this.state.openReceiverModal
        });
    };

    private updateSendFailure() {
        if (this.props.sendFailures.length > 0)
            this.setState({
                isOpenFailure: true,
                sendFailure: this.props.sendFailures[this.props.sendFailures.length-1]
            });
        else
            this.setState({
                isOpenFailure: false,
                sendFailure: {konsilID: "", failure: new Failure()}
            });
    }

    render() {
        const criticalNotifications: Notification[] = this.props.notifications.filter(notification => notification.notificationType === "RISK" || notification.notificationType === "WARNING");
        const normalNotifications: Notification[] = this.props.notifications.filter(notification => notification.notificationType === "NEWS" || notification.notificationType === "NOTE");
        let alerts: string[] = [];
        /*
        if ((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false)))
            alerts.push("Arztzertifikat muss erneuert werden!")
        if (this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1))
            alerts.push("Betriebsstättenzertifikat muss erneuert werden!")
        */
        if (this.props.role === "DERMATOLOGE") {
            if (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false)) {
                alerts.push("Arztzertifikat muss erneuert werden!")
            }
            if (this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1)) {
                alerts.push("Betriebsstättenzertifikat muss erneuert werden!")
            }
        }

        if (this.props.role === "HAUSARZT") {
            if (this.props.zertifikatStatus.betriebsstaettenZertifikat === "GESPERRT" || this.props.zertifikatStatus.betriebsstaettenZertifikat === "GELOESCHT") {
                alerts.push("Betriebsstättenzertifikat muss erneuert werden!")
            }
            if (this.props.zertifikatStatus.arztZertifikat === "GESPERRT" || this.props.zertifikatStatus.arztZertifikat === "GELOESCHT") {
                alerts.push("Arztzertifikat muss erneuert werden!")
            }
        }

        const updated = this.props.updateInfo.filter(module => module.updateVerfuegbar);
        updated.length>0 &&
        updated.map(item => {
            if (item.modul==="teleScan" && item.installierteVersion!=="unbekannt") {
                alerts.push("Neue Version von TeleScan verfügbar!");
                return null;
            }
            else if (item.modul==="hpm" && item.installierteVersion!=="unbekannt") {
                alerts.push("Neue Version von HPM verfügbar!");
                return null;
            }
            else
                return null;
        })

        const uniqueFailures = Array.from(new Set(this.props.failures.map(item => item.failure.error))) // remove duplicated failure before showing them to user
        .map(item => {
            return this.props.failures.find(failureObj => failureObj.failure.error === item)
        });
        return (
            <>
                <IdleTimer // IdleTimer component track all childrens
                    element={document} //Defaults to document, may pass a ref to another element.
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={1000} //Debounce the onAction function with delay in milliseconds
                    timeout={this.state.timeout}
                />

                <Dialog open = {this.state.isShowLogoutWarning}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sie werden aufgrund von Inaktivität in 30 Sekunden ausgeloggt.
                            Klicken Sie auf "Fortsetzen", um die automatische Abmeldung abzubrechen.
                        </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button
                            variant = 'contained'
                            color = 'primary'
                            onClick = {() => {
                                this.stopLogout();
                                this.setState({
                                    isShowLogoutWarning: false,
                                });
                                const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
                                const refresh_token = telescanConfig.getConstant('REFRESH_TOKEN');
                                this.props.dispatch_refresh(refresh_token);
                            }}
                        >
                            Fortsetzen
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.props.location !== "/settings" &&
                    <NotificationLoginPopup
                        isCriticalNotifications = {this.props.isCriticalNotifications}
                        isWarningNotifications = {this.props.isWarningNotifications}
                        dispatch_logout = {this.props.dispatch_logout}
                        dispatch_sendNotification = {this.props.dispatch_sendNotification}
                        criticalNotifications = {criticalNotifications}
                        dispatch_removeNotification = {this.props.dispatch_removeNotification}
                        dispatch_navigate = {this.props.dispatch_navigate}
                        dispatch_setIsWarningNotification = {this.props.dispatch_setIsWarningNotification}
                    />
                }

                <NotificationPopover
                    isCriticalNotifications = {this.props.isCriticalNotifications}
                    isWarningNotifications = {this.props.isWarningNotifications}
                    isNormalNotifications = {this.props.isNormalNotifications}
                    dispatch_sendNotification = {this.props.dispatch_sendNotification}
                    normalNotifications = {normalNotifications}
                    dispatch_setIsNormalNotification = {this.props.dispatch_setIsNormalNotification}
                    dispatch_removeNotification = {this.props.dispatch_removeNotification}
                />


                {alerts.length > 0 &&
                    <Alert
                        className="update-alert"
                        variant="filled"
                        icon={false}
                        severity="success"
                        onClick={() => this.props.dispatch_navigate("/settings")}
                    >
                        <a href="#update-anchor">{alerts.join(" / ")}</a>
                    </Alert>
                }
                <Snackbar
                    className = 'snackbar'
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    autoHideDuration={10000}
                    open={this.props.failures.length > 0}
                    onClose={() => {
                        this.props.failures &&
                        this.props.failures.map(item => {
                            return (
                                item && this.props.dispatch_removeFailure(item.id)
                            )
                        })
                    }}
                >
                    <div>
                        { //here all failures collected in redux store shown: main error and  messages if they exist
                            uniqueFailures && uniqueFailures.length>0 &&
                            uniqueFailures.map((item) => {
                                return(
                                    item &&
                                    <Alert 
                                        severity = "error"
                                        key = {item.id}
                                        icon = {
                                                <div>
                                                    <img  src = {alertIcon} alt = ""/>
                                                </div>
                                            }
                                        >
                                        <div >
                                            {item.failure.error}
                                            {
                                                item.failure.messages && item.failure.messages.length>0 &&
                                                item.failure.messages.map((subitem: string) => {
                                                    return (
                                                        <li key = {subitem}>
                                                            {subitem}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Alert>
                                )
                            })
                        }
                    </div>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    autoHideDuration={3000}
                    open={this.props.successMessage !== ""}
                    onClose={() => this.props.dispatch_removeSuccessMessage()}
                    >
                    <Alert severity="success">
                        {this.props.successMessage}
                    </Alert>
                </Snackbar>

                {this.props.location === "/" && <HeaderKonsillisteContainer/>}
                {this.props.location === "/settings" && <HeaderSettingsContainer/>}
                {this.props.location === "/impressum" && <HeaderImprintContainer/>}
                {this.props.location === "/datenschutz" && <HeaderDataprotectionContainer/>}
                {this.props.location === "/hilfe" && <HeaderHelpContainer/>}
                {this.props.location === "/initialisation" && <HeaderInitialisationContainer/>}
                {this.props.location === "/billing" && <HeaderBillingListContainer/>}
                {this.props.location.includes("/billing/") && <HeaderBillingContainer/>}
                {
                    this.props.location !== "/" &&
                    this.props.location !== "/billing" &&
                    this.props.location !== "/settings" &&
                    this.props.location !== "/impressum" &&
                    this.props.location !== "/datenschutz" &&
                    this.props.location !== "/hilfe" &&
                    this.props.location !== "/updates" &&
                    this.props.location !== "/initialisation" &&
                    !this.props.location.includes("/billing/") &&
                    (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                    <HeaderKonsilContainer
                        toggleReceiverModal={this.toggleReceiverModal}
                        openReceiverModal={this.state.openReceiverModal}
                    />
                }
                {
                    this.props.location !== "/" &&
                    this.props.location !== "/billing" &&
                    this.props.location !== "/settings" &&
                    this.props.location !== "/impressum" &&
                    this.props.location !== "/datenschutz" &&
                    this.props.location !== "/hilfe" &&
                    this.props.location !== "/updates" &&
                    this.props.location !== "/initialisation" &&
                    !this.props.location.includes("/billing/") &&
                    (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                    <HeaderDermaKonsilContainer/>
                }
                {(this.props.loading.length > 0) && <LinearProgress/>}
                <FailurePopup isOpen={this.state.isOpenFailure} closeFailurePopup={() => this.setState({isOpenFailure: false})}
                              toggleReceiverModal={() => this.openKonsil()} konsilID={this.state.sendFailure?.konsilID || ""}
                              error={this.state.sendFailure?.failure || ""}/>
                <ServerAwayPopupComponent/>
            </>
        );
    }
}

export const HeaderContainer = connector(Header);
export default HeaderContainer;



class HeaderSettings extends React.Component<TPropsFromRedux> {
    render() {
        return (
            <header className="header-settings">
                <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/")}>
                    Zurück
                </Button>
                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <h1 className="title">Einstellungen</h1>
                <UserContainer userDetails={this.props.user}/>
            </header>
        );
    }
}

const HeaderSettingsContainer = connector(HeaderSettings);

class HeaderImprint extends React.Component<TPropsFromRedux> {
    render() {
        return (
            <header className="header-settings">
                {this.props.user.length > 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/")}>
                    Zurück
                </Button>}
                {this.props.user.length === 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/login")}>
                    Zurück
                </Button>}
                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <h1 className="title">Impressum</h1>
                {this.props.user.length > 0 && <UserContainer userDetails={this.props.user}/>}
            </header>
        );
    }
}

const HeaderImprintContainer = connector(HeaderImprint);

class HeaderDataprotection extends React.Component<TPropsFromRedux> {
    render() {
        return (
            <header className="header-settings">
                {this.props.user.length > 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/")}>
                    Zurück
                </Button>}
                {this.props.user.length === 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/login")}>
                    Zurück
                </Button>}
                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <h1 className="title">Datenschutz</h1>
                {this.props.user.length > 0 && <UserContainer userDetails={this.props.user}/>}
            </header>
        );
    }
}

const HeaderDataprotectionContainer = connector(HeaderDataprotection);

class HeaderHelp extends React.Component<TPropsFromRedux> {
    render() {
        return (
            <header className="header-settings">
                {this.props.user.length > 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/")}>
                    Zurück
                </Button>}
                {this.props.user.length === 0 && <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/login")}>
                    Zurück
                </Button>}
                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <h1 className="title">Hilfe</h1>
                {this.props.user.length > 0 && <UserContainer userDetails={this.props.user}/>}
            </header>
        );
    }
}

const HeaderHelpContainer = connector(HeaderHelp);


