import React from 'react';
import {IconButton, InputAdornment, TextField} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


interface IProps {
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

interface IState {
    showId: boolean;
    isPasswordShown: boolean;
}


export default class ZertifikatPasswortComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showId: false,
            isPasswordShown: false,
        };
    }

    render() {
        return (
            <TextField
                fullWidth={true}
                name="password"
                label="Passwort Ihres Arzt-Zertifikats"
                value={this.props.password}
                onChange={(event) => this.props.handlePasswordChange(event)}
                type = {this.state.isPasswordShown ? 'text': 'password'}
                InputProps = {{
                    endAdornment: (
                        <InputAdornment position = 'end'>
                            <IconButton
                            aria-label="toggle password visibility"
                                onClick = {() => {
                                    if (!this.state.isPasswordShown) {
                                        this.setState(prevState => {
                                            return {isPasswordShown: !prevState.isPasswordShown}
                                        });
                                        setTimeout(() => this.setState({isPasswordShown: false}), 4000);
                                    } else
                                        this.setState(prevState => {
                                            return {isPasswordShown: !prevState.isPasswordShown}
                                        });
                                }}
                            >   
                                {this.state.isPasswordShown ? <Visibility fontSize = 'small'/> : <VisibilityOff fontSize = 'small'/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        )
    }
}


