import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { BeschwerdeDauer, DiagnoseSicherheit, KonsilUpdateConflictData, LaborwertInterpratation, Seitenlokation } from 'telescan-core';
import { mapZeitEinheit } from './vorbehandlung_display';

interface IProps {
    isKonsilUpdateConflictDialog: boolean,
    dispatch_setIsKonsilUpdateConflictDialog: (isKonsilUpdateDialog: boolean) => void,
    konsilUpdateConflictData: KonsilUpdateConflictData[]
}

export default class UpdateConflictDialog extends React.Component<IProps> {
    render() {
        return (
            <Dialog
                open = {this.props.isKonsilUpdateConflictDialog}
            >
                <DialogTitle>
                    Bei der Aktualisierungen wurden Konflikte festgestellt
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Die Angaben dienen zur Information: Ihre Änderungen werden übernommen. Hier sehen Sie, welche Änderung der letzten Version damit überschrieben werden.
                    </DialogContentText>
                    {this.props.konsilUpdateConflictData.map((item: KonsilUpdateConflictData) => {
                        let oldValue: any;
                        let newValue: any;
                        if (item.fieldType === "BeschwerdeDauer") {
                            oldValue = BeschwerdeDauer[item.oldValue];
                            newValue = BeschwerdeDauer[item.newValue];
                        } else if (item.fieldType === "ZeitEinheit") {
                            oldValue = mapZeitEinheit[item.oldValue];
                            newValue = mapZeitEinheit[item.newValue];
                        } else if (item.fieldType === "Seitenlokation") {
                            oldValue = Seitenlokation[item.oldValue];
                            newValue = Seitenlokation[item.newValue];
                        } else if (item.fieldType === "LaborwertInterpratation") {
                            oldValue = LaborwertInterpratation[item.oldValue];
                            newValue = LaborwertInterpratation[item.newValue];
                        } else if (item.fieldType === "DiagnoseSicherheit") {
                            oldValue = DiagnoseSicherheit[item.oldValue];
                            newValue = DiagnoseSicherheit[item.newValue];
                        } else if (item.fieldType === "Boolean" || item.fieldType === "boolean") {
                            enum BooleanEnum {
                                false = "nein",
                                true = "ja",
                                null = "unbekannt"
                            }
                            oldValue = BooleanEnum[item.oldValue];
                            newValue = BooleanEnum[item.newValue];
                        } else if (typeof item.oldValue === "object" || typeof item.newValue === "object") {
                            oldValue = item.oldValueString.join("\n\n");
                            newValue = item.newValueString.join("\n\n");
                        } else {
                            oldValue = item.oldValueString;
                            newValue = item.newValueString;
                        }
                        return (
                            <Box key = {item.dateChanged}>
                                <Typography gutterBottom>{item.fieldPath && item.fieldPath.replace(/ *\[[^\]]*]/g, '')}</Typography>
                                <Box className = 'grid-two-columns'>
                                    <Box>
                                        <TextField
                                            label = "Ihre Änderung"
                                            variant = 'outlined'
                                            multiline
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue = {newValue}
                                            fullWidth = {true}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            label = "letzte Version"
                                            variant = 'outlined'
                                            multiline
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue = {oldValue}
                                            fullWidth = {true}
                                        />
                                    </Box>

                                </Box>
                                <br/>
                            </Box>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        onClick = {() => this.props.dispatch_setIsKonsilUpdateConflictDialog(false)}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
