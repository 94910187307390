import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Collapse, TablePagination, Avatar } from '@material-ui/core';
import { push } from 'connected-react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Arzt, Patient, Konsil, TelescanConfig, KonsilStatusType, BearbeitungsStatusType } from 'telescan-core';
import { KonsilPatient, KonsilStatus } from '.';
import { RootState } from '../../redux/reducers';
import { IconButton, Tooltip } from '@material-ui/core';
import { GetApp, SortByAlpha, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { loadArztKonsile, downloadKonsil, updateDermaKonsileAndMetaData, loading, setSuccessMessage, refreshKonsilStatus } from '../../redux/actions';

import { TableSort, TablePaginationActions } from './konsil_table';
import { TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import SortTableComponent from '../elements/sort_table';
import { assembleArztName } from '../../utils/utils';
import RefreshIcon from '@material-ui/icons/Refresh';

export interface DermaRow {
    id: string;
    patient: Patient| undefined;
    date_created: any;
    date_send: number;
    letzterBearbeitungszeitpunkt: number;
    status: keyof typeof KonsilStatusType;
    bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
    senderArzt: Arzt;
    senderLanr: string;
    konsil: Konsil | undefined;
    isMetaData: boolean;
    dokumentenId: string | undefined;
}

interface DermaTableProps {
    data: DermaRow[];
    metadata: DermaRow[];
    totalSize: number;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface IDermaTableState {
    isCollapsed: boolean,
    propertyName: string;
    direction: "asc" | "desc" | "default";
}

const mapStateToProps = (state: RootState, ownProps: DermaTableProps) => ({
    loading: state.general.loading,
    router: state.router,
    currentKonsil: state.konsile.current_konsil,
    metadatenGerichtet: state.dermatologe.metadatenGerichtet,
    konsilSortSearchFilterParams: state.konsile.konsilSortSearchFilterParams,
    isSupportEnabled: state.general.isSupportEnabled,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loadKonsile: () => loadArztKonsile(),
    dispatch_updateDermaKonsileAndMetaData: () => updateDermaKonsileAndMetaData(),
    push: (path) => push(path),
    dispatch_downloadKonsil: (dokumentenId: string, vorgangsId: string) => downloadKonsil(dokumentenId, vorgangsId),
    dispatch_loading: (id: string) => loading(id),
    dispatch_successMessage: (message: string) => setSuccessMessage(message),
    dispatch_refreshKonsilStatus: (konsil_id: string) => refreshKonsilStatus(konsil_id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class DermaKonsilTable extends React.Component<TPropsFromRedux, IDermaTableState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isCollapsed: false,
            propertyName: "konsilStatus",
            direction: "default"
        };
        this.handleDermaCollapse = this.handleDermaCollapse.bind(this);
        this.setPropertyNameAndDirection = this.setPropertyNameAndDirection.bind(this);

        this.emptyPatient.vorname = "";
        this.emptyPatient.nachname = "";
        this.emptyPatient.geschlecht = "UNBEKANNT";
    }

    private konsil(vorgangsId: string, isMetaData: boolean, dokumentenId: string | undefined) {
        if (isMetaData && dokumentenId){
            this.props.dispatch_downloadKonsil(dokumentenId, vorgangsId);
        } else
            this.props.push(this.props.router.location.pathname + "konsil/" + vorgangsId);
    }

    private emptyPatient = new Patient();
    private emptyArzt = new Arzt();

    private stringToColor(str: string) { // function to conver full name of Arzt to background color of avatar such that each Arzt will get unique color
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + (hash << 5) - hash;
        }
        return `hsl(${hash % 360}, 30%, 80%)`;
    }

    private getMetadataArzt(senderArzt: string | undefined, senderLanr: string) {
        if ((senderArzt === undefined || senderArzt === "") && (!senderLanr || senderLanr === ""))
            return "Keine Angabe";
        else {
            const name: string = senderArzt ? senderArzt : "";
            const lanr: string = senderLanr ? `[${senderLanr}]` : "";
            return `${lanr} ${name}`;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            //this.props.dispatch_updateDermaKonsileAndMetaData(this.state.propertyName, this.state.direction)
            //this.props.dispatch_loadDermaKonsile();
            //this.props.dispatch_updateMetadatenAdressiert();
            //this.props.dispatch_updateMetadatenGerichtet();
        }
    }

    handleDermaCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    getDate(rowData: DermaRow) {
        if (rowData.date_send)
            return new Date(rowData.date_send).toLocaleString();
        else if (!rowData.date_send && rowData.date_created)
            return new Date(rowData.date_created).toLocaleString();
        else {
            return "keine Angabe"
        }
    }

    setPropertyNameAndDirection(propertyName: string, direction: "asc" | "desc" | "default"): void {
        this.setState({
            propertyName: propertyName,
            direction: direction
        })
    }


    render() {
        const telescanConfig = TelescanConfig.getInstance();

        return (
            <>
                <TableContainer className="konsil-table derma-version">
                    <Box className="mobile-table-header">
                        <TableSort />
                        <IconButton aria-label="delete">
                            <SortByAlpha />
                        </IconButton>
                    </Box>
                    <Table stickyHeader aria-label="sticky table" className="table-connector">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "3%" }}></TableCell>
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "patient"} propertyName="konsilPatient.vorname" title="Patient " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilID"} propertyName="id" title="Konsil ID " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "zeitpunkt"} propertyName="datecreated" title="Zeitpunkt " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilStatus"} propertyName="konsilStatus" title="Status " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "adressat"} propertyName="konsiliar.nachname" title="LANR " />
                                <TableCell align="right"></TableCell>
                                </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.props.konsilSortSearchFilterParams.pageNb === 0 && this.props.metadata.map(rowData => {
                                let className: string;
                                if (rowData.status === "ABGEBROCHEN")
                                    className = rowData.konsil?.statusAenderung? "abgebrochen-konsil-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";
                                else
                                    className = rowData.konsil?.statusAenderung? "bold-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";
                                return (
                                    <TableRow className={"table-row " + className} key={rowData.id} onClick={(event) => this.konsil(rowData.id, rowData.isMetaData, rowData.dokumentenId)}>
                                        <TableCell className="konsil-patient" style={{ width: "3%" }}/>
                                        <TableCell className="konsil-patient"><KonsilPatient patient={rowData.patient || new Patient()} /></TableCell>
                                        <TableCell className="konsil-id" scope="row">
                                            {rowData.id.substring(0, 8)}
                                        </TableCell>
                                        <TableCell className="konsil-date">
                                            {rowData.letzterBearbeitungszeitpunkt && new Date(rowData.letzterBearbeitungszeitpunkt).toLocaleString()}
                                        </TableCell>
                                        <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={rowData.bearbeitungsStatus} status={rowData.status} isMetaData={rowData.isMetaData} role="DERMATOLOGE" /></TableCell>
                                        <TableCell className="konsil-receiver">{this.getMetadataArzt(rowData.senderArzt.nachname, rowData.senderLanr)}</TableCell>
                                        <TableCell align="right" className="konsil-actions"></TableCell>
                                    </TableRow>)}
                            )}
                            { (this.props.konsilSortSearchFilterParams.pageNb === 0 && this.props.metadata.length > 0) &&
                                <TableRow style={{height: "8px"}}/>
                            }
                            {this.props.data.map(rowData => {

                                const className: string = rowData.konsil?.statusAenderung? "bold-highlight ": (rowData.konsil?.konsilStatus === "BEAUFTRAGT" || rowData.konsil?.konsilStatus === "BEANTWORTET")? "highlight": "";
                                const avatarName: string = rowData?.konsil?.konsiliar ?
                                `${rowData?.konsil?.konsiliar.vorname} ${rowData?.konsil?.konsiliar.nachname}` :
                                "";
                                const avatarLabel: string = (rowData?.konsil?.konsiliar && rowData?.konsil?.konsiliar.vorname && rowData?.konsil?.konsiliar.nachname) ?
                                rowData?.konsil?.konsiliar?.vorname[0] + rowData?.konsil?.konsiliar?.nachname[0] :
                                "";
                                let cssProperties = {};
                                cssProperties['--btn-bg-color'] = this.stringToColor(avatarName); // apply color generated from Arzt full name to css class
                                return (
                                    <TableRow className={"table-row " + className} key={rowData.id} onClick={(event) => this.konsil(rowData.id, rowData.isMetaData, rowData.dokumentenId)}
                                    >
                                        {rowData.konsil?.konsiliar ?
                                            <TableCell className="doctor-avatar" style={{ width: "3%" }}>
                                                <Tooltip placement = 'top' title = {avatarName}>
                                                    <Avatar className = "avatar" style = {cssProperties}>{avatarLabel}</Avatar>
                                                </Tooltip>
                                            </TableCell> :
                                            <TableCell className="konsil-patient" style={{ width: "3%" }}/>
                                        }
                                        <TableCell className="konsil-patient">
                                            <KonsilPatient patient={rowData.patient || new Patient()} />
                                        </TableCell>
                                        <TableCell className="konsil-id" scope="row">
                                            {rowData.id.substring(0, 8)}
                                        </TableCell>
                                        <TableCell className="konsil-date">{this.getDate(rowData)}</TableCell>
                                        <TableCell className="konsil-status">
                                            <KonsilStatus therapiedringlichkeit={(rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit)?
                                            TherapieDringlichkeit[rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit]: undefined}
                                            hasPvsPatient={true} bearbeitungsStatus={rowData.bearbeitungsStatus} status={rowData.status}
                                            isMetaData={rowData.isMetaData} role="DERMATOLOGE" />
                                        </TableCell>
                                        <TableCell className="konsil-receiver">{assembleArztName(rowData.senderArzt)}</TableCell>
                                        <TableCell align="right" className="konsil-actions">
                                            <Box className = 'flex-row-center justify-end'>
                                            {this.props.isSupportEnabled &&
                                                <Tooltip title = "Bearbeitungsstatus aktualisieren">
                                                    <IconButton
                                                        onClick = {(event) => {
                                                            event.stopPropagation();
                                                            this.props.dispatch_refreshKonsilStatus(rowData.id);
                                                        }}
                                                    >
                                                        <RefreshIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                                {(rowData.isMetaData === false && rowData.status !== "IN_ARBEIT") &&
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="Download PDF"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            this.props.dispatch_successMessage("Der Anhang wird in einem neuen Tab geöffnet");
                                                            if (rowData.status === "ABGEBROCHEN")
                                                                window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${rowData.id}/pdf/latest?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blanck");
                                                            else
                                                                window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${rowData.id}/pdf?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blanck");
                                                        }}
                                                    >
                                                        <GetApp />
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                            </Box>
                                        </TableCell>
                                    </TableRow>)}
                            )}
                            <TableRow className="table-footer-pagination">
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    colSpan={7}
                                    count={(this.props.totalSize !== -1)? this.props.totalSize: 0}
                                    rowsPerPage={this.props.konsilSortSearchFilterParams.numberPerPage}
                                    page={this.props.konsilSortSearchFilterParams.pageNb}
                                    onPageChange={this.props.handleChangePage}
                                    onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {(this.props.metadatenGerichtet.length !== 0 && this.props.metadatenGerichtet != null)?
                <Box className="derma-table-container ">
                    <Typography className="derma-header" variant="subtitle1" onClick={this.handleDermaCollapse}>
                        {this.props.metadatenGerichtet.length} Extern verfügbare Konsile
                        {(this.state.isCollapsed)? <ArrowDropDown/>: <ArrowDropUp/>}
                    </Typography>
                    <Collapse in={this.state.isCollapsed} >
                        <Table className="derma-table table-connector">
                            <TableBody>
                                {this.props.metadatenGerichtet.map(rowData => {
                                    this.emptyArzt.nachname = rowData.senderArzt || ""
                                    return (
                                    <TableRow
                                        hover
                                        className="table-row"
                                        key={rowData.vorgangsId}
                                        onClick={(event) => {
                                            if (rowData.vorgangsId) {
                                                this.konsil(rowData.vorgangsId, true, rowData.dokumentIdentifikator)
                                            }
                                        }}
                                    >
                                        <TableCell className="konsil-patient"><KonsilPatient patient={this.emptyPatient} /></TableCell>
                                        <TableCell className="konsil-id" scope="row">
                                            {rowData.vorgangsId?.substring(0, 8)}
                                        </TableCell>
                                        <TableCell className="konsil-date">Keine Angabe</TableCell>
                                        {/* {this.props.loading.includes(rowData.vorgangsId || "") ? // show loading progress when external konsil clicked
                                            <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"DOWNLOAD"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>:
                                            <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"OK"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>
                                        } */}
                                        <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={rowData.bearbeitungsStatus} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>
                                            {/*<TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"OK"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>*/}
                                        <TableCell className="konsil-receiver">{assembleArztName(this.emptyArzt)}</TableCell>
                                        <TableCell align="right" className="konsil-actions">
                                            {/* <Tooltip title="Download PDF" placement="top">
                                                <IconButton size="small" aria-label="Download PDF" onClick={(event)=> { event.stopPropagation(); window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/${rowData.id}/pdf?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blank")}} >
                                                    <GetApp />
                                                </IconButton>
                                            </Tooltip> */}
                                        </TableCell>
                                    </TableRow>)}
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </Box>
                :
                <Box className="derma-table-container ">
                    <Typography className="derma-header" variant="subtitle1" onClick={this.handleDermaCollapse}>
                        Extern verfügbare Konsile
                        {(this.state.isCollapsed)? <ArrowDropDown/>: <ArrowDropUp/>}
                    </Typography>
                    <Collapse in={this.state.isCollapsed} >
                            <Box className="derma-table table-connector pad-left pad-top pad-bottom full-span">
                                <Typography>Es sind keine gerichteten Konsile verfügbar.</Typography>
                            </Box>
                    </Collapse>
                </Box>
                }
            </>
        );
    }
}

export const DermaKonsilTableContainer = connector(DermaKonsilTable);
