import uuid from 'uuid';
import { BeschwerdeDauer, BeschwerdeType, Anamnese, Arzt, KonsilStatusType, Failure } from "telescan-core";
import { AttachmentState } from "../redux/reducers/image_attachment_reducer";


export const telescanFixable = " Bitte versuchen Sie es erneut oder wenden Sie sich an den Support unter www.telescan-software.de."
//const hpmFixable = " Bitte überprüfen Sie die Erreichbarkeit und Einstellung Ihres HÄVG-Prüfmoduls oder wenden Sie sich an den Support unter telescan-software.de."

export function assembleAdresse(strasseHausnummer: string | undefined, plz: string | undefined, ort: string | undefined): string[] {
    const firstPart = strasseHausnummer || "";
    const plzText = plz || "";
    const ortText = ort || "";
    const secondPart = [plzText, ortText].join(" ")

    return [(firstPart !== " ")? firstPart: "-", (secondPart !== " ")? secondPart: "-"]
}

export function assembleMalignitaetText(form: boolean, farbe: boolean, groesse: boolean, aussehen: string | undefined) {
    let result: string = "";
    let arr: string[] = [];
    const bool = [form, farbe, groesse, aussehen != null && aussehen !== ""];
    const textArr = ["Formänderung", "Farbänderung", "Größenänderung", aussehen as string] 
    bool.forEach((item, index) => item && arr.push(textArr[index]))
    if (arr.length > 0)
        result = arr.join(", ")
    else result = "NEIN"
    return result
}

export function getBeschwerde(anamnese: Anamnese | undefined): string {
    let result: string = "";
    let arr: string[] = [];
    const bool = [anamnese?.jucken, anamnese?.schmerzen, anamnese?.brennen, anamnese?.naessen, anamnese?.bluten];
    bool.forEach((item, index) => {if (item) arr.push(Object.values(BeschwerdeType)[index])})
    if (arr.length > 0)
        result = arr.join(", ")
    else result = "Keine"
    return result
}

export function assembleBeschwerdeDauer (beschwerdeDauer: keyof typeof BeschwerdeDauer, sonstigeDauer: string | undefined){
    let sonstigeDauerText = (sonstigeDauer === "" || sonstigeDauer === undefined || sonstigeDauer === null)? "-": sonstigeDauer ;
    return (beschwerdeDauer!=="D90")? BeschwerdeDauer[beschwerdeDauer]: sonstigeDauerText
}

export function assembleBeschwerdeVerlauf(unveraendert: boolean | undefined, zunehmend: boolean | undefined, rezidivierend: boolean | undefined) {
    let arr: string[] = [];
    let result: string = "";
    const bool = [zunehmend, rezidivierend, unveraendert];
    const textArr = ["zunehmend", "rezidivierend", "unverändert"]
    bool.forEach((item, index) => item && arr.push(textArr[index]))
    if (arr.length > 0)
        result = arr.join(", ")
    else result = "-"
    return result
}

export function assembleAtopie(heuschnupfen: boolean, asthma: boolean, neurodermites: boolean) {
    let arr: string[] = [];
    let result: string = "";
    const bool = [heuschnupfen, asthma, neurodermites];
    const textArr = ["Heuschnupfen", "Asthma", "Neurodermitis"]
    bool.forEach((item, index) => item && arr.push(textArr[index]))
    if (arr.length > 0)
        result = arr.join(", ")
    else result = "-"
    return result
}

export function assembleArztName(arzt: Arzt) {
    let result: string;
    if (arzt.vorname === undefined || arzt.vorname === "")
        result = arzt.nachname ? arzt.nachname: 'keine Angabe';
    else
        result = arzt.id ? "[" + arzt.lanr + "] " + [arzt.vorname, arzt.nachname].join(' ') : 'keine Angabe';
    return result;
}



export function isEmpty(obj: Arzt) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function needsUpdate(date: Date, interval: number) {
    let threeMonthsFromToday = new Date();
    threeMonthsFromToday.setMonth(threeMonthsFromToday.getMonth() + interval);
    return date <= threeMonthsFromToday
}

export const calculateAge = (dateString: string | number | Date): number => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const removeWhiteSpaces = (str: string): string => {
    const regex = /^\S*$/; //no whitespaces
    if (regex.test(str)){
        return str;
    } else {
        return str.replace(/ /g, '');
    }
}

export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const displayAttachment = (attachment_id: string, attachments: Map<string, AttachmentState>, downloadAttachment: (attachment_id: string) => Promise<any>, dispatch_failure: (id: string, failure: Failure) => void, dispatch_setSuccessMessage?: (message: string) => void) => {
    const loadInstanceId: string = uuid.v4();
    if (attachments[attachment_id] && attachments[attachment_id].data) {
        const pdfWindow = window.open()
        fetch(attachments[attachment_id].data)
            .then(res => res.blob())
            .then(blobPDF =>  {
                const url = URL.createObjectURL(blobPDF);
                pdfWindow!=null &&
                pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>")
                if (pdfWindow == null) {
                    const failure = new Failure();
                    failure.error = "Ihr Browser blockiert die Anzeige von Anhängen. Bitte erlauben Sie in den Sicherheitseinstellungen Ihres Browsers Popups für <BASE_URL>.";
                    dispatch_failure(loadInstanceId, failure)
                } else {
                    dispatch_setSuccessMessage && dispatch_setSuccessMessage("Der Anhang wird in einem neuen Tab geöffnet")
                }                   
            })
            .catch(error => console.log(error)); //!!! error handling?
    }
    else {
        downloadAttachment(attachment_id).then((data) => {
            const pdfWindow = window.open()
            fetch(data)
            .then(res => res.blob())
            .then(blobPDF =>  {
                const url = URL.createObjectURL(blobPDF);
                pdfWindow!=null &&
                pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>")
                if (pdfWindow == null) {
                    const failure = new Failure();
                    failure.error = "Ihr Browser blockiert die Anzeige von Anhängen. Bitte erlauben Sie in den Sicherheitseinstellungen Ihres Browsers Popups für <BASE_URL>.";
                    dispatch_failure(loadInstanceId, failure)
                } 
            })
            .catch(error => console.log(error)); //!!! error handling?
        }).catch((response) => {
            dispatch_failure(loadInstanceId, response)
        })
    }
}

export function checkCdaSize(size: number, konsilStatus: keyof typeof KonsilStatusType): boolean{
    //83,886,080 Bytes = 80 MB; 62,914,560 = 60 MB
    if (konsilStatus === "IN_ARBEIT")
        return (size <= 62914560)
    else if (konsilStatus === "RUECKFRAGE")
        return (size <= 83886080)
    else 
        return true
}

export function roundDateToDay (dateAsNumber: number): number {
    return new Date(dateAsNumber).setHours(0 ,0, 0, 0);
}

export function getCurrentQuartal(): number {
    return Math.floor((new Date().getMonth() + 3) / 3);
}