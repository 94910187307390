import { Box, Button, MenuItem, Paper, TextField, Tabs, Tab} from '@material-ui/core';
import React from 'react';
import { Failure, Vorbehandlung, ZeitEinheit } from 'telescan-core';
import SimpleModalContainer from '../elements/simple_modal';
import {DatePicker} from "./date_picker";
import {RootState} from "../../redux/reducers";
import { failure } from "../../redux/actions";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';
import { roundDateToDay } from '../../utils';

const mapZeitEinheit: Object = {
    min: "Minuten",
    h: "Stunden",
    d: "Tage",
    wk: "Wochen",
    mo: "Monate",
    a: "Jahre",
}

const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObject: Failure) => failure(id, failureObject)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IVorbehandlungEditState {
    vorbehandlungLocal: Vorbehandlung;
    tabValue: number;
}

interface IVorbehandlungEditProps {
    vorbehandlung: Vorbehandlung;
    open: boolean;
    add: boolean;
    handleVorbehandlungEditCallback: (vorbehandlung: Vorbehandlung | null, clickedId: string) => void;
    clickedId: string;
}

export class VorbehandlungEdit extends React.Component<IVorbehandlungEditProps & TPropsFromRedux, IVorbehandlungEditState> {
    constructor(props: IVorbehandlungEditProps & TPropsFromRedux) {
        super(props);
        this.state = {
            vorbehandlungLocal: props.vorbehandlung,
            tabValue: 0,
        }
    }

    componentDidUpdate(prevProps: Readonly<IVorbehandlungEditProps>) {
        if (prevProps.vorbehandlung !== this.props.vorbehandlung) {
            this.setState({
                vorbehandlungLocal: this.props.vorbehandlung
            });
            if (this.props.vorbehandlung.vorbehandlungAm)
                this.setState({tabValue: 1});
            else if (this.props.vorbehandlung.dauer && this.props.vorbehandlung.dauer !== "")
                this.setState({tabValue: 2});
            else
                this.setState({tabValue: 0});
        }
    }

    handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({tabValue: newValue});
        if (newValue === 2 && (this.state.vorbehandlungLocal.einheit === undefined || this.state.vorbehandlungLocal.einheit == null)) {
            this.setState({ // set default value of Einheit to Tage
                vorbehandlungLocal: {
                    ...this.state.vorbehandlungLocal,
                    einheit: "d",
                }
            })
        }
    }

    onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        const vorbehandlungLocal: Vorbehandlung = this.state.vorbehandlungLocal;
        const failureID: string = uuid.v4();
        const failure: Failure = new Failure();
        if (vorbehandlungLocal.beschreibung === "" || !vorbehandlungLocal.beschreibung) {
            failure.error = "Bitte fügen Sie zuerst einen Beschreibungstext hinzu.";
            this.props.dispatch_failure(failureID, failure);
        }
        else if (!vorbehandlungLocal.vorbehandlungVon && !vorbehandlungLocal.vorbehandlungBis && !vorbehandlungLocal.vorbehandlungAm && (vorbehandlungLocal.dauer === "" || !vorbehandlungLocal.dauer)) {
            failure.error = "Bitte wählen Sie eine Vorbehandlungsdauer aus (Zeitraum, Zeitpunkt, oder Dauer)";
            this.props.dispatch_failure(failureID, failure);
        }
        else if ((vorbehandlungLocal.vorbehandlungVon && roundDateToDay(vorbehandlungLocal.vorbehandlungVon) > roundDateToDay(new Date().getTime())) ||
            (vorbehandlungLocal.vorbehandlungBis && roundDateToDay(vorbehandlungLocal.vorbehandlungBis) > roundDateToDay(new Date().getTime())) ||
            (vorbehandlungLocal.vorbehandlungAm && roundDateToDay(vorbehandlungLocal.vorbehandlungAm) > roundDateToDay(new Date().getTime()))
        ) {
            failure.error = "Das Datum darf nicht in der Zukunft liegen.";
            this.props.dispatch_failure(failureID, failure);
        }
        else if (vorbehandlungLocal.vorbehandlungVon && vorbehandlungLocal.vorbehandlungBis && roundDateToDay(vorbehandlungLocal.vorbehandlungVon) >= roundDateToDay(vorbehandlungLocal.vorbehandlungBis)) {
            failure.error = "Das Anfangsdatum muss vor dem Enddatum liegen";
            this.props.dispatch_failure(failureID, failure);
        }
        else       
            this.props.handleVorbehandlungEditCallback(this.state.vorbehandlungLocal, this.props.clickedId);

    }
    render() {
        return (
            <SimpleModalContainer
                isOpen={this.props.open}
                //additionalClassname="modal-add-data"
            >
                <Paper className="modal-wrapper modal-wrapper-vorbehandlung">
                    <h2 className="modal-header">
                        {this.props.add ? "Vorbehandlung hinzufügen" : "Vorbehandlung bearbeiten"}
                    </h2>
                    <Box className="modal-content">
                        <form className="add-form" noValidate autoComplete="off">
                            <TextField
                                id="vorbehandlung-beschreibung-textarea"
                                className="beschreibung pad-top"
                                placeholder="Beschreibungstext *"
                                multiline
                                error={this.state.vorbehandlungLocal.beschreibung === "" || !this.state.vorbehandlungLocal.beschreibung}
                                variant="filled"
                                minRows = {3}
                                maxRows={10}
                                value={this.state.vorbehandlungLocal?.beschreibung || ""}
                                onChange={(event) => {
                                    const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                    vorbehandlung.beschreibung = event.target.value;
                                    this.setState({
                                        vorbehandlungLocal: vorbehandlung,
                                    });
                                }}                           
                            />
                            <Tabs
                                value={this.state.tabValue}
                                aria-label="simple tabs example"
                                onChange = {this.handleTabValueChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Zeitraum" {...a11yProps(0)}/>
                                <Tab label="Zeitpunkt" {...a11yProps(1)}/>
                                <Tab label="Dauer" {...a11yProps(2)}/>
                            </Tabs>
                            <Box>    
                                <TabPanel value={this.state.tabValue} index={0}>
                                    <DatePicker
                                        isRequired = {false}
                                        label="von"
                                        id="von"
                                        inputLabelProps={{}}
                                        hasError={(!this.state.vorbehandlungLocal.vorbehandlungVon &&
                                            !this.state.vorbehandlungLocal.vorbehandlungBis &&
                                            !this.state.vorbehandlungLocal.vorbehandlungAm &&
                                            (this.state.vorbehandlungLocal.dauer === "" || !this.state.vorbehandlungLocal.dauer))}
                                        initialDate={this.state.vorbehandlungLocal.vorbehandlungVon ? new Date(this.state.vorbehandlungLocal.vorbehandlungVon) : null}
                                        onChange={(date: Date) => {
                                            const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                            if (date != null) {
                                                vorbehandlung.vorbehandlungVon = date.getTime();
                                                vorbehandlung.vorbehandlungAm = null;
                                                vorbehandlung.dauer = "";
                                                vorbehandlung.einheit = undefined;
                                            }
                                            if (date == null) {
                                                vorbehandlung.vorbehandlungVon = null;
                                            }
                                            this.setState({
                                                vorbehandlungLocal: vorbehandlung
                                            })
                                        }}
                                        isMaxDateNow = {true}
                                    />
                                    <DatePicker
                                    isRequired = {false}
                                        label="bis"
                                        id="bis"
                                        inputLabelProps={{}}
                                        hasError={!this.state.vorbehandlungLocal.vorbehandlungVon &&
                                            !this.state.vorbehandlungLocal.vorbehandlungBis &&
                                            !this.state.vorbehandlungLocal.vorbehandlungAm &&
                                            (this.state.vorbehandlungLocal.dauer === "" || !this.state.vorbehandlungLocal.dauer)}
                                        initialDate={this.state.vorbehandlungLocal.vorbehandlungBis ? new Date(this.state.vorbehandlungLocal.vorbehandlungBis) : null}
                                        onChange={(date: Date) => {
                                            const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                            if (date != null) {
                                                vorbehandlung.vorbehandlungBis = date.getTime();
                                                vorbehandlung.vorbehandlungAm = null;
                                                vorbehandlung.dauer = "";
                                                vorbehandlung.einheit = undefined;
                                            }
                                            if (date == null) {
                                                vorbehandlung.vorbehandlungBis = null;
                                            }
                                            this.setState({
                                                vorbehandlungLocal: vorbehandlung
                                            })
                                        }}
                                        isMaxDateNow = {true}
                                    />
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={1}>
                                    <DatePicker
                                        isRequired = {false}
                                        label="am"
                                        id="am"
                                        inputLabelProps={{}}
                                        hasError={!this.state.vorbehandlungLocal.vorbehandlungVon &&
                                            !this.state.vorbehandlungLocal.vorbehandlungBis &&
                                            !this.state.vorbehandlungLocal.vorbehandlungAm &&
                                            (this.state.vorbehandlungLocal.dauer === "" || !this.state.vorbehandlungLocal.dauer)}
                                        initialDate={this.state.vorbehandlungLocal.vorbehandlungAm ? new Date(this.state.vorbehandlungLocal.vorbehandlungAm) : null}
                                        onChange={(date: Date) => {
                                            const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                            if (date != null) {
                                                vorbehandlung.vorbehandlungAm = date.getTime();
                                                vorbehandlung.vorbehandlungVon = null;
                                                vorbehandlung.vorbehandlungBis = null;
                                                vorbehandlung.dauer = "";
                                                vorbehandlung.einheit = undefined;
                                            }
                                            this.setState({
                                                vorbehandlungLocal: vorbehandlung
                                            })
                                        }}
                                        isMaxDateNow = {true}
                                    />
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={2}>                                  
                                    <Box className = "dauer-input">
                                        <TextField
                                            id="vorbehandlung-dauer-textarea"
                                            className="vorbehandlung-dauer-textarea"
                                            variant="filled"
                                            error={!this.state.vorbehandlungLocal.vorbehandlungVon &&
                                                !this.state.vorbehandlungLocal.vorbehandlungBis &&
                                                !this.state.vorbehandlungLocal.vorbehandlungAm &&
                                                (this.state.vorbehandlungLocal.dauer === "" || !this.state.vorbehandlungLocal.dauer)}
                                            placeholder="Zeit"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={
                                                (isNaN(parseInt(this.state.vorbehandlungLocal.dauer || ""))) ? "" : this.state.vorbehandlungLocal.dauer
                                            }
                                            onChange={(event) => {
                                                const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                                vorbehandlung.dauer = (isNaN(parseInt(event.target.value))) ? "" : parseInt(event.target.value).toString();
                                                vorbehandlung.vorbehandlungVon = null;
                                                vorbehandlung.vorbehandlungBis = null;
                                                vorbehandlung.vorbehandlungAm = null;
                                                this.setState({
                                                    vorbehandlungLocal: vorbehandlung,
                                                });
                                            }}
                                        />
                                        <TextField
                                            id="vorbehandlung-dauer-select"
                                            className="vorbehandlung-dauer-select"
                                            select
                                            placeholder="Einheit"
                                            value={this.state.vorbehandlungLocal?.einheit || "d"}
                                            onChange={(event) => {
                                                const vorbehandlung: Vorbehandlung = this.state.vorbehandlungLocal || new Vorbehandlung();
                                                vorbehandlung.einheit = event.target.value as keyof typeof ZeitEinheit;
                                                this.setState({
                                                    vorbehandlungLocal: vorbehandlung
                                                })
                                            }}
                                            variant="filled"
                                        >
                                            {Object.keys(mapZeitEinheit).map(key => 
                                                <MenuItem key={key} value={key}>
                                                    {mapZeitEinheit[key]}
                                                </MenuItem>
                                            
                                            )}
                                        </TextField>
                                    </Box>
                                </TabPanel>
                            </Box>
                        </form>
                    </Box>
                    <Box className="modal-footer">
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                this.setState({ // set state back to previous if "Abbrechnen" is clicked
                                    vorbehandlungLocal: this.props.vorbehandlung
                                })
                                this.props.handleVorbehandlungEditCallback(null, "");
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary" 
                            onClick={this.onSubmit}
                        >
                            {this.props.add ? "Hinzufügen" : "Speichern"}
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

const VorbehandlungEditContainer = connector(VorbehandlungEdit);
export default VorbehandlungEditContainer;


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        className = "vorbehandlung-tab"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
        <>{children}</>
        )}
      </Box>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }