import { Box, IconButton, Menu, MenuItem, Paper, Popover, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Failure, getEnumKeyByEnumValue, Konsil, KonsilImageType, Rueckantwort } from 'telescan-core';
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import { changeRueckantwortImageType, deleteRueckantwortAttachment, deleteRueckantwortImage, failure, loadSuccess, setSuccessMessage, updateKonsil, uploadRueckantwortAttachment, uploadRueckantwortImage } from '../../../redux/actions';
import { AttachmentState, KonsilImageState } from '../../../redux/reducers/image_attachment_reducer';
import { Add, Delete, InsertDriveFile, ZoomIn } from '@material-ui/icons';
import { withFileUpload, WithFileUploadProps, withPdfCreation, WithPdfCreationProps } from '../../../utils';
import { withLoadKonsileAsAttachments, WithLoadKonsileAsAttachmentsProps } from '../../../utils/with_load_konsile_attachments';
import { FullScreenImageComponent } from '../viewComponents/view_lokalisationcontent';
import { AltesKonsil } from '../../elements/altes_konsil';

interface IState {
    konsil: Konsil;
    previousKonsil: Konsil,
    rueckantwortImages: Map<string, KonsilImageState>;
    imageId: string | null;
    attachmentAnchor: Map <string, HTMLElement> | null;
    attachmentMenuAnchor: HTMLButtonElement | null;
    attachemntOldKonsilsAnchor: HTMLButtonElement | null;
    isOldKonsilsListOpen: boolean;
    isFullScreenImage: boolean;
}

interface IProps {
    displayAttachment: (attachment_id: string, dispatch_setSuccessMessage: (message: string) => void) => void;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    currentKonsil: state.konsile.current_konsil,
    rueckantwortImages: state.image_attachment.rueckantwortImages,
    rueckantwortAttachments: state.image_attachment.rueckantwortAttachments,
    ...ownProps,
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil, previousKonsil: Konsil) => updateKonsil(konsil, previousKonsil),
    uploadImage: (konsil: Konsil, file: File, imageType: string, loadInstanceId: string) => uploadRueckantwortImage(konsil, file, imageType, loadInstanceId),
    deleteImage: (konsil: Konsil, imageId: string) => deleteRueckantwortImage(konsil, imageId),
    changeImageType: (imageId: string | null, imageType: string) => changeRueckantwortImageType(imageId, imageType),
    uploadAttachment: (file: File, loadInstanceId: string, rueckantwort_id: string) => uploadRueckantwortAttachment(rueckantwort_id, file, loadInstanceId),
    deleteAttachment: (attachment_id: string) => deleteRueckantwortAttachment(attachment_id),
    dispatch_failure: (id: string, failureObj: Failure) => failure(id, failureObj),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_setSuccessMessage: (message: string) => setSuccessMessage(message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithFileUploadProps & WithLoadKonsileAsAttachmentsProps & WithPdfCreationProps

export class RueckAntwortContent extends React.Component<Props, IState> {
    private input = React.createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props)
        this.state = {
            konsil: this.props.currentKonsil || new Konsil(),
            previousKonsil: new Konsil(),
            imageId: "",
            rueckantwortImages: this.props.rueckantwortImages || new Map<string, KonsilImageState>(),
            attachmentAnchor: null,
            attachmentMenuAnchor: null,
            attachemntOldKonsilsAnchor: null,
            isOldKonsilsListOpen: false,
            isFullScreenImage: false,
        }
        this.uploadImagesAndAttachments = this.uploadImagesAndAttachments.bind(this);
        this.handleClickPrevImg = this.handleClickPrevImg.bind(this);
        this.handleClickNextImg = this.handleClickNextImg.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({konsil: this.props.currentKonsil});
        }
    }

    uploadImagesAndAttachments(file: File, loadInstanceId: string, rueckAntwortId: string, konsil: Konsil, imageType: string) {
        if (file.type === "image/png" || file.type === "image/jpeg") {
            this.props.uploadImage(konsil, file, imageType, loadInstanceId)
        } else if (file.type === "application/pdf") {
            this.props.uploadAttachment(file, loadInstanceId, rueckAntwortId)
        }
    }

    handleClickPrevImg() {
        const id = this.state.imageId || "";
        const idArray = Object.keys(this.props.rueckantwortImages);
        const index = (idArray.indexOf(id) - 1 !== -1) ? idArray.indexOf(id) - 1 : idArray.length - 1
        this.setState({
            imageId: idArray[index]
        });
    };

    handleClickNextImg() {
        const id = this.state.imageId || "";
        const idArray = Object.keys(this.props.rueckantwortImages);
        const index = (idArray.indexOf(id) + 1 !== idArray.length) ? idArray.indexOf(id) + 1 : 0
        this.setState({
            imageId: idArray[index]
        });
    };

    render() {
        return (
            <>
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    Ihre Antwort
                </Typography>
                <TextField // Rendering "Ihre Antwort" text field
                    required
                    error={this.state.konsil.rueckantwort?.zusaetzlicheInformationen == null || this.state.konsil.rueckantwort?.zusaetzlicheInformationen === ""}
                    className="answer-text"
                    placeholder="Antworttext"
                    multiline
                    variant="filled"
                    minRows = {3}
                    maxRows = {5}
                    value={this.state.konsil.rueckantwort?.zusaetzlicheInformationen || ""}    
                    onChange={(event) => {
                        const antwort: Rueckantwort = this.state.konsil.rueckantwort || new Rueckantwort();
                        antwort.zusaetzlicheInformationen = event.target.value;
                        let updatedKonsil = {
                            ...this.state.konsil,
                            rueckantwort: antwort
                        }
                        this.setState({
                            konsil: updatedKonsil
                        });
                    }}
                    onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                    onBlur={() => {
                        this.props.updateKonsil(this.state.konsil, this.state.previousKonsil);
                    }}                  
                />
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    Anhänge
                </Typography>
                {/* Rendering attachement container */}
                <Box className="flex-row"> 
                    {/* Rendering list of images */}
                    {Object.keys(this.props.rueckantwortImages).map((imageId, index) => {
                        const konsilImageState: KonsilImageState = this.props.rueckantwortImages[imageId];
                        return (!konsilImageState.loading &&
                            <Box key = {imageId}>
                                <Tooltip placement = 'top' title = {(this.state.rueckantwortImages[imageId]) ? this.state.rueckantwortImages[imageId].imageType: ""}>
                                    <Paper
                                        className="reply-popup-attachemnts margin-right"
                                        style={{backgroundImage: `url(${konsilImageState.url})`}}
                                        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                            const anchor = new Map();
                                            anchor.set(imageId, event.currentTarget as HTMLElement)
                                            this.setState({attachmentAnchor: anchor})
                                        }}
                                    />
                                </Tooltip>
                                <Popover
                                    open={Boolean(this.state.attachmentAnchor?.get(imageId))}
                                    onClose={() => this.setState({attachmentAnchor: null})}
                                    anchorEl={this.state.attachmentAnchor?.get(imageId)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center"
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >  
                                    <Box className = "flex-row-center pad">
                                        <Select
                                            className="file-name margin-left"
                                            labelId="status-select-label"
                                            value={(this.state.rueckantwortImages[imageId]) ? this.state.rueckantwortImages[imageId].imageType : "UEBERSICHTSFOTO"}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                if (event.target.value == null || event.target.value === "") {
                                                    this.setState({
                                                        imageId: null
                                                    });
                                                    return;
                                                }
                                                this.setState({
                                                    imageId: imageId,
                                                    rueckantwortImages: {
                                                        ...this.state.rueckantwortImages,
                                                        [imageId]: {
                                                            ...this.state.rueckantwortImages[imageId],
                                                            imageType: event.target.value as "NAHAUFNAME" | "DERMATOSKOPIEAUFNAHME" | "UEBERSICHTSFOTO"
                                                        }
                                                    }
                                                    }, 
                                                    () => {
                                                        this.props.changeImageType(this.state.imageId, event.target.value as "NAHAUFNAME" | "DERMATOSKOPIEAUFNAHME" | "UEBERSICHTSFOTO");
                                                        this.setState({
                                                            imageId: null
                                                    });
                                                });
                                            }}
                                            displayEmpty
                                        >
                                            {Object.keys(KonsilImageType).map((key) =>
                                                <MenuItem
                                                    key={key}
                                                    value={key}>
                                                    {KonsilImageType[key]}
                                                </MenuItem>
                                            )}
                                        </Select>
                                        <IconButton
                                            aria-label="zoomIn"
                                            onClick={() =>
                                                this.setState({isFullScreenImage: true, imageId: imageId})
                                            }
                                        >
                                            <ZoomIn/>
                                        </IconButton>
                                        <IconButton
                                            className="delete"
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.props.deleteImage(this.props.currentKonsil, imageId)
                                            }}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </Box> 
                                </Popover>
                            </Box>
                        )
                    })}
                    {/* ************************** */}
                    {/* Rendering list of pdf files and old konsils */}
                    {Object.keys(this.props.rueckantwortAttachments).map((attachmentId) => {
                        const attachmentState: AttachmentState = this.props.rueckantwortAttachments[attachmentId];
                        return (!attachmentState.loading &&
                            <Box key = {attachmentId}>
                                <Tooltip placement = 'top' title = {attachmentState.originalFilename}>
                                    <Paper
                                        className="reply-popup-attachemnts margin-right"
                                        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                            const anchor = new Map();
                                            anchor.set(attachmentId, event.currentTarget as HTMLElement)
                                            this.setState({attachmentAnchor: anchor})
                                        }}
                                    >
                                        <InsertDriveFile className="file-icon" />
                                    </Paper>
                                </Tooltip>
                                <Popover
                                    className = "reply-popover"
                                    open={Boolean(this.state.attachmentAnchor?.get(attachmentId))}
                                    onClose={() => this.setState({attachmentAnchor: null})}
                                    anchorEl={this.state.attachmentAnchor?.get(attachmentId)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center"
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box className = "flex-row-center pad">
                                        <IconButton
                                            aria-label="zoomIn"
                                            onClick={() => {
                                                this.props.displayAttachment(attachmentId, this.props.dispatch_setSuccessMessage);
                                            }}
                                        >
                                            <ZoomIn/>
                                        </IconButton>
                                        <IconButton
                                            className="delete"
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.props.deleteAttachment(attachmentId);
                                            }}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </Box>                                    
                                </Popover>
                            </Box>
                        )
                    })}
                    {/* ************************** */}

                    {/* Rendering add button with menu to choose: attach image or pdf file and old konsils */}
                    <Tooltip title = "Bilder und Anhänge hinzufügen" placement = 'top'>
                        <Box 
                            onClick = {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                                        this.setState({
                                            attachmentMenuAnchor: event.currentTarget as HTMLButtonElement,
                                            attachemntOldKonsilsAnchor: event.currentTarget as HTMLButtonElement,
                                        })}
                        >            
                            <Paper
                                elevation={3}
                                className="reply-popup-attachemnts margin-right">
                                <Add/>
                            </Paper>
                        </Box>
                    </Tooltip>
                      
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.attachmentMenuAnchor}
                        keepMounted
                        open={Boolean(this.state.attachmentMenuAnchor)}
                        onClose={() => this.setState({attachmentMenuAnchor: null})}
                    >
                        <MenuItem onClick={() => {
                            this.input?.current?.click();
                            this.setState({attachmentMenuAnchor: null});
                        }}
                        >
                            PNG, JPG, PDF
                        </MenuItem>
                        <MenuItem
                            onClick={() => 
                                this.setState({
                                    isOldKonsilsListOpen: true,
                                    attachmentMenuAnchor: null,
                                })}
                        >
                            Alte Konsile
                        </MenuItem>
                    </Menu>
                    {/* ************************** */}
                </Box>
                {/* ************************** */}


                <input // handle select and attach file or image from local drive 
                    accept="image/png, image/jpeg, application/pdf"
                    className="upload-input"
                    id="upload-img"
                    multiple={true}
                    type="file"
                    ref={this.input}
                    onChange={(event) =>
                        this.props.onInputChanged(
                            event,
                            this.uploadImagesAndAttachments,
                            [2000, 1500, Infinity, Infinity],
                            this.props.currentKonsil?.rueckantwort?.id || "Unknown rueckantwortID",
                            this.props.currentKonsil,
                            getEnumKeyByEnumValue(KonsilImageType, KonsilImageType.UEBERSICHTSFOTO))}
                />

                <Popover // rendering popover with available old konsils to attach 
                    className="old-konsil-popover"
                    open={this.state.isOldKonsilsListOpen}
                    onClose={() => 
                        this.setState({
                            attachemntOldKonsilsAnchor: null,
                            isOldKonsilsListOpen: false,
                        })}
                    anchorEl={this.state.attachemntOldKonsilsAnchor}
                    anchorOrigin={{
                        vertical: 0,
                        horizontal: 70,
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography className="pad-l" variant="subtitle1" color="inherit">
                        Alte Konsile des Patienten anhängen
                    </Typography>
                    {this.props.oldKonsileAsAttachments.length === 0 &&
                    <Typography className="pad" variant="body1" color="inherit">
                        Keine Konsile vorhanden.
                    </Typography>
                    }
                    <Box className="old-konsile-rueckantwort">
                        {this.props.oldKonsileAsAttachments.map((konsilMetaInfo) => {
                            const filteredKonsile = Object.keys(this.props.rueckantwortAttachments).filter((attachment_id) => (this.props.rueckantwortAttachments[attachment_id].originalFilename.substring(24) === konsilMetaInfo.id))
                            return (
                                <AltesKonsil
                                    key={konsilMetaInfo.id}
                                    variant="outlined"
                                    selected={(filteredKonsile.length > 0) ? filteredKonsile[0] : undefined}
                                    konsilMetaInfo={konsilMetaInfo}
                                    selectAsAttachment={(event, attachmentKonsilId) => this.props.createPdf(attachmentKonsilId, this.props.uploadAttachment, this.props.currentKonsil?.rueckantwort?.id || "Unknown rueckantwortID")}
                                    deleteAttachment={this.props.deleteAttachment}
                                />
                            )
                        })}
                    </Box>
                </Popover>

                {this.state.isFullScreenImage &&  // handling full screen view of atttached images                      
                    <FullScreenImageComponent
                        isActive={(this.state.isFullScreenImage)}
                        handleClose={() => this.setState({
                            isFullScreenImage: false,
                            imageId: ""
                        })}
                        handleClickPrev={this.handleClickPrevImg}
                        handleClickNext={this.handleClickNextImg}
                        title={KonsilImageType[this.props.rueckantwortImages[this.state.imageId || ""]?.imageType]}
                        src={this.props.rueckantwortImages[this.state.imageId || ""]?.url}
                        alt={this.props.rueckantwortImages[this.state.imageId || ""]?.imageType}
                        timeStamp={this.props.rueckantwortImages[this.state.imageId || ""]?.dateRecorded === null ? "-" : new Date(this.props.rueckantwortImages[this.state.imageId || ""]?.dateRecorded).toLocaleString()}
                    />
                }
            </>
        )
    }
}

export const RueckAntwortContentComponent = connector(withFileUpload(withLoadKonsileAsAttachments(withPdfCreation(RueckAntwortContent))));
export default RueckAntwortContentComponent;