import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import uuid from 'uuid';
import {Box, Button, Link, Snackbar, TextField, Typography} from '@material-ui/core';
import Logo from '../../static/images/logo-telescan.svg';
import Ellipse from '../../static/images/login-ellipse.svg';
import Triangle from '../../static/images/login_triangle.svg';
import {login, loadFailure, removeLoadFailure, navigate, logout, getInfo} from '../../redux/actions';
import {Alert} from '@material-ui/lab';
import {RootState} from '../../redux/reducers';
import {UserService, Failure} from 'telescan-core';
import { removeWhiteSpaces } from '../../utils';
import alertIcon from '../../static/images/ic-hinweis.svg';
import Skin from '../../static/images/graf-skin.svg'

interface IState {
    bsnr: string;
    lanr: string;
}

const mapStateToProps = (state: RootState, ownProps: any) => ({
    failures: state.general.failures,
    version: state.user.kennzeichnung.software?.version,
    validityPeriod: state.user.kennzeichnung.software?.validityPeriod,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_login: (bsnr: string, password: string, lanr: string | null, id: string) => login(bsnr, password, lanr, id),
    dispatch_loadFailure: (id:string, failure: Failure) => loadFailure(id, failure),
    dispatch_removeFailure: (id: string) => removeLoadFailure(id),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_logout: () => logout(),
    dispatch_getInfo: () => getInfo(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class Welcome extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            bsnr: localStorage.getItem('BSNR') || "",
            lanr: localStorage.getItem('LANR') || "",
        };

        this.handleBsnrChange = this.handleBsnrChange.bind(this);
        this.handleLanrChange = this.handleLanrChange.bind(this);
        this.handleInitializeArztClick = this.handleInitializeArztClick.bind(this);
        this.handleInitializeDermaClick = this.handleInitializeDermaClick.bind(this);
        this.setInitialisation = this.setInitialisation.bind(this);
        this.handleInitializeDemoArztClick = this.handleInitializeDemoArztClick.bind(this);
    }

    componentDidMount() {
        if (this.props.version == null || this.props.version === undefined || this.props.version === "")
            this.props.dispatch_getInfo();
    }

    handleBsnrChange(e) {
        const cleanedBsnr = removeWhiteSpaces(e.target.value)
        this.setState({bsnr: cleanedBsnr});
    }

    handleLanrChange(e) {
        const cleanedLanr = removeWhiteSpaces(e.target.value)
        this.setState({lanr: cleanedLanr});
    }

    setInitialisation(bsnr: string, lanr: string, role: "HAUSARZT" | "DERMATOLOGE" | null) {
        const loadInstanceIdArzt = uuid.v4();
        const userService = new UserService();
        userService.setInitialisierung(bsnr, lanr, role).then((response) => {
            this.props.dispatch_login(bsnr, response.recoveryCode.substring(3,23), lanr, loadInstanceIdArzt);
        }).catch((error) => {
            this.props.dispatch_loadFailure(loadInstanceIdArzt, error);
        })
    }

    handleInitializeDemoArztClick(e) {
        e.preventDefault();
        this.setInitialisation(this.state.bsnr, this.state.lanr, "HAUSARZT")
    }

    handleInitializeArztClick(e) {
        e.preventDefault();
        this.setInitialisation(this.state.bsnr, this.state.lanr, null)
    }

    handleInitializeDermaClick(e) {
        e.preventDefault();
        this.setInitialisation(this.state.bsnr, this.state.lanr, "DERMATOLOGE")
    }

    render() {
        const uniqueFailures = Array.from(new Set(this.props.failures.map(item => item.failure.error))) // remove duplicated failure before showing them to user
        .map(item => {
            return this.props.failures.find(failureObj => failureObj.failure.error === item)
        });
        return (
            <div>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    autoHideDuration={10000}
                    open={this.props.failures.length > 0}
                    onClose={() => {if (this.props.failures[0]) this.props.dispatch_removeFailure(this.props.failures[0].id)}}
                >
                      <div>
                        { //here all failures collected in redux store shown: main error and  messages if they exist
                            uniqueFailures && uniqueFailures.length>0 &&
                            uniqueFailures.map((item) => {
                                return(
                                    <Alert severity = "error" key = {item.id} icon={<img src = {alertIcon} alt = ""/>}>
                                        <div>
                                            {item.failure.error}
                                            {
                                                item.failure.messages && item.failure.messages.length>0 &&
                                                item.failure.messages.map((subitem: string) => {
                                                    return (
                                                        <li key = {subitem}>
                                                            {subitem}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Alert>
                                )
                            })
                        }
                    </div>
                </Snackbar>
                
                <Box className="initialisation-wrapper">
                    <Box className="login-side">
                        <img className="ce-icon" src={Skin} height="80" alt="Derma-Icon"/>
                        <h1>Erstein&shy;richtung</h1>
                        <Typography
                            align="center"
                            className="version"
                            variant="caption"
                            color="textSecondary"
                        >
                            Willkommen<br/>
                            Version: {this.props.version ? this.props.version : "unbekannt"}<br/>
                            {this.props.validityPeriod ? this.props.validityPeriod : "unbekannt"}
                        </Typography>
                    </Box>
                    <Box className="login-input">
                        <Box className="initialisation-header">
                            <img className="login-logo" src={Logo} alt="Logo"/>
                        </Box>
                        <form noValidate autoComplete="off">
                            <TextField name="bsnr" label="BSNR" value={this.state.bsnr}
                                       onChange={this.handleBsnrChange}/>
                            <TextField name="lanr" label="LANR" value={this.state.lanr || ""}
                                       onChange={this.handleLanrChange}/>
                            <Typography align="left" variant="body1"
                                    color="textSecondary">Einrichten als:</Typography>
                            <Box className="button-container">
                                <Button variant="contained" type="submit" color="primary" className="login-btn"
                                        onClick={this.handleInitializeArztClick}>
                                    Hausarzt
                                </Button>
                                <Button variant="contained" type="submit" color="primary" className="login-btn"
                                        onClick={this.handleInitializeDermaClick}>
                                    Dermatologe
                                </Button>
                            </Box>
                            {/*
                            <Typography>
                                ACHTUNG: Bei der Ersteinrichtung mit Firefox kann es zu Problemen kommen. Bitte nutzen Sie zur Ersteinrichtung einen anderen Webbrowser. 
                            </Typography>
                            
                            <Box className="button-container">
                                <Button variant="contained" type="submit" color="default" className="login-btn"
                                        onClick={this.handleInitializeDemoArztClick}>
                                    Demo Hausarzt Standalone einrichten
                                </Button>
                            </Box>
                            */}
                        </form>
                        <Box className="legal flex-row">
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/impressum")} variant="caption">
                                Impressum
                            </Link>
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/datenschutz")} variant="caption">
                                Datenschutz
                            </Link>
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/hilfe")} variant="caption">
                                Hilfe
                            </Link>
                        </Box>
                    </Box>
                    <img src={Ellipse} className="ellipse" alt="TeleScan Login Logo"/>
                    <img src={Triangle} className="triangle" alt="TeleScan Login Logo"/>
                </Box>
            </div>

        );
    }
}

export const WelcomeContainer = connector(Welcome);
export default WelcomeContainer;
