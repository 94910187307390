import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';
import {
    DermatologenService,
    KonsilMetaInformation,
    Failure,
    BearbeitungsStatusType,
    KonsilService,
    KonsilSortSearchFilterParams,
} from 'telescan-core';
import * as DermatologeTypes from '../types/dermatologe_types';
import {RootState} from '../reducers';
import {loadSuccess} from '.';
import { setKonsile,  setTotalSize, setKonsilSortSearchFilterParams, loadArztKonsile } from './konsil_actions';
import { clearKonsilImageList, clearAttachmentList, clearRueckantwortImageList, clearRueckantwortAttachmentList } from './image_attachment_actions';
import { loading, setSuccessMessage, failure } from './general_actions';

// 1. Pure redux actions
export function setMetainformationenAdressiert(metadaten: KonsilMetaInformation[]): DermatologeTypes.ISetMetadatenAdressiertAction {
    return {
        type: DermatologeTypes.EDermatologeActions.SET_METADATEN_ADRESSIERT,
        metadatenAdressiert: metadaten
    }
}

export function setMetainformationenGerichtet(metadaten: KonsilMetaInformation[]): DermatologeTypes.ISetMetadatenGerichtetAction {
    return {
        type: DermatologeTypes.EDermatologeActions.SET_METADATEN_GERICHTET,
        metadatenGerichtet: metadaten
    }
}

export function downloadKonsil(documentenId: string, vorgangsId?: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const dermatologenService: DermatologenService = new DermatologenService();
        dermatologenService.downloadCDA([documentenId], getState().webSocket.clientId);
    }
}

export function downloadNewKonsile(ids: string[]): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const dermatologenService: DermatologenService = new DermatologenService();
        if (ids.length > 0)
            dermatologenService.downloadCDA(ids, getState().webSocket.clientId);
    }
}

export function setActiveAreas(areaIndex: number, isActive: boolean): DermatologeTypes.ISetActiveAreasAction {
    return {
        type: DermatologeTypes.EDermatologeActions.SET_ACTIVE_AREAS,
        areaIndex: areaIndex,
        isActive: isActive
    }
}

export function setMetadatenAdressiertKonsilBearbeitungsStatus(vorgangsId: string, bearbeitungsStatus: keyof typeof BearbeitungsStatusType): DermatologeTypes.ISetMetadatenAdressiertKonsilBearbeitungsstatus {
    return {
        type: DermatologeTypes.EDermatologeActions.SET_METADATEN_ADRESSIERT_KONSIL_BEARBEITUNGSSTATUS,
        vorgangsId: vorgangsId,
        bearbeitungsStatus: bearbeitungsStatus,
    }
}

export function setMetadatenGerichtetKonsilBearbeitungsStatus(vorgangsId: string, bearbeitungsStatus: keyof typeof BearbeitungsStatusType): DermatologeTypes.ISetMetadatenGerichtetKonsilBearbeitungsstatus {
    return {
        type: DermatologeTypes.EDermatologeActions.SET_METADATEN_GERICHTET_KONSIL_BEARBEITUNGSSTATUS,
        vorgangsId: vorgangsId,
        bearbeitungsStatus: bearbeitungsStatus,
    }
}

export function removeMetadatenAdressiertKonsil(vorgangsId: string): DermatologeTypes.IRemoveMetadatenAdressiertKonsil {
    return {
        type: DermatologeTypes.EDermatologeActions.REMOVE_METADATEN_ADRESSIERT_KONSIL,
        vorgangsId: vorgangsId,
    }
}

export function removeMetadatenGerichtetKonsil(vorgangsId: string): DermatologeTypes.IRemoveMetadatenGerichtetKonsil {
    return {
        type: DermatologeTypes.EDermatologeActions.REMOVE_METADATEN_GERICHTET_KONSIL,
        vorgangsId: vorgangsId,
    }
}

// 2. Thunk middleware functions


export function loadDermaKonsile(loadInstanceId?: string, propertyName?: string, direction?: "asc" | "desc" | "default"): ThunkAction<void, RootState, unknown, Action<string>> { // depricated
    return (dispatch, getState) => {
        const id: string = loadInstanceId || uuid.v4();
        dispatch(clearKonsilImageList());
        dispatch(clearAttachmentList());
        dispatch(clearRueckantwortImageList());
        dispatch(clearRueckantwortAttachmentList());
        dispatch(loading(id));
        if (propertyName && direction) {
            const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
            konsilSortSearchFilterParams.propertyName = propertyName;
            konsilSortSearchFilterParams.direction = direction;
            dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        }
        const konsilService: KonsilService = new KonsilService();
        konsilService.getKonsile(getState().konsile.konsilSortSearchFilterParams.pageNb, getState().konsile.konsilSortSearchFilterParams.numberPerPage, getState().konsile.konsilSortSearchFilterParams.search, getState().konsile.konsilSortSearchFilterParams.konsilStatus, getState().konsile.konsilSortSearchFilterParams.propertyName, getState().konsile.konsilSortSearchFilterParams.direction)
        .then(
            (konsile) => {
                dispatch(loadSuccess(id));
                dispatch(setKonsile(konsile.content ? konsile.content : []));
                dispatch(setTotalSize(konsile.totalSize || -1));
            }
        ).catch(
            (response) => {
                dispatch(failure(id, response));
            }
        );
    }
}

export function updateDermaKonsileAndMetaData(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(loadArztKonsile());
        dispatch(updateMetadatenAdressiert());
        dispatch(updateMetadatenGerichtet());
    }
}


export function updateMetadatenAdressiert(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    // load metadaten for the logged-in dermatologe from ZI
    return (dispatch, getState) => {
        const id: string = uuid.v4();
        dispatch(loading(id));
        return new Promise((resolve, reject) => {
            const dermatologenService: DermatologenService = new DermatologenService();
            dermatologenService.updateMetadataAdressiert(getState().webSocket.clientId).then(
                (metadaten) => {
                    dispatch(loadSuccess(id));
                    dispatch(setMetainformationenAdressiert(metadaten || []));
                    resolve(metadaten);
                }
            ).catch(
                (response) => {
                    dispatch(failure(id, response));
                }
            );
        })
    }
}

export function updateMetadatenGerichtet(): ThunkAction<void, RootState, unknown, Action<string>> {
    // load metadaten for the dermatologe from ZI
    // load metadaten for the group, that the logged-in dermatologe belongs to, from ZI
    return dispatch => {
        const id: string = uuid.v4();
        dispatch(loading(id));

        const dermatologenService: DermatologenService = new DermatologenService();

        dermatologenService.updateMetadataGerichtet().then(
            (metadaten) => {
                dispatch(loadSuccess(id));
                dispatch(setMetainformationenGerichtet(metadaten || []));
                //dispatch(setMetainformationen(metadaten || []));
            }
        ).catch(
            (response) => {
                dispatch(failure(id, response));
            }
        );
    }
}

export function handleDownloadMetadata(data: any) { //not in use
    return (dispatch) => {
        const loadInstanceId: string = uuid.v4();
        if (data.status === "start") {
            console.log("Download der Metadaten gestartet.");
            dispatch(loadArztKonsile()); // to show loading symbol
            dispatch(loading(data.eventID || loadInstanceId));
            return;
        }
        if (data.status === "ok") {
            dispatch(loadSuccess(data.eventID || loadInstanceId));
            dispatch(loadArztKonsile()).then(
                () => {dispatch(setSuccessMessage("Metadaten erfolgreich heruntergeladen."))
            })
        } else {
            dispatch(loadArztKonsile()).then(
            () => {
                const error: Failure = new Failure();
                error.error = "Download fehlgeschlagen: " + data.errorMessage || "";
                dispatch(failure(data.eventID || loadInstanceId, error));
            })
        }
    }
}
