import { DermatoscopeType, KonsilImageType } from "telescan-core";
import {EImageAttachmentActions, TImageAttachmentActionTypes} from '../types/image_attachment_types';

import * as _ from 'lodash';

export interface KonsilImageState {
    percent: number;
    loading: boolean;
    imageType: KonsilImageType;
    dateCreated: number;
    dateRecorded: number;
    filename: string;
    url: string;
    dermatoscopeType: keyof typeof DermatoscopeType;
}

export interface AttachmentState {
    percent: number;
    loading: boolean;
    date: any;
    filename: string;
    originalFilename: string;
    data: string;
}

export interface IImageAttachmentState {
    konsilImages: Map<string, KonsilImageState>;
    rueckantwortImages: Map<string, KonsilImageState>;
    konsilImageLocation: string;
    attachments: Map<string, AttachmentState>;
    rueckantwortAttachments: Map<string, AttachmentState>;
    isImageLoading: boolean;
}

const initialState: IImageAttachmentState = {
    konsilImages: new Map<string, KonsilImageState>(),
    rueckantwortImages: new Map<string, KonsilImageState>(),
    konsilImageLocation: 'unbekannt',
    attachments: new Map<string, AttachmentState>(),
    rueckantwortAttachments: new Map<string, AttachmentState>(),
    isImageLoading: false,
}

export function image_attachment_reducer(state = initialState, action: TImageAttachmentActionTypes): IImageAttachmentState {
    switch (action.type) {
        case EImageAttachmentActions.LOAD_KONSIL_IMAGE:
            return {
                ...state,
                konsilImages: {
                    ...state.konsilImages,
                    [action.image_id]: {
                        ...state.konsilImages[action.image_id],
                        url: action.url,
                        loading: action.loading
                    }
                }
            }
        
        case EImageAttachmentActions.LOAD_RUECKANTWORT_IMAGE:
            return {
                ...state,
                rueckantwortImages: {
                    ...state.rueckantwortImages,
                    [action.image_id]: {
                        ...state.rueckantwortImages[action.image_id],
                        url: action.url,
                        loading: action.loading
                    }
                }
            }
        case EImageAttachmentActions.ADD_KONSIL_IMAGE:
            return {
                ...state,
                konsilImages: {
                    ...state.konsilImages,
                    [action.image_id]: {
                        ...state.konsilImages[action.image_id],
                        percent: 0,
                        loading: action.loading,
                        imageType: action.image_type,
                        dateCreated: action.dateCreated,
                        dateRecorded: action.dateRecorded,
                        originalFilename: action.originalFilename,
                        dermatoscopeType: action.dermatoscopeType,
                    }
                }
            }
        case EImageAttachmentActions.CHANGE_KONSIL_IMAGE_LOADING_STATUS:
            return {
                ...state,
                konsilImages: {
                    ...state.konsilImages,
                    [action.image_id]: {
                        ...state.konsilImages[action.image_id],
                        loading: action.loading,
                    }
                }
            }

        case EImageAttachmentActions.ADD_RUECKANTWORT_IMAGE:
            return {
                ...state,
                rueckantwortImages: {
                    ...state.rueckantwortImages,
                    [action.image_id]: {
                        ...state.rueckantwortImages[action.image_id],
                        percent: 0,
                        loading: action.loading,
                        imageType: action.image_type,
                        dateRecorded: action.dateRecorded,
                        originalFilename: action.originalFilename
                    }
                }
            }

        case EImageAttachmentActions.REMOVE_KONSIL_IMAGE:
            return {
                ...state,
                konsilImages: _.omitBy(state.konsilImages, (value, key) => key === action.image_id)
            }
            
        case EImageAttachmentActions.REMOVE_RUECKANTWORT_IMAGE:
            return {
                ...state,
                rueckantwortImages: _.omitBy(state.rueckantwortImages, (value, key) => key === action.image_id)
            }
            
        case EImageAttachmentActions.CLEAR_KONSIL_IMAGE_LIST:
            return {
                ...state,
                konsilImages: new Map<string, KonsilImageState>()
            }

        case EImageAttachmentActions.CLEAR_RUECKANTWORT_IMAGE_LIST:
            return {
                ...state,
                rueckantwortImages: new Map<string, KonsilImageState>()
            }

        case EImageAttachmentActions.UPLOAD_KONSIL_IMAGE_PROCESS:
            return {
                ...state,
                konsilImages: {
                    ...state.konsilImages,
                    [action.image_id]: {
                        ...state.konsilImages[action.image_id],
                        process: action.percent,
                        loading: action.percent < 100,
                        failure: ""
                    }
                }
            }

        case EImageAttachmentActions.CHANGE_KONSIL_IMAGE_TYPE:
            return {
                ...state,
                konsilImages: {
                    ...state.konsilImages,
                    [action.image_id]: {...state.konsilImages[action.image_id], imageType: action.imageType}
                }
            }

        case EImageAttachmentActions.CHANGE_RUECKANTWORT_IMAGE_TYPE:
            return {
                ...state,
                rueckantwortImages: {
                    ...state.rueckantwortImages,
                    [action.image_id]: {...state.rueckantwortImages[action.image_id], imageType: action.imageType}
                }
            }

        case EImageAttachmentActions.CHANGE_KONSIL_IMAGE_LOCATION:
            return {
                ...state,
                konsilImageLocation: action.location
            }

        case EImageAttachmentActions.ADD_ATTACHMENT:
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    [action.attachment_id]: {
                        percent: 0,
                        loading: action.loading,
                        date: action.date,
                        filename: action.filename,
                        originalFilename: action.originalFilename
                    }
                }
            }

        case EImageAttachmentActions.LOAD_ATTACHMENT:
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    [action.attachment_id]: {
                        ...state.attachments[action.attachment_id],
                        data: action.data,
                        loading: action.loading
                    }
                }
            }

        case EImageAttachmentActions.LOAD_RUECKANTWORT_ATTACHMENT:
            return {
                ...state,
                rueckantwortAttachments: {
                    ...state.rueckantwortAttachments,
                    [action.attachment_id]: {
                        ...state.rueckantwortAttachments[action.attachment_id],
                        data: action.data,
                        loading: action.loading
                    }
                }
            }

        case EImageAttachmentActions.ADD_RUECKANTWORT_ATTACHMENT:
            return {
                ...state,
                rueckantwortAttachments: {
                    ...state.rueckantwortAttachments,
                    [action.attachment_id]: {
                        percent: 0,
                        loading: action.loading,
                        date: action.date,
                        filename: action.filename,
                        originalFilename: action.originalFilename
                    }
                }
            }

        case EImageAttachmentActions.REMOVE_ATTACHMENT:
            return {
                ...state,
                attachments: _.omitBy(state.attachments, (value, key) => key === action.attachment_id)
            }
            
        case EImageAttachmentActions.REMOVE_RUECKANTWORT_ATTACHMENT:
            return {
                ...state,
                rueckantwortAttachments: _.omitBy(state.rueckantwortAttachments, (value, key) => key === action.attachment_id)
            }

        case EImageAttachmentActions.CLEAR_ATTACHMENT_LIST:
            return {
                ...state,
                attachments: new Map<string,AttachmentState>()
            }

        case EImageAttachmentActions.CLEAR_RUECKANTWORT_ATTACHMENT_LIST:
            return {
                ...state,
                rueckantwortAttachments: new Map<string,AttachmentState>()
            }

        case EImageAttachmentActions.UPLOAD_ATTACHMENT_PROCESS:
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    [action.attachment_id]: {
                        ...state.attachments[action.attachment_id],
                        process: action.percent,
                        loading: action.percent < 100,
                        failure: ""
                    }
                }
            }

        case EImageAttachmentActions.UPLOAD_RUECKANTWORT_ATTACHMENT_PROCESS:
            return {
                ...state,
                rueckantwortAttachments: {
                    ...state.rueckantwortAttachments,
                    [action.attachment_id]: {
                        ...state.attachments[action.attachment_id],
                        process: action.percent,
                        loading: action.percent < 100,
                        failure: ""
                    }
                }
            }
        
        case EImageAttachmentActions.SET_IS_FIRST_IMAGE_LOADING:
            return {
                ...state,
                isImageLoading: action.isImageLoading
            }

        
        default:
            return state
    }
}
