import React from "react";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';
import {Box, Button, Tab, Tabs} from "@material-ui/core";
import {
    Archive,
    Assignment,
    CheckCircle,
    Close,
    ContactSupport,
    Description,
    ErrorOutline,
    MyLocation,
    Person
} from "@material-ui/icons";

import Logo from "../../static/images/logo-telescan.svg";
import {UserContainer} from "../header"
import {KonsilAnfrageReceiverContainer} from "../konsil"
import {RootState} from "../../redux/reducers";
import {
    addSendFailure,
    changeKonsilTab,
    checkAnamneseStatus,
    checkKonsilPatientStatus,
    checkKonsilStatus,
    checkLocationStatus,
    clearKonsilImageList,
    clearPdfString,
    clearRueckantwortImageList,
    failure,
    getCdaSize,
    getKonsilValidationStatus,
    getPdfString,
    handleVersandKonsil,
    loadArztKonsile,
    loadKonsil,
    loadKonsiliarGruppen,
    navigate,
    setComfortVersandPassword,
    setIsComfortVersand,
    setIsKonsilDeleted,
    setIsKonsilUpdateConflictDialog,
    setIsSendingKonsil,
    setSuccessMessage,
    updateKonsilOnEvent
} from "../../redux/actions";

import {Arzt, BearbeitungsStatusType, KonsilEmpfaengerGruppe, KonsilStatusType, Failure, Patient, Konsil } from "telescan-core";
import {KonsilStatus} from "../konsilliste";
import {TherapieDringlichkeit} from "telescan-core/lib/entities/konsil_abschluss";
import {StartSendPopup} from "../konsil/sending_results_popup";
import IdButtonComponent from "../elements/id_button";
import {withPdfPreviewCreation, WithPdfPreviewCreationProps} from "../../utils/with_pdf_preview";
import VersendenButtonComponent from "../elements/versenden_button";
import { Patienteneinwilligung } from "telescan-core/lib/entities/patienteneinwilligung";
import { KonsilImageState } from "../../redux/reducers/image_attachment_reducer";
import DeletedKonsilNotificationContainer from "../elements/konsil_deleted_notification";
import KonsilabschlussPopup from "../konsil/konsilAbschlussPopup";
import UpdateConflictDialog from "../konsil/update_conflict_dialog";

interface IProps {
    toggleReceiverModal: () => void,
    openReceiverModal: boolean,
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    isDesktop: state.general.isDesktop,
    tabIndex: state.konsile.tabIndex,
    tabStatus: state.evaluation.tabStatus,
    currentKonsil: state.konsile.current_konsil,
    user: state.user.user,
    arztFeedbackErrors: state.evaluation.konsilFeedbackErrors,
    location: state.router.location.pathname,
    role: state.user.role,
    isSendingKonsil: state.send_download_konsil.isSendingKonsil,
    konsilImages: state.image_attachment.konsilImages,
    isKonsilDeleted: state.konsile.isKonsilDeleted,
    isComfortVersand: state.general.isComfortVersand,
    comfortVersandPassword: state.general.comfortVersandPassword,
    pdfString: state.pdf.pdfString,
    isPdfLoading: state.pdf.isPdfLoading,
    isKonsilUpdateConflictDialog: state.konsile.isKonsilUpdateConflictDialog,
    konsilUpdateConflictData: state.konsile.konsilUpdateConflictData,
    frageError: state.evaluation.konsilFrageErrors,
    konsilAnamneseError: state.evaluation.konsilAnamneseErrors,
    konsilPatientError: state.evaluation.konsilPatientErrors,
    locationError: state.evaluation.locationErrors,
    isPending: state.general.isPending,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_tabChange: (index: number) => changeKonsilTab(index),
    dispatch_successMessage: (message: string) => setSuccessMessage(message),
    dispatch_addSendFailure: (konsilID: string, failure: Failure) => addSendFailure(konsilID, failure),
    dispatch_loadArztKonsile: () => loadArztKonsile(),
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_getCdaSize: (konsil_id: string) => getCdaSize(konsil_id),
    dispatch_failure: (loadInstanceId: string, error: Failure) => failure(loadInstanceId, error),
    dispatch_checkKonsilPatientStatus: (patient: Patient, patienteneinwilligung: Patienteneinwilligung, konsilId: string) => checkKonsilPatientStatus(patient, patienteneinwilligung, konsilId),
    dispatch_checkLocationStatus: (konsil: Konsil, konsilImages: Map<string, KonsilImageState>) => checkLocationStatus(konsil, konsilImages),
    dispatch_checkAnamneseStatus: (konsil: Konsil) => checkAnamneseStatus(konsil),
    dispatch_checkKonsilStatus: (konsil: Konsil, konsilImages: Map<string, KonsilImageState>) => checkKonsilStatus(konsil, konsilImages),
    dispatch_setIsSendingKonsil: (isSendingKonsil: boolean) => setIsSendingKonsil(isSendingKonsil),
    dispatch_handleVersandKonsil: (konsilId: string, password: string, eventText: string) => handleVersandKonsil(konsilId, password, eventText),
    dispatch_setIsKonsilDeleted: (isDeleted: boolean) => setIsKonsilDeleted(isDeleted),
    dispatch_getKonsilValidationStatus: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getKonsilValidationStatus(konsilId, konsilStatus),
    dispatch_clearKonsilImageList: () => clearKonsilImageList(),
    dispatch_clearRueckantwortImageList: () => clearRueckantwortImageList(),
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => setIsComfortVersand(isComfortVersand),
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => setComfortVersandPassword(comfortVersandPassword),
    dispatch_getPdfString: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getPdfString(konsilId, konsilStatus),
    dispatch_clearPdfString: () => clearPdfString(),
    dispatch_setIsKonsilUpdateConflictDialog : (isKonsilUpdateDialog: boolean) => setIsKonsilUpdateConflictDialog(isKonsilUpdateDialog),
    dispatch_updateKonsilOnEvent: (konsilId: string) => updateKonsilOnEvent(konsilId),
    dispatch_loadKonsiliarGruppen: () => loadKonsiliarGruppen(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithPdfPreviewCreationProps

interface IHeaderState {
    openModal: boolean;
    konsiliare: Arzt[],
    error: any,
    selectedReceiver: Arzt,
    selectedReceiverGroup: KonsilEmpfaengerGruppe;
    receiverGroups: KonsilEmpfaengerGruppe[],
    password: string;
    hasPasswordChanged: boolean;
}

export class HeaderKonsil extends React.Component<Props, IHeaderState> {
    private intervalId = 0;
    constructor(props: Props) {
        super(props);
        this.state = {
            openModal: false,
            konsiliare: [],
            error: null,
            selectedReceiver: {} as any,
            selectedReceiverGroup: {} as any,
            receiverGroups: [],
            password: "",
            hasPasswordChanged: false,
        }

        this.openEmpfaengerAuswahl = this.openEmpfaengerAuswahl.bind(this);
        this.closeStartSendPopup = this.closeStartSendPopup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleVersendenKonsilabschluss = this.handleVersendenKonsilabschluss.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.closeKonsilDeletedNotification = this.closeKonsilDeletedNotification.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        if (this.props.tabIndex === 0 && this.props.currentKonsil.id !== "")
            this.props.dispatch_checkKonsilPatientStatus(this.props.currentKonsil.konsilPatient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), this.props.currentKonsil.id || "");
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.clearInterval(this.intervalId);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.props.dispatch_checkKonsilPatientStatus(this.props.currentKonsil.konsilPatient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), this.props.currentKonsil.id || "");
            // this.props.dispatch_clearKonsilImageList();
            // this.props.dispatch_clearRueckantwortImageList();
        }
    }

    closeKonsilDeletedNotification() {
        this.props.dispatch_setIsKonsilDeleted(false);
        this.props.dispatch_navigate("/");
    }

    openEmpfaengerAuswahl() {
        this.props.toggleReceiverModal();
        this.setState({
            selectedReceiver: {} as any,
            selectedReceiverGroup: {} as any
        });
    };

    toggleModal() {
        this.setState({openModal: !this.state.openModal})
    }

    closeStartSendPopup() {
        this.props.dispatch_navigate("/");
        this.props.dispatch_setIsSendingKonsil(false);
    }

    handlePasswordChange(e) {
        this.setState({password: e.target.value});
        this.setState({hasPasswordChanged: true});
    }

    handleVersendenKonsilabschluss() {
        if (this.props.currentKonsil.id) {
            this.toggleModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged)
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsiliarberichts zum Konsil mit der ID");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand des Konsiliarberichts zum Konsil mit der ID");
        }
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(failureId, failureObj);
            this.toggleModal();
        }
    }

    handleChangeTab(_, index) {
        this.props.dispatch_tabChange(index);
        if (index === 1)
            this.props.dispatch_checkLocationStatus(this.props.currentKonsil, this.props.konsilImages);
        else if (index === 2)
            this.props.dispatch_checkAnamneseStatus(this.props.currentKonsil);
        else if (index === 3)
            this.props.dispatch_checkKonsilStatus(this.props.currentKonsil, this.props.konsilImages);
    }

    handleKeyDown(event: KeyboardEvent) {
        if (!Array.prototype.some.call(document.getElementsByClassName("MuiInputBase-input"), item => item === document.activeElement)) {
            if (event.keyCode === 39) {
                event.preventDefault();
                this.handleChangeTab(event, (this.props.tabIndex + 1) % 4);
            } else if (event.keyCode === 37) {
                event.preventDefault();
                this.handleChangeTab(event, (this.props.tabIndex === 0) ? 3 : (this.props.tabIndex - 1) % 4);
            } else if ((event.key === "1" || event.key === "2" || event.key === "3" || event.key === "4" || event.key === "5")) {
                event.preventDefault();
                this.handleChangeTab(event, parseInt(event.key) - 1);
            }
        }
    }

    refreshKonsilBeforeSending(): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(this.props.dispatch_updateKonsilOnEvent(this.props.currentKonsil.id || ""));
        })
    }

    evaluateKonsilBeforeSending(): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(this.props.dispatch_checkKonsilStatus(this.props.currentKonsil, this.props.konsilImages));
        })
    }

    loadReceiverGroups = () => {
        if (this.props.user.length === 1)
            this.props.dispatch_loadKonsiliarGruppen()
            .then((groups: KonsilEmpfaengerGruppe[]) => this.setState({receiverGroups: groups}));
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Bitte Arzt auswählen";
            this.props.dispatch_failure(failureId, failureObj);
        }
    }

    handleVersendenButtonClick = () => {
        window.clearInterval(this.intervalId);
        this.refreshKonsilBeforeSending()
            .then(() => {
                this.evaluateKonsilBeforeSending()
                .then(() => {
                    const patientError: Map<string, string> = this.props.konsilPatientError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
                    const anamneseError: Map<string, string> = this.props.konsilAnamneseError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
                    const locationError: Map<string, string> = this.props.locationError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
                    const frageError: Map<string, string> = this.props.frageError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
                    const errorArray: boolean[] = [patientError.size !== 0,
                                                    locationError.size !== 0,
                                                    anamneseError.size !== 0,
                                                    frageError.size !== 0
                                                    ]
                    const errors = Array.from(patientError.values()).concat(Array.from(locationError.values()))
                                    .concat(Array.from(anamneseError.values())).concat(Array.from(frageError.values()))
                    const firstErrorIndex: number = errorArray.findIndex(item => item === true);
                    const tabStatus: Map<number, Boolean | null> = this.props.tabStatus.find(item => item.konsilID === this.props.currentKonsil.id)?.statusMap || new Map<number, Boolean | null>();
                    if (Array.from(tabStatus.values()).includes(false)) {
                        const failureId: string = uuid.v4();
                        const failureObj: Failure = new Failure();
                        failureObj.error = "Es wurden Änderungen an diesem Konsilauftrag vorgenommen. Aus folgendem Grund kann der Konsilauftrag daher nicht versendet werden:";
                        failureObj.messages = errors;
                        (firstErrorIndex !== -1) && this.props.dispatch_tabChange(firstErrorIndex)
                        this.props.dispatch_failure(failureId, failureObj);
                    } else {
                        this.props.dispatch_clearPdfString();
                        this.props.dispatch_getCdaSize(this.props.currentKonsil.id || "");
                        this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                        this.openEmpfaengerAuswahl();
                        this.loadReceiverGroups();
                        this.props.dispatch_getKonsilValidationStatus(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                    }
                })
            })
    }

    renderButton(konsilStatus: keyof typeof KonsilStatusType | undefined, bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined, tabIndex: number) {
        //const arztFeedbackErrors: Map<string, string> = this.props.arztFeedbackErrors.find(item => item.KonsilabschlussID === this.props.currentKonsil.konsilabschluss?.id)?.errors || new Map<string, string>();
        const enabled = this.props.currentKonsil?.konsilabschluss && this.props.currentKonsil.konsilabschluss.feedbackKonsilqualitaet && this.props.currentKonsil?.konsilStatus !== "ABGESCHLOSSEN" && this.props.currentKonsil?.konsilStatus !== "ABGEBROCHEN";

        if (tabIndex < 3 && konsilStatus != null && konsilStatus === "IN_ARBEIT")
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="btn-right"
                    onClick={(event) => {
                        this.handleChangeTab(event, tabIndex + 1);
                    }}
                >
                    Weiter
                </Button>
            )
        else if (tabIndex === 3 && konsilStatus != null && konsilStatus === "IN_ARBEIT" && bearbeitungsStatus !== "UPLOAD") {

            return (
                <VersendenButtonComponent
                    onClick={() => {
                        if (!this.props.isPending)
                            this.handleVersendenButtonClick();
                        else {
                            this.intervalId = window.setInterval(() => this.handleVersendenButtonClick(), 500);
                        }
                    }}
                />
            )

        } else if (konsilStatus != null && konsilStatus === "BEFUNDET")
            //else if (konsilStatus != null && konsilStatus !== "IN_ARBEIT" && this.props.currentKonsil?.konsilStatus !== "ABGESCHLOSSEN" && this.props.currentKonsil?.konsilStatus !== "ABGEBROCHEN")
            return (
                <>
                    <Button variant={(enabled) ? "contained" : "outlined"} color="primary" size="small"
                            className="btn-right"
                            onClick={() => {
                                const loadInstanceId: string = uuid.v4();
                                if (enabled)
                                    this.toggleModal()
                                else {
                                    let falsyElement = document.getElementById(this.props.arztFeedbackErrors.keys().next().value);
                                    if (falsyElement) {
                                        falsyElement.scrollIntoView({block: 'center'});
                                        falsyElement.focus();
                                    }
                                    let failure = new Failure();
                                    //failure.error = this.props.arztFeedbackErrors.values().next().value
                                    failure.error = "Die Qualität des Konsiliarberichts muss bewertet werden";
                                    this.props.dispatch_failure(loadInstanceId, failure)
                                }
                            }} //disabled={!enabled}
                    >
                        {(this.props.isDesktop) ? "Abschließen" : <Archive/>}
                    </Button>
                    <KonsilabschlussPopup
                        isOpen={this.state.openModal}
                        password={this.state.password}
                        toggleModal={this.toggleModal}
                        handleVersenden={this.handleVersendenKonsilabschluss}
                        handlePasswordChange={this.handlePasswordChange}
                        isComfortVersand = {this.props.isComfortVersand}
                        comfortVersandPassword = {this.props.comfortVersandPassword}
                        dispatch_setIsComfortVersand = {this.props.dispatch_setIsComfortVersand}
                        dispatch_setComfortVersandPassword = {this.props.dispatch_setComfortVersandPassword}
                        hasPasswordChanged = {this.state.hasPasswordChanged}
                    />
                    <StartSendPopup open={(this.props.isSendingKonsil)} closeStartSendPopup={this.closeStartSendPopup}/>
                </>
            )
        else
            return (<></>)
    }

    render() {
        const hasPvsPatient = this.props.currentKonsil.pvsPatient != null
        const tabStatus: Map<number, Boolean | null> = this.props.tabStatus.find(item => item.konsilID === this.props.currentKonsil.id)?.statusMap || new Map<number, Boolean | null>();
        return (
            <header className="header-konsil">
                <Box className="header-top-left-content">
                    <Button
                        variant="text"
                        size="small"
                        className="btn-left slim"
                        onClick={() => this.props.dispatch_navigate("/")}
                    >
                        <Close/>
                    </Button>
                    {this.props.isDesktop &&
                    <Box className="title">
                        <h1>Konsil
                            <IdButtonComponent konsilId={this.props.currentKonsil?.id || ""}/>
                            <div className="konsil-status">
                                <KonsilStatus
                                    therapiedringlichkeit={(this.props.currentKonsil?.konsiliarbericht?.therapieDringlichkeit) ?
                                        TherapieDringlichkeit[this.props.currentKonsil?.konsiliarbericht?.therapieDringlichkeit] : undefined}
                                    hasPvsPatient={hasPvsPatient}
                                    bearbeitungsStatus={this.props.currentKonsil?.bearbeitungsStatus}
                                    status={this.props.currentKonsil?.konsilStatus} isMetaData={false}
                                    role="HAUSARZT"
                                />
                            </div>
                        </h1>
                        <div className="patient">
                            {this.props.currentKonsil?.konsilPatient?.nachname}, {this.props.currentKonsil?.konsilPatient?.vorname}
                        </div>
                    </Box>}
                </Box>

                <img src={Logo} alt="Logo" className="logo" onClick={() => this.props.dispatch_navigate("/")}/>
                <UserContainer userDetails={this.props.user}/>

                {this.renderButton(this.props.currentKonsil?.konsilStatus, this.props.currentKonsil.bearbeitungsStatus, this.props.tabIndex)}

                {!this.props.isDesktop &&
                <Box className="title">
                    <h1>Konsil
                        <IdButtonComponent konsilId={this.props.currentKonsil?.id || ""}/>
                    </h1>
                    <div
                        className="patient">{this.props.currentKonsil?.konsilPatient?.nachname}, {this.props.currentKonsil?.konsilPatient?.vorname}</div>
                </Box>}

                {this.props.tabIndex === 3 &&
                <KonsilAnfrageReceiverContainer
                    pdfPreviewData={this.props.pdfString}
                    isOpenReceiverModal={this.props.openReceiverModal}
                    toggleReceiverModal={this.props.toggleReceiverModal}
                    selectedReceiver={this.state.selectedReceiver}
                    selectedReceiverGroup={this.state.selectedReceiverGroup}
                    createPdfPreview={this.props.createPdfPreview}
                    dispatch_failure = {this.props.dispatch_failure}
                    isPdfLoading = {this.props.isPdfLoading}
                    receiverGroups = {this.state.receiverGroups}
                />
                }

                <Tabs
                    value={this.props.tabIndex}
                    className="headerTabs"
                    onChange={this.handleChangeTab}
                    variant="scrollable"
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example">

                    {this.props.currentKonsil?.konsilStatus != null && this.props.currentKonsil?.konsilStatus !== "IN_ARBEIT" &&
                        <Tab value={4}
                            label="Konsiliarbericht"
                            icon={<Description/>}
                            {...a11yProps(4)}
                        />
                    }

                    {
                        [{title: "Patientendaten", component: <Person/>, errorText: "Pflichtfelder"},
                            {title: "Bildaufnahmen", component: <MyLocation/>, errorText: "Körper / Bilder"},
                            {title: "Anamnese", component: <Assignment/>, errorText: "Pflichtfelder"},
                            {title: "Fragestellung", component: <ContactSupport/>, errorText: "Pflichtfelder"}]
                            .map((item, index) =>
                                <Tab
                                    key={item.title}
                                    value={index}
                                    label={
                                        <Box className="tab-label">
                                            <span>{item.title}</span>
                                            {!tabStatus.get(index) && tabStatus.get(index) != null && this.props.currentKonsil?.konsilStatus === "IN_ARBEIT" ?
                                                <span className="error-text">{item.errorText}</span> : ""}
                                        </Box>}
                                    className={!tabStatus.get(index) && tabStatus.get(index) != null && this.props.currentKonsil?.konsilStatus === "IN_ARBEIT" ? 'error' : ''}
                                    icon={
                                        (this.props.currentKonsil?.konsilStatus != null && this.props.currentKonsil?.konsilStatus === "IN_ARBEIT" ?
                                        (tabStatus.get(index) != null &&
                                        (tabStatus.get(index) ? <CheckCircle/> : <ErrorOutline/>)) ||
                                        (<span className="tab-number">{index + 1}</span>) :
                                            item.component)}
                                    {...a11yProps(index)}
                                />
                            )
                    }
                </Tabs>
                <DeletedKonsilNotificationContainer
                    isKonsilDeleted = {this.props.isKonsilDeleted}
                    onClose = {this.closeKonsilDeletedNotification}
                />
                <UpdateConflictDialog
                    isKonsilUpdateConflictDialog = {this.props.isKonsilUpdateConflictDialog}
                    dispatch_setIsKonsilUpdateConflictDialog = {this.props.dispatch_setIsKonsilUpdateConflictDialog}
                    konsilUpdateConflictData = {this.props.konsilUpdateConflictData}
                />
            </header>
        );
    }
}

export function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}

const HeaderKonsilContainer = connector(withPdfPreviewCreation(HeaderKonsil));
export default HeaderKonsilContainer;

