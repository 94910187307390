import React from 'react';
import uuid from 'uuid';
import { Lokalisation, Lateralitaet, Failure } from 'telescan-core';
import {
    fussBodyMapping,
    handBodyMapping,
    kopfBodyMapping,
    mainBodyMapping
} from "../components/konsil/editComponents/edit_lokalisation_lokations";
import { telescanFixable } from './utils';


export interface WithLokalisationHelpertsProps {
    getBodyElementValue: (element: string) => string;
    getElementName: (location: Lokalisation) => string;
    getBodyElementSide: (element: string) => "L" | "R" | "B";
}

export function withLokalisationHelper(Component) {
    type Props = {
        dispatch_failure: (id: string, failure: Failure) => void;
    }
    type State = {
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
            };

            this.getBodyElementValue = this.getBodyElementValue.bind(this);
            this.getElementName = this.getElementName.bind(this);
            this.getBodyElementSide = this.getBodyElementSide.bind(this);
        }


        getBodyElementValue(element: string): string {
            const loadInstanceId: string = uuid.v4();
            let finalName = "";
            element = element.replace("_l", "").replace("_r", "")
            if (Object.keys(mainBodyMapping).indexOf(element) !== -1) {
                finalName = mainBodyMapping[element];
                //console.log("Found in mainBodyMapping", finalName, element);
            } else if (Object.keys(kopfBodyMapping).indexOf(element) !== -1) {
                finalName = kopfBodyMapping[element];
                //console.log("Found in kopfBodyMapping", finalName, element);
            } else if (Object.keys(handBodyMapping).indexOf(element) !== -1) {
                finalName = handBodyMapping[element];
                //console.log("Found in handBodyMapping", finalName, element);
            } else if (Object.keys(fussBodyMapping).indexOf(element) !== -1) {
                finalName = fussBodyMapping[element];
                //console.log("Found in fussBodyMapping", finalName, element);
            } else {
                const failure = new Failure();
                failure.error = `Kann kein Bodymapping für element ${element} durchführen.` + telescanFixable;
                this.props.dispatch_failure(loadInstanceId, failure);
                return "";
            }
            //console.log("FinalName: ", finalName);
            return finalName;
        }

        getElementName(location: Lokalisation): string {
            let elementName: string;
            if (location.lateralitaet === "LINKS" || location.lateralitaet === "RECHTS")
                elementName = location.lokalisation + "_" + Lateralitaet[location.lateralitaet].toLowerCase();
            else
                elementName = location.lokalisation || ""
            return elementName
        }

        private getBodyElementSide(element: string): "L" | "R" | "B" {
            if (element.includes("_l"))
                return "L"
            else if (element.includes("_r"))
                return "R"
            else
                return "B"
        }
    
        render() {
            return (
                <Component 
                    getBodyElementValue={this.getBodyElementValue}
                    getElementName={this.getElementName}
                    getBodyElementSide={this.getBodyElementSide}
                    {...(this.props as any)}
                />
            )
        }
    };
}