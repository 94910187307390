import React from "react";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';

import {
    Box,
    Button,
    Tab,
    Tabs,
} from "@material-ui/core";

import Logo from "../../static/images/logo-telescan.svg";
import {RootState} from "../../redux/reducers";
import { changeKonsilTab, navigate, logout, getBetriebsstaettenAerzte, login, failure } from "../../redux/actions";
import {a11yProps} from '../header/header_konsil'
import { BasisdatenService, ArztBasisdaten, Arzt, TelescanConfig, Failure } from 'telescan-core';
import { updateModul } from "../../redux/actions/update_actions";


interface IProps {
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    tabIndex: state.konsile.tabIndex,
    basisdatenBetriebsstaette: state.user.betriebsstaette,
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenBSErrors: state.user.basisdatenBSErrors,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    initialisationStatus: state.user.initialisationStatus,
    einverstandnis: state.user.einverstaendnis,
    zertifikatBetriebsstaetteVorhanden: state.user.zertifikatBetriebsstaetteVorhanden,
    zertifikatArztVorhanden: state.user.zertifikatArztVorhanden,
    role: state.user.role,
    isHpmConfigChanged: state.general.isHpmConfigChanged,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_tabChange: (index: number) => changeKonsilTab(index),
    dispatch_logout: () => logout(),
    dispatch_failure: (loadInstanceId: string, error: Failure) => failure(loadInstanceId, error),
    dispatch_getBetriebsstaettenAerzte: () => getBetriebsstaettenAerzte(),
    dispatch_updateModul: (modulName: string) => updateModul(modulName),
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


class HeaderInitialisation extends React.Component<TPropsFromRedux> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
        }

        this.updateBasisdatenBS = this.updateBasisdatenBS.bind(this);
        this.updateBasisdatenAerzte = this.updateBasisdatenAerzte.bind(this);
        this.handleWeiter = this.handleWeiter.bind(this);
    }

    updateBasisdatenBS() {
        const loadInstanceId: string = uuid.v4();
        const basisdatenService = new BasisdatenService();
        basisdatenService.setBasisdatenBetriebsstaette(this.props.basisdatenBetriebsstaette).then((response) => {
            this.props.dispatch_tabChange(this.props.tabIndex + 1)
        }).catch((error) => {
            this.props.dispatch_tabChange(1);
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    updateBasisdatenAerzte() {
        const loadInstanceId: string = uuid.v4();
        const basisdatenService = new BasisdatenService();
        let basisdatenAerzteFull: Arzt[] = this.props.basisdatenAerzte.map(arzt => ({...arzt}));
        let basisdatenAerzte: ArztBasisdaten[] = basisdatenAerzteFull.map((arzt) => {arzt.id = undefined; return arzt;})
        basisdatenService.setBasisdatenAerzte(basisdatenAerzte).then((response) => {
            this.props.dispatch_tabChange(this.props.tabIndex + 1)
        }).catch((error) => {
            this.props.dispatch_tabChange(2);
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    handleWeiter() {
        if (this.props.tabIndex === 1) {
            this.updateBasisdatenBS();
        }
        else if (this.props.tabIndex === 2) {
            this.updateBasisdatenAerzte();
        } 
        else {
            this.props.dispatch_tabChange(this.props.tabIndex + 1)
        }
    }

    render() {
        let disabledWeiter: boolean;
        if (this.props.tabIndex === 1) {
            disabledWeiter = (this.props.basisdatenBSErrors.size > 0 || this.props.basisdatenBetriebsstaette === null)
        }
        else if (this.props.tabIndex === 2) {
            disabledWeiter = (this.props.basisdatenArztErrors.size > 0 || this.props.basisdatenAerzte?.length === 0);
        }
        else if (this.props.tabIndex === 0){
            disabledWeiter = (this.props.einverstandnis === false || !this.props.initialisationStatus.recoveryCodeErzeugt)
        }
        else if (this.props.tabIndex === 4){
            if (this.props.initialisationStatus.standalone){
                disabledWeiter = (!this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.initialisationStatus.arztZertifikatErzeugt)
            }
            else
                disabledWeiter = (!this.props.zertifikatBetriebsstaetteVorhanden || !this.props.zertifikatArztVorhanden)
        }
        else if (this.props.tabIndex === 3){
                //disabledWeiter = (!this.props.initialisationStatus.hpmInstalliert || this.props.isHpmConfigChanged)
                disabledWeiter = (this.props.isHpmConfigChanged)
        }
        else
            disabledWeiter = false

        return (
            <>
            <header className="header-initialisation">
                <Button className="grid-button back-btn left narrow" onClick={this.props.dispatch_logout}
                >
                    Abbrechen
                </Button>
                <img src={Logo} alt="Logo" className="logo"/>
                <h1 className="title">Ersteinrichtung</h1>
                <Tabs
                    value={this.props.tabIndex}
                    className="initialisation-tabs"
                    onChange={(_, index) => {this.props.dispatch_tabChange(index)}}
                    variant="scrollable"
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    {["Passwort", "Betriebsstätte", "Ärzte", "HÄVG-Prüfmodul", "Zertifikate", "Zusammenfassung"].map((titleItem, index) => 
                        <Tab value={index}
                            key={titleItem}
                            disabled={this.props.tabIndex <= index}
                            label={
                                <Box className="tab-label">
                                <Box className="tab-number">{index+1}</Box> <span> {titleItem}</span>
                                </Box>}
                            {...a11yProps(index)} />
                    )}
                </Tabs>
                {(this.props.tabIndex < 5) &&
                <Button variant="contained" color="primary" size="small" className="btn-right"
                        disabled={disabledWeiter}
                        onClick={this.handleWeiter}>
                    Weiter
                </Button>
                }
                {this.props.tabIndex === 5 &&
                <Button variant="contained" color="primary" size="small" className="btn-right"
                        disabled={!this.props.initialisationStatus.abgeschlossen}
                        onClick={() => {
                            const lanr = localStorage.getItem("LANR");
                            const bsnr = localStorage.getItem("BSNR");
                            const password = TelescanConfig.getInstance().getConstant("PASSWORD");
                            this.props.dispatch_login(bsnr || "", password, lanr)
                    }}
                >
                    Abschließen
                </Button>
                }
            </header>
            </>
        );
    }
}

const HeaderInitialisationContainer = connector(HeaderInitialisation);
export default HeaderInitialisationContainer;
