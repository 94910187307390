import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, Paper, Button, Tooltip, CircularProgress } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { failure, getInitialisationStatus, getIsHpmAvailableStatus, setIsHpmConfigChanged } from "../../redux/actions";
import HPMInstallationComponent from "./hpm_installation";
import { WithHpmInstallMethodsProps, withHpmInstallMethods } from "../../utils";
import { updateModul } from "../../redux/actions/update_actions";
import HPMKonfigurationComponent from "./hpm_konfiguration";
import { Edit } from "@material-ui/icons";
import { HPMKonfiguration, Failure} from "telescan-core";


interface IState {
    isEditHpmConfig: boolean;
    hpmConfig: HPMKonfiguration;
}

const mapStateToProps = (state: RootState) => ({
    initialisationStatus: state.user.initialisationStatus,
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    isHpmConfigChanged: state.general.isHpmConfigChanged,
})

const mapDispatchToProps = {
    dispatch_updateModul: (modulName: string) => updateModul(modulName),
    dispatch_getInitialisationStatus: () => getInitialisationStatus(),
    dispatch_failure: (id: string, error: Failure) => failure(id, error),
    dispatch_getIsHpmAvailableStatus: () => getIsHpmAvailableStatus(),
    setIsHpmConfigChanged,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithHpmInstallMethodsProps

export class Update extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isEditHpmConfig: false,
            hpmConfig: new HPMKonfiguration(),
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDoneClick = this.handleDoneClick.bind(this);
    }

    componentDidMount() {
        this.props.setIsHpmConfigChanged(true);
        this.props.handleCheckHpmInstallation();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.hpmUpdateSteps !== this.props.hpmUpdateSteps && this.props.hpmUpdateSteps.every(item => item.status === "DONE")) {
            this.props.handleCheckHpmInstallation();
            this.props.dispatch_getInitialisationStatus();
        }
    }

    handleEditClick() {
        this.setState({
            isEditHpmConfig: true,
        })
    }

    handleDoneClick(hpmConfig: HPMKonfiguration) {
        this.setState({
            isEditHpmConfig: false,
            hpmConfig: hpmConfig,
        })
        //if (!this.props.initialisationStatus.standalone) {
            this.props.handleCheckHpmInstallation();
            this.props.dispatch_getInitialisationStatus();
            this.props.dispatch_getIsHpmAvailableStatus();
        //}
    }

    renderCheckHPM() {
        if (!this.props.isCheckedHPM && this.props.hpmStatus==="checking") { 
            this.props.setIsHpmConfigChanged(true);       
            return(
                <Box className="hpm-config-two-buttons pad-top">
                    <CircularProgress/>
                    <Typography className="pad-top" >Prüfe HPM Version...</Typography>
                </Box>
            )
        }
        else if (this.props.isCheckedHPM){
            if (this.props.hpmStatus==="ok" && this.props.hpmVersion !== "unbekannt")
                return (
                    <Typography className="pad-top" >HPM Version {this.props.hpmVersion} ist installiert.</Typography>
                )
            else {
                if (this.props.initialisationStatus.standalone)
                    return (
                        <Typography className="pad-top failure" >Keine installierte HPM-Version gefunden.</Typography>
                    )
                else if (!this.props.initialisationStatus.standalone && !this.props.isInstallingHpm)
                    return (
                        <Typography className="pad-top failure" >Keine installierte HPM-Version gefunden. Bitte wenden Sie sich an Ihren PVS-Hersteller!</Typography>
                    )
                else 
                    return (<></>)
            }
        } else 
            return (<></>)
    }

    render() {
        return (
            <Box className="main-content initialisation-content">
                <form className="form-content" noValidate autoComplete="off">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Installation des HÄVG-Prüfmoduls (HPM)
                    </Typography>
                    <Paper className="hpm-card hpm-installation-card" elevation={0}>
                        <Typography variant="caption" className="pad-bottom" color="inherit">
                            Um TeleScan nutzen zu können, ist eine kompatible HPM-Version notwendig.
                        </Typography>
                        {(this.props.initialisationStatus.standalone)? 
                        <Typography variant="caption" className="pad-bottom" color="inherit">
                            Die folgende Konfiguration wird standardmäßig für die HPM-Installation genutzt. Falls Sie das HPM über TeleScan installieren wollen, können Sie den Port anpassen.
                            Sollten Sie den Status Ihrer bereits installierten HPM-Version prüfen wollen, so wählen Sie dafür bitte das passende Protokoll, den passenden Host, sowie den richtigen Port aus. Diese Konfiguration kann nachträglich in den Einstellungen geändert werden.
                        </Typography>
                        :
                        <Typography variant="caption" className="pad-bottom" color="inherit">
                            Bitte geben Sie das passende Protokoll, den passenden Host, sowie den richtigen Port an, unter denen das HPM installiert ist, um die installierte HPM-Version zu prüfen. Diese Konfiguration kann nachträglich in den Einstellungen geändert werden. Sollten Fehler auftreten, wenden Sie sich bitte an Ihren PVS Hersteller.
                        </Typography>
                        // Prüfe Sie bitte die Installation des HPM, die über Ihr PVS vorgenommen wurde. Wählen Sie dafür bitte das passende Protokoll, den passenden Host, sowie den richtigen Port aus.
                        }
                        <div className="hpm-config-container">
                            <HPMKonfigurationComponent handleDoneClick={this.handleDoneClick} isEdit={this.state.isEditHpmConfig}/>
                            {!this.state.isEditHpmConfig && 
                                <Tooltip title="Konfiguration bearbeiten">
                                    <Button className="slim edit-button margin-left" variant="contained" color="primary" onClick={this.handleEditClick} >
                                        <Edit/>
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        {(this.props.initialisationStatus.standalone && (this.state.hpmConfig.protokoll !== "http" && this.state.hpmConfig.host !== "docker-build.intern.zollsoft.org" )
                        //(window?.location?.hostname || "localhost")) 
                        ) &&
                        <Typography className="failure pad-bottom">Achtung! Die Installation des HPMs ist unter diesem Pfad nicht möglich.</Typography>
                        }
                        <div className = {this.props.initialisationStatus.standalone ? "hpm-config-three-buttons" : "hpm-config-two-buttons"}>
                            {this.props.initialisationStatus.standalone &&
                            <Button variant="contained" color="primary" onClick={this.props.handleClickInstallHpm} 
                                    disabled={this.props.isInstallingHpm || (this.props.hpmVersion !== "unbekannt" && this.props.hpmStatus === "ok") || this.props.initialisationStatus.hpmInstalliert ||
                                    (this.state.hpmConfig.protokoll !== "http" && this.state.hpmConfig.host !== "docker-build.intern.zollsoft.org") //(window?.location?.hostname || "localhost")
                                }
                                >
                                HPM-Installation starten
                            </Button> 
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.props.handleCheckHpmInstallation();
                                    this.props.dispatch_getInitialisationStatus();
                                }}
                            >
                                HPM-Pfad prüfen
                            </Button> 
                            {(this.props.isCheckedHPM && this.props.hpmStatus==="ok" && this.props.hpmVersion !== "unbekannt") &&
                            <Button
                                variant="contained"
                                color="primary" 
                                onClick={() => {
                                    this.props.setIsHpmConfigChanged(false);
                                }} 
                                disabled = {!this.props.isHpmConfigChanged}
                            >
                                Anwenden
                            </Button> 
                            }
                            {(this.props.isCheckedHPM && this.props.hpmStatus!=="ok") &&
                            <Button
                                variant="contained"
                                color="primary" 
                                onClick={() => {
                                    this.props.setIsHpmConfigChanged(false);
                                }} 
                                disabled = {!this.props.isHpmConfigChanged}
                            >
                                Trotzdem verwenden
                            </Button> 
                            }
                        </div>
                        {this.props.isInstallingHpm &&
                            <HPMInstallationComponent steps={this.props.hpmUpdateSteps} context="INITIALISATION"/> 
                        }
                        {this.renderCheckHPM()}
                    </Paper>
                </form>
            </Box>
        )
    }
}

export const UpdateComponent = connector(withHpmInstallMethods(Update));
export default UpdateComponent;