import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {
    Krankenkasse,
    Krankheit,
    Labor,
    Medikation,
    Patient,
    BeschwerdeDauer,
    Geschlecht,
    VersichertenStatus,
    Personengruppe,
    DMPZuordnung,
    DiagnoseSicherheit,
    Konsil,
} from 'telescan-core';
import * as EvaluationTypes from '../types/evaluation_types';
import {RootState} from '../reducers';
import { Diagnose, Konsilabschluss, TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import { Patienteneinwilligung } from 'telescan-core/lib/entities/patienteneinwilligung';
import { KonsilImageState } from '../reducers/image_attachment_reducer';

export function changeKonsilTabStatus(konsilId: string, statusMap: Map<number, Boolean | null>): EvaluationTypes.ITabStatusChangedAction {
    return {
        type: EvaluationTypes.EEvaluationActions.TAB_STATUS_CHANGED,
        konsilID: konsilId,
        statusMap: statusMap
    }
}

export function addKonsilTabStatus(konsilId: string, statusMap: Map<number, Boolean | null>): EvaluationTypes.IAddTabStatusAction {
    return {
        type: EvaluationTypes.EEvaluationActions.ADD_TAB_STATUS,
        konsilID: konsilId,
        statusMap: statusMap
    }
}

export function deleteTabStatus(konsilId: string): EvaluationTypes.IDeleteTabStatusAction {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_TAB_STATUS,
        konsilID: konsilId,
    }
}

export function checkKonsilStatus(konsil: Konsil, konsilImages: Map<string, KonsilImageState>): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch) => {
        dispatch(checkAnamneseStatus(konsil));
        dispatch(checkKonsilFrageStatus(konsil));
        dispatch(checkLocationStatus(konsil, konsilImages));
        dispatch(checkKonsilPatientStatus(konsil.konsilPatient, konsil.patienteneinwilligung || new Patienteneinwilligung(), konsil.id || ""));
    }
}

export function checkAnamneseStatus(konsil: Konsil): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const errors = new Map<string, string>()
        if (konsil.anamnese.veraenderungAuessereFaktoren && (konsil.anamnese?.veraenderungAuessereFaktorenFreitext == null || konsil.anamnese.veraenderungAuessereFaktorenFreitext === ""))
            errors.set("konsil.veraenderungAuessereFaktorenFreitext", "Veränderung durch externe Faktoren muss ausgefüllt sein!");
        // if (konsil.befund?.malignitaet == null)
        //     errors.set("konsil.malignitaetFalse", "Verdacht auf Malignität muss ausgefüllt sein!");
        if (konsil.befund?.malignitaet == null || (konsil.befund?.malignitaet === true && (konsil.befund.aenderungGroesse === false && konsil.befund.aenderungForm === false &&
            konsil.befund.aenderungFarbe === false && (konsil.befund.aenderungAussehen == null || konsil.befund.aenderungAussehen === ""))))
            errors.set("konsil.malignitaet", "Verdacht auf Malignität muss ausgefüllt sein!");
        // if (konsil.befund?.malignitaet === true && (konsil.befund.aenderungGroesse == null || konsil.befund.aenderungForm == null || konsil.befund.aenderungFarbe == null || konsil.befund.aenderungAussehen == null || konsil.befund.aenderungAussehen === ""))
        //     errors.set("konsil.malignitaetTrue", "Veränderungen muss ausgefüllt sein bei Verdacht auf Malignität!");
        if ((konsil.anamnese?.beschwerdeDauer == null || Object.keys(BeschwerdeDauer).indexOf(konsil.anamnese?.beschwerdeDauer) === -1 || (konsil.anamnese.beschwerdeDauer==="D90" &&
            (konsil.anamnese.beschwerdeSonstigeDauer == null || konsil.anamnese.beschwerdeSonstigeDauer === ""))) && !konsil.anamnese.noComplaint)
            errors.set("konsil.beschwerdeDauer", "Die Beschwerdendauer muss gewählt werden, sonstige Beschwerdedauer muss spezifiziert werden!");
        if (!(konsil.anamnese.noComplaint || konsil.anamnese?.jucken || konsil.anamnese?.schmerzen || konsil.anamnese?.brennen || konsil.anamnese?.naessen || konsil.anamnese?.bluten) &&
            (konsil.anamnese?.beschwerdeFreitext == null || konsil.anamnese?.beschwerdeFreitext === ""))
             errors.set("konsil.beschwerde", "Die Beschwerden müssen beschrieben werden!");
        if ((!konsil.anamnese?.verlaufRezidivierend && !konsil.anamnese?.verlaufZunehmend && !konsil.anamnese?.verlaufUnveraendert &&
            (!konsil.anamnese?.veraenderungAuessereFaktoren || (konsil.anamnese?.veraenderungAuessereFaktoren && (konsil.anamnese?.veraenderungAuessereFaktorenFreitext == null || konsil.anamnese.veraenderungAuessereFaktorenFreitext === "")))) && !konsil.anamnese.noComplaint)
            errors.set("konsil.beschwerdeVerlauf", "Angaben zum Verlauf müssen gemacht werden!");
        // if (konsil.anamnese?.beschwerdeDauerUndVerlauf == null || konsil.anamnese.beschwerdeDauerUndVerlauf === "")
        //     errors.set("konsil.beschwerdeDauerUndVerlauf", "Angaben zum Verlauf müssen gemacht werden!");
        // if (konsil.konsilPatient.allergien?.sofort_reaktionen == null || konsil.konsilPatient.allergien?.sofortReaktionen === "")
        //     errors.set("konsil.allergien.sofortReaktionen", "Angaben zu Allergien mit Sofortreaktionen müssen gemacht werden!");
        // if (konsil.konsilPatient.allergien?.spaetReaktionen == null || konsil.konsilPatient.allergien?.spaetReaktionen === "")
        //     errors.set("konsil.allergien.spaetReaktionen", "Angaben zu Allergien mit Spätreaktionen müssen gemacht werden!");
        if (!konsil.konsilPatient.familienanamnese?.keineErkrankungen && !konsil.konsilPatient.familienanamnese?.hautmelanom && !konsil.konsilPatient.familienanamnese?.psoriasis &&
            !konsil.konsilPatient.familienanamnese?.heuschnupfen && !konsil.konsilPatient.familienanamnese?.asthma && !konsil.konsilPatient.familienanamnese?.neurodermites &&
            (konsil.konsilPatient.familienanamnese?.weitereFamilienerkrankungen == null || konsil.konsilPatient.familienanamnese?.weitereFamilienerkrankungen === ""))
            errors.set("konsil.familienanamnese", "Angaben zu Familien- oder Eigenerkrankungen müssen gemacht werden!");

        if (getState().evaluation.konsilAnamneseErrors.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.ADD_KONSILANAMNESE_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }
        else {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.CHANGE_KONSILANAMNESE_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }
        if (getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            const statusMap = new Map<number, Boolean | null>();
            statusMap.set(2, !(errors.size > 0))
            konsil.id && dispatch(addKonsilTabStatus(konsil.id, statusMap))
        }
        else {
            const statusMap: Map<number, Boolean | null> = getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.statusMap || new Map<number, Boolean | null>();
            statusMap.set(2, !(errors.size > 0))
            konsil.id && dispatch(changeKonsilTabStatus(konsil.id, statusMap));
        }
    }
}

export function deleteKonsilAnamneseErrors(konsilId: string): EvaluationTypes.IDeleteKonsilAnamneseErrors {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_KONSILANAMNESE_ERRORS,
        konsilID: konsilId,
    }
}

export function deleteKonsilFrageErrors(konsilId: string): EvaluationTypes.IDeleteKonsilFrageErrors {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_KONSILFRAGE_ERRORS,
        konsilID: konsilId,
    }
}

export function deleteKonsilPatientErrors(konsilId: string): EvaluationTypes.IDeleteKonsilPatientErrors {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_KONSILPATIENT_ERRORS,
        konsilID: konsilId,
    }
}

export function deleteLocationErrors(konsilId: string): EvaluationTypes.IDeleteLocationErrors {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_LOCATION_ERRORS,
        konsilID: konsilId,
    }
}

export function deleteKonsilFeedbackErrors(konsilabschlussID: string): EvaluationTypes.IDeleteKonsilFeedbackErrors {
    return {
        type: EvaluationTypes.EEvaluationActions.DELETE_KONSILFEEDBACK_ERRORS,
        konsilabschlussID: konsilabschlussID,
    }
}

export function checkKonsilFrageStatus(konsil: Konsil): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const errors = new Map<string, string>()
        if (konsil.fragestellung === null || konsil.fragestellung === "")
            errors.set("konsil.fragestellung", "Eine Frage an den Konsiliar muss gestellt werden!");

        if (getState().evaluation.konsilFrageErrors.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.ADD_KONSILFRAGE_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }
        else {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.CHANGE_KONSILFRAGE_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }

        if (getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            const statusMap = new Map<number, Boolean | null>();
            statusMap.set(3, !(errors.size > 0));
            konsil.id && dispatch(addKonsilTabStatus(konsil.id, statusMap));
        }
        else {
            const statusMap: Map<number, Boolean | null> = getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.statusMap || new Map<number, Boolean | null>();
            statusMap.set(3, !(errors.size > 0));
            konsil.id && dispatch(changeKonsilTabStatus(konsil.id, statusMap));
        }
    }
}

export function checkKonsilPatientStatus(patient: Patient, patientenEinwilligung: Patienteneinwilligung, konsilId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        let errors = checkPatient(patient);
        errors = new Map([...Array.from(errors), ...Array.from(checkKrankenkasse(patient.krankenkasse))]);
        errors = new Map([...Array.from(errors), ...Array.from(checkKrankheiten(patient.krankheiten))]);
        errors = new Map([...Array.from(errors), ...Array.from(checkMedikation(patient.medikationen))]);
        errors = new Map([...Array.from(errors), ...Array.from(checkLaborwerte(patient.laborwerte))]);
        //errors = new Map([...Array.from(errors), ...Array.from(checkEinwilligung(patientenEinwilligung))]);

        if (getState().evaluation.konsilPatientErrors.find(item => item.konsilID === konsilId)?.konsilID === undefined) {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.ADD_KONSILPATIENT_ERRORS,
                konsilID: konsilId,
                fieldErrorMap: errors
            });
        }
        else {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.CHANGE_KONSILPATIENT_ERRORS,
                konsilID: konsilId,
                fieldErrorMap: errors
            });
        }

        if (getState().evaluation.tabStatus.find(item => item.konsilID === konsilId)?.konsilID === undefined) {
            const statusMap = new Map<number, Boolean | null>();
            statusMap.set(0, !(errors.has("patient") || errors.has("krankheit") || errors.has("medikation") || errors.has("labor") || errors.has("einwilligung")));
            dispatch(addKonsilTabStatus(konsilId, statusMap))
        }
        else {
            const statusMap: Map<number, Boolean | null> = getState().evaluation.tabStatus.find(item => item.konsilID === konsilId)?.statusMap || new Map<number, Boolean | null>();
            statusMap.set(0, !(errors.has("patient") || errors.has("krankheit") || errors.has("medikation") || errors.has("labor") || errors.has("einwilligung")));
            dispatch(changeKonsilTabStatus(konsilId, statusMap));
        }
    }
}

export function checkLocationStatus(konsil: Konsil, konsilImages: Map<string, KonsilImageState>): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        let errors = new Map<string, string>();
        if (konsilImages == null || Object.keys(konsilImages).length === 0)
            errors.set("konsil.images", "Es muss mindestens ein Bild hinterlegt sein.");
        if (konsilImages != null && Object.keys(konsilImages).length  > 3)
            errors.set("konsil.images", "Durch Synchronisation mit der mobilen Anwendung sind zur Zeit mehr als drei Fotografien an Ihren Konsilauftrag angehängt. Bitte reduzieren Sie die Anzahl durch Löschen der der überzähligen Fotografien.");
        if (konsil.befund?.lokalisation == null || konsil.befund?.lokalisation.length === 0)
            errors.set("konsil.lokation", "Es muss mindestens eine Lokalisation angegeben werden");


        if (getState().evaluation.locationErrors.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.ADD_LOCATION_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }
        else {
            dispatch({
                type: EvaluationTypes.EEvaluationActions.CHANGE_LOCATION_ERRORS,
                konsilID: konsil.id,
                fieldErrorMap: errors
            });
        }
        if (getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.konsilID === undefined) {
            const statusMap = new Map<number, Boolean | null>();
            statusMap.set(1, !(errors.size > 0));
            konsil.id && dispatch(addKonsilTabStatus(konsil.id, statusMap));
        }
        else {
            const statusMap: Map<number, Boolean | null> = getState().evaluation.tabStatus.find(item => item.konsilID === konsil.id)?.statusMap || new Map<number, Boolean | null>();
            statusMap.set(1, !(errors.size > 0));
            konsil.id && dispatch(changeKonsilTabStatus(konsil.id, statusMap));
        }
    }
}

export function checkKonsilFeedbackStatus(konsilabschluss: Konsilabschluss): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const errors = new Map<string, string>()
        if (konsilabschluss.feedbackKonsilqualitaet === null)
            errors.set("konsilabschluss.feedbackKonsilqualitaet", "Die Qualität des Konsiliarberichts muss bewertet werden!"); 

        // if (getState().evaluation.konsilFeedbackErrors.find(item => item.KonsilabschlussID === konsilabschluss.id)?.KonsilabschlussID === undefined) {
        //     dispatch({
        //         type: EvaluationTypes.EEvaluationActions.ADD_KONSILFEEDBACK_ERRORS,
        //         konsilID: konsilabschluss.id,
        //         fieldErrorMap: errors
        //     });
        // }
        // else {
        //     dispatch({
        //         type: EvaluationTypes.EEvaluationActions.CHANGE_KONSILFEEDBACK_ERRORS,
        //         konsilID: konsilabschluss.id,
        //         fieldErrorMap: errors
        //     });
        // }
    }
}

function checkPatient(patient: Patient | undefined): Map<string, string> {
    const errors = new Map<string, string>();
    if (!patient)
        return errors;

    if (patient.egkNummer == null || patient.egkNummer === "")
        errors.set("patient.egknummer", "Es wurde keine eGK-Nummer des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.geschlecht == null || Object.keys(Geschlecht).indexOf(patient.geschlecht) === -1)
        errors.set("patient.geschlecht", "Es wurde kein Geschlecht des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.versichertenStatus == null || Object.keys(VersichertenStatus).indexOf(patient.versichertenStatus) === -1)
        errors.set("patient.versichertenStatus", "Es wurde kein Versichertenstatus des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.personengruppe == null || Object.keys(Personengruppe).indexOf(patient.personengruppe) === -1)
        errors.set("patient.personengruppe", "Es wurde keine Personengruppe des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.dmpZuordnung == null || Object.keys(DMPZuordnung).indexOf(patient.dmpZuordnung) === -1)
        errors.set("patient.dmp_zuordnung", "Es wurde keine DMP-Zuordnung des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.vorname == null || patient.vorname === "")
        errors.set("patient.vorname", "Es wurde keinen Vornamen des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.nachname == null || patient.nachname === "")
        errors.set("patient.name", "Es wurde keinen Nachnamen des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
        /*
    if (patient.strasseHausnummer == null || patient.strasseHausnummer === "")
        errors.set("patient.strasse", "Es wurde eine unvollständige Adresse des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.plz == null || patient.plz === "")
        errors.set("patient.plz", "Es wurde eine unvollständige Adresse des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (patient.wohnort == null || patient.wohnort === "")
        errors.set("patient.wohnort", "Es wurde eine unvollständige Adresse des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
        */
    if (patient.geburtsdatum == null || new Date(patient.geburtsdatum).toLocaleDateString() === ""|| isNaN(patient.geburtsdatum)===true)
        errors.set("patient.geburtsdatum", "Es wurde kein Geburtsdatum des Patienten aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (errors.size !== 0)
        errors.set("patient", "Die Stammdaten des Patienten enthalten Fehler.");

    return errors;
}

function checkKrankenkasse(krankenkasse: Krankenkasse | undefined): Map<string, string> {
    const errors = new Map<string, string>();
    if (!krankenkasse || (krankenkasse && (krankenkasse.name == null || krankenkasse.name === "")))
        errors.set("krankenkasse.name", "Es wurde kein Name der Krankenkasse aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");
    if (!krankenkasse || (krankenkasse && (krankenkasse.iknr == null || krankenkasse.iknr === "")))
        errors.set("krankenkasse.iknr", "Es wurde keine IKNr der Krankenkasse aus ihrem PVS übertragen. Bitte übertragen Sie die Daten erneut");

    if (errors.size !== 0)
        errors.set("krankenkasse", "Die Krankenkassendaten des Patienten enthalten Fehler.");

    return errors;
}

function checkKrankheiten(krankheiten: Krankheit[] | undefined): Map<string, string> {
    const errors = new Map<string, string>();
    if (!krankheiten)
        return errors;

    let i = 0;
    krankheiten.forEach((krankheit) => {
        let found: boolean = false;
        if (krankheit.dauerdiagnose == null) {
            errors.set(`krankheit.${i}.dauerdiagnose`, "Es muss festgelegt werden, ob dies eine Dauerdiagnose ist.");
            found = true;
        }
        // if (krankheit.diagnosetext == null || krankheit.diagnosetext === "") {
        //     errors.set(`krankheit.${i}.diagnosetext`, "Ein ICD Test muss für die Diagnose angegeben werden.");
        //     found = true;
        // }
        if (krankheit.diagnosecode == null || krankheit.diagnosecode === "") {
            errors.set(`krankheit.${i}.diagnosecode`, "Ein ICD Code muss gesetzt sein.");
            found = true;
        }
        if (krankheit.diagnosesicherheit == null || Object.keys(DiagnoseSicherheit).indexOf(krankheit.diagnosesicherheit) === -1) {
            errors.set(`krankheit.${i}.diagnosesicherheit`, "Die Diagnosesicherheit muss festgelegt werden.");
            found = true;
        }

        if (found) {
            errors.set(`krankheit`, `Die Liste der Diagnosen enthält Fehler`);
            errors.set(`krankheit.${i}`, `Die ${i}. Diagnose enthält Fehler`);
        }

        ++i;
    });

    return errors;
}

function checkMedikation(medikationen: Medikation[] | undefined): Map<string, string> {
    const errors = new Map<string, string>();
    if (!medikationen)
        return errors;

    let i = 0;
    medikationen.forEach((medikation) => {
        let found = false;
        if (medikation.dauermedikation == null) {
            errors.set(`medikation.${i}.dauermedikation`, "Es muss festgelegt werden, ob dies eine Dauermedikation ist.");
            found = true;
        }

        if ((medikation.arzneimittelPzn == null || medikation.arzneimittelPzn === "") && medikation.arzneimittelType==="PZN") {
            errors.set(`medikation.${i}.arzneimittelPzn`, "Die Pharmazentralnummer der Medikation muss gesetzt sein.");
            found = true;
        }
        if ((medikation.arzneimittelHandelsbezeichnung == null || medikation.arzneimittelHandelsbezeichnung === ""))
        //&& medikation.arzneimittelType!=="NA")
         {

            errors.set(`medikation.${i}.arzneimittelHandelsbezeichnung`, "Die Handelsbezeichnung der Medikation muss gesetzt sein.");
            found = true;
        }
        /*
        if (medikation.dosiermenge == null || medikation.dosiermenge === 0 || isNaN(medikation.dosiermenge) === true) {
            errors.set(`medikation.${i}.dosiermenge`, "Die Dosiermenge der Medikation muss angegeben werden.");
            found = true;
        }
        if (medikation.dosiereinheit == null || medikation.dosiereinheit === "") {
            errors.set(`medikation.${i}.dosiereinheit`, "Bitte geben sie die Dosiereinheit der Medikation an.");
            found = true;
        }
        if (medikation.dosierschema == null || medikation.dosierschema === "") {
            errors.set(`medikation.${i}.dosierschema`, "Bitte geben sie das Dosierschema der Medikation ein.");
            found = true;
        }*/
        // if (medikation.sonstige_angaben == null || medikation.sonstige_angaben === "") {
        //     errors.set(`medikation.${i}.sonstige_angaben`, "Bitte fügen Sie sonstige Angaben hinzu.");
        //     found = true;
        // }

        if (found) {
            errors.set(`medikation`, `Die Liste der Medikationen enthält Fehler`);
            errors.set(`medikation.${i}`, `Die ${i}. Medikation enthält Fehler`);
        }

        ++i;
    });

    return errors;
}

function checkLaborwerte(laborwerte: Labor[] | undefined): Map<string, string> {
    const errors = new Map<string, string>();
    if (!laborwerte)
        return errors;

    let i = 0;
    laborwerte.forEach((labor: Labor) => {
        let found = false;
        if (labor.datum == null || labor.datum === "") {
            errors.set(`labor.${i}.datum`, "Es muss ein Datum für den Messwert gesetzt sein.");
            found = true;
        }
        if (labor.einheit == null || labor.einheit === "") {
            errors.set(`labor.${i}.einheit`, "Eine Einheit für den Messwert muss eingetragen werden.");
            found = true;
        }
        if (labor.messwert == null || labor.messwert === "") {
            errors.set(`labor.${i}.messwert`, "Ein Messwert muss eingetragen werden.");
            found = true;
        }
        if (labor.laborwertName == null || labor.laborwertName === "") {
            errors.set(`labor.${i}.wert`, "Bitte geben sie den codierten Laborwert an, für den der Messwert aufgenommen wurde.");
            found = true;
        }
        if (labor.laborwertCode == null || labor.laborwertCode === "") {
            errors.set(`labor.${i}.code`, "Bitte geben sie den Code zum Laborwert an, für den der Messwert aufgenommen wurde.");
            found = true;
        }

        if (found) {
            errors.set(`labor`, `Die Liste der Laborwerte enthält Fehler`);
            errors.set(`labor.${i}`, `Der ${i}. Laborwert enthält Fehler`);
        }

        ++i;
    });
    return errors;
}

function isEmptyDiagnose(diagnose: Diagnose) {
    return (
        (diagnose?.diagnoseCode === "" || diagnose?.diagnoseCode == null) &&
        (diagnose?.diagnoseAnzeigenname === "" || diagnose?.diagnoseAnzeigenname == null) &&
        (diagnose?.diagnoseSicherheit == null) &&
        diagnose?.seitenlokalisation == null
    )
}

export function checkKonsiliarberichtStatus(konsil: Konsil): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const errors = new Map<string, string>()
        if (konsil.konsiliarbericht?.befund == null || konsil.konsiliarbericht?.befund === ""){
            errors.set("konsiliarbericht.befund", "Angaben zum Befund müssen gemacht werden!");}
        if ( ((konsil.konsiliarbericht?.diagnose == null || isEmptyDiagnose(konsil.konsiliarbericht?.diagnose[0])) && (konsil.konsiliarbericht?.keineDiagnose === false || konsil.konsiliarbericht?.keineDiagnose == null)) || 
            ((konsil.konsiliarbericht?.diagnose!==[] || konsil.konsiliarbericht?.diagnose !== null) && konsil.konsiliarbericht?.diagnose?.some(item => {return (item.diagnoseCode == null || item.diagnoseCode === "" || item.diagnoseAnzeigenname == null || 
            item.diagnoseAnzeigenname === "" || Object.keys(DiagnoseSicherheit).indexOf(item.diagnoseSicherheit || "") === -1)})))
            errors.set("konsiliarbericht.diagnosen", "Zur Befundung ist die Eingabe einer Diagnose notwendig!");
        if (konsil.konsiliarbericht?.diagnoseKonfidenz == null ){
            errors.set("konsiliarbericht.diagnoseKonfidenz", "Angabe zur Diagnosekonfidenz müssen gemacht werden!");}
        if (konsil.konsiliarbericht?.therapieDringlichkeit == null || Object.keys(TherapieDringlichkeit).indexOf(konsil.konsiliarbericht?.therapieDringlichkeit) === -1 || TherapieDringlichkeit[konsil.konsiliarbericht?.therapieDringlichkeit]  ===  TherapieDringlichkeit['KEINEANGABE'])
            errors.set("konsiliarbericht.therapieDringlichkeit", "Angaben zur Therapiedringlichkeit müssen gemacht werden!");

        dispatch({
            type: EvaluationTypes.EEvaluationActions.KONSILIARBERICHT_ERRORS,
            fieldErrorMap: errors
        });
    }
}

export function checkDermaFeedbackStatus(konsil: Konsil): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const errors = new Map<string, string>()
        if (konsil.konsiliarbericht?.feedbackBildqualitaet == null){
            errors.set("feedbackDermatologe.qualitaetBild", "Die Qualität der Bilder muss bewertet werden!");}
        if (konsil.konsiliarbericht?.feedbackAngabenqualitaet == null ){
            errors.set("feedbackDermatologe.qualitaetAngaben", "Die Qualität der anamnesistischen Angaben muss bewertet werden!");}
        // if ((konsil.konsiliarbericht?.feedbackFreitext == null || konsil.konsiliarbericht?.feedbackFreitext === "") && konsil.konsiliarbericht?.feedbackGeben === true){
        //     errors.set("feedbackDermatologe.freitext", "Das Freitextfeld muss gefüllt, bzw. der Haken bei keine Angaben gesetzt werden!");}

        dispatch({
            type: EvaluationTypes.EEvaluationActions.KONSILIARFEEDBACK_ERRORS,
            fieldErrorMap: errors
        });
    }
}