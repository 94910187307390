import { Arzt } from "telescan-core";
import {EArztActions, TArztActionTypes} from '../types/arzt_types';


export interface IArztState {
    lastReceiver: Arzt;
}

const initialState: IArztState = {
    lastReceiver: new Arzt()
}

export function arzt_reducer(state = initialState, action: TArztActionTypes): { lastReceiver: Arzt } {
    switch (action.type) {
        case EArztActions.SET_LAST_RECEIVER:
            return {
                ...state,
                lastReceiver: action.lastReceiver
            }
        default:
            return state
    }
}
