import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Paper,
    Button, 
    Avatar,
    Typography
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { connect, ConnectedProps } from 'react-redux';
import {Arzt, Patient, Konsil, TelescanConfig} from 'telescan-core';
import { KonsilPatient, KonsilStatus } from '.';
import { RootState } from '../../redux/reducers';
import { FormControl, IconButton, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Delete, SortByAlpha, PictureAsPdf, Edit} from '@material-ui/icons';
import { deleteKonsil, updateKonsil, loadArztKonsile, setSuccessMessage, refreshKonsilStatus } from '../../redux/actions';

import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SortTableComponent from '../elements/sort_table';
import { TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import SimpleModalContainer from '../elements/simple_modal';
import ConfirmationPopupComponent from './confirmation_popup';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import RefreshIcon from '@material-ui/icons/Refresh';

export interface Row {
    adressat: string;
    id: string;
    patient: Patient;
    date_created: any;
    status: "IN_ARBEIT" | "BEAUFTRAGT" | "RUECKFRAGE" | "BEANTWORTET" | "BEFUNDET" | "ABGESCHLOSSEN" | "ABGEBROCHEN";
    bearbeitungsStatus: "OK" | "ERROR" | "UPLOAD" | "DOWNLOAD" | "GESPERRT" | "MALFORMED";
    konsiliar: Arzt;
    konsil: Konsil
}

interface TableProps {
    data: Row[],
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface ITableState {
    isOpenDateModal: boolean,
    konsil: Konsil,
    propertyName: string;
    direction: "asc" | "desc" | "default";
    isOpenDeleteConfirm: boolean,
    deleteId: string,
    isDatePickerError: boolean,
}

const mapStateToProps = (state: RootState, ownProps: TableProps) => ({
    queryString: state.router.location.search,
    router: state.router,
    currentKonsil: state.konsile.current_konsil,
    totalSize: state.konsile.totalSize,
    konsilSortSearchFilterParams: state.konsile.konsilSortSearchFilterParams,
    isSupportEnabled: state.general.isSupportEnabled,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_deleteKonsil: (id: string) => deleteKonsil(id),
    updateKonsil: (konsil: Konsil ) => updateKonsil(konsil),
    dispatch_loadKonsile: (propertyName?: string, direction?: "asc" | "desc" | "default") => loadArztKonsile(propertyName, direction),
    push: (path: string) => push(path),
    dispatch_successMessage: (message: string) => setSuccessMessage(message),
    dispatch_refreshKonsilStatus: (konsil_id: string) => refreshKonsilStatus(konsil_id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class KonsilTable extends React.Component<TPropsFromRedux, ITableState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isOpenDateModal: false,
            konsil: this.props.currentKonsil,
            propertyName: "konsilStatus",
            direction: "default",
            isOpenDeleteConfirm: false,
            deleteId: "",
            isDatePickerError: false,
        };
        this.handleClose = this.handleClose.bind(this);
        this.setPropertyNameAndDirection = this.setPropertyNameAndDirection.bind(this);

    }

    private stringToColor(str: string) { // function to conver full name of Arzt to background color of avatar such that each Arzt will get unique color
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + (hash << 5) - hash;
        }
        return `hsl(${hash % 360}, 30%, 80%)`;
    }

    private konsil(id: string) {
        this.props.push(this.props.router.location.pathname + "konsil/" + id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({konsil: this.props.currentKonsil});
            this.props.dispatch_loadKonsile();
            
        }
    }

    handleClose = () => {
        this.setState({
            isOpenDateModal: false,
            isDatePickerError: false,
        });
        //this.updateKonsil(this.state.konsil);
    };

    setPropertyNameAndDirection(propertyName: string, direction: "asc" | "desc" | "default") {
        this.setState({
            propertyName: propertyName,
            direction: direction
        })
    }


    render() {
        const telescanConfig = TelescanConfig.getInstance();
        
        return (
            <TableContainer className="konsil-table">
                <Box className="mobile-table-header">
                    <TableSort />
                    <IconButton aria-label="delete">
                        <SortByAlpha />
                    </IconButton>
                </Box>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: "3%" }}></TableCell>
                            <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "patient"} propertyName="konsilPatient.vorname" title="Patient " />
                            <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilID"} propertyName="id" title="Konsil ID " />
                            <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "zeitpunkt"} propertyName="datecreated" title="Zeitpunkt " />
                            <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilStatus"} propertyName="konsilStatus" title="Status " />
                            <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "adressat"} propertyName="beauftragender_arzt.nachname" title="Adressat " />
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map(rowData => {
                            let className: string;
                            if (rowData.status === "ABGEBROCHEN")
                                className = rowData.konsil?.statusAenderung? "abgebrochen-konsil-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";
                            else
                                className = rowData.konsil?.statusAenderung? "bold-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";                         
                            const avatarName: string = (rowData?.konsil.beauftragender_arzt.vorname && rowData?.konsil?.beauftragender_arzt.nachname) ?
                            `${rowData?.konsil.beauftragender_arzt.vorname} ${rowData?.konsil?.beauftragender_arzt.nachname}` :
                            "Keine Information";
                            let cssProperties = {};
                            cssProperties['--btn-bg-color'] = this.stringToColor(avatarName); // apply color generated from Arzt full name to css class
                            const avatarLabel: string = (rowData?.konsil.beauftragender_arzt.vorname && rowData?.konsil?.beauftragender_arzt.nachname) ?
                            rowData?.konsil.beauftragender_arzt.vorname[0] + rowData?.konsil?.beauftragender_arzt.nachname[0] :
                            "??";
                            return (
                            <TableRow
                                className={"table-row " + className}
                                key={rowData.id}
                                onClick={(event) => this.konsil(rowData.id)} //style={rowData.konsil.statusAenderung? {backgroundColor: '#e7d1d1'}: {}}
                            >
                                 <TableCell className="doctor-avatar" style={{ width: "3%" }}>
                                    <Tooltip placement = 'top' title = {avatarName}>
                                        <Avatar className = "avatar" style = {cssProperties}>{avatarLabel}</Avatar>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className="konsil-patient">
                                    <KonsilPatient patient={rowData.patient} />
                                </TableCell>
                                <TableCell className="konsil-id" scope="row">
                                    {rowData.id.substring(0, 8)}
                                </TableCell>
                                <TableCell className="konsil-date">
                                    {new Date(rowData.date_created).toLocaleString()}
                                    {rowData.status === "IN_ARBEIT" &&
                                        <Tooltip title="Bearbeite Zeitpunkt" placement="top">
                                            <IconButton size="small" aria-label="delete" onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                    isOpenDateModal: true,
                                                    konsil: rowData.konsil
                                                })
                                                }}>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </TableCell>
                                <TableCell className="konsil-status"><KonsilStatus therapiedringlichkeit={(rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit)? TherapieDringlichkeit[rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit]: undefined} hasPvsPatient={rowData.konsil.pvsPatient != null} bearbeitungsStatus={rowData.bearbeitungsStatus} status={rowData.status} isMetaData={false} role="HAUSARZT"/></TableCell>
                                <TableCell className="konsil-receiver">
                                    {rowData.konsiliar?.id && 
                                    <>
                                        <span>{[rowData.konsiliar.titel, rowData.konsiliar.nachname].join(' ') + ' • '}</span> 
                                        <Tooltip title="LANR" placement="top"><span>{rowData.konsiliar.lanr}</span></Tooltip>
                                    </>
                                    }
                                    {(!rowData.konsiliar?.id && rowData.adressat) &&
                                        rowData.adressat
                                    }
                                    {(!rowData.konsiliar?.id && !rowData.adressat) &&
                                        "Keine Angabe"
                                    }
                                </TableCell>
                                <TableCell align="right" className="konsil-actions">
                                    <Box className = 'flex-row-center justify-end'>
                                        {this.props.isSupportEnabled &&
                                            <Tooltip title = "Bearbeitungsstatus aktualisieren">
                                                <IconButton
                                                    onClick = {(event) => {
                                                        event.stopPropagation();
                                                        this.props.dispatch_refreshKonsilStatus(rowData.id);
                                                    }}
                                                >
                                                    <RefreshIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {rowData.status === "IN_ARBEIT" &&
                                            <Tooltip title="Lösche Konsil" placement="top">
                                                <IconButton size="small" aria-label="Lösche Konsil" onClick={(event) => { event.stopPropagation(); this.setState({isOpenDeleteConfirm: true, deleteId: rowData.id});}}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {rowData.status !== "IN_ARBEIT" &&
                                            <Tooltip title="Download PDF" placement="top">
                                                <IconButton
                                                    size="small"
                                                    aria-label="Download PDF"
                                                    onClick={(event) => {
                                                        this.props.dispatch_successMessage("Der Anhang wird in einem neuen Tab geöffnet");
                                                        event.stopPropagation();
                                                        if (rowData.status === "ABGEBROCHEN")
                                                            window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${rowData.id}/pdf/latest?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blanck");
                                                        else
                                                            window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${rowData.id}/pdf?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blanck");
                                                        // var newBlob = new Blob([`${telescanConfig.getConstant("SERVER_API_URL")}konsil/${rowData.id}/pdf?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`], {type: "application/pdf"})
                                                        // // For other browsers: 
                                                        // // Create a link pointing to the ObjectURL containing the blob.
                                                        // const data = window.URL.createObjectURL(newBlob);
                                                        // const link = document.createElement('a');
                                                        // link.href = data;
                                                        // link.download="file.pdf";
                                                        // link.click();
                                                        // setTimeout(function(){
                                                        //     // For Firefox it is necessary to delay revoking the ObjectURL
                                                        //     window.URL.revokeObjectURL(data);
                                                        // }, 100);
                                                    }}
                                                >
                                                    <PictureAsPdf />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                </TableCell>
                            </TableRow>)}
                        )}
                        <TableRow className="table-footer-pagination">
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                colSpan={7}
                                count={(this.props.totalSize !== -1)? this.props.totalSize: 0}
                                rowsPerPage={this.props.konsilSortSearchFilterParams.numberPerPage}
                                page={this.props.konsilSortSearchFilterParams.pageNb}
                                onPageChange={this.props.handleChangePage}
                                onRowsPerPageChange={this.props.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>

                        <SimpleModalContainer isOpen={this.state.isOpenDateModal}
                            onClose={()=> {this.handleClose()}}
                            additionalClassname="modal-time-pop"
                        >
                            <Paper className="modal-wrapper">
                                <h3 id="transition-modal-title" className="modal-header">Zeitpunkt bearbeiten</h3>
                                <Box className="modal-content">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            className="konsil-datetime-picker"
                                            label="Zeitpunkt"
                                            value={this.state.konsil.dateCreated? this.state.konsil.dateCreated: new Date().toLocaleString}
                                            onChange={(date: MaterialUiPickersDate, value?: string | null) => {
                                                if (date && date.toString() === "Invalid Date")
                                                    this.setState({isDatePickerError: true})
                                                else
                                                    this.setState({isDatePickerError: false});
                                                this.setState({
                                                    konsil: {
                                                        ...this.state.konsil,
                                                        dateCreated: date
                                                    }
                                                });
                                            }}
                                            format="dd.MM.yyyy HH:mm:ss"
                                            invalidDateMessage = {<Typography>Ungültiges Datumsformat</Typography>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box className="modal-footer">
                                    <Button
                                        variant="text"
                                        onClick = {this.handleClose}
                                    >
                                        Abbrechen
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.props.updateKonsil(this.state.konsil);
                                            this.handleClose();
                                        }}
                                        disabled = {this.state.isDatePickerError}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Paper>
                        </SimpleModalContainer>

                        <ConfirmationPopupComponent 
                            text="Wollen Sie das Konsil wirklich löschen?" 
                            isOpen={this.state.isOpenDeleteConfirm}
                            onClose={(event) => {event.stopPropagation(); this.setState({isOpenDeleteConfirm: false, deleteId: ""})}}
                            onConfirm={(event) => {event.stopPropagation(); this.props.dispatch_deleteKonsil(this.state.deleteId); this.setState({isOpenDeleteConfirm: false, deleteId: ""})}}/>        
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export const KonsilTableContainer = connector(KonsilTable);
export default KonsilTableContainer;

interface IState {
    sortValue: any
}

interface IProps {
}

export class TableSort extends React.Component<IProps, IState>  {
    constructor(props: IProps) {
        super(props);
        this.state = {
            sortValue: ""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            sortValue: event.target.value
        });
    };

    render() {
        return (
            <Tooltip title="sortieren nach" placement="top">
                <FormControl className="table-sort">
                    <Select
                        value={this.state.sortValue}
                        onChange={this.handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="">ID</MenuItem>
                        <MenuItem value="patient">Patient</MenuItem>
                        <MenuItem value="time">Zeitpunkt</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                        <MenuItem value="receiver">Adressat</MenuItem>
                    </Select>
                </FormControl>
            </Tooltip>
        );
    }
}


interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  }


export function TablePaginationActions (props:TablePaginationActionsProps)  {


    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, props.page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, props.page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1));
    };

        return (
        <>
            <IconButton
                className="first-button"
                onClick={handleFirstPageButtonClick}
                disabled={props.page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={props.page === 0}
            className="previous-button"
            aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
            onClick={handleNextButtonClick}
            disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
            aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
            onClick={handleLastPageButtonClick}
            disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
            aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </ >
        );
}


// interface TablePaginationActionsProps {
//     count: number;
//     page: number;
//     rowsPerPage: number;
//     onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
//   }


// export class TablePaginationActions extends React.Component<TablePaginationActionsProps>  {


//     handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         this.props.onChangePage(event, 0);
//     };

//     handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         this.props.onChangePage(event, this.props.page - 1);
//     };

//     handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         this.props.onChangePage(event, this.props.page + 1);
//     };

//     handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
//     };

//     render() {

//         return (
//         <>
//             <IconButton
//                 className="first-button"
//                 onClick={this.handleFirstPageButtonClick}
//                 disabled={this.props.page === 0}
//                 aria-label="first page"
//             >
//                 <FirstPageIcon />
//             </IconButton>
//             <IconButton onClick={this.handleBackButtonClick} disabled={this.props.page === 0}
//             className="previous-button"
//             aria-label="previous page">
//                 <KeyboardArrowLeft />
//             </IconButton>
//             <IconButton
//             onClick={this.handleNextButtonClick}
//             disabled={this.props.page >= Math.ceil(this.props.count / this.props.rowsPerPage) - 1}
//             aria-label="next page"
//             >
//                 <KeyboardArrowRight />
//             </IconButton>
//             <IconButton
//             onClick={this.handleLastPageButtonClick}
//             disabled={this.props.page >= Math.ceil(this.props.count / this.props.rowsPerPage) - 1}
//             aria-label="last page"
//             >
//                 <LastPageIcon />
//             </IconButton>
//         </ >
//         );
//     }
// }
