import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import { UserContentContainer, UserPopupContentContainer } from '../header';
// material components
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import {Notifications} from '@material-ui/icons';

import {Arzt, KonsilMetaInformation, Notification} from 'telescan-core';
import BellPopupContainer from './bell_popup';
import {RootState} from '../../redux/reducers';
import { loadAvatarImage } from '../../redux/actions';

// Avatars
import AvatarMale from '../../static/images/ic-doctor-m.svg';
import AvatarFemale from '../../static/images/ic-doctor-w.svg';
import { needsUpdate } from '../../utils/utils';
import { Drawer } from '@material-ui/core';



interface IState {
    anchorEl: any
    popoverOpen: boolean;
    bellPopoverOpen: boolean;
    id: string | undefined
    userName: string,
    avatar: string,
    lanr: string,
    bsnr: string,
    alerts: any[]
}

interface IProps {
    userDetails: Arzt[]
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    role: state.user.role,
    metadataAdressiert: state.dermatologe.metadatenAdressiert,
    sendFailures: state.konsile.sendFailures,
    location: state.router.location.pathname,
    betriebsstaettenAerzte: state.user.betriebsstaettenAerzte,
    arztZertifikateMetaInfo: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikatMetaInfo: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
    notifications: state.notifications.notifications,
    ...ownProps
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class UserComponent extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            anchorEl: null,
            popoverOpen: false,
            bellPopoverOpen: false,
            id: '',
            userName: "",
            avatar: "",
            lanr: "",
            bsnr: "",
            alerts: []
        };

        this.getAvatarAndUserInfo = this.getAvatarAndUserInfo.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleBellClick = this.handleBellClick.bind(this)
    }

    componentDidMount() {
        this.getAvatarAndUserInfo((this.props.userDetails.length === 1) ? this.props.userDetails[0]?.lanr || "" : "")
        this.updateAlerts();
    }

    getAvatarAndUserInfo(lanr: string) {
        if (lanr !== "") {
            let arzt: Arzt = this.props.userDetails.filter((item) => item.lanr === lanr)[0];
            //let arzt: Arzt = this.props.betriebsstaettenAerzte.filter((item) => item.lanr === lanr)[0];
            let avatar: string = "";
            if (arzt.avatar)
                avatar = loadAvatarImage(arzt.avatar);
            else 
                if (arzt.geschlecht ==="WEIBLICH")
                    avatar = AvatarFemale;
                else if (arzt.geschlecht ==="MAENNLICH")
                    avatar = AvatarMale;
                else 
                    avatar = ""
            this.setState({
                userName: [arzt.titel, arzt.vorname, arzt.nachname].join(" "),
                lanr: arzt.lanr || "",
                bsnr: arzt.bsnr || "",
                avatar: avatar
            })
        }
    }

    private updateAlerts() {
        let alerts: any[] = [];
        const normalNotifications: Notification[] = this.props.notifications.filter(notification => notification.notificationType === "NEWS" || notification.notificationType === "NOTE");
        if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
            this.props.metadataAdressiert.map((item: KonsilMetaInformation) => alerts.push({
                title: "Neues Konsil",
                displayContent: item.vorgangsId,
                content: item,
                type: "new_konsil"
            }));
        }
        this.props.sendFailures.map((item) => alerts.push({
            title: "Versand des Konsils mit der ID " + item.konsilID + " fehlgeschlagen",
            displayContent: item.failure.error,
            content: item,
            type: "failure"
        }));
        if (normalNotifications.length !== 0) {
            alerts.push({
                title: "Neue Benachrichtigungen",
                displayContent: `${normalNotifications.length} neue Hinweis(e) oder Neuigkeit(en) sind vorhanden, bitte klicken Sie hier, um sie zu sehen`,
                type: "notification"
            })
        }

        /*
        if ((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 3): false))
            alerts.push({
                title: "Zertifikat Erneuerung",
                displayContent: "Arztzertifikat muss erneuert werden!",
                type: "zertifikat"
            })
        if (needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 3))
            alerts.push({
                title: "Zertifikat Erneuerung",
                displayContent: "Betriebsstättenzertifikat muss erneuert werden!",
                type: "zertifikat"
            })
            */
    
        if (this.props.role === "DERMATOLOGE") {
            if (needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 3)) {
                alerts.push({
                    title: "Zertifikat Erneuerung",
                    displayContent: "Betriebsstättenzertifikat muss erneuert werden!",
                    type: "zertifikat"
                })
            }
            if ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 3): false) {
                alerts.push({
                    title: "Zertifikat Erneuerung",
                    displayContent: "Arztzertifikat muss erneuert werden!",
                    type: "zertifikat"
                })
            }
        }
    
        this.setState({
            alerts: alerts
        })
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.userDetails !== this.props.userDetails) {
            this.getAvatarAndUserInfo((this.props.userDetails.length === 1) ? this.props.userDetails[0].lanr || "" : "")
        }
        if (prevProps.metadataAdressiert !== this.props.metadataAdressiert || prevProps.sendFailures !== this.props.sendFailures || prevProps.arztZertifikateMetaInfo !== this.props.arztZertifikateMetaInfo || prevProps.bsZertifikatMetaInfo !== this.props.bsZertifikatMetaInfo || prevProps.notifications !== this.props.notifications) {
            this.updateAlerts();
        }
        if (prevProps.betriebsstaettenAerzte !== this.props.betriebsstaettenAerzte) {
            this.getAvatarAndUserInfo((this.props.userDetails.length === 1) ? this.props.userDetails[0].lanr || "" : "")
        }
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            popoverOpen: true,
            id: 'simple-popover'
        });
    };

    handleBellClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            bellPopoverOpen: true,
            id: 'simple-bell-popover'
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            popoverOpen: false,
            bellPopoverOpen: false,
            id: undefined
        });
    };


    render() {
        return (
            <div className="flex-row header-top-right">
                {(this.props.location !== "/settings" && this.props.location !== "/billing") &&
                <>
                    <Avatar className="notifications" onClick={this.handleBellClick}>
                        {this.state.alerts.length !== 0 &&
                            <Box className="notify-badge">
                                {this.state.alerts.length}
                            </Box>
                        }
                        <Notifications/>
                    </Avatar>
                    <Popover
                        id={this.state.id}
                        open={this.state.bellPopoverOpen}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        className="bell-popup-content"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <BellPopupContainer alerts={this.state.alerts} handleClose = {this.handleClose}/>
                    </Popover>
                </>}
                <Box className="user-content" aria-describedby={this.state.id} onClick={this.handleClick}>
                    <UserContentContainer userName={this.state.userName} 
                        avatar={this.state.avatar} 
                        lanr={this.state.lanr} 
                        bsnr={this.state.bsnr} />
                </Box>
                <Drawer className="user-drawer-wrapper" anchor="right" open={this.state.popoverOpen} onClose={this.handleClose}>
                    <UserPopupContentContainer userName={this.state.userName} 
                        avatar={this.state.avatar}
                        lanr={this.state.lanr}
                        bsnr={this.state.bsnr}/>
                </Drawer>
            </div>
        );
    }
}

export const UserContainer = connector(UserComponent);
export default UserContainer;
