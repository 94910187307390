import { Box, Button, Checkbox, FormControlLabel, Paper, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Failure, KonsilValidationBeforeSendStatusTypes } from 'telescan-core';
import { KonsilValidationBadge } from '../../elements/konsil_validation_badge';
import PdfInline from '../../elements/pdf_inline';
import SimpleModalContainer from '../../elements/simple_modal';
import ZertifikatPasswortComponent from '../../elements/zertifikat_passwort';

interface IVersandProps {
    openModal: boolean;
    password: string;
    pdfPreviewData: any;
    konsilId: string;
    toggleModal: () => void;
    handleVersenden: () => void;
    handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    dispatch_clearPdfString: () => void;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    isPdfLoading: boolean;
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
    hasPasswordChanged: boolean;
}

export class KonsiliarberichtVersandPopup extends React.Component<IVersandProps> {
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.openModal}>
                <Paper className="modal-wrapper reciever-pop">
                    <Box className="reciever-split">
                        <Box className="form-content">
                            <KonsilValidationBadge
                                konsilValidationStatus = {this.props.konsilValidationStatus || null}
                                konsilValidationError = {this.props.konsilValidationError}
                            />
                            <h2 className="modal-header">Konsiliarberichtversand</h2>
                            <Box className="modal-content">
                                {this.props.error &&
                                <Typography className="modal-content failure">{this.props.error}</Typography>
                                }
                                <Typography className="last-reciver-label" variant="body1" color="inherit">
                                    Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen Sie den
                                    Konsilauftrag (.pdf) auf Richtigkeit.
                                </Typography>
                                <ZertifikatPasswortComponent
                                    password = {(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                    handlePasswordChange={this.props.handlePasswordChange}
                                />
                                <Tooltip
                                    title = "Passwort für die aktuelle Sitzung speichern"
                                    placement = 'top'
                                >
                                    <FormControlLabel
                                        control =
                                            {<Checkbox
                                                name="comfort-versand-checkbox"
                                                checked = {this.props.isComfortVersand}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (!event.target.checked)
                                                        this.props.dispatch_setComfortVersandPassword("");
                                                    this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                }}
                                            />}
                                        label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                    />
                                </Tooltip>
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                        this.props.toggleModal();
                                        //this.props.resetPdfPreviewData();
                                        this.props.dispatch_clearPdfString();
                                    }}
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!this.props.pdfPreviewData || this.props.pdfPreviewData === "" || (this.props.password === "" && this.props.comfortVersandPassword === "") || this.props.error != null || this.props.konsilValidationStatus !== "OK"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.props.handleVersenden();
                                        this.props.dispatch_clearPdfString();
                                        if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                            this.props.dispatch_setComfortVersandPassword(this.props.password);
                                    }}
                                >
                                    Versenden
                                </Button>
                            </Box>
                        </Box>
                        <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
