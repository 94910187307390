import React from 'react';
// material components
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core'
import {checkCdaSize} from '../../utils';
import SimpleModalContainer from '../elements/simple_modal';
import {CdaSizeComponent} from '../elements/cda_size';
import PdfInline from '../elements/pdf_inline';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';
import {Failure, Konsil, KonsilValidationBeforeSendStatusTypes} from 'telescan-core';
import { KonsilValidationBadge } from '../elements/konsil_validation_badge';

interface IProps {
    isOpen: boolean;
    handleVersenden: () => void;
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    pdfPreviewData: string | null;
    handleClearPassword: () => void;
    toggleReplyPopup: () => void;
    resetPdfPreview: () => void;
    currentKonsil: Konsil;
    cdaSize: number;
    dispatch_setCdaSize: (cdaSize: number) => void;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    isPdfLoading: boolean;
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
    hasPasswordChanged: boolean;
}

export default class ReplyPopup extends React.Component<IProps> {
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper reciever-pop">
                    <Box className="reciever-split">
                        <form className="form-content" noValidate autoComplete="off">
                            <KonsilValidationBadge
                                konsilValidationStatus = {this.props.konsilValidationStatus || null}
                                konsilValidationError = {this.props.konsilValidationError}
                            />
                            <h2 className="modal-header">Rückfrage beantworten</h2>
                            <Box className="modal-content">
                                <CdaSizeComponent size={this.props.cdaSize} konsilStatus={this.props.currentKonsil.konsilStatus}/>
                                <Typography className="last-reciver-label pad-bottom" variant="body1" color="inherit">
                                    Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen
                                    Sie den Konsilauftrag (.pdf) auf Richtigkeit.
                                </Typography>
                                <ZertifikatPasswortComponent
                                    password={(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                    handlePasswordChange={this.props.handlePasswordChange}
                                />
                                 <Tooltip
                                    title = "Passwort für die aktuelle Sitzung speichern"
                                    placement = 'top'
                                >
                                    <FormControlLabel
                                        control =
                                            {<Checkbox
                                                name="comfort-versand-checkbox"
                                                checked = {this.props.isComfortVersand}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (!event.target.checked)
                                                        this.props.dispatch_setComfortVersandPassword("");
                                                    this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                }}
                                            />}
                                        label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                    />
                                </Tooltip>
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        this.props.dispatch_setCdaSize(0);
                                        this.props.toggleReplyPopup();
                                        this.props.resetPdfPreview();
                                    }}
                                >
                                    Zurück
                                </Button>
                                <Button variant="contained" color="primary" type="submit"
                                        disabled = {!this.props.pdfPreviewData || this.props.pdfPreviewData === "" ||
                                            (this.props.password === "" && this.props.comfortVersandPassword === "") ||
                                            !checkCdaSize(this.props.cdaSize, this.props.currentKonsil.konsilStatus) || this.props.konsilValidationStatus !== "OK"}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.dispatch_setCdaSize(0);
                                            this.props.handleVersenden();
                                            this.props.handleClearPassword();
                                            this.props.resetPdfPreview();
                                            if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                                this.props.dispatch_setComfortVersandPassword(this.props.password);
                                        }}>
                                    Absenden
                                </Button>
                            </Box>
                        </form>
                        <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
