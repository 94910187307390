import React from "react";
import {connect, ConnectedProps} from 'react-redux'
import { Box, Link, Paper, Typography } from "@material-ui/core";
import Company from '../../static/images/a_001.svg';
import Ref from '../../static/images/a_007.svg';
import Lot from '../../static/images/a_006.svg';
import Handbook from '../../static/images/t1_028.svg';
import CE from '../../static/images/ic_CE.svg';
import Legal from '../../static/images/ic_legal.svg';
import Support from '../../static/images/ic_support.svg';
import { RootState } from "../../redux/reducers";
import { Failure, TestVersion } from "telescan-core";
import { loadFailure, getInfo } from "../../redux/actions";

const mapStateToProps = (state: RootState) => ({
    kennzeichnung: state.user.kennzeichnung,
    fachrichtung: state.user.fachrichtung,
})

const mapDispatchToProps = {
    dispatch_loadFailure: (id:string, failure: Failure) => loadFailure(id, failure),
    dispatch_getInfo: () => getInfo(),
}


const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class Help extends React.Component<TPropsFromRedux> {


    render() {
        (this.props.kennzeichnung.software && this.props.kennzeichnung.software.versionLOT) || this.props.dispatch_getInfo()
        return (
            <Box className="help-content">
                <Box className="help-grid ">
                    <Paper className="flex-row-center pad icon-container">
                        <img src={Company} width="100" height="100" alt = ""/>
                        <Typography variant="body1">
                            <strong>{this.props.kennzeichnung?.firma?.name}</strong><br/>
                            {this.props.kennzeichnung?.firma?.street}<br/>
                            {`${this.props.kennzeichnung?.firma?.postalCode} ${this.props.kennzeichnung?.firma?.city}`}<br/>
                            {this.props.kennzeichnung.firma?.country}
                        </Typography>
                    </Paper>
                    {this.props.fachrichtung && this.props.fachrichtung === "DERMATOLOGE" &&
                        <Paper className="flex-row-center pad icon-container">
                            <img src={Ref} width="100" height="100" alt = ""/>
                            <Typography variant="body1">
                                <strong>Identifikation der Anwendung</strong><br/>
                                {this.props.kennzeichnung.software?.id}
                            </Typography>
                        </Paper>
                    }
                    <Paper className="flex-row-center pad icon-container">
                        <img src={Lot} width="100" height="100" alt = ""/>
                        <Typography variant="body1">
                            <strong>Versionsnummer</strong><br/>
                            {this.props.kennzeichnung.software?.versionLOT}<br/>
                            {this.props.kennzeichnung.software?.validityPeriod}
                        </Typography>
                    </Paper>
                    {this.props.fachrichtung && this.props.fachrichtung === "DERMATOLOGE" &&
                        <Paper className="flex-row-center pad icon-container">
                            <img src={Handbook} width="100" height="100" alt = ""/>
                            <Typography variant="body1">
                                <strong>Elektronische Gebrauchsanweisung</strong><br/>
                                <a href={"https://"+this.props.kennzeichnung.service?.website}>{this.props.kennzeichnung.service?.website}</a><br/>
                                Kostenlose Papierversion über<br/>
                                {this.props.kennzeichnung.service?.email}
                            </Typography>
                        </Paper>
                    }
                    <Paper className="flex-row-center pad icon-container">
                        <img src={Support} width="100" height="100" alt = ""/>
                        <Typography variant="body1">
                            <strong>Support</strong><br/>
                            Wir helfen Ihnen weiter unter: {this.props.kennzeichnung.service?.phone}<br/>
                            E-Mail: <a href="mailto:telescan@zollsoft.de">telescan@zollsoft.de</a><br/>
                            Supportzeiten: {this.props.kennzeichnung.service?.workingHour}
                        </Typography>
                    </Paper>
                    {!TestVersion.getInstance().getConstant() && (this.props.fachrichtung && this.props.fachrichtung === "DERMATOLOGE") &&
                        <Paper className="flex-row-center pad icon-container">
                            {<img src={CE} width="100" height="100" alt = ""/>}
                            <Typography variant="body1">
                                Dieses Produkt entspricht der Richtlinie 93/42/EWG des Rates über Medizinprodukte.
                            </Typography>
                        </Paper>
                    }
                    <Paper className="flex-row-center pad icon-container">
                        <img src={Legal} width="100" height="100" alt = ""/>
                        <Typography variant="body1">
                        <Link href="/impressum" variant="body1">
                            Impressum
                        </Link>&nbsp;
                        <Link href="/datenschutz" variant="body1">
                            Datenschutz
                        </Link>
                        </Typography>
                    </Paper>
                </Box>
            </Box>

        )
    }
}

export const HelpContainer = connector(Help);
export default HelpContainer;
