import * as GeneralTypes from '../types/general_types';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../reducers';
import {Action} from 'redux';
import {push} from 'connected-react-router';
import uuid from 'uuid';
import { HPMService, Failure, UserService, HpmServiceStatusType, ServerStatus, EnableNotifications } from 'telescan-core';
import { logout } from './login_actions';

//1. Pure Redux actions
export function loading(id: string): GeneralTypes.ILoading {
    return {
        type: GeneralTypes.EGeneralActions.LOADING,
        id: id
    }
}

export function loadFailure(id: string, failure: Failure): GeneralTypes.ILoadingFailure {
    return {
        type: GeneralTypes.EGeneralActions.LOADING_FAILURE,
        id: id,
        failure: failure
    }
}

export function removeLoadFailure(id: string): GeneralTypes.ILoadingFailureRemove {
    return {
        type: GeneralTypes.EGeneralActions.LOADING_FAILURE_REMOVE,
        id: id
    }
}

export function loadSuccess(id: string): GeneralTypes.ILoadingSuccess {
    return {
        type: GeneralTypes.EGeneralActions.LOADING_SUCCESS,
        id: id
    }
}

export function setSuccessMessage(message: string): GeneralTypes.ISetSuccessMessage {
    return {
        type: GeneralTypes.EGeneralActions.SET_SUCCESS_MESSAGE,
        message: message
    }
}

export function removeSuccessMessage(): GeneralTypes.ISuccessMessageRemove {
    return {
        type: GeneralTypes.EGeneralActions.SUCCESS_MESSAGE_REMOVE,
    }
}

export function refreshFailure(failure: string): GeneralTypes.IRefreshFailure {
    return {
        type: GeneralTypes.EGeneralActions.REFRESH_FAILURE,
        auth_failure: failure
    }
}

export function refreshSuccess(): GeneralTypes.IRefreshSuccess {
    return {
        type: GeneralTypes.EGeneralActions.REFRESH_SUCCESS,
    }
}

export function screenSizeChanged(screensize: number): GeneralTypes.IScreenSizeChanged {
    return {
        type: GeneralTypes.EGeneralActions.SCREENSIZE_CHANGED,
        isDesktop: screensize > 900
    }
}

export function setIsHpmAvailable(isHpmAvailable: boolean): GeneralTypes.ISetIsHpmAvailable {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_HPM_AVAILABLE,
        isHpmAvailable: isHpmAvailable,
    }
}

export function setHpmServiceStatus(hpmServiceStatus: keyof typeof HpmServiceStatusType): GeneralTypes.ISetHpmServiceStatus {
    return {
        type: GeneralTypes.EGeneralActions.SET_HPM_SERVICE_STATUS,
        hpmServiceStatus: hpmServiceStatus,
    }
}

export function setAccessTokenExpiration(currentDate: number): GeneralTypes.ISetAccessTokenExpiration {
    return {
        type: GeneralTypes.EGeneralActions.SET_ACCESS_TOKEN_EXPIRATION,
        currentDate: currentDate,
    }
}

export function setIsComfortVersand(isComfortVersand: boolean): GeneralTypes.ISetIsComfortVersand {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_COMFORT_VERSAND,
        isComfortVersand: isComfortVersand,
    }
}

export function setComfortVersandPassword(comfortVersandPassword: string): GeneralTypes.ISetComfortVersandPassword {
    return {
        type: GeneralTypes.EGeneralActions.SET_COMFORT_VERSAND_PASSWORD,
        comfortVersandPassword: comfortVersandPassword,
    }
}

export function setIsHpmConfigChanged(isHpmConfigChanged: boolean): GeneralTypes.ISetIsHpmConfigChanged {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_HPM_CONFIG_CHANGED,
        isHpmConfigChanged: isHpmConfigChanged
    }
}

export function setIsInitialisationStatusLoading(isInitialisationStatusLoading: boolean) {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_INITIALISATION_STATUS_LOADING,
        isInitialisationStatusLoading: isInitialisationStatusLoading
    }
}

export function setHasRiskNotification(hasRiskNotification: boolean) {
    return {
        type: GeneralTypes.EGeneralActions.SET_HAS_RISK_NOTIFICATION,
        hasRiskNotification: hasRiskNotification
    }
}

export function setIsSupportEnabled(isSupportEnabled: boolean): GeneralTypes.ISetIsSupportEnabled {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_SUPPORT_ENABLED,
        isSupportEnabled: isSupportEnabled
    }
}

export function setIsPending(isPending: boolean): GeneralTypes.ISetIsPending {
    return {
        type: GeneralTypes.EGeneralActions.SET_IS_PENDING,
        isPending: isPending,
    }
}
// 2. Thunk middleware functions

/*
function to save errors in redux
error in general object with:
    code: string,
    error: string,
    messages: string[],
*/
export function failure(id: string, inputError: any): ThunkAction<void, RootState, unknown, Action<string>> {
    let failureObj = new Failure();
    return (dispatch) => {
        if (inputError == null) {
            failureObj.error = "Ein unbekannter Fehler ist aufgetreten. Bitte überprufen Sie TeleScan Log.";
        }
        else if (typeof inputError==="string") {
            failureObj.error = inputError
        }
        else if (inputError.hasOwnProperty("code") && inputError.hasOwnProperty("error") && inputError.hasOwnProperty("messages")) {
            failureObj = inputError;
        }
        else if (inputError.hasOwnProperty("message")) {
            failureObj.error = String(inputError.message);
        }
        else if (inputError.hasOwnProperty("error")) {
            failureObj.error = String(inputError.error);
        }
        else {
            failureObj.error = String(inputError);
        }
        if (failureObj.error === "Authentifizierung fehlgeschlagen! Bitte melden Sie sich erneut an." || failureObj.error === "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."){

            dispatch(logout());
            dispatch(loadFailure(id, failureObj))
            dispatch(refreshFailure(failureObj.error));
        } else {
            dispatch(loadFailure(id, failureObj));
        }
    }
}

export function navigate(url: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => dispatch(push(url));
}

export function getHPMInfo(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        const id: string = uuid.v4();
        //dispatch(loading(id)); // results in uploading line by logging, not necessary to wait for hpm response

        const hpmService: HPMService = new HPMService();
        return new Promise((resolve, reject) => {
            hpmService.getHPMInfo().then(
                (response) => {
                    //dispatch(loadSuccess(id));
                    resolve(response);
                }
            ).catch(
                (error) => {
                    dispatch(failure(id, error));
                    reject(error);
                }
            );
        });
    }
}

export function getServerStatus(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const userService = new UserService();
        userService.getServerStatus().then((response: ServerStatus) => {
            if (!response.isInitialized)
                dispatch(navigate("/welcome"));
            if (new EnableNotifications().getConstant() && response.hasRiskNotification) {
                dispatch(setHasRiskNotification(true));
            }
        }).catch((error) => {
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function getIsHpmAvailableStatus(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const hpmService = new HPMService();
        hpmService.getHPMServiceStatus()
        .then((response: boolean) => {
            response ? dispatch(setHpmServiceStatus("RUNNING")) : dispatch(setHpmServiceStatus("NOT_RUNNING"));
            dispatch(setIsHpmAvailable(response))
        })
        .catch(error => {
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function startHpm(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setHpmServiceStatus("WAITING_FOR_SERVER_RESPONSE"));
        const hpmService = new HPMService();
        hpmService.startHpm()
        .then(() => {
            dispatch(setIsHpmAvailable(true));
        })
        .catch(error => {
            dispatch(setHpmServiceStatus("NOT_RUNNING"));
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function restartHpm(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setHpmServiceStatus("WAITING_FOR_SERVER_RESPONSE"));
        const hpmService = new HPMService();
        hpmService.restartHpm()
        .then(() => {
            dispatch(setIsHpmAvailable(true));
        })
        .catch(error => {
            dispatch(setHpmServiceStatus("RUNNING"));
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function stopHpm(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setHpmServiceStatus("WAITING_FOR_SERVER_RESPONSE"));
        const hpmService = new HPMService();
        hpmService.stopHpm()
        .then(() => {
            dispatch(setHpmServiceStatus("NOT_RUNNING"));
            dispatch(setIsHpmAvailable(false));
        })
        .catch(error => {
            dispatch(setHpmServiceStatus("RUNNING"));
            const failureId = uuid.v4();
            dispatch(failure(failureId, error));
        })
    }
}

export function hpmStatusEventListenerCallback(status: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        if (status === "started") {
            dispatch(setIsHpmAvailable(true));
            dispatch(setHpmServiceStatus("RUNNING"));
        }
        else if (status === "stopped") {
            dispatch(setIsHpmAvailable(false));
            dispatch(setHpmServiceStatus("NOT_RUNNING"));
        }
        else if (status === "error") {
            const failureId = uuid.v4();
            const failureObj = new Failure();
            failureObj.error = "Anfrage kann nicht abgeschlossen werden. Bitte versuchen sie es erneut";
            dispatch(failure(failureId, failureObj));
        }
    }
}

export function getSupportEnabledStatus(password: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const userService = new UserService();
        userService.getSupportEnabledStatus(password)
        .then(() => {
            dispatch(setIsSupportEnabled(true));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        })
    }
}

export function refreshKonsilStatus(konsil_id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const userService = new UserService();
        userService.refreshKonsilStatus(konsil_id)
        .then(response => {
            console.log(response);
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        })
    }
}
