import React from "react";
import {Konsil} from "telescan-core";
import {EditFragestellungContainer, ViewFragestellungContainer} from ".";

interface IProps {
    konsil: Konsil | null;
}

export class Fragestellung extends React.Component<IProps> {
    render() {
        if (this.props.konsil?.konsilStatus === "IN_ARBEIT" && this.props.konsil?.bearbeitungsStatus !== "UPLOAD")
            return <EditFragestellungContainer/>
        else
            return <ViewFragestellungContainer/>
    }
}
