import { Box, Button, Checkbox, FormControlLabel, Paper, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import SimpleModalContainer from '../elements/simple_modal';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';

interface IAbschlussProps {
    isOpen: boolean;
    password: string;
    toggleModal: () => void;
    handleVersenden: () => void;
    handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPasswort: string) => void; 
    hasPasswordChanged: boolean;
}

export default class KonsilabschlussPopup extends React.Component<IAbschlussProps> {

    render() {
        return (
            <SimpleModalContainer
                isOpen={this.props.isOpen}
                onClose={() => this.props.toggleModal()}
            >
                <Paper className="modal-wrapper">
                    <form className="form-content" noValidate autoComplete="off">
                        <h2 className="modal-header">Konsilabschluss</h2>
                        <Box className="modal-content">
                            <ZertifikatPasswortComponent
                                password={(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                handlePasswordChange={this.props.handlePasswordChange}
                            />
                            <Tooltip
                                title = "Passwort für die aktuelle Sitzung speichern"
                                placement = 'top'
                            >
                                <FormControlLabel
                                    control = 
                                        {<Checkbox
                                            name="comfort-versand-checkbox"
                                            checked = {this.props.isComfortVersand}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (!event.target.checked)
                                                    this.props.dispatch_setComfortVersandPassword("");
                                                this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                            }}
                                        />}
                                    label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                />
                            </Tooltip>
                        </Box>
                        <Box className="modal-footer">
                            <Button variant="text" color="primary" onClick={() => {
                                this.props.toggleModal();
                            }}>
                                Abbrechen
                            </Button>
                            <Tooltip title="Hiermit schließen Sie das Konsil ab und versenden Ihr Feedback."
                                     placement="top">
                                <Button variant="contained" color="primary" type="submit"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.toggleModal();
                                            this.props.handleVersenden();
                                            if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                                this.props.dispatch_setComfortVersandPassword(this.props.password);
                                        }}
                                        disabled={this.props.password === "" && this.props.comfortVersandPassword === ""}
                                >
                                    Abschließen
                                </Button>
                            </Tooltip>
                        </Box>
                    </form>
                </Paper>
            </SimpleModalContainer>
        )
    }
}