import React, {createRef} from 'react';
import {Box, Button, Paper, Tooltip, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import ReplyPopup from '../reply_popup';
import RueckfragePopup from '../dermatologeComponents/rueckfrage_popup';
import { InsertDriveFile } from '@material-ui/icons';
import {BearbeitungsStatusType, Konsil, KonsilImageType, KonsilStatusType, Rueckantwort, Failure,} from 'telescan-core';
import {
    addSendFailure,
    clearPdfString,
    deleteRueckantwortAttachment,
    deleteRueckantwortImage,
    downloadRueckantwortAttachment,
    failure,
    getCdaSize,
    getKonsilValidationStatus,
    getPdfString,
    handleVersandKonsil,
    loadArztKonsile,
    loadKonsil,
    navigate,
    setCdaSize,
    setComfortVersandPassword,
    setIsComfortVersand,
    setIsSendingKonsil,
    setSuccessMessage,
    updateKonsil,
    uploadRueckantwortAttachment,
    uploadRueckantwortImage
} from '../../../redux/actions';
import {StartSendPopup} from '../sending_results_popup';
import {Konsilrueckfrage} from 'telescan-core/lib/entities/konsil_rueckfrage';
import {displayAttachment } from '../../../utils';
import {FullScreenImageComponent} from './view_lokalisationcontent';
import RueckAntwortContentComponent from '../editComponents/edit_rueckantwort_content';
import uuid from 'uuid';

interface IState {
    isOpenReply: boolean;
    isOpenRueckfrage: boolean;
    isOpenNotification: boolean;
    isOpenImage: boolean;
    imageId: string | undefined;
    password: string;
    dateRecorded: number | undefined;
    hasPasswordChanged: boolean;
}

interface IProps {
    isReplyPopupOpen: boolean;
    toggleReplyPopup: () => void;
    createPdfPreview: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => void;
    pdfPreviewData: string;
    resetPdfPreview: () => void;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    konsile: state.konsile,
    rueckantwortImages: state.image_attachment.rueckantwortImages,
    rueckantwortAttachments: state.image_attachment.rueckantwortAttachments,
    location: state.router.location.pathname,
    isSendingKonsil: state.send_download_konsil.isSendingKonsil,
    isComfortVersand: state.general.isComfortVersand,
    comfortVersandPassword: state.general.comfortVersandPassword,
    cdaSize: state.konsile.cdaSize,
    pdfString: state.pdf.pdfString,
    isPdfLoading: state.pdf.isPdfLoading,
    konsilValidationStatus: state.konsile.konsilValidationStatus,
    konsilValidationError: state.konsile.konsilValidationError,
    ...ownProps,
})

const mapDispatchToProps = {
    dispatch_updateKonsil: (konsil: Konsil) => updateKonsil(konsil),
    dispatch_setSuccessMessage: (message: string) => setSuccessMessage(message),
    dispatch_addSendFailure: (konsilID: string, failureObj: Failure) => addSendFailure(konsilID, failureObj),
    downloadRueckantwortAttachment: (attachment_id: string) => downloadRueckantwortAttachment(attachment_id),
    dispatch_loadArztKonsile: () => loadArztKonsile(),
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_getCdaSize: (konsil_id: string) => getCdaSize(konsil_id),
    uploadImage: (konsil: Konsil, file: File, imageType: string, loadInstanceId: string) => uploadRueckantwortImage(konsil, file, imageType, loadInstanceId),
    deleteImage: (konsil: Konsil, imageId: string) => deleteRueckantwortImage(konsil, imageId),
    uploadAttachment: (file: File, loadInstanceId: string, rueckantwort_id: string) => uploadRueckantwortAttachment(rueckantwort_id, file, loadInstanceId),
    deleteAttachment: (attachment_id: string) => deleteRueckantwortAttachment(attachment_id),
    dispatch_handleVersandKonsil: (konsilId: string, password: string, eventText: string) => handleVersandKonsil(konsilId, password, eventText),
    dispatch_setIsSendingKonsil: (isSendingKonsil: boolean) => setIsSendingKonsil(isSendingKonsil),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => setIsComfortVersand(isComfortVersand),
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => setComfortVersandPassword(comfortVersandPassword),
    dispatch_setCdaSize: (size: number) => setCdaSize(size),
    dispatch_getPdfString: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getPdfString(konsilId, konsilStatus),
    dispatch_clearPdfString: () => clearPdfString(),
    dispatch_getKonsilValidationStatus: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getKonsilValidationStatus(konsilId, konsilStatus),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class ViewKonsiliarberichtFragen extends React.Component<TPropsFromRedux, IState> {
    private input = createRef<HTMLInputElement>();
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isOpenReply: false,
            isOpenRueckfrage: false,
            isOpenNotification: false,
            isOpenImage: false,
            imageId: undefined,
            password: "",
            dateRecorded: undefined,
            hasPasswordChanged: false,
        };
        this.toggleRueckfrageModal = this.toggleRueckfrageModal.bind(this);
        this.toggleImageModal = this.toggleImageModal.bind(this);

        this.handleVersendenAntwort = this.handleVersendenAntwort.bind(this);
        this.handleVersendenFrage = this.handleVersendenFrage.bind(this);
        this.closeStartSendPopup = this.closeStartSendPopup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.openImage = this.openImage.bind(this);
        this.handleClickPrevImg = this.handleClickPrevImg.bind(this);
        this.handleClickNextImg = this.handleClickNextImg.bind(this);
        this.renderSwitchArzt = this.renderSwitchArzt.bind(this);
        this.renderSwitchDerma = this.renderSwitchDerma.bind(this);
        this.handleClearPassword = this.handleClearPassword.bind(this);
    }

    toggleRueckfrageModal() {
        this.setState({
            isOpenRueckfrage: !this.state.isOpenRueckfrage
        })
    }

    toggleImageModal() {
        this.setState({
            isOpenImage: !this.state.isOpenImage
        })
    }

    handleVersendenAntwort() {
        if (this.props.currentKonsil.id) {
            this.toggleRueckfrageModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "")
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand der Rückantwort zum Konsil mit der ID");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand der Rückantwort zum Konsil mit der ID");
        }
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil ist fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(failureId, failureObj);
            this.toggleRueckfrageModal();
        }

    }

    handleVersendenFrage() {
        if (this.props.currentKonsil.id) {
            this.toggleRueckfrageModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged)
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand der Rückfrage zum Konsil mit der ID");
            else
                this.props.dispatch_handleVersandKonsil(this.props.currentKonsil.id, this.state.password, "Versand der Rückfrage zum Konsil mit der ID");
        }
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil ist fehlgeschlagen. Das Konsil hat keine ID!";
            this.props.dispatch_failure(failureId, failureObj);
            this.toggleRueckfrageModal();
        }
    }

    closeStartSendPopup() {
        //if (this.props.currentKonsil.id && this.props.location === "/konsil/" + this.props.currentKonsil.id) {
            // load konsil
            //this.props.dispatch_loadKonsil(this.props.currentKonsil.id);
        //}
        this.props.dispatch_navigate("/");
        this.props.dispatch_setIsSendingKonsil(false);
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({password: event.target.value});
        this.setState({hasPasswordChanged: true});
    }

    openImage(imageId: string) {
        this.setState({imageId: imageId},
            () => this.toggleImageModal());
    }

    handleClickPrevImg() {
        const id = this.state.imageId || "";
        const idArray = Object.keys(this.props.rueckantwortImages);
        const index = (idArray.indexOf(id) - 1 !== -1) ? idArray.indexOf(id) - 1 : idArray.length - 1
        this.setState({
            imageId: idArray[index]
        });
    };

    handleClickNextImg() {
        const id = this.state.imageId || "";
        const idArray = Object.keys(this.props.rueckantwortImages);
        const index = (idArray.indexOf(id) + 1 !== idArray.length) ? idArray.indexOf(id) + 1 : 0
        this.setState({
            imageId: idArray[index]
        });
    };

    handleClearPassword() {
        this.setState({password: ""});
    }

    renderSwitchArzt(konsilStatus: keyof typeof KonsilStatusType,
                     bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined,
                     konsilrueckfrage: Konsilrueckfrage | undefined,
                     rueckantwort: Rueckantwort | undefined) {

        if (konsilStatus === "BEAUFTRAGT" || (konsilStatus === "IN_ARBEIT" && bearbeitungsStatus === "UPLOAD"))
            return (
                <>
                    {/* <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                <Typography className="no-answer pad-bottom" variant="body1" color="inherit">
                    Noch keine Rückfrage erhalten
                </Typography> */}
                </>
            )
        else if (this.props.currentKonsil.konsilrueckfrage == null && (konsilStatus === "BEFUNDET" || konsilStatus === "ABGESCHLOSSEN"))
            return (
                <>
                    {/* <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                <Typography className="no-answer pad-bottom" variant="body1" color="inherit">
                    Keine Rückfrage erhalten
                </Typography> */}
                </>
            )
        else if (konsilStatus === "RUECKFRAGE")
            return (
                <Box className="question-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                    <Box className="question-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit">
                            {new Date(this.props.currentKonsil.konsilrueckfrage?.dateRueckfrage || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            <Typography style={{whiteSpace: 'pre-line'}}>{konsilrueckfrage?.freitext}</Typography>
                        </Paper>
                    </Box>

                    <RueckAntwortContentComponent
                        displayAttachment={(attachmentId: string) => displayAttachment(
                            attachmentId,
                            this.props.rueckantwortAttachments,
                            this.props.downloadRueckantwortAttachment,
                            this.props.dispatch_failure,
                            this.props.dispatch_setSuccessMessage
                        )}
                    />

                    <Button
                        className="grid-button left"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (this.props.currentKonsil.rueckantwort?.zusaetzlicheInformationen === "" || this.props.currentKonsil.rueckantwort?.zusaetzlicheInformationen === undefined || this.props.currentKonsil.rueckantwort?.zusaetzlicheInformationen == null) {
                                const failureId: string = uuid.v4();
                                const failure: Failure = new Failure();
                                failure.error = "Bitte geben Sie Ihre Antwort in das Antworttextfeld ein";
                                this.props.dispatch_failure(failureId, failure);
                            }
                            else {
                                this.props.toggleReplyPopup();
                                //this.props.createPdfPreview(this.props.currentKonsil.id || "", "BEANTWORTET")
                                this.props.dispatch_clearPdfString();
                                this.props.dispatch_getCdaSize(this.props.currentKonsil.id || "");
                                this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEANTWORTET");
                                this.props.dispatch_getKonsilValidationStatus(this.props.currentKonsil.id || "", "BEANTWORTET");
                            }
                        }}
                        disabled={bearbeitungsStatus === "UPLOAD"}
                    >
                        Antworten
                    </Button>
                    <ReplyPopup
                        isOpen={this.props.isReplyPopupOpen}
                        handleVersenden={this.handleVersendenAntwort}
                        handlePasswordChange={this.handlePasswordChange}
                        password={this.state.password}
                        handleClearPassword = {this.handleClearPassword}
                        pdfPreviewData = {this.props.pdfString}
                        resetPdfPreview = {this.props.dispatch_clearPdfString}
                        toggleReplyPopup = {this.props.toggleReplyPopup}
                        currentKonsil = {this.props.currentKonsil}
                        cdaSize = {this.props.cdaSize}
                        dispatch_setCdaSize = {this.props.dispatch_setCdaSize}
                        isComfortVersand = {this.props.isComfortVersand}
                        comfortVersandPassword = {this.props.comfortVersandPassword}
                        dispatch_setIsComfortVersand = {this.props.dispatch_setIsComfortVersand}
                        dispatch_setComfortVersandPassword = {this.props.dispatch_setComfortVersandPassword}
                        isPdfLoading = {this.props.isPdfLoading}
                        konsilValidationStatus = {this.props.konsilValidationStatus}
                        konsilValidationError = {this.props.konsilValidationError}
                        hasPasswordChanged = {this.state.hasPasswordChanged}
                    />
                </Box>
            )
        else // if (this.state.konsil.konsilStatus !== "IN_ARBEIT" && !(this.state.konsil.konsilrueckfrage == null && (this.state.konsil.konsilStatus === "BEFUNDET" || this.state.konsil.konsilStatus === "ABGESCHLOSSEN")))
            return (
                <Box className="question-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                    <Box className="question-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit">
                            {new Date(konsilrueckfrage?.dateRueckfrage || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            <Typography style={{whiteSpace: 'pre-line'}}>{konsilrueckfrage?.freitext}</Typography>
                        </Paper>
                    </Box>
                    <Box className="answer-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit">
                            Ihre Antwort: {new Date(rueckantwort?.dateAntwort || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            {rueckantwort?.zusaetzlicheInformationenHtml ?
                                <div dangerouslySetInnerHTML={{__html: rueckantwort?.zusaetzlicheInformationenHtml}}/> :
                                <Typography style={{whiteSpace: 'pre-line'}}>{rueckantwort?.zusaetzlicheInformationen}</Typography>
                            }
                        </Paper>
                        <Box className="attatchments">
                            {Object.keys(this.props.rueckantwortImages).map((imageId) => {

                                return(
                                <Tooltip key={imageId} title={this.props.rueckantwortImages[imageId].imageType}
                                         placement="top">
                                    <Paper className="img-preview"
                                           onClick={() => {
                                               this.openImage(imageId);

                                            }}
                                           style={{
                                               backgroundImage: `url(${this.props.rueckantwortImages[imageId].url})`,
                                               backgroundSize: 'cover',
                                               backgroundPosition: 'center'
                                           }}></Paper>
                                </Tooltip>)
                            }
                            )}
                            {Object.keys(this.props.rueckantwortAttachments).map((attachmentId) =>
                                <AttachmentCard
                                    key={attachmentId}
                                    toolTipTitle={this.props.rueckantwortAttachments[attachmentId].originalFilename}
                                    attachmentId={attachmentId}
                                    displayAttachment={(attachmentId: string) => displayAttachment(
                                        attachmentId,
                                        this.props.rueckantwortAttachments,
                                        this.props.downloadRueckantwortAttachment,
                                        this.props.dispatch_failure,
                                        this.props.dispatch_setSuccessMessage
                                    )}
                                    dispatch_setSuccessMessage = {this.props.dispatch_setSuccessMessage}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            )
    }

    renderSwitchDerma(konsilStatus: keyof typeof KonsilStatusType, bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined, konsilrueckfrage: Konsilrueckfrage | undefined, rueckantwort: Rueckantwort | undefined) {
        if (konsilStatus === "RUECKFRAGE")
            return (
                <Box className="question-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                    <Box className="question-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit">
                            Ihre Frage: {new Date(konsilrueckfrage?.dateRueckfrage || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            <Typography style = {{whiteSpace: 'pre-line'}}>{konsilrueckfrage?.freitext}</Typography>
                        </Paper>
                    </Box>
                    {/* <Button disabled className="grid-button left" variant="contained" color="primary">
                        Rückfrage gestellt
                    </Button> */}
                </Box>
            )
        else if (konsilrueckfrage?.freitext != null &&
            (konsilStatus === "BEFUNDET" || konsilStatus === "BEANTWORTET" ||
                konsilStatus === "ABGESCHLOSSEN" || konsilStatus === "ABGEBROCHEN"))
            return (
                <Box className="question-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                    <Box className="question-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit">
                            Ihre Frage: {new Date(konsilrueckfrage?.dateRueckfrage || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            <Typography style={{whiteSpace: 'pre-line'}}>{konsilrueckfrage?.freitext}</Typography>
                        </Paper>
                    </Box>
                    <Box className="answer-txt-container">
                        <Typography className="txt-label" variant="caption" color="inherit"
                                    style={{alignSelf: "flex-end"}}>
                            {new Date(rueckantwort?.dateAntwort || "").toLocaleString()}
                        </Typography>
                        <Paper className="pad">
                            {rueckantwort?.zusaetzlicheInformationenHtml ?
                                <div dangerouslySetInnerHTML={{__html: rueckantwort?.zusaetzlicheInformationenHtml}} /> :
                                <Typography style={{whiteSpace: 'pre-line'}}>{rueckantwort?.zusaetzlicheInformationen}</Typography>
                            }
                        </Paper>

                        <Box className="attatchments">
                            {Object.keys(this.props.rueckantwortImages).map((imageId) =>
                                <Tooltip key={imageId} title={this.props.rueckantwortImages[imageId].imageType}
                                         placement="top">
                                    <Paper className="img-preview"
                                           onClick={() => {
                                                this.setState({
                                                        imageId: imageId,
                                                        dateRecorded: this.props.currentKonsil.rueckantwort?.rueckantwortImages?.filter(item => item.id === imageId)[0].dateRecorded
                                                        },
                                                        () => this.toggleImageModal())
                                                    }}
                                           style={{
                                               backgroundImage: `url(${this.props.rueckantwortImages[imageId].url})`,
                                               backgroundSize: 'cover',
                                               backgroundPosition: 'center'
                                           }}>
                                    </Paper>
                                </Tooltip>
                            )}
                            {Object.keys(this.props.rueckantwortAttachments).map((attachmentId) =>
                                <AttachmentCard
                                    key={attachmentId}
                                    toolTipTitle="PDF"
                                    attachmentId={attachmentId}
                                    displayAttachment={(attachmentId: string) => displayAttachment(
                                        attachmentId,
                                        this.props.rueckantwortAttachments,
                                        this.props.downloadRueckantwortAttachment,
                                        this.props.dispatch_failure,
                                        this.props.dispatch_setSuccessMessage
                                    )}
                                    dispatch_setSuccessMessage = {this.props.dispatch_setSuccessMessage}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            )
        else if (konsilStatus === "BEAUFTRAGT") {
            return (
                <Box className="question-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Rückfrage
                    </Typography>
                    <Button className="grid-button left" variant="contained" color="primary"
                            onClick={this.toggleRueckfrageModal}
                            disabled={bearbeitungsStatus === "UPLOAD"}
                    >
                        Rückfrage stellen
                    </Button>
                </Box>
            )
        } else // (this.state.konsil.konsilStatus !== "RUECKFRAGE" && this.state.konsil.konsilStatus !== "BEANTWORTET" && this.state.konsil.konsilrueckfrage?.freitext == null && this.state.konsil.konsilStatus !== "ABGESCHLOSSEN" && this.state.konsil.konsilStatus !== "ABGEBROCHEN")
            return (<></>)
    }

    render() {

        const renderSwitch: (konsilStatus: keyof typeof KonsilStatusType, bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined, konsilrueckfrage: Konsilrueckfrage | undefined, rueckantwort: Rueckantwort | undefined) => any =
            (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") ? this.renderSwitchArzt : this.renderSwitchDerma;

        return (
            <>
                {renderSwitch(this.props.currentKonsil.konsilStatus, this.props.currentKonsil.bearbeitungsStatus, this.props.currentKonsil.konsilrueckfrage, this.props.currentKonsil.rueckantwort)}
                {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                    <RueckfragePopup
                        isOpen={this.state.isOpenRueckfrage}
                        toggleRueckfrageModal={() => {
                            this.toggleRueckfrageModal()
                        }}
                        handleVersenden={this.handleVersendenFrage}
                        handlePasswordChange={this.handlePasswordChange}
                        password={this.state.password}
                        dispatch_updateKonsil = {this.props.dispatch_updateKonsil}
                        currentKonsil = {this.props.currentKonsil}
                        isComfortVersand = {this.props.isComfortVersand}
                        comfortVersandPassword = {this.props.comfortVersandPassword}
                        dispatch_setIsComfortVersand = {this.props.dispatch_setIsComfortVersand}
                        dispatch_setComfortVersandPassword = {this.props.dispatch_setComfortVersandPassword}
                        hasPasswordChanged = {this.state.hasPasswordChanged}
                    />
                }
                {this.state.isOpenImage &&

                <FullScreenImageComponent
                    isActive={(this.state.isOpenImage)}
                    handleClose={() => this.setState({
                        isOpenImage: false,
                        imageId: undefined
                    })}
                    handleClickPrev={this.handleClickPrevImg}
                    handleClickNext={this.handleClickNextImg}
                    title={KonsilImageType[this.props.rueckantwortImages[this.state.imageId || ""]?.imageType]}
                    src={this.props.rueckantwortImages[this.state.imageId || ""]?.url}
                    alt={this.props.rueckantwortImages[this.state.imageId || ""]?.imageType}
                    //timeStamp={(!this.props.rueckantwortImages[this.state.imageId || ""]?.dateRecorded) ? "-" : new Date(this.props.rueckantwortImages[this.state.imageId || ""]?.dateRecorded).toLocaleString()}
                    timeStamp={!this.state.dateRecorded ? "-" : new Date(this.state.dateRecorded).toLocaleString()}
                />
                }
                <StartSendPopup open={this.props.isSendingKonsil} closeStartSendPopup={this.closeStartSendPopup}/>
            </>
        )
    }
}

export const ViewKonsiliarberichtFragenContainer = connector(ViewKonsiliarberichtFragen);
export default ViewKonsiliarberichtFragenContainer;


interface ICardProps {
    attachmentId: string;
    displayAttachment: (attachmentId: string, dispatch_setSuccessMessage: (message: string) => void) => void;
    toolTipTitle: string;
    dispatch_setSuccessMessage: (message: string) => void;
}

export class AttachmentCard extends React.Component<ICardProps> {

    render() {
        return (
            <Tooltip title={this.props.toolTipTitle} placement="top">
                <Paper
                    className="file-box"
                    onClick={() => {
                        this.props.displayAttachment(this.props.attachmentId, this.props.dispatch_setSuccessMessage);
                    }}
                >
                    <InsertDriveFile className="file-icon"/>
                </Paper>
            </Tooltip>
        )
    }
}
