import { Box, Typography, Paper, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core';
import React from 'react';

interface IProps{
    isSupportEnabled: boolean;
    dispatch_getSupportEnabledStatus: (password: string) => void;
    dispatch_setSupportEnabledStatus: (isSupportEnabled: boolean) => void;
}

interface IState {
    isDialog: boolean;
    password: string
}

export default class SupportSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state= {
            isDialog: false,
            password: "",
        }
    }
    onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {           
            this.setState({isDialog: true});
        } else 
            this.props.dispatch_setSupportEnabledStatus(false);
    }
    render() {
        return (
            <Box className="bsnr-info-container">
                <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Support-Einstellungen
                    </Typography>
                </Box>
                <Paper elevation={0}>
                
                <TableContainer className="icd10-table">
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            <TableRow key = 'status_update'>
                                <TableCell>Aktualisierung des Bearbeitungsstatus aktivieren</TableCell>
                                <TableCell align = 'right'>
                                    <Checkbox
                                        checked = {this.props.isSupportEnabled}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.onClick(event)}
                                        className = 'bsnr-info'
                                    />        
                                </TableCell>                          
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>         

                </Paper>
                <Dialog open = {this.state.isDialog}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Bitte geben Sie das Supportpasswort ein
                        </DialogContentText>
                        <TextField
                            type = "password"
                            name="password"
                            label="Support-passwort"
                            value={this.state.password}
                            onChange = {(e) => this.setState({password: e.target.value})}
                        >

                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant = 'outlined'
                            color = 'primary'
                            onClick = {() => {
                                this.setState({isDialog: false});
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant = 'contained'
                            color = 'primary'
                            onClick = {() => {
                                this.props.dispatch_getSupportEnabledStatus(this.state.password);
                                this.setState({isDialog: false});
                            }}
                        >
                            Einsenden
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }
}