import React from 'react';
// material components
import {Box, Button, Checkbox, FormControlLabel, Paper, TextField, Tooltip, Typography} from '@material-ui/core'
import {Konsil} from 'telescan-core';
import SimpleModalContainer from '../../elements/simple_modal';
import ZertifikatPasswortComponent from '../../elements/zertifikat_passwort';


interface IState {
    konsil: Konsil;
}

interface IProps {
    isOpen: boolean;
    toggleRueckfrageModal: () => void;
    handleVersenden: () => void;
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    dispatch_updateKonsil: (konsil: Konsil) => void;
    currentKonsil: Konsil;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersnad: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    hasPasswordChanged: boolean;
}


export default class RueckfragePopup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            konsil: this.props.currentKonsil || new Konsil(),
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil(),
            });
        }
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper konsil-question-pop">
                    <form className="form-content" noValidate autoComplete="off">
                        <Box className="modal-content">
                            <h2>Rückfrage</h2>
                            <TextField
                                required
                                error={this.state.konsil.konsilrueckfrage?.freitext == null || this.state.konsil.konsilrueckfrage?.freitext === ""}
                                placeholder="Rückfragetext"
                                multiline
                                variant="filled"
                                minRows = {3}
                                maxRows={6}
                                value={this.state.konsil.konsilrueckfrage?.freitext || ""}
                                onChange={(event) => {
                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsilrueckfrage: {
                                                ...this.state.konsil.konsilrueckfrage,
                                                freitext: event.target.value
                                            }
                                        }
                                    });
                                }}
                                onBlur={() =>  this.props.dispatch_updateKonsil(this.state.konsil)}
                            />
                            <ZertifikatPasswortComponent
                                password={(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                handlePasswordChange={this.props.handlePasswordChange}
                            />
                            <Tooltip
                                title = "Passwort für die aktuelle Sitzung speichern"
                                placement = 'top'
                            >
                                <FormControlLabel
                                    control =
                                        {<Checkbox
                                            name="comfort-versand-checkbox"
                                            checked = {this.props.isComfortVersand}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (!event.target.checked)
                                                    this.props.dispatch_setComfortVersandPassword("");
                                                this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                            }}
                                        />}
                                    label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                />
                            </Tooltip>
                        </Box>
                        <Box className="modal-footer">
                            <Button variant="text" onClick={() => this.props.toggleRueckfrageModal()}>
                                Zurück
                            </Button>
                            <Button
                                disabled={this.state.konsil.konsilrueckfrage?.freitext == null ||
                                this.state.konsil.konsilrueckfrage?.freitext === "" ||
                                (this.props.password === "" && this.props.comfortVersandPassword === "")}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.handleVersenden();
                                    if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                        this.props.dispatch_setComfortVersandPassword(this.props.password);
                                }}>
                                Absenden
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
