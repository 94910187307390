import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';
import {
    Konsil,
    KonsilService,
    Patient,
    Arzt,
    HPMService,
    PatientNumber,
    Failure,
    BearbeitungsStatusType,
    KonsilStatusType,
    KonsilSortSearchFilterParams,
    KonsilUpdateConflictData,
} from 'telescan-core';
import * as KonsilTypes from '../types/konsil_types';
import {RootState} from '../reducers';
import { loadSuccess, setIsPending} from '.';
import { loading, failure } from './general_actions';
//import { checkVernetzungStatus } from './patient_actions';
import { Patienteneinwilligung } from 'telescan-core/lib/entities/patienteneinwilligung';
import { checkKonsilPatientStatus, checkKonsilStatus } from './evaluation_actions';
import { TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import { clearAttachmentList, clearKonsilImageList, clearRueckantwortAttachmentList, clearRueckantwortImageList, updateLoadedKonsil } from './image_attachment_actions';

// 1. Pure redux actions
export function addKonsil(konsil: Konsil): KonsilTypes.IAddKonsilAction {
    return {
        type: KonsilTypes.EKonsilActions.ADD_KONSIL,
        konsil: konsil
    }
}

export function removeKonsil(id: string): KonsilTypes.IRemoveKonsilAction {
    return {
        type: KonsilTypes.EKonsilActions.REMOVE_KONSIL,
        id: id
    }
}

export function setKonsile(konsile: Konsil[]): KonsilTypes.ISetKonsileAction {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSILE,
        konsile: konsile
    }
}

export function setKonsil(konsil: Konsil): KonsilTypes.ISetKonsilAction {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL,
        konsil: konsil
    }
}

export function setTotalSize(totalSize: number): KonsilTypes.ISetKonsileTotalSizeAction {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSILE_TOTAL_SIZE,
        totalSize: totalSize
    }
}

export function changeKonsilTab(index: number): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        //dispatch(loadKonsil(konsil_id));
        dispatch({
            type: KonsilTypes.EKonsilActions.TAB_CHANGED,
            tabIndex: index
        });
    };
}

export function setCdaSize(size: number): KonsilTypes.ISetCdaSizeAction {
    return {
        type: KonsilTypes.EKonsilActions.SET_CDA_SIZE,
        cdaSize: size
    }
}

export function addSendFailure(konsilID: string, failure: Failure): KonsilTypes.IAddSendFailure {
    return {
        type: KonsilTypes.EKonsilActions.ADD_SEND_FAILURE,
        konsilID: konsilID,
        failure: failure
    }
}

export function removeSendFailure(konsilID: string): KonsilTypes.IRemoveSendFailure {
    return {
        type: KonsilTypes.EKonsilActions.REMOVE_SEND_FAILURE,
        konsilID: konsilID
    }
}

export function setCurrentKonsilBearbeitungsStatus(bearbeitungsStatus: keyof typeof BearbeitungsStatusType): KonsilTypes.ISetCurrentKonsilBearbeitungsStatus {
    return {
        type: KonsilTypes.EKonsilActions.SET_CURRENT_KONSIL_BEARBEITUNGSSTATUS,
        bearbeitungsStatus: bearbeitungsStatus,
    }
}

export function setKonsilBearbeitungsStatus(konsilId: string, bearbeitungsStatus: keyof typeof BearbeitungsStatusType): KonsilTypes.ISetKonsilBearbeitungsStatus {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL_BEARBEITUNGSSTATUS,
        konsilId: konsilId,
        bearbeitungsStatus: bearbeitungsStatus,
    }
}

export function setKonsilStatus(konsilId: string, konsilStatus: keyof typeof KonsilStatusType): KonsilTypes.ISetKonsilStatus {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL_STATUS,
        konsilId: konsilId,
        konsilStatus: konsilStatus,
    }
}

export function setKonsilTherapyDringlichkeit(konsilId: string, therapyDringlichkeit: keyof typeof TherapieDringlichkeit): KonsilTypes.ISetKonsilTherapyDringlichkeit {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL_THERAPY_DRINGLICHKEIT,
        konsilId: konsilId,
        therapyDringlichkeit: therapyDringlichkeit,
    }
}

export function setCurrentKonsilTherapyDringlichkeit(therapyDringlichkeit: keyof typeof TherapieDringlichkeit): KonsilTypes.ISetCurrentKonsilTherapyDringlichkeit {
    return {
        type: KonsilTypes.EKonsilActions.SET_CURRENT_KONSIL_THERAPY_DRINGLICHKEIT,
        therapyDringlichkeit: therapyDringlichkeit,
    }
}

export function setIsKonsilDeleted(isKonsilDeleted: boolean): KonsilTypes.ISetIsKonsilDeleted {
    return {
        type: KonsilTypes.EKonsilActions.SET_IS_KONSIL_DELETED,
        isKonsilDeleted: isKonsilDeleted,
    }
}

export function setKonsilSortSearchFilterParams(konsilSetSortSearchFilterParams: KonsilSortSearchFilterParams): KonsilTypes.ISetKonsilSortSearchFilterParams {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL_SORT_SEARCH_FILTER_PARAMS,
        konsilSortSearchFilterParams: konsilSetSortSearchFilterParams,
    }
}

export function setIsKonsilUpdatedEventShown(isKonsilUpdatedEventShown: boolean): KonsilTypes.ISetIsKonsilUpdatedEventShown {
    return {
        type: KonsilTypes.EKonsilActions.SET_IS_KONSIL_UPDATED_EVENT_SHOWN,
        isKonsilUpdatedEventShown: isKonsilUpdatedEventShown,
    }
}

export function setIsKonsilUpdateEventSuccess(isKonsilUpdateEventSuccess: boolean): KonsilTypes.ISetIsKonsilUpdateEventSuccess {
    return {
        type: KonsilTypes.EKonsilActions.SET_IS_KONSIL_UPDATE_EVENT_SUCCESS,
        isKonsilUpdateEventSuccess: isKonsilUpdateEventSuccess
    }
}

export function setIsKonsilUpdateConflict(isKonsilUpdateConflict: boolean): KonsilTypes.ISetIsKonsilUpdateConflict {
    return {
        type: KonsilTypes.EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT,
        isKonsilUpdateConflict: isKonsilUpdateConflict
    }
}

export function setIsKonsilUpdateConflictDialog(isKonsilUpdateConflictDialog: boolean): KonsilTypes.ISetIsKonsilUpdateConflictDialog {
    return {
        type: KonsilTypes.EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT_DIALOG,
        isKonsilUpdateConflictDialog: isKonsilUpdateConflictDialog
    }
}

export function setKonsilUpdateConflictData(konsilUpdateKonflictData: KonsilUpdateConflictData[]): KonsilTypes.ISetKonsilUpdatedConflictData {
    return {
        type: KonsilTypes.EKonsilActions.SET_KONSIL_UPDATE_CONFLICT_DATA,
        konsilUpdateConflictData: konsilUpdateKonflictData,
    }
}

// 2. Thunk middleware functions

export function createKonsil(patient: Patient, beauftragender_arzt: Arzt): ThunkAction<Promise<Konsil>, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const id: string = uuid.v4();
        dispatch(loading(id));
        const konsilService: KonsilService = new KonsilService();
        return new Promise((resolve, reject) => {
            konsilService.create(patient, beauftragender_arzt, getState().webSocket.clientId).then(
                (konsil) => {
                    dispatch(loadSuccess(id));
                    dispatch({
                        type: KonsilTypes.EKonsilActions.ADD_KONSIL,
                        konsil: konsil
                    });
                    //dispatch(loadKonsile());
                    resolve(konsil);
                }
            ).catch(
                (response) => {
                    dispatch(failure(id, response));
                    //reject(response);
                }
            );
        });
    };
}

export function changeKonsilTablePage(page: number): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
        konsilSortSearchFilterParams.pageNb = page;
        dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        dispatch(loadArztKonsile());
    };
}

export function changeKonsilTabelRowsPerPage(rowsPerPage: number): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
        konsilSortSearchFilterParams.numberPerPage = rowsPerPage;
        konsilSortSearchFilterParams.pageNb = 0;
        dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        dispatch(loadArztKonsile());
    };
}

export function loadArztKonsile(propertyName?: string, direction?: "asc" | "desc" | "default"): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch(clearKonsilImageList());
        dispatch(clearAttachmentList());
        dispatch(clearRueckantwortImageList());
        dispatch(clearRueckantwortAttachmentList());
        if (propertyName && direction) {
            const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
            konsilSortSearchFilterParams.propertyName = propertyName;
            konsilSortSearchFilterParams.direction = direction;
            dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        }
        const id: string = uuid.v4();
        dispatch(loading(id));
        const konsilService: KonsilService = new KonsilService();
        const patientNumber: PatientNumber = PatientNumber.getInstance();
        if (patientNumber.getConstant("PATIENT_NUMBER")!=="") {
            return konsilService.getPatientKonsile(patientNumber.getConstant("PATIENT_NUMBER"), getState().konsile.konsilSortSearchFilterParams.pageNb, getState().konsile.konsilSortSearchFilterParams.numberPerPage, getState().konsile.konsilSortSearchFilterParams.search, getState().konsile.konsilSortSearchFilterParams.konsilStatus, getState().konsile.konsilSortSearchFilterParams.propertyName, getState().konsile.konsilSortSearchFilterParams.direction)
            .then(
                (konsile) => {
                    dispatch(loadSuccess(id));
                    dispatch(setKonsile(konsile.content ? konsile.content : []));
                    dispatch(setTotalSize(konsile.totalSize || -1))
                }
            ).catch(
                (response) => {
                    dispatch(failure(id, response));
                }
            );
        }
        else {
            return konsilService.getKonsile(getState().konsile.konsilSortSearchFilterParams.pageNb, getState().konsile.konsilSortSearchFilterParams.numberPerPage, getState().konsile.konsilSortSearchFilterParams.search, getState().konsile.konsilSortSearchFilterParams.konsilStatus, getState().konsile.konsilSortSearchFilterParams.propertyName, getState().konsile.konsilSortSearchFilterParams.direction)
            .then(
                (konsile) => {
                    dispatch(loadSuccess(id));
                    dispatch(setKonsile(konsile.content ? konsile.content : []));
                    dispatch(setTotalSize(konsile.totalSize || -1))
                }
            ).catch(
                (response) => {
                    dispatch(failure(id, response));
                }
            );
        }
    }
}

export function loadKonsil(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));
        const konsilService: KonsilService = new KonsilService();
        return konsilService.query(id).then(
            (konsil) => {
                if (konsil){
                    if (konsil.statusAenderung === true) {
                        konsil.statusAenderung = false;
                        dispatch(updateKonsil(konsil, undefined, loadInstanceId));
                    } else {
                        dispatch(updateLoadedKonsil(konsil, loadInstanceId))}

                    if (konsil?.konsilStatus === "IN_ARBEIT" || konsil?.konsilStatus === "BEAUFTRAGT")
                        dispatch({
                            type: KonsilTypes.EKonsilActions.TAB_CHANGED,
                            tabIndex: 0
                        });
                    else{
                        dispatch({
                            type: KonsilTypes.EKonsilActions.TAB_CHANGED,
                            tabIndex: 4
                        });}
                }
            }
        ).catch(
            (response) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(failure(loadInstanceId, response));
            }
        );
    }
}

export function updateKonsilOnEvent(konsilId: string):  ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        const konsilService: KonsilService = new KonsilService();
        return konsilService.query(konsilId)
        .then((konsil: Konsil) => {
            if (konsil) {
                dispatch(updateLoadedKonsil(konsil, loadInstanceId));
                dispatch(checkKonsilStatus(getState().konsile.current_konsil, getState().image_attachment.konsilImages));
            }
        })
        .catch((failureObj: Failure) => dispatch(failure(loadInstanceId, failureObj)));
    }
}

export function deleteKonsil(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch(removeKonsil(id));
        const konsilService: KonsilService = new KonsilService();
        konsilService.delete(id, getState().webSocket.clientId).catch(
            (error) => {
                const failureId: string = uuid.v4();
                dispatch(failure(failureId, error));
            }
        );
    }
}

export function updateKonsil(konsil: Konsil, previousKonsil?: Konsil, loadInstanceId?: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        loadInstanceId && dispatch(loadSuccess(loadInstanceId));
        const id: string = loadInstanceId || uuid.v4();
        if (!konsil) {
            const error = new Failure();
            error.error = "url oder accessToken leer, kann den WebSocket nicht verbinden!";
            dispatch(failure(id, error))
        }
        
        const konsilService: KonsilService = new KonsilService();
        
        if (previousKonsil && previousKonsil !== undefined) {
            return new Promise((resolve, reject) => {
                konsilService.updateKonsil(konsil, previousKonsil, getState().webSocket.clientId)
                .then(konsil => {
                    dispatch(setIsPending(false));
                    dispatch(updateLoadedKonsil(konsil, id));                         
                    dispatch(checkKonsilStatus(getState().konsile.current_konsil, getState().image_attachment.konsilImages));
                    resolve(konsil);
                })
                .catch(error=> {
                    dispatch(setIsPending(false));
                    dispatch(failure(id, error));
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                konsilService.updateKonsil(konsil)
                .then(konsil => {
                    dispatch(setIsPending(false));
                    dispatch(updateLoadedKonsil(konsil, id));
                    resolve(konsil);
                })
                .catch(error=> {
                    dispatch(setIsPending(false));
                    dispatch(failure(id, error));
                });
            });
        }

    }
}

export function getKonsileStatusChanges(...args: any[]): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        //dispatch(loading(loadInstanceId));

        const konsilService: KonsilService = new KonsilService();
        konsilService.getKonsilStatusChanges().catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    }
}

export function updateKonsilPatient(konsil_id: string, patient: Patient | null, patientenEinwilligung: Patienteneinwilligung, id?: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = id || uuid.v4();
        let error = new Failure();
        if (konsil_id === null) {
            error.error = "Kann Daten nicht ändern, da die Konsil-ID null ist...";
            dispatch(failure(loadInstanceId, error));
        }
        else if (patient === null) {
            error.error = "Kann Daten nicht ändern, Patientdaten sind nicht vorhanden...";
            dispatch(failure(loadInstanceId, error));
        }
        else {
            dispatch(checkKonsilPatientStatus(patient, patientenEinwilligung, konsil_id));
            dispatch({
                type: KonsilTypes.EKonsilActions.UPDATE_KONSIL_PATIENT,
                patient: patient
            });
            //dispatch(checkKonsilPatientStatus(patient));
            const konsilService: KonsilService = new KonsilService();
            konsilService.updateKonsilPatient(konsil_id, patient).then(
                (response) => {
                    /*dispatch({
                        type: KonsilTypes.EKonsilActions.UPDATE_KONSIL_PATIENT,
                        patient: response
                    });
                    dispatch(checkKonsilPatientStatus(response)); */
                }
            ).catch((response) => dispatch(failure(loadInstanceId, response)));
        }
    }
}

export function getCdaSize(konsil_id: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const hpmService = new HPMService();
        return new Promise((resolve, reject) => {
            hpmService.getCdaSize(konsil_id).then((response) => {
                dispatch(setCdaSize(response))
                resolve(response)
            }).catch((response) => {
                dispatch(failure(loadInstanceId, response))
                //reject(response)
            });
        })
    }
}

export function searchKonsile(searchString: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch({
            type: KonsilTypes.EKonsilActions.SET_KONSIL_SEARCH_STRING,
            searchString: searchString,
        });
        const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
        konsilSortSearchFilterParams.pageNb = 0;
        dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        dispatch(loadArztKonsile());
    }
}

export function filterKonsile(konsilStatus: (keyof typeof KonsilStatusType)[]): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch({
            type: KonsilTypes.EKonsilActions.SET_KONSIL_STATUS_FILTER,
            konsilStatusFilter: konsilStatus,
        });
        const konsilSortSearchFilterParams: KonsilSortSearchFilterParams = getState().konsile.konsilSortSearchFilterParams;
        konsilSortSearchFilterParams.pageNb = 0;
        dispatch(setKonsilSortSearchFilterParams(konsilSortSearchFilterParams));
        dispatch(loadArztKonsile());
    }
}

export function resetKonsilSearchFilter(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch({
            type: KonsilTypes.EKonsilActions.SET_KONSIL_SEARCH_STRING,
            searchString: "",
        })
        dispatch({
            type: KonsilTypes.EKonsilActions.SET_KONSIL_STATUS_FILTER,
            konsilStatusFilter: [],
        })
        dispatch(loadArztKonsile());
    }
}

export function getKonsilValidationStatus(konsilId: string, konsilStatus: keyof typeof KonsilStatusType): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: KonsilTypes.EKonsilActions.SET_KONSIL_VALIDATION_STATUS,
                konsilValidationStatus: "LOADING",
                konsilValidationError: "",
            });
            const konsilService: KonsilService = new KonsilService();
            konsilService.validateKonsilBeforeSend(konsilId, konsilStatus)
            .then(() => {
                resolve (
                    dispatch({
                        type: KonsilTypes.EKonsilActions.SET_KONSIL_VALIDATION_STATUS,
                        konsilValidationStatus: "OK",
                        konsilValidationError: "",
                    })
                )
            })
            .catch((failureObj: Failure) => {
                dispatch({
                    type: KonsilTypes.EKonsilActions.SET_KONSIL_VALIDATION_STATUS,
                    konsilValidationStatus: "ERROR",
                    konsilValidationError: failureObj,
                });
                dispatch(failure(uuid.v4(), failureObj));
            })
        })
       
    }
}

// function checkEinwilligung(patientenEinwilligung: Patienteneinwilligung) {
//     const errors = new Map<string, string>();
//     if (!(patientenEinwilligung.einwilligungKonsil))
//         errors.set("konsil.einwilligungKonsil", "Die Patienteneinwilligung muss gegeben werden!");
//     if (errors.size !== 0)
//         errors.set("einwilligung", "Die Patienteneinwilligung muss gegeben werden!");
//     return errors;
// }



