import {BearbeitungsStatusType, Failure, Konsil, KonsilSortSearchFilterParams, KonsilStatusType, KonsilValidationBeforeSendStatusTypes, Patient, KonsilUpdateConflictData} from "telescan-core";
import { TherapieDringlichkeit } from "telescan-core/lib/entities/konsil_abschluss";

export enum EKonsilActions {
    ADD_KONSIL = "ADD_KONSIL",
    REMOVE_KONSIL = "REMOVE_KONSIL",
    SET_KONSILE = "SET_KONSILE",
    SET_KONSIL = "SET_KONSIL",
    UPDATE_KONSIL_PATIENT = "UPDATE_KONSIL_PATIENT",
    TAB_CHANGED = "TAB_CHANGED",
    SET_KONSILE_TOTAL_SIZE = "SET_KONSILE_TOTAL_SIZE",
    ADD_SEND_FAILURE = "ADD_SEND_FAILURE",
    REMOVE_SEND_FAILURE = "REMOVE_SEND_FAILURE",
    SET_CDA_SIZE = "SET_CDA_SIZE",
    SET_CURRENT_KONSIL_BEARBEITUNGSSTATUS = "SET_CURRENT_KONSIL_BEARBEITUNGSSTATUS",
    SET_KONSIL_BEARBEITUNGSSTATUS = "SET_KONSIL_BEARBEITUNGSSTATUS",
    SET_KONSIL_STATUS = "SET_KONSIL_STATUS",
    SET_KONSIL_THERAPY_DRINGLICHKEIT = "SET_KONSIL_THERAPY_DRINGLICHKEIT",
    SET_CURRENT_KONSIL_THERAPY_DRINGLICHKEIT = "SET_CURRENT_KONSIL_THERAPY_DRINGLICHKEIT",
    SET_IS_KONSIL_DELETED = "SET_IS_KONSIL_DELETED",
    SET_KONSIL_SORT_SEARCH_FILTER_PARAMS = "SET_KONSIL_SORT_SEARCH_FILTER_PARAMS",
    SET_KONSIL_STATUS_FILTER = "SET_KONSIL_STATUS_FILTER",
    SET_KONSIL_SEARCH_STRING = "SET_KONSIL_SEARCH_STRING",
    SET_KONSIL_VALIDATION_STATUS = "SET_KONSIL_VALIDATION_STATUS",
    SET_IS_KONSIL_UPDATED_EVENT_SHOWN = "SET_IS_KONSIL_UPDATED_EVENT_SHOWN",
    SET_IS_KONSIL_UPDATE_EVENT_SUCCESS = "SET_IS_KONSIL_UPDATE_EVENT_SUCCESS",
    SET_IS_KONSIL_UPDATE_CONFLICT = "SET_IS_KONSIL_UPDATE_CONFLICT",
    SET_IS_KONSIL_UPDATE_CONFLICT_DIALOG = "SET_IS_KONSIL_UPDATE_CONFLICT_DIALOG",
    SET_KONSIL_UPDATE_CONFLICT_DATA = "SET_KONSIL_UPDATE_CONFLICT_DATA",
    SET_KONSIL_UPDATE_CONFLICT_SELECTED_DATA = "SET_KONSIL_UPDATE_CONFLICT_SELECTED_DATA",
}

export interface IAddKonsilAction {
    readonly type: EKonsilActions.ADD_KONSIL;
    readonly konsil: Konsil;
}

export interface IRemoveKonsilAction {
    readonly type: EKonsilActions.REMOVE_KONSIL;
    readonly id: string;
}

export interface ISetKonsileAction {
    readonly type: EKonsilActions.SET_KONSILE;
    readonly konsile: Konsil[];
}

export interface ISetKonsilAction {
    readonly type: EKonsilActions.SET_KONSIL;
    readonly konsil: Konsil;
}

export interface ITabChangedAction {
    readonly type: EKonsilActions.TAB_CHANGED;
    readonly tabIndex: number;
}

export interface IUpdateKonsilPatient {
    readonly type: EKonsilActions.UPDATE_KONSIL_PATIENT;
    readonly patient: Patient;
}

export interface ISetKonsileTotalSizeAction {
    readonly type: EKonsilActions.SET_KONSILE_TOTAL_SIZE;
    readonly totalSize: number;
}

export interface IAddSendFailure {
    readonly type: EKonsilActions.ADD_SEND_FAILURE;
    readonly konsilID: string;
    readonly failure: Failure;
}

export interface IRemoveSendFailure {
    readonly type: EKonsilActions.REMOVE_SEND_FAILURE;
    readonly konsilID: string;
}

export interface ISetCdaSizeAction {
    readonly type: EKonsilActions.SET_CDA_SIZE;
    readonly cdaSize: number;
}

export interface ISetCurrentKonsilBearbeitungsStatus {
    readonly type: EKonsilActions.SET_CURRENT_KONSIL_BEARBEITUNGSSTATUS;
    readonly bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
}

export interface ISetKonsilBearbeitungsStatus {
    readonly type: EKonsilActions.SET_KONSIL_BEARBEITUNGSSTATUS;
    readonly konsilId: string;
    readonly bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
}

export interface ISetKonsilStatus {
    readonly type: EKonsilActions.SET_KONSIL_STATUS;
    readonly konsilId: string;
    readonly konsilStatus: keyof typeof KonsilStatusType;
}

export interface ISetKonsilTherapyDringlichkeit {
    readonly type: EKonsilActions.SET_KONSIL_THERAPY_DRINGLICHKEIT;
    readonly konsilId: string;
    readonly therapyDringlichkeit: keyof typeof TherapieDringlichkeit;
}

export interface ISetCurrentKonsilTherapyDringlichkeit {
    readonly type: EKonsilActions.SET_CURRENT_KONSIL_THERAPY_DRINGLICHKEIT;
    readonly therapyDringlichkeit: keyof typeof TherapieDringlichkeit;
}

export interface ISetIsKonsilDeleted {
    readonly type: EKonsilActions.SET_IS_KONSIL_DELETED;
    readonly isKonsilDeleted: boolean;
}

export interface ISetKonsilSortSearchFilterParams {
    readonly type: EKonsilActions.SET_KONSIL_SORT_SEARCH_FILTER_PARAMS;
    readonly konsilSortSearchFilterParams: KonsilSortSearchFilterParams;
}

export interface ISetKonsilStatusFilter {
    readonly type: EKonsilActions.SET_KONSIL_STATUS_FILTER;
    readonly konsilStatusFilter: (keyof typeof KonsilStatusType)[];
}

export interface ISetKonsilSearchString {
    readonly type: EKonsilActions.SET_KONSIL_SEARCH_STRING;
    readonly searchString: string;
}

export interface ISetKonsilValidationStatus {
    readonly type: EKonsilActions.SET_KONSIL_VALIDATION_STATUS;
    readonly konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes;
    readonly konsilValidationError: Failure;
}

export interface ISetIsKonsilUpdatedEventShown {
    readonly type: EKonsilActions.SET_IS_KONSIL_UPDATED_EVENT_SHOWN;
    readonly isKonsilUpdatedEventShown: boolean;
}

export interface ISetIsKonsilUpdateEventSuccess {
    readonly type: EKonsilActions.SET_IS_KONSIL_UPDATE_EVENT_SUCCESS;
    readonly isKonsilUpdateEventSuccess: boolean;
}

export interface ISetIsKonsilUpdateConflict {
    readonly type: EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT;
    readonly isKonsilUpdateConflict: boolean;
}

export interface ISetIsKonsilUpdateConflictDialog {
    readonly type: EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT_DIALOG;
    readonly isKonsilUpdateConflictDialog: boolean;
}

export interface ISetKonsilUpdatedConflictData {
    readonly type: EKonsilActions.SET_KONSIL_UPDATE_CONFLICT_DATA;
    readonly konsilUpdateConflictData: KonsilUpdateConflictData[];
}

export interface ISetKonsilUpdateConflictSelectedData {
    readonly type: EKonsilActions.SET_KONSIL_UPDATE_CONFLICT_SELECTED_DATA;
    readonly konsilUpdateConflictSelectedData: string[];
}

export type TKonsilActionTypes =
    IAddKonsilAction |
    IRemoveKonsilAction |
    ISetKonsileAction |
    ISetKonsilAction |
    ITabChangedAction |
    IUpdateKonsilPatient |
    ISetKonsileTotalSizeAction |
    IAddSendFailure |
    IRemoveSendFailure |
    ISetCdaSizeAction |
    ISetCurrentKonsilBearbeitungsStatus |
    ISetKonsilBearbeitungsStatus |
    ISetKonsilStatus |
    ISetKonsilTherapyDringlichkeit |
    ISetCurrentKonsilTherapyDringlichkeit |
    ISetIsKonsilDeleted |
    ISetKonsilSortSearchFilterParams |
    ISetKonsilStatusFilter |
    ISetKonsilSearchString |
    ISetKonsilValidationStatus |
    ISetIsKonsilUpdatedEventShown |
    ISetIsKonsilUpdateEventSuccess |
    ISetKonsilUpdatedConflictData |
    ISetIsKonsilUpdateConflict |
    ISetIsKonsilUpdateConflictDialog |
    ISetKonsilUpdateConflictSelectedData;
