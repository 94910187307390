import { Failure, HpmServiceStatusType } from 'telescan-core';

export enum EGeneralActions {
    LOADING = "LOADING",
    LOADING_FAILURE = "LOADING_FAILURE",
    LOADING_FAILURE_REMOVE = "LOADING_FAILURE_REMOVE",
    LOADING_SUCCESS = "LOADING_SUCCESS",
    SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE",
    SUCCESS_MESSAGE_REMOVE = "SUCCESS_MESSAGE_REMOVE",
    SCREENSIZE_CHANGED = "SCREENSIZE_CHANGED",
    REFRESH_FAILURE = "REFRESH_FAILURE",
    REFRESH_SUCCESS = "REFRESH_SUCCESS",
    SET_IS_HPM_AVAILABLE = "SET_IS_HPM_AVAILABLE",
    SET_HPM_SERVICE_STATUS = "SET_HPM_SERVICE_STATUS",
    SET_ACCESS_TOKEN_EXPIRATION = "SET_ACCESS_TOKEN_EXPIRATION",
    SET_IS_COMFORT_VERSAND = "SET_IS_COMFORT_VERSAND",
    SET_COMFORT_VERSAND_PASSWORD = "SET_COMFORT_VERSAND_PASSWORD",
    SET_IS_HPM_CONFIG_CHANGED = "SET_IS_HPM_CONFIG_CHANGED",
    SET_IS_INITIALISATION_STATUS_LOADING = "SET_IS_INITIALISATION_STATUS_LOADING",
    SET_HAS_RISK_NOTIFICATION = "SET_HAS_RISK_NOTIFICATION",
    SET_IS_SUPPORT_ENABLED = "SET_IS_SUPPORT_ENABLED",
    SET_IS_PENDING = "SET_IS_PENDING"
}

export interface ILoading {
    readonly type: EGeneralActions.LOADING;
    readonly id: string;
}

export interface ILoadingFailure {
    readonly type: EGeneralActions.LOADING_FAILURE;
    readonly failure: Failure;
    readonly id: string;
}

export interface ILoadingFailureRemove {
    readonly type: EGeneralActions.LOADING_FAILURE_REMOVE;
    readonly id: string;
}

export interface ILoadingSuccess {
    readonly type: EGeneralActions.LOADING_SUCCESS;
    readonly id: string;
}

export interface ISetSuccessMessage {
    readonly type: EGeneralActions.SET_SUCCESS_MESSAGE;
    readonly message: string;
}

export interface ISuccessMessageRemove {
    readonly type: EGeneralActions.SUCCESS_MESSAGE_REMOVE;
}

export interface IRefreshFailure {
    readonly type: EGeneralActions.REFRESH_FAILURE;
    readonly auth_failure: string;
}

export interface IRefreshSuccess {
    readonly type: EGeneralActions.REFRESH_SUCCESS;
}

export interface IScreenSizeChanged {
    readonly type: EGeneralActions.SCREENSIZE_CHANGED;
    readonly isDesktop: boolean;
}

export interface ISetIsHpmAvailable {
    readonly type: EGeneralActions.SET_IS_HPM_AVAILABLE;
    readonly isHpmAvailable: boolean;
}

export interface ISetHpmServiceStatus {
    readonly type: EGeneralActions.SET_HPM_SERVICE_STATUS;
    readonly hpmServiceStatus: keyof typeof HpmServiceStatusType;
}

export interface ISetAccessTokenExpiration {
    readonly type: EGeneralActions.SET_ACCESS_TOKEN_EXPIRATION;
    readonly currentDate: number;
}

export interface ISetIsComfortVersand {
    readonly type: EGeneralActions.SET_IS_COMFORT_VERSAND;
    readonly isComfortVersand: boolean;
}

export interface ISetComfortVersandPassword {
    readonly type: EGeneralActions.SET_COMFORT_VERSAND_PASSWORD;
    readonly comfortVersandPassword: string;
}

export interface ISetIsHpmConfigChanged {
    readonly type: EGeneralActions.SET_IS_HPM_CONFIG_CHANGED;
    readonly isHpmConfigChanged: boolean;
}

export interface ISetIsInitialisationStatusLoading {
    readonly type: EGeneralActions.SET_IS_INITIALISATION_STATUS_LOADING;
    readonly isInitialisationStatusLoading: boolean;
}

export interface ISetHasRiskNotification {
    readonly type: EGeneralActions.SET_HAS_RISK_NOTIFICATION;
    readonly hasRiskNotification: boolean;
}

export interface ISetIsSupportEnabled {
    readonly type: EGeneralActions.SET_IS_SUPPORT_ENABLED;
    readonly isSupportEnabled: boolean;
}

export interface ISetIsPending {
    readonly type: EGeneralActions.SET_IS_PENDING;
    readonly isPending: boolean;
}

export type TGeneralActionTypes = ILoading |
    ILoadingFailure |
    ILoadingFailureRemove |
    ILoadingSuccess |
    ISetSuccessMessage |
    ISuccessMessageRemove |
    IScreenSizeChanged |
    IRefreshFailure |
    IRefreshSuccess |
    ISetIsHpmAvailable|
    ISetHpmServiceStatus |
    ISetAccessTokenExpiration |
    ISetIsComfortVersand |
    ISetComfortVersandPassword |
    ISetIsHpmConfigChanged |
    ISetIsInitialisationStatusLoading |
    ISetHasRiskNotification |
    ISetIsSupportEnabled |
    ISetIsPending;
