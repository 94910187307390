export const kopfBodyMapping = {
    "kopfundhals": "Kopf und Hals",
    "scalp": "Behaarter Kopf (Skalp)",
    "stirn": "Stirn",
    "augenbraue": "Augenbraue",
    "augenlid": "Augenlid",
    "auge": "Auge",
    "ohr": "Ohr",
    "nase": "Nase",
    "wange": "Wange",
    "oberlippe": "Oberlippe",
    "unterlippe": "Unterlippe",
    "mundraum": "Mundhöhle",
    "zunge": "Zunge",
    "kinn": "Kinn",
    "hals": "Hals"
}

export const handBodyMapping = {
    "handruecken": "Handrücken",
    "handflaeche": "Handfläche",
    "finger": "Finger",
    "naegel": "Fingernagel",
    "hand-interdigital": "Interdigital (zwischen den Fingern)",
}

export const fussBodyMapping = {
    "fussruecken": "Fussrücken",
    "fussrand": "Fussrand",
    "sohle": "Fusssohle",
    "zehen": "Zehen",
    "fussnagel": "Fussnagel",
    "fuss-interdigital": "Interdigital (zwischen den Zehen)",
}

export const mainBodyMapping = {
    "extremitaeten": "Extremitäten",
    "rumpf": "Rumpf",
    "schulter": "Schulter",
    "dekoltee": "Dekolleté",
    "oberarm": "Oberarm",
    "unterarm": "Unterarm",
    "abdomen": "Abdomen",
    "leiste": "Leisten",
    "genital": "Genitale",
    "ruecken": "Rücken",
    "gesaess": "Gesäß",
    "anus": "Anus",
    "oberschaenkel-ventral": "Oberschenkel ventral (bauchseitig)",
    "oberschaenkel-dorsal": "Oberschenkel dorsal (rückenseitig)",
    "knie": "Knie",
    "kniekehle": "Kniekehle",
    "schienbein": "Schienbein",
    "wade": "Wade"
}

export const MenuItems = {
    "Kopf und Hals": kopfBodyMapping,
    "Rumpf": mainBodyMapping,
    "Hand": handBodyMapping,
    "Fuß": fussBodyMapping
}
